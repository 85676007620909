import { useEffect, useState } from 'react';
import {
	Badge,
	Form,
	ListGroup,
	ListGroupItem,
} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { Panel } from './Panel';

export interface SimpleFacetMenuOption {
	value: string;
	label: React.ReactNode;
	count?: number | string;
	default?: boolean;
	/**
	 * Custom filter to apply to the facet when this option is selected
	 */
	customFilter?: string;
}

export interface SimpleFacetMenuProps {
	title: React.ReactNode;
	prop: string;
	displayValue?: (value: string) => React.ReactNode;
	options?: SimpleFacetMenuOption[];
	searchResult?: Record<string, number>;
	searchable?: boolean;
	supportNegation?: boolean;
	canSelectMultiple?: boolean;
}

/**
 * SIMPLE Facet menu, just changes a search parameter
 */
export const SimpleFacetMenu = ({
	title, prop, options, searchResult, searchable, supportNegation, displayValue, canSelectMultiple = false,
}: SimpleFacetMenuProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [facetOptions, setFacetOptions] = useState<SimpleFacetMenuOption[]>([]);
	const [longPressTimer, setLongPressTimer] = useState<NodeJS.Timeout | null>(null);
	const [flashNegation, setFlashNegation] = useState<string | null>(null);
	const [isLongPress, setIsLongPress] = useState(false);

	// Get the current selected values as an array
	const getSelectedValues = (paramName: string): string[] => {
		const value = searchParams.get(paramName);
		return value ? value.split(',') : [];
	};

	// Get the count of selected values
	const getSelectedCount = (): number => {
		return getSelectedValues(prop).length;
	};

	useEffect(() => {
		if (options) {
			const optionsWithCount: SimpleFacetMenuOption[] = options.map((option) => ({
				...option,
				count: option.count || searchResult?.[option.value],
			}));
			setFacetOptions(optionsWithCount);

			// Automatically select the default option if specified
			const defaultOption = options.find((option) => option.default);
			if (defaultOption && !searchParams.get(prop)) {
				setSearchParams((prev) => {
					prev.set(prop, defaultOption.value);
					return prev;
				});
			}
		} else if (searchResult) {
			// Combine normal and negated facet values
			const combinedResults = { ...searchResult };
			const negatedResults = Object.entries(searchResult)
				.filter(([value]) => searchParams.get(`!${prop}`) === value)
				.reduce((acc, [value, count]) => ({ ...acc, [value]: count }), {});

			Object.assign(combinedResults, negatedResults);

			const searchResultOptions: SimpleFacetMenuOption[] = Object.entries(combinedResults)
				.map(([value, count]) => ({
					value,
					label: displayValue ? displayValue(value) : value,
					count,
				}))
				.sort((a, b) => (b.count as number) - (a.count as number));

			searchResultOptions.unshift({
				value: '',
				label: 'All',
			});
			setFacetOptions(searchResultOptions);
		}
	}, [searchResult, options, searchParams]);

	const handleMouseDown = (value: string) => {
		if (!supportNegation || value === '') return; // Prevent negation for "All" option

		setIsLongPress(false);
		setLongPressTimer(setTimeout(() => {
			setIsLongPress(true);
			setFlashNegation(value);
		}, 500));
	};

	const handleMouseUp = (option: SimpleFacetMenuOption) => {
		if (longPressTimer) {
			clearTimeout(longPressTimer);
			setLongPressTimer(null);
		}

		// Apply the filter
		setSearchParams((prev) => {
			if (option.value === '') {
				// If "All" is selected, clear the filter
				prev.delete(prop);
				prev.delete(`!${prop}`);
			} else if (isLongPress && supportNegation) {
				// Handle negation
				prev.delete(prop);
				prev.set(`!${prop}`, option.value);
			} else {
				// Handle normal selection
				const currentValues = getSelectedValues(prop);

				// If the value is already selected, remove it
				if (currentValues.includes(option.value)) {
					if (currentValues.length === 1) {
						// If it's the only value, remove the parameter
						prev.delete(prop);
					} else {
						// Otherwise, remove just this value
						const newValues = currentValues.filter((v) => v !== option.value);
						prev.set(prop, newValues.join(','));
					}
				} else if (currentValues.length > 0 && canSelectMultiple) {
					// Add to existing values
					currentValues.push(option.value);
					prev.set(prop, currentValues.join(','));
				} else {
					// Set as the first value
					prev.set(prop, option.value);
				}

				// Always clear negation when making a positive selection
				prev.delete(`!${prop}`);
			}
			return prev;
		});

		// Reset states
		setFlashNegation(null);
		setIsLongPress(false);
	};

	const handleMouseLeave = () => {
		if (longPressTimer) {
			clearTimeout(longPressTimer);
			setLongPressTimer(null);
		}
		setFlashNegation(null);
		setIsLongPress(false);
	};

	const selectedCount = getSelectedCount();

	return (
		<Panel header={
			<div className="d-flex justify-content-between align-items-center">
				{title}
				{selectedCount > 1 && (
					<Badge bg="info" pill>
						{selectedCount}
					</Badge>
				)}
			</div>
		}>
			{searchable && <Form.Control
				type="text"
				placeholder="Search"
				onChange={(e) => {
					setSearchParams((prev) => {
						if (e.target.value) {
							prev.set(prop, `${e.target.value}*`);
						} else {
							prev.delete(prop);
						}
						return prev;
					});
				}}
			/>}
			<ListGroup className="mb-4">
				{facetOptions?.map((option, index) => {
					const selectedValues = getSelectedValues(prop);
					const selected = option.value === ''
						? selectedValues.length === 0
						: selectedValues.includes(option.value);
					const negated = (searchParams.get(`!${prop}`) || '!') === option.value;
					const isAll = option.value === '';

					return (
						<ListGroupItem
							key={`${prop}-${option.value}-${index}`}
							className={`list-group-item-action
								${selected ? 'active' : ''}
								${negated ? 'active bg-danger' : ''}
								${!isAll && flashNegation === option.value ? 'bg-danger-subtle' : ''}`}
							style={{ cursor: 'pointer' }}
							onMouseDown={() => handleMouseDown(option.value)}
							onMouseUp={() => handleMouseUp(option)}
							onMouseLeave={handleMouseLeave}
						>
							{option.label}
							{option.count != null && <Badge
								className="float-end"
								bg={selected || negated ? 'light' : 'secondary'}
								text={selected || negated ? 'dark' : 'light'}
							>{option.count}</Badge>}
						</ListGroupItem>
					);
				})}
			</ListGroup>
		</Panel>
	);
};
