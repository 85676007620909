import { z } from 'zod';

import { BaseObjectSchema } from './base';
import { DeliverySchema } from './delivery';
import { ReferenceSchema } from './reference';
import { StatusChoiceSchema } from './status';

/**
 * Schema for distributor report settings
 */
export const DistributorReportSettingsSchema = z.object({
	frequency: z.enum(['Weekly', 'Monthly'])
		.describe('Frequency of the report'),
	emails: z.array(z.string().email())
		.describe('List of email addresses to receive the report'),
	enabled: z.boolean()
		.describe('Whether the report is enabled'),
}).describe('Configuration for distributor reports');

export type DistributorReportSettings = z.infer<typeof DistributorReportSettingsSchema>;

/**
 * Schema for a distributor in the system
 */
export const DistributorSchema = BaseObjectSchema.extend({
	$type: z.literal('Distributor'),

	code: z.string().optional()
		.describe('Short code for this distributor, used primarily for AI Score references and story counts'),

	alt_names: z.array(z.string()).optional()
		.describe('Alternative names for this distributor'),

	recipient_name: z.string().optional()
		.describe('Recipient name used in StoryCounts TimeStream table'),

	newscrunch_id: z.number().int().optional()
		.describe('Legacy NewsCrunch ID for the distributor'),

	candidates_reports: DistributorReportSettingsSchema
		.describe('Report on New Publications offered to the distributor'),

	inventory_reports: DistributorReportSettingsSchema
		.describe('Report on the Publications currently or previously subscribed to by the distributor'),

	status: StatusChoiceSchema
		.describe('Status of the distributor'),

	products: z.array(z.string())
		.describe('Products directly associated with this distributor, calculated based on associated Deliveries'),

	qualification_criteria: z.string()
		.describe('AI Qualification criteria used to determine publication scores for this distributor'),

	deliveries: z.array(ReferenceSchema<z.infer<typeof DeliverySchema>>()).optional()
		.describe('Associated deliveries, calculated specifically for Search Indexing'),
}).describe('A distributor in the system');

export type Distributor = z.infer<typeof DistributorSchema>;

export const isDistributor = (item: any, typeHint?: string): item is Distributor => {
	return typeHint ? typeHint === 'Distributor' : item.$type === 'Distributor';
};
