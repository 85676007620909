import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalFields } from '~/commands/type';
import { useAIChat } from '~/providers/ai-chat-provider';
import { useAI } from '~/providers/ai-provider';
import { useUserInfo } from '~/providers/user-info';
import { TOOLS } from '~/routes/tools';

import { CreatePublicationHubSpotModal } from '../create-publication-hubspot-modal';
import ModalDataEditor from '../modal-data-editor';
import { RequirePermissions } from '../require-permissions';

import {
	MenuLink,
	SidebarFooter,
	SidebarHeader,
	SidebarProps,
} from './shared';

export default function Sidebar({ sidebarShrink }: SidebarProps) {
	const [modalData, setModalData] = useState<ModalFields | null>(null);
	const [criticalAlerts, setCriticalAlerts] = useState<number | null>(null);
	const [showAddPublication, setShowAddPublication] = useState(false);
	const userInfo = useUserInfo();
	const navigate = useNavigate();
	const ai = useAI();
	const aiChat = useAIChat();

	useEffect(() => {
		setCriticalAlerts(userInfo?.alerts?.length || null);
	}, [userInfo?.alerts]);

	return (<>
		<ModalDataEditor
			modalData={modalData}
			setModalData={setModalData}
		/>
		<CreatePublicationHubSpotModal
			show={showAddPublication}
			onClose={(success) => {
				setShowAddPublication(false);
			}}
		/>
		<div
			className={`sidebar ${sidebarShrink ? 'shrink show' : ''}`}
			id="sidebar"
		>
			<div className="sidebar-content">
				<SidebarHeader sidebarShrink={sidebarShrink} />

				<div className="menu-section">
					<ul className="list-unstyled">
						<li className="sidebar-list-item active">
							<MenuLink
								to="/"
								icon="grid-2"
								title="Dashboard"
								tooltip="This is your main dashboard. It shows a summary of recent activity."
								id="home"
								sidebarShrink={sidebarShrink} />
						</li>

						<RequirePermissions permissions={['admin']}>
							<RequirePermissions permissions={['newscrunch']}>
								<li className="nav-item">
									<MenuLink
										to="/newscrunch"
										icon="file-invoice-dollar"
										title="Newscrunch"
										tooltip="Newscrunch is the Newstex accounting system."
										id="newscrunch"
										sidebarShrink={sidebarShrink} />
								</li>
							</RequirePermissions>
							<li className="nav-item">
								<MenuLink
									to="/docs/kb"
									icon="file-circle-info"
									title="Knowledge Base"
									tooltip="The Newstex Knowledge Base is a collection of articles and guides that can help you get started with Newstex."
									id="docs-kb"
									sidebarShrink={sidebarShrink}
								/>
							</li>
							<li className="nav-item">
								<MenuLink
									to="/alerts"
									icon="bell"
									title="Alerts"
									tooltip="Alerts are periodic searches that can be configured to send you notifications when certain thresholds are met (or not met)."
									id="alerts"
									badge={criticalAlerts}
									badgeColor="danger"
									sidebarShrink={sidebarShrink} />
							</li>
							<li>
								<MenuLink
									to="/users"
									icon="user"
									title="Users"
									tooltip="Users are the people who use Newstex. They can be assigned to one or more authorization groups. They usually belong to one or more Publishers and/or Distributors, or Newstex Administrators."
									id="users"
									sidebarShrink={sidebarShrink}
								/>
							</li>
						</RequirePermissions>
					</ul>
				</div>

				<RequirePermissions permissions={['admin', 'publisher', 'distributor']}>
					<div className="content-section">
						<h6 className="sidebar-heading">Content</h6>
						<ul className="list-unstyled">
							<RequirePermissions permissions={['admin', 'distributor']}>
								<li className="nav-item">
									<MenuLink to="/publishers" icon="briefcase" title="Publishers" tooltip="Groups of Publications" id="publishers" sidebarShrink={sidebarShrink} />
								</li>
								<li className="nav-item">
									<MenuLink to="/publications" icon="file-lines" title="Publications" tooltip="Publishes multiple stories" id="publications" sidebarShrink={sidebarShrink} />
								</li>
							</RequirePermissions>
							<RequirePermissions permissions={['admin']}>
								<li>
									<MenuLink
										to="/feeds?status=Active"
										icon="signal-stream"
										title="Feeds"
										tooltip="Feeds are how Newstex gets stories from Publishers. Includes RSS, Atom, and other syndication formats."
										id="feeds"
										sidebarShrink={sidebarShrink}
									/>
								</li>
							</RequirePermissions>
							<li>
								<MenuLink
									to="/stories"
									icon="book-open"
									title="Stories"
									tooltip="Stories are the individual articles or posts published by Publishers."
									id="story"
									sidebarShrink={sidebarShrink}
								/>
							</li>
							<RequirePermissions permissions={['admin']}>
								<li>
									<MenuLink
										to="/categories"
										icon="folder"
										title="Categories"
										tooltip="Categories are assigned by Newstex to individual stories. They are based on the story's content."
										id="category"
										sidebarShrink={sidebarShrink}
									/>
								</li>
							</RequirePermissions>
							{userInfo?.auth_groups?.includes('publisher') && (
								<li>
									<MenuLink
										id="add-publication"
										to="#"
										icon="plus"
										title="Add Publication"
										tooltip="Use this to submit a new publication to Newstex."
										sidebarShrink={sidebarShrink}
										onClick={(e) => {
											e.preventDefault();
											setShowAddPublication(true);
											(e.currentTarget as HTMLElement).blur();
											return false;
										}}
									/>
								</li>
							)}
						</ul>
					</div>
				</RequirePermissions>

				<RequirePermissions permissions={['admin']}>
					<div className="distribution-section">
						<h6 className="sidebar-heading">Dist{sidebarShrink ? '' : 'ribution'}</h6>
						<ul className="list-unstyled">
							<li className="nav-item">
								<MenuLink
									to="/distributors"
									icon="users"
									title="Distributors"
									tooltip="Distributors are companies that receive Newstex content and distribute it to their customers. A single Distributor may have multiple Deliveries."
									id="distributors"
									sidebarShrink={sidebarShrink}
								/>
							</li>
							<li>
								<MenuLink
									to="/products"
									icon="box"
									title="Products"
									tooltip="Products are groups of stories that are distributed to Distributors. Products are assigned to the Publication level."
									id="products"
									sidebarShrink={sidebarShrink}
								/>
							</li>
							<li>
								<MenuLink
									to="/delivery?status=Active"
									icon="truck"
									title="Deliveries"
									tooltip="Deliveries are how Newstex sends stories to Distributors. Deliveries are assigned one or more product, and always belong to exactly one Distributor."
									id="delivery"
									sidebarShrink={sidebarShrink}
								/>
							</li>
						</ul>
					</div>
				</RequirePermissions>

				<div className="tools-section">
					<h6 className="sidebar-heading">Tools</h6>
					<ul className="list-unstyled">
						{TOOLS.filter((tool) => tool.public).map((tool) => (
							<li key={tool.id}>
								<MenuLink
									to={`#${tool.id}`}
									icon={tool.icon}
									title={tool.name}
									id={tool.id}
									tooltip={tool.description}
									sidebarShrink={sidebarShrink}
									onClick={(e) => {
										e.preventDefault();
										console.log('clicked', tool);
										tool.handler({
											setModalData,
											navigate,
											ai,
											aiChat,
										});
										(e.currentTarget as HTMLElement).blur();
										return false;
									}}
								/>
							</li>
						))}
					</ul>
				</div>

				<RequirePermissions permissions={['admin']}>
					<div className="admin-tools-section">
						<h6 className="sidebar-heading">Admin Tools</h6>
						<ul className="list-unstyled">
							{TOOLS.filter((tool) => !tool.public).map((script) => (
								<li key={script.id}>
									<MenuLink
										to={`#${script.id}`}
										icon={script.icon}
										title={script.name}
										tooltip={script.description}
										id={script.id}
										sidebarShrink={sidebarShrink}
										onClick={(e) => {
											e.preventDefault();
											console.log('clicked', script);
											script.handler({
												setModalData,
												navigate,
												ai,
												aiChat,
											});
											(e.currentTarget as HTMLElement).blur();
											return false;
										}}
									/>
								</li>
							))}
							<li>
								<MenuLink
									to="/reports"
									icon="file-invoice-dollar"
									title="Reports"
									tooltip="Reports are a way to view and analyze your data. They are used to query the SQL database."
									id="reports"
									sidebarShrink={sidebarShrink}
								/>
							</li>
							<li>
								<MenuLink
									to="/dashboards" icon="dashboard" title="Dashboards"
									tooltip="Dashboards are a custom collection of Reports."
									id="dashboards"
									sidebarShrink={sidebarShrink}
								/>
							</li>
						</ul>
					</div>
				</RequirePermissions>

				<RequirePermissions permissions={['admin']}>
					<div className="labs-section">
						<h6 className="sidebar-heading">Labs</h6>
						<ul className="list-unstyled">
							<li>
								<MenuLink
									to="/sites"
									icon="project-diagram"
									title="Sites"
									tooltip="Special Newstex blogs, usually with AI Generated content."
									id="sites"
									sidebarShrink={sidebarShrink} />
							</li>
							<li>
								<MenuLink
									to="/subscribers"
									icon="users"
									title="Newsletter Subscribers"
									tooltip="Email addresses of people who have subscribed to a newsletter."
									id="subscribers"
									sidebarShrink={sidebarShrink} />
							</li>
						</ul>
					</div>
				</RequirePermissions>

				<SidebarFooter sidebarShrink={sidebarShrink} />
			</div>
		</div>
	</>);
}
