// Stories grouped by publication
import { faDownload, faEdit, faStop } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchResults } from '@newstex/search/client';
import { Publication } from '@newstex/types/content';
import { Story } from '@newstex/types/story';
import { createColumnHelper } from '@tanstack/react-table';
import omit from 'object.omit';
import { useRef, useState } from 'react';
import {
	Button,
	Form,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateEditorialNoteModal } from '~/components/create-editorial-note-modal';
import { DateTime } from '~/components/date-time';
import { GroupSelectionActions } from '~/components/group-selection-actions';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { LoadMoreFunction } from '~/hooks/use-typesense-search';
import { formatDateString, generateFileNameFromFilters } from '~/lib/utils';

import DataTable, { DataTableMethods } from '../data-table';
import { LoadMoreButton } from '../load-more-button';
import { RequirePermissions } from '../require-permissions';

export const StoriesByPublicationTable: React.FC<{
	groupedHits: SearchResults<Story>['grouped_hits'],
	hasMore: boolean,
	onLoadMore: LoadMoreFunction<Story>,
}> = ({
	groupedHits,
	hasMore,
	onLoadMore,
}) => {
	const [searchParams] = useSearchParams();
	const [editingPublication, setEditingPublication] = useState<Partial<Publication> | null>(null);
	const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
	const dataTableRef = useRef<DataTableMethods>(null);

	const columnHelper = createColumnHelper<{
		group_key: string[];
		found: number;
		hits: Story[];
		Publication?: Publication;
	}>();

	const columns = [
		columnHelper.display({
			id: 'selection',
			size: 10,
			meta: {
				priority: 6,
			},
			header: () => (
				<RequirePermissions permissions={['admin']}>
					<GroupSelectionActions
						selectedGroups={selectedGroups}
						groupKey="publication"
						onClearSelection={() => setSelectedGroups([])}
						onSelectAll={() => setSelectedGroups(groupedHits?.map((g) => g.group_key[0]) || [])}
					/>
				</RequirePermissions>
			),
			cell: ({ row }) => (
				<RequirePermissions permissions={['admin']}>
					<Form.Check
						type="checkbox"
						checked={selectedGroups.includes(row.original.group_key[0])}
						onChange={(e) => {
							if (e.target.checked) {
								setSelectedGroups([...selectedGroups, row.original.group_key[0]]);
							} else {
								setSelectedGroups(selectedGroups.filter((g) => g !== row.original.group_key[0]));
							}
						}}
					/>
				</RequirePermissions>
			),
		}),
		columnHelper.accessor('hits', {
			id: 'publication',
			meta: {
				priority: 1,
			},
			header: 'Publication',
			cell: ({ row }) => (
				<>
					<Link to={`/publications/${row.original.group_key[0]}`}>
						{row.original.hits?.[0]?.Publication?.name || row.original.group_key[0]}
						{row.original.hits?.[0]?.Publication?.newstex_id && ` [${row.original.hits?.[0]?.Publication?.newstex_id}]`}
					</Link>
					{row.original.hits?.[0]?.Publication?.Publisher?.name && (
						<div className="text-muted">
							<small>
								{row.original.hits[0].Publication.Publisher.name} {row.original.hits[0].Publication.Publisher.newstex_id && `[${row.original.hits[0].Publication.Publisher.newstex_id}]`}
							</small>
						</div>
					)}
				</>
			),
		}),
		columnHelper.accessor('found', {
			header: 'Stories',
			meta: {
				priority: 2,
			},
			cell: ({ row }) => (
				<Link to={`?${new URLSearchParams({
					...omit(Object.fromEntries(searchParams.entries()), ['group_by', 'sort_by', 'page']),
					sort_by: 'received_at:desc',
					[searchParams.get('group_by') || 'group']: row.original.group_key[0],
				})}`}>{row.original.found}</Link>
			),
		}),
		columnHelper.accessor('hits', {
			id: 'recent_date',
			meta: {
				priority: 3,
			},
			header: 'Most Recent Publication Date',
			cell: ({ row }) => <DateTime ts={row.original.hits?.[0]?.received_at} dateOnly />,
		}),
		columnHelper.accessor('hits', {
			id: 'monthly_revenue',
			meta: {
				priority: 4,
			},
			header: 'Average Monthly Revenue',
			cell: ({ row }) => (
				<RequirePermissions permissions={['admin']}>
					{row.original.hits?.[0]?.Publication?.average_monthly_revenue?.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
					})}
				</RequirePermissions>
			),
		}),
		columnHelper.accessor('hits', {
			id: 'last_reviewed',
			meta: {
				priority: 5,
			},
			header: 'Last Reviewed',
			cell: ({ row }) => (
				<RequirePermissions permissions={['admin']}>
					<>
						{row.original.hits?.[0]?.Publication?.last_editorial_review && (
							<>
								<div>
									<DateTime ts={row.original.hits[0].Publication.last_editorial_review} dateOnly />
									{row.original.hits[0].Publication.reviewed_by && (
										<span className="text-muted">
											&nbsp;&mdash; <PropertyDisplayValue propValue={row.original.hits[0].Publication.reviewed_by} propName="reviewed_by" />
										</span>
									)}
								</div>
								{row.original.hits[0].Publication.last_review_comment && (
									<div className="comment">
										{row.original.hits[0].Publication.last_review_comment}
									</div>
								)}
							</>
						)}
						{row.original.hits[0].Publication && (
							<div className="float-end">
								<OverlayTrigger
									placement="left"
									overlay={<Tooltip>Edit publication notes</Tooltip>}
								>
									<Button variant="link" onClick={() => {
										setEditingPublication({
											$type: 'Publication',
											...row.original.hits[0].Publication,
										});
									}}>
										<FontAwesomeIcon icon={faEdit} />
									</Button>
								</OverlayTrigger>
							</div>
						)}
					</>
				</RequirePermissions>
			),
		}),
	];

	return (<>
		{editingPublication && (
			<CreateEditorialNoteModal
				publication={editingPublication}
				show={!!editingPublication}
				onHide={() => setEditingPublication(null)}
				onSave={() => {
					window.location.reload();
				}}
			/>
		)}

		<DataTable
			columns={columns}
			items={groupedHits || []}
			ref={dataTableRef}
			defaultSort="recent_date"
			striped
			sortable
		/>
		<LoadMoreButton show={hasMore} onClick={onLoadMore} />
		<div className="float-end pb-2">
			<Button variant="outline-secondary" size="sm" onClick={async () => {
				const controller = new AbortController();
				if (hasMore) {
					toast.info(<div className="d-flex align-items-center w-100">
						<div className="flex-grow-1">Loading all results...</div>
						<Button variant="danger" size="sm" className="ms-3" onClick={() => {
							controller.abort();
						}}>
							<FontAwesomeIcon icon={faStop} className="me-2" />
							Stop
						</Button>
					</div>, {
						toastId: 'loading-results-story-publication-table',
						autoClose: false,
						closeOnClick: false,
						closeButton: false,
						isLoading: true,
					});
				}
				// Load all results first
				let nextPage = 2;
				while (hasMore && onLoadMore && !controller.signal.aborted) {
					console.log('Loading more');
					const resp = await onLoadMore(nextPage);
					console.log('Loaded more', resp);
					toast.update('loading-results-story-publication-table', {
						render: (
							<div className="d-flex align-items-center w-100">
								<div className="flex-grow-1">Loaded {resp.page * resp.request_params.per_page} / {resp.found}</div>
								<Button variant="danger" size="sm" className="ms-3" onClick={() => {
									controller.abort();
								}}>
									<FontAwesomeIcon icon={faStop} className="me-2" />
							Stop
								</Button>
							</div>
						),
						isLoading: true,
						progress: (resp.page * resp.request_params.per_page) / resp.found,
					});
					nextPage = resp.page + 1;
					if (!resp.hasMore || !resp.grouped_hits?.length) {
						break;
					}
				}
				toast.update('loading-results-story-publication-table', {
					render: 'Loaded all results',
					isLoading: false,
					autoClose: 2000,
					progress: 1,
				});

				dataTableRef.current?.exportToCSV(generateFileNameFromFilters(searchParams, 'Stories'), {
					Publication: (item) => item.hits?.[0]?.Publication?.name || item.group_key[0],
					'Publication Newstex ID': (item) => item.hits?.[0]?.Publication?.newstex_id || '',
					'Stories Count': 'found',
					'Most Recent Publication Date': (item) => formatDateString(item.hits?.[0]?.received_at),
					'Average Monthly Revenue': (item) => item.hits?.[0]?.Publication?.average_monthly_revenue,
					'Last Reviewed': (item) => formatDateString(item.hits?.[0]?.Publication?.last_editorial_review),
					'Reviewed By': (item) => item.hits?.[0]?.Publication?.reviewed_by,
					'Review Comment': (item) => item.hits?.[0]?.Publication?.last_review_comment,
				});
			}}>
				<FontAwesomeIcon icon={faDownload} /> CSV
			</Button>
		</div>
	</>);
};
