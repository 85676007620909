import {
	BarController,
	BarElement,
	CategoryScale,
	Chart,
	Legend,
	LineController,
	LinearScale,
	PointElement,
	Tooltip,
} from 'chart.js';
import React, { useEffect, useRef } from 'react';

import { colors } from './defaults';

interface Dataset {
	label: string;
	data: number[];
	backgroundColor?: string;
	borderColor?: string;
	type?: 'bar' | 'line';
	lineWidth?: number;
}

interface BarChart2Props {
	className?: string;
	labels: string[];
	datasets: Dataset[];
	stacked?: boolean;
	dollar?: boolean;
	hideX?: boolean;
	grid?: boolean;
	min?: number;
	max?: number;
	legend?: {
		display?: boolean;
		position?: 'top' | 'bottom' | 'left' | 'right';
	};
}

export default function BarChart2({
	className,
	labels,
	datasets,
	stacked = false,
	dollar,
	hideX,
	grid,
	min,
	max,
	legend,
}: BarChart2Props) {
	const chartRef = useRef<HTMLCanvasElement>(null);
	const options: any = {
		plugins: {
			legend: legend || {
				display: true,
				position: 'top' as const,
			},
			tooltip: {
				mode: 'index',
				intersect: false,
				callbacks: {
					label: (TooltipItem: any) => {
						if (dollar) {
							let tooltipLabelString = TooltipItem.dataset.label || '';
							if (tooltipLabelString) {
								tooltipLabelString += ': ';
							}
							tooltipLabelString += `$${TooltipItem.formattedValue}`;
							return tooltipLabelString;
						}
						return `${TooltipItem.dataset.label}: ${TooltipItem.formattedValue}`;
					},
				},
			},
		},
		scales: {
			x: {
				stacked,
				grid: {
					display: false,
					border: false,
				},
				ticks: {
					fontSize: 12,
					color: '#97a4af',
					padding: 5,
				},
				categoryPercentage: 0.5,
				maxBarThickness: 15,
			},
			y: {
				stacked,
				grid: {
					color: '#e9ecef',
					drawBorder: false,
					zeroLineColor: '#e9ecef',
				},
				ticks: {
					beginAtZero: true,
					stepSize: 100,
					fontSize: 12,
					color: '#97a4af',
					padding: 10,
				},
			},
		},
	};

	useEffect(() => {
		Chart.register(
			LineController,
			CategoryScale,
			BarController,
			BarElement,
			PointElement,
			LinearScale,
			Tooltip,
			Legend,
		);

		const data = {
			labels,
			datasets: datasets.map((dataset, index) => {
				const colorKeys = Object.keys(colors) as (keyof typeof colors)[];
				const colorKey = colorKeys[index % colorKeys.length];
				return {
					...dataset,
					backgroundColor: dataset.backgroundColor || colors[colorKey],
					borderColor: dataset.borderColor || dataset.backgroundColor || colors[colorKey],
					borderWidth: dataset.type === 'line' ? 2 : 0,
					barPercentage: 0.5,
					lineWidth: dataset.lineWidth || (dataset.type === 'line' ? 2 : 0),
					order: dataset.type === 'line' ? 1 : 2,
				};
			}),
		};

		Chart.defaults.font.family = 'Poppins, system-ui, -apple-system, Roboto, Arial, system-ui, -apple-system, sans-serif';
		Chart.defaults.plugins.tooltip.padding = 10;
		Chart.defaults.plugins.tooltip.backgroundColor = 'rgba(0, 0, 0, 0.7)';
		Chart.defaults.scale.ticks.color = 'rgba(0, 0, 0, 0.4)';

		console.log('**CHART**', {
			data,
			options,
		});

		const chart = new Chart(chartRef.current!, {
			type: 'bar',
			options,
			data,
		});

		return () => chart.destroy();
	}, [labels, datasets, stacked, options]);

	return <canvas className={className} ref={chartRef} />;
}
