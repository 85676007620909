import { AccountInfoResponse } from '@newstex/types/responses/info';
import { UserGroup } from '@newstex/types/user';
import React from 'react';
import { useUserInfo } from '~/providers/user-info';
import { useCacheStore } from '~/stores/cache-store';

interface RequirePermissionsProps {
	permissions: UserGroup[];
	children: React.ReactNode;
	fallback?: React.ReactNode;
}

export function RequirePermissions({ permissions, children, fallback }: RequirePermissionsProps) {
	const userInfo = useUserInfo();

	// Check if the user has all required permissions
	const hasPermissions = hasPermission(permissions, userInfo);

	// Render children only if the user has all required permissions
	return hasPermissions ? <>{children}</> : <>{fallback}</>;
}

export function hasPermission(permissions: UserGroup[], userInfo?: AccountInfoResponse | null) {
	const cacheStore = useCacheStore();
	// NOTE: Can't just use `useUserInfo` because this is also used in non-react contexts (e.g. in `revenue-stats.tsx`)
	if (!userInfo) {
		userInfo = cacheStore.getItem('userInfo');
	}

	if (!userInfo) {
		return false;
	}
	return permissions.some((permission) => userInfo?.auth_groups?.includes(permission));
}
