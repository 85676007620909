import { faChevronUp, faDownload } from '@fortawesome/free-solid-svg-icons';
import { faChevronsRight } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Publication, Publisher } from '@newstex/types/content';
import type { PublicationInfo } from '@newstex/types/responses/info';
import type { RevenueAndEarningsForContact } from '@newstex/types/revenue';
import { createColumnHelper } from '@tanstack/react-table';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import {
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	OverlayTrigger,
	Row,
	Tooltip,
} from 'react-bootstrap';
import { Step } from 'react-joyride';
import BarChart, { BarChartProps } from '~/components/charts/BarChart';
import DataTable, { DataTableMethods } from '~/components/data-table';
import { PublisherSelect } from '~/components/editors/publisher-select';
import LoadingSpinner from '~/components/LoadingSpinner';
import MyPubs from '~/components/my-pubs';
import { PageTitle } from '~/components/page-title';
import KPIStatsWidget from '~/components/Widgets/KPIStats';
import { formatDatePeriod, formatDateYYYYMMDD } from '~/lib/utils';
import { useAnalytics } from '~/providers/analytics-provider';
import { useAPI } from '~/providers/api-provider';
import { useSearch } from '~/providers/search';
import { useUserInfo } from '~/providers/user-info';
import { getPublisherDashboardSteps } from '~/tutorials/publishers/dashboard';

export function RevenueChart({ data }: { data: RevenueAndEarningsForContact }) {
	// Short circuit if there's no revenue data
	if (!data.earnings?.length || data.earnings.length === 0) {
		return <h4 className="text-center">No revenue data available</h4>;
	}

	const [stats, setStats] = useState<BarChartProps['datasets']>([]);
	const [dataLabels, setLabels] = useState<BarChartProps['labels']>([]);

	useEffect(() => {
		const periods: string[] = [];

		// Find the newest date in the revenue data
		const startDate = new Date(Math.max(...data.earnings.map((row) => new Date(`${row.period}T00:00:00`).getTime())));
		const currentDate = new Date(`${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-01T00:00:00`);

		// Calculate half-year periods for the last 10 years (20 periods)
		const numPeriods = 20;
		const currentHalfYear = currentDate.getMonth() < 6 ? 1 : 2;
		currentDate.setMonth(currentHalfYear === 1 ? 0 : 6); // Set to start of current half-year

		for (let i = 0; i < numPeriods; i++) {
			const year = currentDate.getFullYear();
			const halfYear = currentDate.getMonth() === 0 ? 1 : 2;
			periods.unshift(`${year}-H${halfYear}`);
			currentDate.setMonth(currentDate.getMonth() - 6);
		}

		const totalsByPeriod: Record<string, number> = {};

		// Calculate total earnings for each half-year period
		for (const row of data.earnings) {
			const month = parseInt(row.period.slice(5, 7), 10);
			const halfYear = month <= 6 ? 1 : 2;
			const period = `${row.period.slice(0, 4)}-H${halfYear}`;

			if (!totalsByPeriod[period]) {
				totalsByPeriod[period] = 0;
			}

			const earnings = row.earnings || 0;
			totalsByPeriod[period] += earnings;
		}

		// Add bar chart
		setStats([
			// 1st half
			{
				label: 'H1 (Jan-Jun)',
				data: periods.map((period) => {
					if (period.slice(5) === 'H1') {
						return totalsByPeriod[period] || 0;
					}
					return 0;
				}),
				backgroundColor: '#2563EB',
				order: 1,
			},
			// 2nd half
			{
				label: 'H2 (Jul-Dec)',
				data: periods.map((period) => {
					if (period.slice(5) === 'H2') {
						return totalsByPeriod[period] || 0;
					}
					return 0;
				}),
				backgroundColor: '#38BDF8',
				order: 2,
			},
		]);

		// Check if the most recent period is the current half-year
		const mostRecentPeriod = periods[periods.length - 1];
		const isPartialPeriod = mostRecentPeriod === `${startDate.getFullYear()}-H${startDate.getMonth() < 6 ? 1 : 2}`;

		setLabels(periods.map((period) => ({
			name: `${period.slice(0, 4)} ${period.slice(5) === 'H1' ? 'Jan-Jun' : 'Jul-Dec'}${
				period === mostRecentPeriod && isPartialPeriod ? '*' : ''
			}`,
			backgroundColor: period === mostRecentPeriod && isPartialPeriod ? 'rgba(0,0,0,0.1)' : undefined,
		})));
	}, [data]);

	return (<>
		<div className="d-flex mb-3 w-100">
			<small className="d-flex ms-auto justify-content-center align-items-center text-muted me-2">
				The chart below shows your revenue for the last 10 years by 6 month periods.
				{stats.length > 0 && <span className="ms-1">* Indicates partial period</span>}
			</small>
		</div>
		{stats.length > 0 && <BarChart
			id="revenue-chart"
			title="Earnings History"
			labels={dataLabels}
			datasets={stats}
			stacked={true}
			legendPosition="bottom"
			formatValue={(value, isAxis) => `$${value.toLocaleString('en-US', {
				minimumFractionDigits: isAxis ? 0 : 2,
				maximumFractionDigits: isAxis ? 0 : 2,
			})}`}
		/>}
	</>);
}

interface RevenueTableProps {
	data: RevenueAndEarningsForContact;
	handleDownloadStatement: (period: string) => void;
}

export function RevenueTable({ data, handleDownloadStatement }: RevenueTableProps) {
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<any>();
	const [isExpanded, setIsExpanded] = useState(false);
	const analytics = useAnalytics();

	// Process data for table display
	const tableData = useMemo(() => {
		if (!data.clientEarnings?.length) return [];

		// Create a map of periods to store monthly data
		const transactionsByPeriod = new Map<string, {
			period: string;
			total: number;
			beginningOfMonthBalance: number;
			amountPaid: number;
			endOfMonthBalance: number;
		}>();

		// Process all transactions data by month
		for (const transaction of data.transactions) {
			if (!transactionsByPeriod.has(transaction.period)) {
				transactionsByPeriod.set(transaction.period, {
					period: transaction.period,
					total: transaction.earnings || 0,
					beginningOfMonthBalance: transaction.beginningOfMonthBalance || 0,
					amountPaid: transaction.amountPaid || 0,
					endOfMonthBalance: transaction.endOfMonthBalance || 0,
				});
			}
		}

		// Convert map to array of objects for table
		return Array.from(transactionsByPeriod.values())
			.sort((a, b) => b.period.localeCompare(a.period)); // Sort by period descending
	}, [data]);

	const columns = useMemo(() => {
		return [
			columnHelper.accessor('period', {
				header: 'Period',
				cell: ({ getValue }) => {
					const period = getValue();
					return <span>{formatDatePeriod(period, { month: 'long' })}</span>;
				},
			}),
			columnHelper.accessor('total', {
				header: 'Monthly Earnings',
				meta: {
					align: 'right',
				},
				cell: ({ getValue }) => {
					const value = getValue() || 0;
					return <div className="text-end">${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>;
				},
			}),
			columnHelper.accessor('beginningOfMonthBalance', {
				header: 'Beginning Balance',
				meta: {
					align: 'right',
				},
				cell: ({ getValue }) => {
					const value = getValue() || 0;
					return <div className="text-end">${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>;
				},
			}),
			columnHelper.accessor('amountPaid', {
				header: 'Amount Paid',
				meta: {
					align: 'right',
				},
				cell: ({ getValue }) => {
					const value = getValue() || 0;
					return <div className="text-end">${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>;
				},
			}),
			columnHelper.accessor('endOfMonthBalance', {
				header: 'End Balance',
				meta: {
					align: 'right',
				},
				cell: ({ getValue }) => {
					const value = getValue() || 0;
					return <div className="text-end">${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>;
				},
			}),
			columnHelper.display({
				id: 'actions',
				header: 'Actions',
				meta: {
					align: 'right',
				},
				size: 10,
				cell: ({ row }) => {
					return <div className="text-end">
						<OverlayTrigger
							placement="top"
							overlay={<Tooltip>Download {formatDatePeriod(row.original.period, { month: 'long' })} Statement</Tooltip>}
						>
							<Button variant="link" size="sm" onClick={() => handleDownloadStatement(row.original.period)}>
								<FontAwesomeIcon icon={faDownload} />
							</Button>
						</OverlayTrigger>
					</div>;
				},
			}),
		];
	}, []);

	const handleExportCSV = () => {
		const formatDollar = (value: number) => `$${(value || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

		// Get the most recent period from the sorted tableData
		const lastPeriod = tableData[0]?.period;
		const formattedPeriod = lastPeriod
			? formatDatePeriod(lastPeriod, { month: 'long' })
			: 'Unknown Period';

		analytics?.trackEvent('Export Revenue', {
			mode: 'summary',
			period: `${tableData[tableData.length - 1]?.period} to ${tableData[0]?.period}`,
		}, tableData.length);

		dataTableRef.current?.exportToCSV(`Newstex Content Fee Summary - ${formattedPeriod}`, {
			Period: (item) => formatDatePeriod(item.period, { month: 'long' }),
			'Monthly Earnings': (item) => formatDollar(item.total || 0),
			'Beginning Balance': (item) => formatDollar(item.beginningOfMonthBalance || 0),
			'Amount Paid': (item) => formatDollar(item.amountPaid || 0),
			'End Balance': (item) => formatDollar(item.endOfMonthBalance || 0),
		});
	};

	return (
		<Card className="mt-4" id="monthly-earnings-summary">
			<Card.Header className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => setIsExpanded(!isExpanded)}>
					<div className="card-heading">
						<span className="me-2">
							<FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronsRight} className="text-muted" />
						</span>
						Monthly Earnings History
					</div>
				</div>
				<div>
					<Button variant="outline-primary" size="sm" onClick={handleExportCSV} id="monthly-earnings-summary-download">
						<FontAwesomeIcon icon={faDownload} /> Export Summary
					</Button>
				</div>
			</Card.Header>
			<div className={`collapse ${isExpanded ? 'show' : ''}`}>
				<Card.Body>
					<DataTable
						columns={columns}
						items={tableData}
						ref={dataTableRef}
						striped
						sortable={false}
					/>
				</Card.Body>
			</div>
		</Card>
	);
}

export function PublisherDashboard() {
	const userInfo = useUserInfo();
	const api = useAPI();
	const search = useSearch();
	const analytics = useAnalytics();
	const [loading, setLoading] = useState(true);
	const [revenue, setRevenue] = useState<RevenueAndEarningsForContact>();
	const [myPubs, setMyPubs] = useState<PublicationInfo[]>([]);
	const [monthCount, setMonthCount] = useState(120); // Start with 10 years worth of months
	const [selectedPublisher, setSelectedPublisher] = useState<Publisher | null>(null);

	// Group publications by publisher
	const publisherGroups = useMemo(() => {
		const groups: Record<string, {
			id: string,
			name: string,
			publications: PublicationInfo[]
		}> = {};

		for (const pub of userInfo?.my_pubs || []) {
			const publisherId = pub.publisher_newscore_id;
			if (!groups[publisherId]) {
				groups[publisherId] = {
					id: publisherId,
					name: pub.publisher_name,
					publications: [],
				};
			}
			groups[publisherId].publications.push(pub);
		}

		return Object.values(groups);
	}, [userInfo?.my_pubs]);

	// Tutorial Steps generated based on the UserInfo
	const tutorialSteps = useMemo<Step[]>(() => {
		return getPublisherDashboardSteps({
			revenue,
			publisherCount: publisherGroups.length,
		});
	}, [revenue, publisherGroups]);

	const handleDownloadStatement = (period: string) => {
		if (!revenue?.earnings) return;

		// Filter earnings for the specific period
		const periodEarnings = revenue.earnings.filter((t) => (t.period === period || `${t.period}-01` === period));

		analytics?.trackEvent('Export Revenue', {
			mode: 'statement',
			period: formatDatePeriod(period, { month: 'long' }),
		}, periodEarnings.length);

		// Create CSV config
		const csvConfig = mkConfig({
			filename: 'Newstex Content Fee Summary',
			fieldSeparator: ',',
			decimalSeparator: '.',
			quoteCharacter: '"',
			quoteStrings: true,
			useKeysAsHeaders: true,
		});

		// Format data for CSV
		const dataToExport = periodEarnings.map((earning) => ({
			Period: formatDatePeriod(earning.period, { month: 'long' }),
			Publication: earning.publicationName,
			'Publication Newstex ID': earning.publicationNewstexID,
			Client: earning.client,
			Earnings: earning.earnings?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00',
		}));

		// Generate and download CSV
		const csv = generateCsv(csvConfig)(dataToExport);
		download(csvConfig)(csv);
	};

	useEffect(() => {
		const fetchData = async () => {
			if (api) {
				// Fetch revenue for up to 10 years
				let url = `revenue?months=${monthCount}`;

				// Add publisher filter if selected
				if (selectedPublisher) {
					url += `&publisher=${selectedPublisher.newstex_id}`;
				}

				const resp = await api.fetchWithAuth<RevenueAndEarningsForContact>(url);

				if (!resp?.clientEarnings?.length && monthCount < 240) {
					setMonthCount(monthCount * 2);
				}
				setRevenue(resp);
			}

			// Use the search client to fetch last post date stats for each publication
			const pubs = userInfo?.my_pubs || [];

			// Filter publications by selected publisher if needed
			const filteredPubs = selectedPublisher
				? pubs.filter((pub) => pub.publisher_newscore_id === selectedPublisher.$id)
				: pubs;

			if (search.searchClient && filteredPubs.length) {
				const searchResp = await search.searchClient.search<Publication>({
					indexName: 'Publication',
					q: '*',
					query_by: 'name',
					sort_by: 'last_post_date:desc',
					per_page: 100,
				});
				for (const hit of searchResp.hits) {
					if (hit.$id && hit.last_post_date) {
						const pub = filteredPubs.find((item) => item.newscore_id === hit.$id);
						if (pub) {
							pub.last_post_date = formatDateYYYYMMDD(hit.last_post_date);
						}
					}
				}
			}
			setMyPubs(filteredPubs.sort((a, b) => (b.last_post_date || '').localeCompare(a.last_post_date || '')));
			setLoading(false);
		};
		setLoading(true);
		fetchData();
	}, [api, search, monthCount, selectedPublisher, userInfo?.my_pubs]);

	return (
		<Container fluid className="px-lg-4 px-xl-5">
			<PageTitle title="Earnings History" tutorial={tutorialSteps} customBreadcrumbs={
				<div>
					<h4>{userInfo?.account_name}</h4>
					{publisherGroups.length > 1 && (
						<div className="mt-2" id="select-publisher-dropdown">
							<PublisherSelect
								value={selectedPublisher}
								onChange={setSelectedPublisher}
							/>
						</div>
					)}
				</div>
			} />
			{loading && <LoadingSpinner loading={loading} /> }
			{!loading && (<>
				<Row className="mb-3">
					<Col sm={6} lg={3} className="mb-4">
						<KPIStatsWidget
							id="kpi-current-earnings"
							name={`Earnings for ${revenue?.currentPeriod
								? formatDatePeriod(revenue.currentPeriod, { month: 'long' })
								: 'the most recent period'}`}
							prefix="$"
							number={revenue?.currentMonthEarnings?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00'}
							footer={<>
								<div>Earnings are reported 3 months in arrears</div>
								<Button
									id="download-statement-button"
									variant="outline-primary"
									size="sm"
									className="mt-2"
									onClick={() => revenue?.currentPeriod && handleDownloadStatement(revenue.currentPeriod)}
								>
									<FontAwesomeIcon icon={faDownload} /> Download Statement
								</Button>
							</>}
							color="green"
						/>
					</Col>
					<Col sm={6} lg={3} className="mb-4">
						<KPIStatsWidget
							id="kpi-beginning-balance"
							name={`Beginning Balance for ${revenue?.currentPeriod ? formatDatePeriod(revenue.currentPeriod, { month: 'long' }) : 'this period'}`}
							prefix="$"
							number={revenue?.currentBeginningOfMonthBalance?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00'}
							footer="This is the balance of unpaid content fees at the beginning of the period"
							color="blue"
						/>
					</Col>
					<Col sm={6} lg={3} className="mb-4">
						<KPIStatsWidget
							id="kpi-amount-to-be-paid"
							name={`Amount to be Paid for ${revenue?.currentPeriod ? formatDatePeriod(revenue.currentPeriod, { month: 'long' }) : 'this period'}`}
							prefix="$"
							number={revenue?.currentMonthAmountPaid?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00'}
							footer={`This is the amount of content fees that will be paid for ${revenue?.currentPeriod ? formatDatePeriod(revenue.currentPeriod, { month: 'long' }) : 'this period'}`}
							color="blue"
						/>
					</Col>

					<Col sm={6} lg={3} className="mb-4">
						<KPIStatsWidget
							id="kpi-end-balance"
							name={`Ending Balance for ${revenue?.currentPeriod ? formatDatePeriod(revenue.currentPeriod, { month: 'long' }) : 'this period'}`}
							prefix="$"
							number={revenue?.currentMonthEndOfMonthBalance?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00'}
							footer="Content fees that will be carried forward to the next period, if you have not met the $25 minimum threshold"
							color="blue"
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						{revenue && <>
							<RevenueChart data={revenue} />
							<RevenueTable data={revenue} handleDownloadStatement={handleDownloadStatement} />
						</>}
					</Col>
				</Row>
			</>)}
			<section className="mb-3 mb-lg-5">
				{userInfo?.my_pubs && (
					<MyPubs
						id="my-publications"
						title="My Publications"
						pubs={myPubs}
						publisherCount={publisherGroups.length}
					/>
				)}
			</section>
		</Container>
	);
}
