import type { Delivery } from '@newstex/types';
import type { Distributor } from '@newstex/types/distributor';
import { useEffect, useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DeliveryForm } from '~/components/editors/delivery-form';
import { useAPI } from '~/providers/api-provider';

interface CreateDeliveryModalProps {
	show: boolean;
	onClose: (saved: boolean) => void;
	initialDistributorId?: string;
}

export function CreateDeliveryModal({
	show,
	onClose,
	initialDistributorId,
}: CreateDeliveryModalProps) {
	const navigate = useNavigate();
	const api = useAPI();
	const [newDelivery, setNewDelivery] = useState<Partial<Delivery>>({});
	const [distributor, setDistributor] = useState<Distributor | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [saving, setSaving] = useState(false);

	// Reset state when modal opens
	useEffect(() => {
		if (show) {
			setNewDelivery({
				type: 'SFTP',
				status: 'Standby',
				verify: true,
			});
		}
	}, [show]);

	// Load initial distributor if ID is provided
	useEffect(() => {
		if (initialDistributorId && show) {
			// Fetch the distributor details if we have an ID
			api.getItem<Distributor>({
				$type: 'Distributor',
				$id: initialDistributorId,
			}).then(setDistributor).catch(console.error);
		} else if (show) {
			// Reset the distributor when modal opens with no ID
			setDistributor(null);
		}
	}, [initialDistributorId, show, api]);

	const handleSave = async () => {
		if (!newDelivery.name?.trim()) {
			setError('Name is required');
			return;
		}

		if (!newDelivery.queue_name?.trim()) {
			setError('Queue name is required');
			return;
		}

		if (!distributor) {
			setError('Distributor is required');
			return;
		}

		// Validate type-specific required fields
		if (newDelivery.type === 'SFTP') {
			if (!newDelivery.host?.trim()) {
				setError('Host is required for SFTP deliveries');
				return;
			}

			if (!newDelivery.username?.trim()) {
				setError('Username is required for SFTP deliveries');
				return;
			}
		} else if (newDelivery.type === 'HTTP') {
			if (!newDelivery.url?.trim()) {
				setError('URL is required for HTTP deliveries');
				return;
			}

			if (!newDelivery.method?.trim()) {
				setError('HTTP Method is required for HTTP deliveries');
				return;
			}
		} else if (newDelivery.type === 'S3') {
			if (!newDelivery.bucket_name?.trim()) {
				setError('Bucket name is required for S3 deliveries');
				return;
			}

			if (!newDelivery.user_id?.trim() && !newDelivery.user_email?.trim()) {
				setError('User ID or Email is required for S3 deliveries');
				return;
			}
		}

		setSaving(true);
		try {
			// Create a combined delivery object with the distributor ID
			const deliveryData = {
				...newDelivery,
				distributor: distributor.$id,
			};

			const response = await api.fetchWithAuth<Delivery>('resources/Delivery', {
				method: 'POST',
				body: JSON.stringify(deliveryData),
			});

			if (response) {
				onClose(true);
				toast.success('Delivery created successfully', {
					onClick: () => {
						navigate(`/delivery/${response.$id}`);
					},
				});
			} else {
				console.error('Failed to save delivery');
				toast.error(`Failed to save delivery: ${response}`);
			}
		} catch (err) {
			console.error('Error saving delivery:', err);
			setError(String(err));
		} finally {
			setSaving(false);
		}
	};

	return (
		<Modal show={show} onHide={() => onClose(false)} size="xl">
			<Modal.Header closeButton>
				<Modal.Title>Create New Delivery</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error && <Alert className="text-center mb-3" variant="danger">{error}</Alert>}
				<DeliveryForm
					delivery={newDelivery}
					onChange={setNewDelivery}
					distributor={distributor}
					onDistributorChange={setDistributor}
					error={error}
					isCreate
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onClose(false)} disabled={saving}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleSave} disabled={saving}>
					{saving ? 'Creating...' : 'Create Delivery'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
