import { AccountInfoResponse } from '@newstex/types/responses/info';
import { User, UserGroup } from '@newstex/types/user';

const SHARED_FACETS = [
	'source',
	'publisher',
	'distributor',
	'language',
	'country',
	'category',
	'subcategory',
	'topic',
	'subtopic',
	'keyword',
	'date',
	'received_at',
	'_tags',
	'categories',
	'content',
	'content_type',
	'provider_categories',
	'provider_tickers',
	'companies',
	'company_tickers',
	'tickers',
	'topics',
	'pub_country',
	'closed_sites_only',
	'last_post_date',
	'integration_completion_date',
];

const ALLOWED_FACETS_BY_GROUP: Partial<Record<UserGroup, string[]>> = {
	distributor: [...SHARED_FACETS],
	publisher: [...SHARED_FACETS, 'products', 'status'],
};

export function userCanAccessFacet(user: User | AccountInfoResponse, facet: string) {
	if (user.auth_groups?.includes('admin')) {
		return true;
	}

	for (const group of user.auth_groups ?? []) {
		if (ALLOWED_FACETS_BY_GROUP[group]?.includes(facet)) {
			return true;
		}
	}

	return false;
}
