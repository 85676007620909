import type { Publication as PublicationType } from '@newstex/types/content';

import type { CollectionFieldSchmaForObject } from './types';

export const Publication: CollectionFieldSchmaForObject<PublicationType & { content?: string[] }> = [
	{
		name: 'name',
		type: 'string',
		sort: true,
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 10,
	},
	{
		name: 'sortable_name',
		type: 'string',
		sort: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'alt_names',
		type: 'string[]',
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 11,
	},
	{
		name: 'code',
		type: 'string',
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'alt_codes',
		type: 'string[]',
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 5,
	},
	{
		name: 'provider_code',
		type: 'string',
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 6,
	},
	{
		name: 'newstex_id',
		type: 'string',
		store: true,
		sort: true,
		searchable: true,
		optional: true,
		facet: true,
		defaultSearchOrder: 0,
	},
	{
		name: 'hubspot_id',
		type: 'string',
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 7,
	},
	{
		name: 'xml_feed',
		type: 'string[]',
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 2,
	},
	{
		name: 'url',
		type: 'string',
		store: true,
		searchable: true,
		sort: true,
		optional: true,
		defaultSearchOrder: 1,
	},
	{
		name: 'alt_urls',
		type: 'string[]',
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 3,
	},
	{
		name: 'home_url',
		type: 'string',
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 1,
	},
	{
		name: 'client_ids',
		type: 'string[]',
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 9,
	},
	{
		name: 'account_id',
		type: 'int32',
		facet: true,
		store: true,
		optional: true,
	},
	{
		name: 'activated',
		type: 'int64',
		range_index: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'start_date',
		type: 'int64',
		range_index: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'integration_completion_date',
		type: 'int64',
		facet: true,
		range_index: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'archive_date',
		type: 'int64',
		facet: true,
		range_index: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'deletion_date',
		type: 'int64',
		facet: true,
		range_index: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'termination_reason',
		type: 'string',
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'city',
		type: 'string',
		store: true,
		facet: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'state',
		type: 'string',
		store: true,
		facet: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'country',
		type: 'string',
		store: true,
		facet: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'pub_country',
		type: 'string',
		store: true,
		facet: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'region',
		type: 'string',
		store: true,
		facet: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'zip',
		type: 'string',
		store: true,
		facet: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'description',
		type: 'string',
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 20,
	},
	{
		name: 'language',
		type: 'string',
		store: true,
		facet: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'lc_class',
		type: 'string[]',
		store: true,
		facet: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'topics',
		type: 'string[]',
		store: true,
		facet: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'products',
		type: 'string[]',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'content_category',
		type: 'string',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'content_type',
		type: 'string',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'source_type',
		type: 'string',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'frequency',
		type: 'string',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'org_type',
		type: 'string',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'status',
		type: 'string',
		sort: true,
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'content_status',
		type: 'string',
		sort: true,
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'restriction_type',
		type: 'string',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'parent',
		type: 'string',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
		reference: 'Publisher.id',
	},
	{
		name: 'owner',
		type: 'string',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'calculate_royalties',
		type: 'bool',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'closed_sites_only',
		type: 'bool',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'copyright',
		type: 'string',
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'feed_priority',
		type: 'string',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'disable_auto_archive',
		type: 'bool',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'allow_marketing',
		type: 'bool',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'can_translate',
		type: 'bool',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'last_post_date',
		type: 'int64',
		facet: true,
		range_index: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'last_received_date',
		type: 'int64',
		facet: true,
		range_index: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'ai_score',
		type: 'float',
		facet: true,
		store: true,
		optional: true,
	},
	{
		name: 'ai_scores.*',
		type: 'int32',
		facet: true,
		store: true,
		sort: true,
		optional: true,
	},
	{
		name: 'ai_scores.default',
		type: 'int32',
		facet: true,
		store: true,
		sort: true,
		optional: true,
	},
	{
		name: 'ai_scores.lexis',
		type: 'int32',
		facet: true,
		store: true,
		sort: true,
		optional: true,
	},
	{
		name: 'ai_scores.thomson',
		type: 'int32',
		facet: true,
		store: true,
		sort: true,
		optional: true,
	},
	/** For Authorization purposes */
	{
		name: 'owner_emails',
		type: 'string[]',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'content',
		type: 'string[]',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	{
		name: 'excluded_distributors',
		type: 'string[]',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	/* END Authorization */
];
