import {
	ActionBarPrimitive,
	BranchPickerPrimitive,
	ComposerPrimitive,
	MessagePrimitive,
	ThreadPrimitive,
	useAssistantRuntime,
	useComposer,
	useComposerRuntime,
	useThreadListItemRuntime,
} from '@assistant-ui/react';
import {
	faCheck,
	faChevronLeft,
	faChevronRight,
	faCopy,
	faPencilAlt,
	faPlay,
	faRotate,
} from '@fortawesome/free-solid-svg-icons';
import { faStop } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type FC, useEffect, useRef } from 'react';
import {
	Button,
	ButtonGroup,
	Card,
	Col,
	Container,
	Form,
	InputGroup,
	Row,
} from 'react-bootstrap';
import { useAI } from '~/providers/ai-provider';

import { MarkdownText } from './markdown-text';
import { GenericToolCall, ToolCallUI } from './tool-call-ui';
import {
	CanvasToolUI,
	ChartToolUI,
	GetCanvasContentUI,
	NavigateToolUI,
	ReportDataToolUI,
} from './tool-uis';
import { TooltipIconButton } from './tooltip-icon-button';

const BranchPicker: FC<BranchPickerPrimitive.Root.Props> = ({
	className,
	...rest
}) => {
	return (
		<BranchPickerPrimitive.Root
			hideWhenSingleBranch
			className="d-inline-flex align-items-center text-muted small"
			{...rest}
		>
			<ButtonGroup size="sm">
				<BranchPickerPrimitive.Previous asChild>
					<TooltipIconButton tooltip="Previous" variant="outline-secondary">
						<FontAwesomeIcon icon={faChevronLeft} />
					</TooltipIconButton>
				</BranchPickerPrimitive.Previous>
				<Button variant="outline-secondary" disabled>
					<BranchPickerPrimitive.Number /> / <BranchPickerPrimitive.Count />
				</Button>
				<BranchPickerPrimitive.Next asChild>
					<TooltipIconButton tooltip="Next" variant="outline-secondary">
						<FontAwesomeIcon icon={faChevronRight} />
					</TooltipIconButton>
				</BranchPickerPrimitive.Next>
			</ButtonGroup>
		</BranchPickerPrimitive.Root>
	);
};

const UserActionBar: FC = () => {
	return (
		<ActionBarPrimitive.Root
			hideWhenRunning
			autohide="not-last"
			className="d-flex flex-column align-items-end mt-2"
		>
			<ActionBarPrimitive.Edit asChild>
				<TooltipIconButton tooltip="Edit" variant="outline-secondary">
					<FontAwesomeIcon icon={faPencilAlt} />
				</TooltipIconButton>
			</ActionBarPrimitive.Edit>
		</ActionBarPrimitive.Root>
	);
};

const AssistantActionBar: FC = () => {
	const { finishReason } = useAI();

	// Create a suggestion that will simulate a continue function with "Please continue processing"
	const handleContinue = () => {
		// Find the input element and create a custom event
		const composer = document.getElementById('ai-chat-composer');
		if (composer) {
			// Focus the composer area
			composer.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });

			// Find the input field and set its value
			const input = composer.querySelector('textarea');
			if (input) {
				// Set the value
				input.value = 'Please continue processing.';

				// Dispatch events to ensure the value is recognized
				input.dispatchEvent(new Event('input', { bubbles: true }));

				// Simulate Enter key press
				setTimeout(() => {
					input.dispatchEvent(new KeyboardEvent('keydown', { key: 'Enter', bubbles: true }));
				}, 100);
			}
		}
	};

	return (
		<ActionBarPrimitive.Root
			hideWhenRunning
			autohide="not-last"
			autohideFloat="single-branch"
			className="d-flex gap-1 text-muted"
		>
			<ButtonGroup size="sm">
				{finishReason === 'tool-calls' && (
					<TooltipIconButton
						tooltip="Continue"
						variant="outline-primary"
						onClick={handleContinue}
					>
						<FontAwesomeIcon icon={faPlay} />
					</TooltipIconButton>
				)}
				<ActionBarPrimitive.Copy asChild>
					<TooltipIconButton tooltip="Copy" variant="outline-secondary">
						<MessagePrimitive.If copied>
							<FontAwesomeIcon icon={faCheck} />
						</MessagePrimitive.If>
						<MessagePrimitive.If copied={false}>
							<FontAwesomeIcon icon={faCopy} />
						</MessagePrimitive.If>
					</TooltipIconButton>
				</ActionBarPrimitive.Copy>
				<ActionBarPrimitive.Reload asChild>
					<TooltipIconButton tooltip="Refresh" variant="outline-secondary">
						<FontAwesomeIcon icon={faRotate} />
					</TooltipIconButton>
				</ActionBarPrimitive.Reload>
			</ButtonGroup>
		</ActionBarPrimitive.Root>
	);
};

const UserMessage: FC = () => {
	return (
		<MessagePrimitive.Root className="d-grid gap-2 w-100 py-2">
			<Row className="justify-content-end">
				<Col>
					<div className="d-flex flex-column align-items-end">
						<Card className="border-0 bg-primary text-white rounded-4">
							<Card.Body className="py-2 px-3">
								<MessagePrimitive.Content />
							</Card.Body>
						</Card>
						<div className="d-flex gap-2 mt-2">
							<UserActionBar />
							<BranchPicker />
						</div>
					</div>
				</Col>
			</Row>
		</MessagePrimitive.Root>
	);
};

const AssistantMessage: FC = () => {
	const { toolCall } = useAI();

	return (
		<MessagePrimitive.Root className="d-grid gap-2 w-100 py-2">
			<Row>
				<Col>
					<div className="d-flex flex-column">
						<Card className="border-0 bg-light rounded-4">
							<Card.Body className="py-2 px-3">
								<MessagePrimitive.Content components={{
									Text: MarkdownText,
									tools: {
										by_name: {
											navigateTo: NavigateToolUI,
											getReportData: ReportDataToolUI,
											generateChart: ChartToolUI,
											useCanvas: CanvasToolUI,
											getCanvasContent: GetCanvasContentUI,
										},
										Fallback: GenericToolCall,
									},
								}} />
								{toolCall && <ToolCallUI
									name={toolCall.cmdName}
									params={toolCall.cmdParams}
									status={toolCall.status}
								/>}
							</Card.Body>
						</Card>
						<div className="d-flex gap-2 mt-2">
							<AssistantActionBar />
							<BranchPicker />
						</div>
					</div>
				</Col>
			</Row>
		</MessagePrimitive.Root>
	);
};

const EditComposer: FC = () => {
	return (
		<Card className="my-3 border-0 bg-light rounded-4">
			<Card.Body>
				<Form>
					<Form.Control
						as={ComposerPrimitive.Input}
						className="border-0 bg-transparent"
					/>
					<div className="d-flex justify-content-end gap-2 mt-3">
						<ComposerPrimitive.Cancel asChild>
							<Button variant="outline-secondary" size="sm">Cancel</Button>
						</ComposerPrimitive.Cancel>
						<ComposerPrimitive.Send asChild>
							<Button variant="primary" size="sm">Send</Button>
						</ComposerPrimitive.Send>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

const Composer: FC = () => {
	const threadRuntime = useThreadListItemRuntime();
	const assistantRuntime = useAssistantRuntime();
	const composerRuntime = useComposerRuntime();
	const composer = useComposer();
	const inputRef = useRef<HTMLTextAreaElement>(null);

	const handleCommand = (command: string) => {
		const [cmd, ...args] = command.slice(1).split(' ');

		switch (cmd.toLowerCase()) {
			case 'clear':
				console.log('clear thread', threadRuntime.archive());
				return true;
			case 'new':
				console.log('new thread', assistantRuntime.threads.switchToNewThread());
				return true;
			default:
				return false;
		}
	};

	const handleSend = () => {
		const value = inputRef.current?.value || '';
		if (value.startsWith('/')) {
			const wasHandled = handleCommand(value);
			if (wasHandled) {
				if (inputRef.current) {
					inputRef.current.value = '';
				}
				return;
			}
		}
		composerRuntime.send();
	};

	const handleCancel = () => {
		composerRuntime.cancel();
	};

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === 'Enter') {
				e.preventDefault();
				handleSend();
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [composerRuntime.send]);

	return (
		<InputGroup>
			<Form.Control
				as={ComposerPrimitive.Input}
				ref={inputRef}
				autoFocus
				placeholder="Write a message..."
				className="border px-3"
			/>
			<ThreadPrimitive.If running={false}>
				<ComposerPrimitive.Send asChild>
					<Button
						variant="primary"
						className="rounded-0 border-0 text-white fw-bold"
						onClick={handleSend}
					>↵</Button>
				</ComposerPrimitive.Send>
			</ThreadPrimitive.If>
			<ThreadPrimitive.If running>
				<ComposerPrimitive.Cancel asChild>
					<Button
						variant="danger"
						className="rounded-0 border-0 text-white fw-bold"
						onClick={handleCancel}
						disabled={!composer.canCancel}
					>
						<FontAwesomeIcon icon={faStop} />
					</Button>
				</ComposerPrimitive.Cancel>
			</ThreadPrimitive.If>
		</InputGroup>
	);
};

const ThreadWelcomeSuggestions: FC = () => {
	return (
		<Row className="mt-4 justify-content-center thread-suggestions">
			<Col>
				<ThreadPrimitive.Suggestion
					prompt="Start a new Knowledge Base article"
					method="replace"
					autoSend
				>
					Start a new Knowledge Base article
				</ThreadPrimitive.Suggestion>
			</Col>
			<Col>
				<ThreadPrimitive.Suggestion
					prompt="How many stories were produced today?"
					method="replace"
					autoSend
				>
					How many stories were produced today?
				</ThreadPrimitive.Suggestion>
			</Col>
		</Row>
	);
};

const ThreadWelcome: FC = () => {
	return (
		<ThreadPrimitive.Empty>
			<Container className="text-center">
				<ThreadWelcomeSuggestions />
			</Container>
		</ThreadPrimitive.Empty>
	);
};

export const Thread: FC = () => {
	useEffect(() => {
		const composer = document.getElementById('ai-chat-composer');
		if (composer) {
			composer.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
				inline: 'start',
			});
		}
	}, []);
	return (
		<ThreadPrimitive.Root
			className="bg-white h-100 d-flex flex-column overflow-hidden"
			style={{
				['--thread-max-width' as string]: '48rem',
			}}
		>
			<ThreadPrimitive.Viewport className="flex-grow-1 overflow-auto px-4 pt-4">
				<Container fluid>
					<ThreadWelcome />
					<ThreadPrimitive.Messages
						components={{
							UserMessage,
							EditComposer,
							AssistantMessage,
						}}
					/>
					<div
						id="ai-chat-composer"
						className="sticky-bottom mt-3 d-flex flex-column align-items-center bg-white border-top pt-3 pb-3 w-100"
					>
						<Composer />
					</div>
				</Container>
			</ThreadPrimitive.Viewport>
		</ThreadPrimitive.Root>
	);
};
