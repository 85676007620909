/* eslint-disable array-callback-return */

import type { IconName } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BUILD_DATE, version } from '@newstex/core/version';
import { useEffect, useState } from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/esm/types';
import { Link } from 'react-router-dom';
import { useSettingsModal } from '~/providers/settings-modal-provider';

import ActiveLink from '../ActiveLink';

export interface SidebarProps {
	sidebarShrink: boolean;
}

export function MenuLink({
	to, icon: iconName, title, tooltip, id, badge, badgeColor, sidebarShrink, onClick,
}: {
	to: string;
	icon: IconName;
	title: string;
	tooltip?: string;
	id: string;
	badge?: number | null;
	badgeColor?: Variant;
	sidebarShrink: boolean;
	onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}) {
	return (
		<ActiveLink
			to={to}
			activeClassName="active"
			className="sidebar-link"
			key={id}
			id={`sidebar-menu-${id}`}
			onClick={onClick}
		>
			{sidebarShrink ? (
				<OverlayTrigger
					placement="right"
					overlay={<Tooltip>
						<h6 className="text-center">{title}</h6>
						{tooltip && <p>{tooltip}</p>}
					</Tooltip>}
				>
					<FontAwesomeIcon className="svg-icon-md me-3 fa-light" icon={['fal', iconName]} />
				</OverlayTrigger>
			) : (
				<>
					{iconName && <FontAwesomeIcon className="svg-icon-md me-3 fa-light" icon={['fal', iconName]} />}
					<OverlayTrigger
						placement="right"
						overlay={<Tooltip>{tooltip}</Tooltip>}
					>
						<span className="text flex-grow-1">{title}</span>
					</OverlayTrigger>
				</>
			)}
			{badge && (<Badge bg={badgeColor} className="ms-auto">{badge}</Badge>)}
		</ActiveLink>
	);
}

export function SidebarFooter({ sidebarShrink }: SidebarProps) {
	const { openModal } = useSettingsModal();

	return (
		<>
			<div className="settings-section">
				<MenuLink
					to="#settings"
					icon="gear"
					title="Settings"
					id="settings"
					tooltip="This is your user settings page. Here you can change your name, email, phone, and other account information."
					sidebarShrink={sidebarShrink}
					onClick={(e) => {
						e.preventDefault();
						openModal();
						(e.currentTarget as HTMLElement).blur();
						return false;
					}}
				/>
			</div>

			<div className="sidebar-bottom">
				<OverlayTrigger
					placement="top"
					overlay={
						<Tooltip>
							Version: {version}<br />
							Build Date: {BUILD_DATE}
						</Tooltip>
					}
				>
					<div className="version text-muted small">
						Version: {version}
					</div>
				</OverlayTrigger>
			</div>
		</>
	);
}

export function SidebarHeader({ sidebarShrink }: SidebarProps) {
	const [logo, setLogo] = useState(sidebarShrink ? '/img/brand/newstex-logo-sm.svg' : '/img/brand/newstex-logo.svg');

	useEffect(() => {
		setLogo(sidebarShrink ? '/img/brand/newstex-logo-small.png' : '/img/brand/newstex-logo.svg');
	}, [sidebarShrink]);

	return (
		<div className="sidebar-logo px-3 py-2">
			<Link to="/" className="d-flex align-items-center">
				<img
					key={sidebarShrink ? 'newstex-logo-small' : 'newstex-logo'}
					src={logo}
					alt="Newstex"
					height={sidebarShrink ? 30 : 50}
				/>
			</Link>
		</div>
	);
}
