export const colors = {
	primary: '#4650dd',
	blue: '#0d6efd',
	indigo: '#6610f2',
	purple: '#6f42c1',
	pink: '#d63384',
	pinkLighter: '#e685b5',
	red: '#dc3545',
	orange: '#fd7e14',
	yellow: '#ffc107',
	green: '#35b653',
	teal: '#20c997',
	cyan: '#17a2b8',
	white: '#fff',
	lightPurple: '#d0d2f3',
	gray: '#6c757d',
	lightGray: '#e9ecef',
	// Shades of blue from Jose/Figma
	blue800: '#1E40AF',
	blue600: '#2563EB',
	blueSky: '#38BDF8',
	blueLight: '#DBEAFE',
};

export interface GradientTypes {
	whiteBlue?: CanvasGradient;
	pinkBlue?: CanvasGradient;
	primaryWhite?: CanvasGradient;
}

export function gradients(chartRef: React.MutableRefObject<any>) {
	const gradientTypes: GradientTypes = {};
	if (chartRef.current) {
		const context = chartRef.current.getContext('2d');

		gradientTypes.whiteBlue = context.createLinearGradient(0, 0, 300, 300);
		gradientTypes.whiteBlue!.addColorStop(0, 'rgba(255,255,255, 0.8)');
		gradientTypes.whiteBlue!.addColorStop(1, 'rgba(70,80,221, 0.8)');

		gradientTypes.pinkBlue = context.createLinearGradient(140, 0, 150, 300.0);
		gradientTypes.pinkBlue!.addColorStop(0, 'rgba(63,94,251,.85)');
		gradientTypes.pinkBlue!.addColorStop(1, 'rgba(252,70,107, .85)');

		gradientTypes.primaryWhite = context.createLinearGradient(0, 0, 0, 200);
		gradientTypes.primaryWhite!.addColorStop(0, colors.primary);
		gradientTypes.primaryWhite!.addColorStop(1, colors.white);
	}

	return gradientTypes;
}
