import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type Distributor } from '@newstex/types/distributor';
import { User } from '@newstex/types/user';
import { useState } from 'react';
import {
	Alert,
	Button,
	Form,
	Modal,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { NewUserOption, UserSelect, UserSelectValue } from '~/components/editors/user-select';
import { useAPI } from '~/providers/api-provider';

interface AttachUserRoleModalProps {
	distributor: Distributor | null;
	show: boolean;
	onClose: (saved: boolean) => void;
}

export function AttachUserRoleModal({
	distributor,
	show,
	onClose,
}: AttachUserRoleModalProps) {
	const api = useAPI();
	const [selectedUser, setSelectedUser] = useState<UserSelectValue>(null);
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [role, setRole] = useState('reader');
	const [error, setError] = useState<string | null>(null);
	const [saving, setSaving] = useState(false);

	// Determine if we're in create mode based on the selected user
	const isCreateMode = selectedUser && '__isNew__' in selectedUser;

	const resetForm = () => {
		setSelectedUser(null);
		setName('');
		setPhone('');
		setRole('reader');
		setError(null);
	};

	const handleSave = async () => {
		if (!distributor) {
			setError('No distributor selected');
			return;
		}

		// Validate form
		if (!selectedUser) {
			setError('Please select a user or enter an email address');
			return;
		}

		if (isCreateMode && !name.trim()) {
			setError('Name is required when creating a new user');
			return;
		}

		setSaving(true);
		setError(null);

		try {
			// Get email to check
			const emailToCheck = selectedUser.email;

			// First check if the user already exists in the system
			const userSearch = await api.fetchWithAuth(`resources/User?email=${encodeURIComponent(emailToCheck)}`, {
				cache: 'no-store',
			});

			// Check if this user is already associated with the distributor
			if (userSearch?.items?.length > 0 && userSearch.items[0].distributor === distributor.$id) {
				console.log('userSearch', userSearch);
				setError('This user is already assigned to this distributor');
				setSaving(false);
				return;
			}

			if (isCreateMode && userSearch?.items?.length > 0) {
				setError('A user with this email already exists');
				setSaving(false);
				return;
			}

			let userToAdd: User | null = null;

			// Handle creating a new user if needed
			if (isCreateMode) {
				const newUserEmail = (selectedUser as NewUserOption).email;

				if (userSearch?.items?.length > 0) {
					// User exists, use that one
					userToAdd = userSearch.items[0];
				} else {
					// Create new user
					userToAdd = await api.createItem<User>({
						$type: 'User',
						name,
						email: newUserEmail,
						phone: phone || undefined,
						distributor: distributor.$id,
						role: role as User['role'],
					});
				}
			} else {
				userToAdd = selectedUser as User;
				// Update the existing user
				await api.updateItem<any>({
					$type: 'User',
					$id: selectedUser.id,
				}, {
					distributor: distributor.$id,
					role,
				});
			}

			toast.success(`User ${userToAdd.name || userToAdd.email} added to ${distributor.name}`);
			setSaving(false);
			resetForm();
			onClose(true);
		} catch (err) {
			console.error('Error adding user role:', err);
			setError(String(err));
		} finally {
			setSaving(false);
		}
	};

	const handleClose = () => {
		resetForm();
		onClose(false);
	};

	const handleUserChange = (user: UserSelectValue) => {
		setSelectedUser(user);
		// Reset name and phone when switching users
		if (!user || !('__isNew__' in user)) {
			setName('');
			setPhone('');
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Add User to {distributor?.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error && <Alert variant="danger">{error}</Alert>}
				<Form>
					<Form.Group className="mb-3">
						<Form.Label>User</Form.Label>
						<UserSelect
							value={selectedUser}
							onChange={handleUserChange}
						/>
						<Form.Text className="text-muted">
							Search for an existing user or enter an email address to create a new user
						</Form.Text>
					</Form.Group>

					{isCreateMode && (
						<>
							<Form.Group className="mb-3">
								<Form.Label>Name</Form.Label>
								<Form.Control
									type="text"
									value={name}
									onChange={(e) => setName(e.target.value)}
									placeholder="Full Name"
									required
								/>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Phone (Optional)</Form.Label>
								<Form.Control
									type="tel"
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
									placeholder="+1 (555) 123-4567"
								/>
							</Form.Group>
						</>
					)}
image.png
					<Form.Group className="mb-3">
						<Form.Label>Role</Form.Label>
						<Form.Select
							value={role}
							onChange={(e) => setRole(e.target.value)}
						>
							<option value="">-- Select Role --</option>
							<option value="tech">Technical</option>
							<option value="reporting">Reporting</option>
							<option value="support">Support</option>
						</Form.Select>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose} disabled={saving}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleSave} disabled={saving}>
					{saving ? 'Saving...' : 'Add User'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export function AttachUserRoleButton({
	distributor,
	onUserAdded,
	size = 'sm',
	variant = 'outline-primary',
	className,
}: {
	distributor: Distributor | null;
	onUserAdded?: () => void;
	size?: 'sm' | 'lg';
	variant?: string;
	className?: string;
}) {
	const [showModal, setShowModal] = useState(false);

	const handleClose = (saved: boolean) => {
		setShowModal(false);
		if (saved && onUserAdded) {
			onUserAdded();
		}
	};

	return (
		<>
			<Button
				size={size}
				variant={variant}
				className={className}
				onClick={() => setShowModal(true)}
			>
				<FontAwesomeIcon icon={faPlus} className="me-1" />
				Add User
			</Button>

			<AttachUserRoleModal
				distributor={distributor}
				show={showModal}
				onClose={handleClose}
			/>
		</>
	);
}
