// stories
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { Story } from '@newstex/types/story';
import { useSearchParams } from 'react-router-dom';
import { GenerateStoryButton } from '~/components/editors/generate-story-button';
import { SearchFacetConfig, SearchWithFacets } from '~/components/search-with-facets';
import { StoriesByPublicationTable } from '~/components/tables/stories-by-publication-table';
import { StoriesTable } from '~/components/tables/stories-table';

export const StoryFacetConfig: SearchFacetConfig[] = [
	{
		attribute: 'date',
		title: 'Publication Date',
		type: 'Date',
	},
	{
		attribute: 'received_at',
		title: 'Received At',
		type: 'Date',
	},
	{
		attribute: '_tags',
		title: 'Tags',
	},
	{
		attribute: 'categories',
		title: 'Category',
	},
	{
		attribute: 'language',
		title: 'Language',
	},
	{
		attribute: 'products',
		title: 'Products',
		searchable: true,
	},
	{
		attribute: 'content',
		title: 'Content',
		searchable: true,
	},
	{
		attribute: 'source',
		title: 'Source (byline)',
	},
	{
		attribute: 'provider_categories',
		title: 'Provider Categories',
	},
	{
		attribute: 'provider_tickers',
		title: 'Provider Tickers',
	},
];

export const StorySearchPage = () => {
	const [searchParams] = useSearchParams();
	const fallbackSearch = async (q: string): Promise<Story[]> => {
		console.log('Fallback Search', q);
		return [];
	};

	return (
		<SearchWithFacets
			indexName="Story"
			title="Stories"
			info="Stories are searchable for 60 days, except for high volume publishers, which are searchable for 1 day."
			defaultSort="received_at:desc"
			resultsTable={StoriesTable}
			facetStats={{
				received_at: 'Received',
				date: 'Published',
			}}
			groupByOptions={[{
				id: 'publication',
				icon: faNewspaper,
				sort: '_group_found:desc,received_at:desc',
			}]}
			extraActions={(results) => (
				<GenerateStoryButton
					stories={results?.hits}
					searchQuery={searchParams.get('q') || undefined}
				/>
			)}
			fallbackSearch={fallbackSearch}
			includeFields='$Publication(id,name,newstex_id,average_monthly_revenue,last_editorial_review,reviewed_by,last_review_comment,$Publisher(id,name,newstex_id))'
			groupTable={StoriesByPublicationTable}
			facets={StoryFacetConfig}
		/>
	);
};
