/* eslint-disable no-restricted-globals */
import type { AuthUser } from '@newstex/types/user';
import type React from 'react';
import {
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginModal from '~/components/modals/login-modal';
import { useCacheStore } from '~/stores/cache-store';

interface AuthData {
	[accountID: string]: AuthUser;
}

function get() {
	try {
		return JSON.parse(localStorage.getItem('auth') || '{}') as AuthData;
	} catch (e) {
		console.warn('Failed to get auth data from localStorage', e);
		return JSON.parse(sessionStorage.getItem('auth') || '{}') as AuthData;
	}
}

function set(auth: AuthData) {
	try {
		localStorage.setItem('auth', JSON.stringify(auth));
	} catch (e) {
		console.warn('Failed to set auth data in localStorage', e);
		sessionStorage.setItem('auth', JSON.stringify(auth));
	}
}
function setRunAs(email: string | null) {
	try {
		if (email) {
			localStorage.setItem('runAs', email || '');
		} else {
			localStorage.removeItem('runAs');
		}
	} catch (e) {
		console.warn('Failed to set runAs in localStorage', e);
	}
	try {
		if (!email) {
			sessionStorage.removeItem('runAs');
		}
	} catch (e) {
		console.warn('Failed to remove runAs in sessionStorage', e);
	}
}

function login() {
	const params = new URLSearchParams({
		client_id: 'solid',
		redirect_uri: `${location.origin}/`,
		response_type: 'token',
		provider: 'google',
	});
	const url = `${import.meta.env.VITE_API_URL}/auth/google/authorize?${params.toString()}`;
	return url;
}

interface AuthContextType {
	user?: AuthUser;
	runAs?: string | null;
	assumeUser?: (email: string | null) => void;
	logout: () => void;
}

export function Logout() {
	localStorage.clear();
	sessionStorage.clear();
	useCacheStore().clear();
	setTimeout(() => {
		window.location.reload();
	}, 500);
	return (
		<Modal
			size="lg"
			centered
			show={true}
		>
			<Modal.Header>
				<Modal.Title>
					Logging Out
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="text-center p-4">
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Logging Out...</span>
				</Spinner>
			</Modal.Body>
		</Modal>
	);
}

const AuthContext = createContext<AuthContextType>({
	logout: Logout,
});

export function AuthProvider({ children }: React.PropsWithChildren<{}>) {
	const location = useLocation();
	const navigate = useNavigate();
	const tokens = get();
	const fragment = new URLSearchParams(location.hash.substring(1));
	const sessionToken = fragment.get('session_token');
	const idToken = fragment.get('id_token');
	if (idToken && sessionToken) {
		const [headerEncoded, payloadEncoded] = idToken.split('.');
		const payload = JSON.parse(
			atob(payloadEncoded.replace(/-/g, '+').replace(/_/g, '/')),
		);
		tokens[payload.sub] = {
			accessToken: fragment.get('access_token'),
			idToken,
			sessionToken,
			isAdmin: payload.email?.endsWith('@newstex.com'),
			...payload,
		};
		set(tokens);
		location.hash = '';
	} else if (sessionToken && Object.values(tokens).length === 0) {
		tokens.session = {
			sessionToken,
			name: 'Session',
			email: fragment.get('email') || 'session@newstex.com',
			accountID: fragment.get('account_id') || 'session',
			accessToken: fragment.get('access_token') || '',
			idToken: sessionToken,
			isAdmin: false,
		};
		set(tokens);
	}

	const [showLoginModal, setShowLoginModal] = useState(false);

	useEffect(() => {
		if (Object.keys(tokens).length === 0) {
			setShowLoginModal(true);
			if (location.pathname === '/logout') {
				navigate('/', { replace: true });
			}
		}
	}, []);

	const ctx: AuthContextType = {
		runAs: localStorage.getItem('runAs') || sessionStorage.getItem('runAs'),
		assumeUser: (email) => {
			setRunAs(email);
			// Wait one second then redirect to the home page
			setTimeout(() => {
				window.location.href = '/';
			}, 1000);
		},
		logout: Logout,
	};

	for (const token of Object.values(tokens)) {
		if (token.type === 'user' || !token.type) {
			ctx.user = token;
		}
	}

	return (
		<>
			<LoginModal
				show={showLoginModal}
				loginUrl={login()}
			/>
			{/* ONLY show the children if the user is logged in */}
			{Object.keys(tokens).length > 0 && (
				<AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>
			)}
		</>
	);
}

export function useAuth() {
	const result = useContext(AuthContext);
	if (!result) throw new Error('useAuth must be used within an AuthProvider');
	return result;
}
