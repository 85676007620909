/*
 * Delivery Object
 */
import { z } from 'zod';

import { BaseObjectSchema } from './base';
import { UnixTimestampSchema } from './dates';
import { StatusChoiceSchema } from './status';

/**
 * Newstex Category
 * TableName: Categories
 *
 * @author: Chris Moyer <cmoyer@newstex.com>
 */
export const DeliverySchema = BaseObjectSchema.extend({
	$type: z.literal('Delivery'),
	type: z.enum(['S3', 'SFTP', 'FTP', 'HTTP']),
	status: StatusChoiceSchema,
	description: z.string().optional(),

	// Simplified Story Templates
	story_template: z.enum(['xml', 'xhtml', 'newsml', 'json']),

	products: z.array(z.string()).optional(),
	queue_name: z.string().optional(),
	distributor: z.string().optional(),
	recipient: z.string().optional(),
	recipient_name: z.string().optional(),

	// Email address of the technical contact for issues with this delivery
	tech_contact: z.string().email().optional(),

	// Last time a delivery was made to this delivery
	last_delivery: UnixTimestampSchema.optional(),

	// Default: '%(story_id)s%(ext)s'
	name_template: z.string().optional(),

	// Number of seconds to wait for stories before sending them
	// Used for batching updates
	update_frequency: z.number().optional(),

	// Encode as Base64
	encode: z.boolean().optional(),

	// If true, verify the delivery was successful
	verify: z.boolean().optional(),

	// If true, merge all stories from each publication into a single file
	merge_pubs: z.boolean().optional(),

	// If true, send updates to stories, otherwise only send version 1
	send_updates: z.boolean().optional(),

	// For HTTP/SFTP/FTP types
	url: z.string().optional(),
	host: z.string().optional(),
	username: z.string().optional(),
	password: z.string().optional(),
	pasv: z.boolean().optional(),
	path: z.string().optional(),
	port: z.number().optional(),
	method: z.enum(['POST', 'PUT']).optional(),

	// For SFTP with Private Key Authentication
	private_key: z.string().optional(),

	// For S3 types
	bucket_name: z.string().optional(),
	user_id: z.string().optional(),
	user_email: z.string().email().optional(),
});

export type Delivery = z.infer<typeof DeliverySchema>;
export type StoryTemplate = z.infer<typeof DeliverySchema.shape.story_template>;

export const STORY_TEMPLATES = DeliverySchema.shape.story_template._def.values;

export function isDelivery(item: any, tableName?: string): item is Delivery {
	return item?.$type === 'Delivery' || tableName === 'Delivery';
}
