/*
 * Dashboard shown for Distributors (Clients that receive content from Newstex)
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Publication } from '@newstex/types/content';
import { Delivery } from '@newstex/types/delivery';
import { Results } from '@newstex/types/results';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '~/components/data-table';
import { DeliveryCard } from '~/components/delivery/delivery-card';
import LoadingSpinner from '~/components/LoadingSpinner';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { StatusBadge } from '~/components/status-badge';
import { useAPI } from '~/providers/api-provider';
import { usePageTitle } from '~/providers/page-title-provider';
import { useSearch } from '~/providers/search';
import { useUserInfo } from '~/providers/user-info';

export function DistributorDashboardDetails({
	deliveries,
}: {
	deliveries: Delivery[];
}) {
	const userInfo = useUserInfo();
	const search = useSearch();
	const api = useAPI();
	const [publications, setPublications] = useState<Publication[]>([]);
	const [loading, setLoading] = useState(true);
	const [showRequestPublicationModal, setShowRequestPublicationModal] = useState(false);
	const [requestPublicationLoading, setRequestPublicationLoading] = useState(false);
	const [requestPublicationError, setRequestPublicationError] = useState<React.ReactNode | null>(null);

	useEffect(() => {
		if (requestPublicationError) {
			setTimeout(() => {
				setRequestPublicationError(null);
			}, 500);
		}
	}, [requestPublicationError]);

	// Get unique product IDs from all deliveries
	const productIds = useMemo(() => {
		const ids = new Set<string>();
		for (const delivery of deliveries) {
			for (const id of delivery.products || []) {
				ids.add(id);
			}
		}
		return Array.from(ids);
	}, [deliveries]);

	const columnHelper = createColumnHelper<Publication>();
	const columns = useMemo(() => [
		columnHelper.accessor('ai_scores', {
			header: 'Score',
			size: 50,
			cell: ({ getValue }) => {
				const scores = getValue();
				if (scores?.[`${userInfo?.distributor?.code || 'default'}`]) {
					return <PropertyDisplayValue
						propName="ai_score"
						propValue={scores[`${userInfo?.distributor?.code || 'default'}`]}
					/>;
				}
				return <small className="text-muted">[Not Set]</small>;
			},
		}),
		columnHelper.accessor('name', {
			header: 'Publication Name',
			cell: ({ row }) => (<>
				<Link to={`/publications/${row.original.$id}`}>
					{row.original.name}
				</Link>
				<div className="text-muted">{row.original.newstex_id}</div>
			</>),
		}),
		columnHelper.accessor('product_status', {
			header: 'Status',
			cell: ({ getValue }) => {
				const value = getValue() as Record<string, string>;
				// Check status for each product ID
				const statuses = productIds.map((productId) => value?.[productId] || 'Pending');

				return <StatusBadge status={statuses} />;
			},
		}),
		columnHelper.accessor('content_category', {
			header: 'Content Category',
			cell: ({ getValue }) => (
				<PropertyDisplayValue propName="content_category" propValue={getValue()} />
			),
		}),
		columnHelper.accessor('frequency', {
			header: 'Publication Frequency',
			cell: ({ getValue }) => (
				<PropertyDisplayValue propName="frequency" propValue={getValue()} />
			),
		}),
		columnHelper.accessor('last_post_date', {
			header: 'Last Post Date',
			cell: ({ getValue }) => (
				<div className="text-start">
					<PropertyDisplayValue propName="last_post_date" propValue={getValue()} />
				</div>
			),
		}),
		columnHelper.accessor('integration_completion_date', {
			header: 'Integration Date',
			cell: ({ getValue }) => (
				<div className="text-start">
					<PropertyDisplayValue propName="integration_completion_date" propValue={getValue()} />
				</div>
			),
		}),
	], [productIds, userInfo?.distributor?.code]);

	useEffect(() => {
		const fetchPublications = async () => {
			if (!search.searchClient || !userInfo?.distributor?.$id) return;

			const products = deliveries.flatMap((d) => d.products || []).filter(Boolean);
			if (products.length === 0) {
				setPublications([]);
				setLoading(false);
				return;
			}

			const response = await search.searchClient.search<Publication>({
				indexName: 'Publication',
				q: '*',
				filter_by: 'status:Active',
				sort_by: [
					// Sort first by the AI Score for our distributor
					`ai_scores.${userInfo?.distributor?.code || 'default'}:desc`,
					// Then sort by the integration completion date
					'integration_completion_date:desc',
				],
				per_page: 100,
			});

			setPublications(response.hits);
			setLoading(false);
		};

		setLoading(true);
		fetchPublications();
	}, [search.searchClient, userInfo?.distributor?.$id, deliveries]);

	if (loading) {
		return <LoadingSpinner loading={loading} />;
	}

	return (
		<>
			<Modal show={showRequestPublicationModal} onHide={() => setShowRequestPublicationModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Request a Publication</Modal.Title>
				</Modal.Header>
				<Form onSubmit={(e) => {
					e.preventDefault();
					setRequestPublicationError(null);
					// Get the form element from the event
					const formData = new FormData(e.currentTarget as HTMLFormElement);
					const pubRequest: Record<string, string> = {};
					for (const [key, value] of formData.entries()) {
						if (value) {
							pubRequest[key] = String(value);
						}
					}
					setRequestPublicationLoading(true);
					api.fetchWithAuth<Publication>('request/Publication', {
						method: 'POST',
						body: JSON.stringify(pubRequest),
					}).then((res: any) => {
						if (res.success) {
							console.log('publication requested', res);
							toast.success('Publication requested');
							setShowRequestPublicationModal(false);
						} else {
							console.error('failed to request publication', res);
							setRequestPublicationError(<div>
								<div>Failed to request publication</div>
								<div className="text-muted"><code>{res.message}</code></div>
								<ul>
									{res.items?.map((item: Publication) => (
										<li key={item.$id}>
											<Link to={`/publications/${item.$id}`}>{item.name}</Link>
										</li>
									))}
								</ul>
							</div>);
						}
					}).catch((err) => {
						console.error(err);
						setRequestPublicationError(<div>
							<div>Failed to request publication</div>
							<div className="text-muted"><code>{err.message}</code></div>
						</div>);
						toast.error(<div>
							<div>Failed to request publication</div>
							<div className="text-muted"><code>{err.message}</code></div>
						</div>);
						setShowRequestPublicationModal(true);
					}).finally(() => {
						setRequestPublicationLoading(false);
					});
				}}>
					<Modal.Body>
						{requestPublicationError ? (
							<Alert variant="danger">
								{requestPublicationError}
							</Alert>
						) : (
							<Alert variant="info">
								Please use this form to request a new publication that is not currently available in Newstex.
							</Alert>
						)}
						<Form.Group className="mb-3">
							<Form.Label>Publication URL<sup className="text-danger">*</sup></Form.Label>
							<Form.Control
								type="url"
								name="url"
								pattern="https?://.+"
								placeholder="https://example.com"
								required
								title="Please enter a valid URL starting with http:// or https://"
								disabled={requestPublicationLoading}
								onChange={(e) => {
									const url = e.target.value;
									const isValid = /^https?:\/\/[^\s/$.?#].[^\s]*$/i.test(url);
									e.target.setCustomValidity(isValid ? '' : 'Please enter a valid URL starting with http:// or https://');
								}}
							/>
							<Form.Text className="text-muted">
								URL must start with http:// or https://
							</Form.Text>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Publication Name</Form.Label>
							<Form.Control type="text" name="name" disabled={requestPublicationLoading} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Publication Description</Form.Label>
							<Form.Control as="textarea" name="description" disabled={requestPublicationLoading} />
							<Form.Text className="text-muted">
								Please provide a description of the publication.
							</Form.Text>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer className="d-flex justify-content-between">
						<Button variant="danger" onClick={() => setShowRequestPublicationModal(false)} disabled={requestPublicationLoading}>
							Cancel
						</Button>
						<Button variant="success" type="submit" disabled={requestPublicationLoading}>
							Request Publication
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Container>
				<Row>
					<Col>
						<Card>
							<Card.Header>
								<h5 className="card-heading">
									Publications Awaiting Review
									<Button variant="primary" onClick={() => {
										setShowRequestPublicationModal(true);
									}}>
										<FontAwesomeIcon icon={['fas', 'plus']} className="me-2" />
										Request Publication
									</Button>
								</h5>
							</Card.Header>
							<Card.Body>
								<DataTable
									columns={columns}
									items={publications}
									striped
								/>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export function DistributorDashboard() {
	const api = useAPI();
	const search = useSearch();
	const userInfo = useUserInfo();
	const { setTitle } = usePageTitle();

	const [loading, setLoading] = useState(true);
	const [deliveries, setDeliveries] = useState<Delivery[]>([]);
	const [showCreateDeliveryModal, setShowCrateDeliveryModal] = useState(false);

	useEffect(() => {
		if (userInfo?.distributor?.$id) {
			setLoading(true);
			api.fetchWithAuth<Results<Delivery>>(`/resources/Delivery?distributor=${userInfo.distributor.$id}`).then((res) => {
				setDeliveries(res.items);
			}).finally(() => {
				setLoading(false);
			});
		}
	}, [userInfo?.distributor?.$id]);

	useEffect(() => {
		setTitle('Distributor Dashboard');
	}, [setTitle]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(false);
		};
		setLoading(true);
		fetchData();
	}, [api, search]);

	return (<>
		<Modal show={showCreateDeliveryModal} onHide={() => setShowCrateDeliveryModal(false)}>
			<Modal.Header>
				<Modal.Title>Create Delivery</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>NOT IMPLEMENTED YET</p>
			</Modal.Body>
		</Modal>
		<Container fluid className="px-lg-4 px-xl-5">
			{loading && <LoadingSpinner loading={loading} />}
			{!loading && (<>
				<Row className="mb-4">
					<Col>
						<DistributorDashboardDetails deliveries={deliveries} />
					</Col>
					<Col sm={12} md={8} lg={4} xl={3} className="border-start">
						<h6 className="text-muted">Deliveries</h6>
						<Container>
							{deliveries?.length ? (
								<>
									{deliveries.map((delivery) => (
										<Row key={delivery.$id} className="mb-4">
											<Col>
												<DeliveryCard item={delivery} />
											</Col>
										</Row>
									))}
								</>
							) : (
								<small className="text-muted">No deliveries found</small>
							)}
							<Row className="mt-4">
								<Col className="text-center">
									<Button variant="success" onClick={() => {
										setShowCrateDeliveryModal(true);
									}}>
										<FontAwesomeIcon icon={['fas', 'plus']} className="me-2" />
										Add Delivery
									</Button>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			</>)}
		</Container>
	</>);
}
