import type { Alert } from '@newstex/types/alert';
import { useState } from 'react';
import {
	Col,
	Form,
	InputGroup,
	Row,
} from 'react-bootstrap';

import { ScheduleInput } from '../schedule-input';

import EditableMultiValueField from './multi-value';

export const AVAILABLE_COLLECTIONS = [
	{ value: 'Story', label: 'Stories' },
	{ value: 'Feed', label: 'Feeds' },
	{ value: 'Publisher', label: 'Publishers' },
	{ value: 'Publication', label: 'Publications' },
	{ value: 'Delivery', label: 'Deliveries' },
] as const;

export const CHECK_PERIODS = [
	{ value: '1h', label: 'Last Hour', seconds: 3600 },
	{ value: '24h', label: 'Last 24 Hours', seconds: 86400 },
	{ value: '7d', label: 'Last 7 Days', seconds: 604800 },
	{ value: '30d', label: 'Last 30 Days', seconds: 2592000 },
] as const;

export type AvailableCollection = typeof AVAILABLE_COLLECTIONS[number]['value'];
export type CheckPeriod = typeof CHECK_PERIODS[number]['value'];

interface AlertFormProps {
	item?: Alert;
	isEdit?: boolean;
	disabled?: boolean;
}

export function getPeriodSeconds(value: string): number {
	return CHECK_PERIODS.find((p) => p.value === value)?.seconds || 86400; // default to 24h
}

export function getPeriodValue(seconds: number): string {
	return CHECK_PERIODS.find((p) => p.seconds === seconds)?.value || '24h';
}

export function AlertForm({ item, isEdit, disabled }: AlertFormProps) {
	const [formUpdates, setFormUpdates] = useState<Partial<Alert>>({});

	// Email validation function
	const validateEmail = (email: string) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	return (
		<>
			<Row>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label className="required">Name</Form.Label>
						<Form.Control
							type="text"
							name="name"
							required
							disabled={disabled}
							defaultValue={item?.name}
							placeholder="Enter alert name"
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label className="optional">Description</Form.Label>
						<Form.Control
							as="textarea"
							name="description"
							rows={5}
							disabled={disabled}
							defaultValue={item?.description}
							placeholder="Enter alert description"
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label className="required">Collection</Form.Label>
						{isEdit ? (
							<Form.Control
								type="text"
								value={item?.collection}
								disabled
							/>
						) : (
							<Form.Select
								name="collection"
								required
								disabled={disabled}
								defaultValue="Story"
							>
								{AVAILABLE_COLLECTIONS.map(({ value, label }) => (
									<option key={value} value={value}>
										{label}
									</option>
								))}
							</Form.Select>
						)}
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label>Search Query</Form.Label>
						<Form.Control
							type="text"
							name="search"
							placeholder="*"
							defaultValue={item?.search.q}
							disabled={disabled}
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label>Search Filters</Form.Label>
						<Form.Control
							type="text"
							name="search_filters"
							placeholder="products:news"
							defaultValue={item?.search.filter_by}
							disabled={disabled}
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label>Schedule</Form.Label>
						<ScheduleInput
							value={item?.schedule || ''}
						/>
						<Form.Text className="text-muted">
							Enter a schedule like "Every weekday from 9am to 5pm" or a cron expression
						</Form.Text>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label>Expected Range</Form.Label>
						<Row>
							<Col>
								<Form.Control
									type="number"
									name="min"
									placeholder="Min"
									defaultValue={item?.expected?.min}
									disabled={disabled}
								/>
								<Form.Text className="text-muted">
									Minimum value to expect
								</Form.Text>
							</Col>
							<Col>
								<Form.Control
									type="number"
									name="max"
									placeholder="Max"
									defaultValue={item?.expected?.max}
									disabled={disabled}
								/>
								<Form.Text className="text-muted">
									Maximum value to expect
								</Form.Text>
							</Col>
							<Col>
								<InputGroup>
									<Form.Control
										type="number"
										name="diff"
										min={0}
										step={10}
										max={100}
										placeholder="Diff"
										defaultValue={item?.expected?.diff}
										disabled={disabled}
									/>
									<InputGroup.Text>%</InputGroup.Text>
								</InputGroup>
								<Form.Text className="text-muted">
									Percentage difference from previous average values
								</Form.Text>
							</Col>
						</Row>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label>Check Field</Form.Label>
						<Form.Control
							type="text"
							name="check_field"
							placeholder="found"
							defaultValue={item?.check_field}
							disabled={disabled}
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label>Alert Recipients</Form.Label>
						<EditableMultiValueField
							typeName="Alert"
							propKey="emails"
							item={item}
							updates={formUpdates}
							setUpdates={setFormUpdates}
							trimValues
							validate={validateEmail}
							showControlOnly
						/>
						<Form.Text className="text-muted">
							Enter email addresses to receive alerts. One email per line. Invalid emails will be highlighted in red.
						</Form.Text>
					</Form.Group>
				</Col>
			</Row>
		</>
	);
}
