import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserError } from '@newstex/core/errors';
import { DetectFeedResponse } from '@newstex/types/detect-feed';
import { useState } from 'react';
import {
	Alert,
	Badge,
	Button,
	Card,
	Col,
	Container,
	Form,
	ListGroup,
	OverlayTrigger,
	Row,
	Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DateTime } from '~/components/date-time';
import PageHeader from '~/components/PageHeader';
import { useAWS } from '~/providers/aws-provider';

interface FeedValidationInput {
	url: string;
	feed_url?: string;
}

export function FeedValidatorPage() {
	const aws = useAWS();
	const [validating, setValidating] = useState(false);
	const [results, setResults] = useState<DetectFeedResponse>();
	const [error, setError] = useState<string>();

	const validateFeed = async (input: FeedValidationInput) => {
		setValidating(true);
		setError(undefined);
		try {
			// If it's a direct feed URL, use parseFeed directly
			if (input.url.includes('/feed') || input.url.includes('.xml') || input.url.includes('.rss')) {
				const result = await aws.detect({
					feed_url: input.url,
				});
				setResults(result);
			} else {
				// Otherwise, let the detect function handle finding the feed first
				const result = await aws.detect({
					url: input.url,
				});
				setResults(result);
			}
		} catch (err) {
			console.error('Feed validation error:', err);
			if (err instanceof UserError) {
				// Handle specific error cases from parseFeed
				switch (err.code) {
					case 'REDIRECTS_TO_AUTOMATTIC_FEED':
						setError('This feed redirects to WordPress.com');
						break;
					case 'DETECTION_FAILED':
						setError('No valid feed found at this URL');
						break;
					default:
						setError(err.message);
				}
			} else {
				setError('An unexpected error occurred while validating the feed');
			}
		} finally {
			setValidating(false);
		}
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const form = e.currentTarget;
		const formData = new FormData(form);
		const url = formData.get('url') as string;
		if (url) {
			validateFeed({ url });
		}
	};

	return (
		<Container fluid>
			<PageHeader title="Feed Validator" />
			{/* Input form for URL */}
			<Row>
				<Col>
					<Card>
						<Card.Body>
							<Form onSubmit={handleSubmit}>
								<Form.Group>
									<Form.Label>Feed URL or Website URL</Form.Label>
									<Form.Control
										type="url"
										name="url"
										placeholder="https://example.com/feed"
										required
									/>
									<Form.Text className="text-muted">
										Enter a direct feed URL or website URL to validate
									</Form.Text>
								</Form.Group>
								<Button
									type="submit"
									disabled={validating}
									className="mt-3"
								>
									{validating ? (
										<>
											<FontAwesomeIcon icon={faSpinner} spin className="me-2" />
											Validating...
										</>
									) : 'Validate Feed'}
								</Button>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			{error && (
				<Row className="mt-3">
					<Col>
						<Alert variant="danger">
							{error}
						</Alert>
					</Col>
				</Row>
			)}

			{/* Results display */}
			{results && (
				<Row className="mt-3">
					<Col>
						<Card>
							<Card.Header>
								<h5>Validation Results for {results.name || results.feed_url || results.url}
									{results.qualified ? (
										<Badge bg="success" className="ms-2">
											Qualified
										</Badge>
									) : (
										<OverlayTrigger
											placement="top"
											overlay={<Tooltip>
												{results.rejection_reasons?.length ? <div>
													{results.rejection_reasons.map((reason, i) => (
														<div key={i}>{reason}</div>
													))}
												</div> : 'Technical Qualification Failed'}
											</Tooltip>}
										>
											<Badge bg="danger" className="ms-2">
												Not Qualified
											</Badge>
										</OverlayTrigger>
									)}
								</h5>
							</Card.Header>
							<Card.Body>
								{/* Display feed metadata */}
								<div>
									<strong>Feed URL: </strong>
									<a href={results.feed_url} target="_blank" rel="noopener noreferrer">
										{results.feed_url} <FontAwesomeIcon icon={faExternalLinkAlt} />
									</a>
								</div>
								{/* Display any errors or issues */}
								{results.feed_error && (
									<Alert variant="danger" className="mt-3">
										<strong>ERROR:</strong> {results.feed_error.message}
									</Alert>
								)}

								{/* Display any articles found */}
								{results.articles?.length > 0 && (
									<>
										<h6 className="mt-3">Articles</h6>
										<ListGroup>
											{results.articles.map((article, i) => (
												<ListGroup.Item key={i}>
													<Link to={article.link} target="_blank" rel="noopener noreferrer">
														<div><strong>{article.title}</strong> <FontAwesomeIcon icon={faExternalLinkAlt} /></div>
													</Link>
													<small className="text-muted">
														<DateTime ts={article.date} inline />
														{article.author && (
															<span className="ps-1">
																by <i>{article.author}</i>
															</span>
														)}
													</small>
													<div className="text-muted">
														{article.summary || article.description}
													</div>
													{!article.guid && (
														<Badge bg="danger" className="me-1">No GUID</Badge>
													)}
													{!article.link && (
														<Badge bg="danger" className="me-1">No Link</Badge>
													)}
													{article.stats?.word_count ? (
														<Badge
															bg={article.stats.word_count > 250 ? 'success' : 'danger'}
															className="me-1">{article.stats.word_count.toLocaleString('en-US')} words</Badge>
													) : (
														<Badge bg="warning" className="me-1">Feed Not Full Text</Badge>
													)}
													{article.categories?.length > 0 ? article.categories.map((
														articleCategoryCode,
														articleCategoryIndex,
													) => (
														<Badge
															key={articleCategoryIndex}
															bg="secondary"
															className="me-1"
														>
															{articleCategoryCode}
														</Badge>
													)) : (
														<Badge bg="warning" className="me-1">No Categories</Badge>
													)}
												</ListGroup.Item>
											))}
										</ListGroup>
									</>
								)}

							</Card.Body>
						</Card>
					</Col>
				</Row>
			)}
		</Container>
	);
}
