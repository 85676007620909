import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartConfig, Report } from '@newstex/types/report';
import { Results } from '@newstex/types/results';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

import ChartConfigModal from '../modals/chart-config-modal';

interface EditChartButtonProps {
	report: Report;
	data?: Results<any>;
	onSave: (config: ChartConfig) => Promise<void>;
	variant?: string;
	size?: 'sm' | 'lg';
}

export default function EditChartButton({
	report,
	data,
	onSave,
	variant = 'outline-primary',
	size,
}: EditChartButtonProps) {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button
				variant={variant}
				size={size}
				onClick={() => setShowModal(true)}
			>
				<FontAwesomeIcon icon={faCog} /> Configure Chart
			</Button>

			<ChartConfigModal
				show={showModal}
				onClose={(saved) => {
					setShowModal(false);
				}}
				report={report}
				data={data}
				onSave={onSave}
			/>
		</>
	);
}
