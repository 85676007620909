import { Publication } from '@newstex/types/content';
import { Delivery } from '@newstex/types/delivery';
import type { RedeliveryRequest } from '@newstex/types/requests/redelivery';
import { Results } from '@newstex/types/results';
import { useEffect, useState } from 'react';
import {
	Alert,
	Button,
	Form,
	Modal,
	Spinner,
} from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useAPI } from '~/providers/api-provider';

export interface RedeliverModalProps {
	pub: Publication;
	hide: (redelivered?: boolean) => void;
}

interface DeliveryOption {
	value: string;
	label: string;
}

export function RedeliverModal({ hide, pub }: RedeliverModalProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOption[]>([]);
	const [selectedDelivery, setSelectedDelivery] = useState<DeliveryOption | null>(null);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

	const api = useAPI();

	useEffect(() => {
		async function fetchDeliveries() {
			try {
				const deliveries = await api.fetchWithAuth<Results<Delivery>>('resources/Delivery?status=Active');
				const options = deliveries.items.map((delivery) => ({
					value: delivery.$id!,
					label: `${delivery.name} (
						${delivery.username || delivery.user_id || delivery.user_email
						? `${delivery.username || delivery.user_id || delivery.user_email}@`
						: ''
					}${delivery.host || delivery.bucket_name || delivery.queue_name || delivery.$id}
					)`,
				}));

				setDeliveryOptions(options);
			} catch (e) {
				console.error('Error fetching deliveries:', e);
				setError('Failed to fetch deliveries');
			}
		}

		fetchDeliveries();
	}, [api]);

	const handleRedeliver = async () => {
		if (!selectedDelivery) {
			setError('Please select a delivery');
			return;
		}

		setIsLoading(true);
		setError('');
		if (!pub.newstex_id) {
			setError('Publication ID not found');
			return;
		}

		if (!selectedDelivery?.value) {
			setError('Please select a delivery');
			return;
		}

		const redeliverData: RedeliveryRequest = {
			pubID: pub.$id!,
			deliveryID: selectedDelivery.value,
			...(startDate && { start: startDate }),
			...(endDate && { end: endDate }),
		};

		try {
			const response = await api.fetchWithAuth('redelivery', {
				method: 'POST',
				body: JSON.stringify(redeliverData),
			});

			console.log('Redelivery initiated:', response);
			toast.success(<div>
				<h6>Starting Redelivery of <i>{pub.newstex_id}</i> to <i>{selectedDelivery.label}</i></h6>
				<hr/>
				<div>Start Date: {startDate}</div>
				<div>End Date: {endDate || 'now'}</div>
			</div>);
			hide(true);
		} catch (e) {
			console.error('Error initiating redelivery:', e);
			setError('Failed to initiate redelivery');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal
			size="lg"
			show={true}
			onHide={() => hide(false)}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					Redeliver Content for <i>{pub.name}</i>
					<small className="text-muted ms-2">
						{pub.newstex_id}
					</small>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error && <Alert variant="danger">{error}</Alert>}
				<Form>
					<Form.Group className="mb-3">
						<Form.Label>Delivery</Form.Label>
						<Select
							options={deliveryOptions}
							value={selectedDelivery}
							onChange={(option) => setSelectedDelivery(option as DeliveryOption)}
							isDisabled={isLoading}
							placeholder="Select a delivery..."
							required
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Start Date</Form.Label>
						<Form.Control
							type="datetime-local"
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
							disabled={isLoading}
							required
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>End Date (optional)</Form.Label>
						<Form.Control
							type="datetime-local"
							value={endDate}
							onChange={(e) => setEndDate(e.target.value)}
							disabled={isLoading}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{isLoading && <Spinner animation="border" size="sm" />}
				<Button variant="secondary" onClick={() => hide(false)} disabled={isLoading}>Cancel</Button>
				<Button variant="primary" onClick={handleRedeliver} disabled={isLoading || !selectedDelivery}>
					Redeliver
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
