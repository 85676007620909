// category/:id
import {
	faDownload,
	faMinus,
	faPlus,
	faSave,
	faSync,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HotTable, HotTableClass } from '@handsontable/react';
import { arrayEqual } from '@newstex/core/array-utils';
import type { Category, CategoryMarker } from '@newstex/types/category';
import { Results } from '@newstex/types/results';
import Handsontable from 'handsontable';
import iso6391 from 'iso-639-1';
import {
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Modal,
	Row,
	Table,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import EditButton from '~/components/edit-button';
import LoadingSpinner from '~/components/LoadingSpinner';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { RefreshButton } from '~/components/refresh-button';
import ViewStoriesButton from '~/components/view-stories-button';
import { useAPI } from '~/providers/api-provider';

export function CategoryPage() {
	const params = useParams();
	const api = useAPI();
	const [category, setCategory] = useState<Category>();
	const [categoryMarkers, setCategoryMarkers] = useState<(string | number | undefined)[][]>();
	const [categoryMarkersOriginal, setCategoryMarkersOriginal] = useState<(string | number | undefined)[][]>();
	const [error, setError] = useState<string | null>(null);
	const [saving, setSaving] = useState(false);
	const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
	const [loading, setLoading] = useState(true);
	const [changedRows, setChangedRows] = useState<number[]>([]);
	const hotRef = useRef<HotTableClass | null>(null);

	const fetchData = async (refresh = false) => {
		const resp = await api.fetchWithAuth<Results<CategoryMarker> & { parent: Category }>(
			`resources/Category/${params.id}/markers`,
			refresh ? { cache: 'reload' } : undefined,
		);
		setCategory(resp.parent);
		if (resp.items?.length) {
			// NOTE: Do not just set these markers to a single array,
			// we need them generated separately since the HotTable is destructive and will
			// change the internal array references.
			setCategoryMarkers(resp.items.map((item) => [item.text, item.language || 'ALL', item.weight]));
			setCategoryMarkersOriginal(resp.items.map((item) => [item.text, item.language || 'ALL', item.weight]));
		} else {
			setCategoryMarkers([]);
			setCategoryMarkersOriginal([]);
		}
		setLoading(false);
	};

	/**
	 * Add, Delete, or Update category markers
	 */
	const updateCategoryMarkers = async (updates: {
		add?: CategoryMarker[];
		remove?: CategoryMarker[];
		update?: CategoryMarker[];
	}) => {
		setSaving(true);
		try {
			await api.fetchWithAuth(`resources/Category/${params.id}/markers`, {
				method: 'POST',
				body: JSON.stringify(updates),
			});
			setError(null);
			await fetchData(true);
		} catch (e: any) {
			console.error('Error updating category markers', e);
			setError(e.message || String(e));
		}
		setSaving(false);
	};

	const languageOptions = useMemo(() => {
		return iso6391.getAllCodes().reduce((acc, code) => ({
			...acc,
			[code]: `${iso6391.getName(code)} (${code})`,
		}), {
			ALL: 'ALL',
		});
	}, []);

	const handleAfterChange = (changes: any, source: string) => {
		if (source !== 'loadData' && changes) { // Check if the change is not from loading data
			for (const change of changes) {
				// Make sure the changed row is actually different
				const originalRow = categoryMarkersOriginal?.[change[0]];
				const newRow = categoryMarkers?.[change[0]];
				if (!arrayEqual(originalRow || [], newRow || [])) {
					if (!changedRows.includes(change[0])) {
						console.log('Changes:', change, source);
						setChangedRows((prev) => {
							if (!prev.includes(change[0])) {
								return [...prev, change[0]];
							}
							return prev;
						});
					}
				} else if (changedRows.includes(change[0])) {
					setChangedRows((prev) => {
						return prev.filter((id) => id !== change[0]);
					});
				}
			}
		}
	};
	const convertToCategoryMarker = (row?: (string | number | undefined)[]): CategoryMarker | null => {
		if (!row?.[0]) {
			return null;
		}

		return {
			$type: 'CategoryMarker',
			category: category?.code || '',
			text: String(row[0] || ''),
			language: String(row[1] || 'ALL') as CategoryMarker['language'],
			weight: Number(row[2] || 0),
		};
	};

	const saveChanges = async () => {
		if (changedRows.length > 0) {
			const updates: {
				add: CategoryMarker[];
				remove: CategoryMarker[];
				update: CategoryMarker[];
			} = {
				add: [],
				remove: [],
				update: [],
			};
			for (const rowNumber of changedRows) {
				const newItem = convertToCategoryMarker(categoryMarkers?.[rowNumber]);
				const oldItem = convertToCategoryMarker(categoryMarkersOriginal?.[rowNumber]);
				if (!oldItem && newItem && newItem.text && newItem.weight != null) {
					console.log('ADD', newItem);
					// TODO: Check if the marker already exists
					updates.add.push(newItem);
				} else if (!newItem && oldItem) {
					console.log('REMOVE', oldItem);
					updates.remove.push(oldItem);
				} else if (newItem && oldItem) {
					// If the text is different, we need to do a delete and an add
					if (newItem.text !== oldItem.text) {
						console.log('REPLACE', {
							oldItem,
							newItem,
						});
						updates.remove.push(oldItem);
						updates.add.push(newItem);
					} else {
						console.log('UPDATE', {
							oldItem,
							newItem,
						});
						updates.update.push(newItem);
					}
				}
			}
			await updateCategoryMarkers(updates);
		}
		setChangedRows([]);
		setShowSaveChangesModal(false);
		setSaving(false);
	};

	const renderMarkerStatus = (row: number) => {
		if (!categoryMarkersOriginal?.[row]?.[0]) {
			return <FontAwesomeIcon icon={faPlus} />;
		}

		if (!categoryMarkers?.[row]?.[0]) {
			return <FontAwesomeIcon icon={faMinus} />;
		}
		return <FontAwesomeIcon icon={faSync} />;
	};

	const exportToCSV = () => {
		const hot = hotRef.current?.hotInstance;
		if (hot) {
			const exportPlugin = hot.getPlugin('exportFile');
			exportPlugin.downloadFile('csv', {
				bom: false,
				columnDelimiter: ',',
				columnHeaders: true,
				fileExtension: 'csv',
				filename: `CategoryMarkers-${category?.code}-${new Date().toISOString().split('T')[0].split('-').join('')}`,
				mimeType: 'text/csv',
				rowDelimiter: '\r\n',
			});
		}
	};

	useEffect(() => {
		fetchData();
		setChangedRows([]);
	}, [params]);

	return (
		<Container fluid>
			{showSaveChangesModal && (
				<Modal
					show={showSaveChangesModal}
					onHide={() => setShowSaveChangesModal(false)}
				>
					<Modal.Header closeButton>
						<Modal.Title>Save Category Marker Changes</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Table>
							<thead>
								<tr>
									<th></th>
									<th>Text</th>
									<th>Language</th>
									<th>Weight</th>
								</tr>
							</thead>
							<tbody>
								{changedRows.map((row) => (
									<tr key={row}>
										<td>
											{renderMarkerStatus(row)}
										</td>
										<td>
											<div className="text-muted text-decoration-line-through">{categoryMarkersOriginal?.[row]?.[0]}</div>
											<div>{categoryMarkers?.[row]?.[0]}</div>
										</td>
										<td>
											<div className="text-muted text-decoration-line-through">{categoryMarkersOriginal?.[row]?.[1]}</div>
											<div>{categoryMarkers?.[row]?.[1]}</div>
										</td>
										<td>
											<div className="text-muted text-decoration-line-through">{categoryMarkersOriginal?.[row]?.[2]}</div>
											<div>{categoryMarkers?.[row]?.[2]}</div>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Modal.Body>
					<Modal.Footer>
						{saving && <LoadingSpinner loading={saving} />}
						{error && <Alert variant="danger">{error}</Alert>}
						<Button disabled={saving} variant="secondary" onClick={() => setShowSaveChangesModal(false)}>Cancel</Button>
						<Button disabled={saving} variant="success" onClick={saveChanges}>Save</Button>
					</Modal.Footer>
				</Modal>
			)}
			<div className="title-wrapper pt-30">
				<Row className="align-items-center">
					<Col md={6}>
						<div className="title">
							<h2>
								{category?.name}
							</h2>
							<h6 className="text-muted">
								{category?.code}
							</h6>
						</div>
					</Col>
					<Col md={6} className="text-end">
						<div className="breadcrumb-wrapper">
							<nav aria-label="breadcrumb" className="mb-0">
								<ol className="breadcrumb">
									<li className="breadcrumb-item active">
										<a>Category</a>
									</li>
								</ol>
							</nav>
							<div className="float-end">
								<span className="ps-1">
									<EditButton item={{
										$type: 'Category',
										$id: category?.code,
										...category && {
											name: category.name,
											code: category.code,
											type: category.type,
											description: category.description,
											category_map: category.category_map,
											negated_by: category.negated_by,
										},
									}} typeHint="Category" size="sm" refreshHandler={() => {
										return fetchData(true);
									}} />
								</span>
								<span className="ps-1">
									<ViewStoriesButton category={category?.code} size="sm" />
								</span>
								<span className="ps-1">
									<RefreshButton size="sm" refreshHandler={() => {
										return fetchData(true);
									}} />
								</span>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			<hr />

			<LoadingSpinner loading={loading} />

			{category && (
				<Row>
					<Col md={6} xxl={4}>
						<Card className="category">
							<Card.Header>
								<Card.Title>General Info</Card.Title>
							</Card.Header>
							<Card.Body>
								<dl className="row">
									<dt className="col-sm-3">Name</dt>
									<dd className="col-sm-9"><PropertyDisplayValue propName="name" propValue={category.name} /></dd>

									<dt className="col-sm-3">Code</dt>
									<dd className="col-sm-9"><PropertyDisplayValue propName="code" propValue={category.code} /></dd>

									<dt className="col-sm-3">Type</dt>
									<dd className="col-sm-9"><PropertyDisplayValue propName="type" propValue={category.type} /></dd>

									<dt className="col-sm-3">Description</dt>
									<dd className="col-sm-9"><PropertyDisplayValue propName="description" propValue={category.description} /></dd>
								</dl>
							</Card.Body>
						</Card>
					</Col>
					<Col md={6} xxl={4}>
						<Card className="category">
							<Card.Header>
								<Card.Title>Mapping Info</Card.Title>
							</Card.Header>
							<Card.Body>
								<dl className="row">
									<dt className="col-sm-3">Category Map</dt>
									<dd className="col-sm-9"><PropertyDisplayValue propName="category_map" propValue={category.category_map} /></dd>

									<dt className="col-sm-3">Negated By</dt>
									<dd className="col-sm-9"><PropertyDisplayValue propName="negated_by" propValue={category.negated_by} /></dd>
								</dl>
							</Card.Body>
						</Card>
					</Col>
					{category.story_count && (
						<Col md={6} xxl={4}>
							<Card className="category">
								<Card.Header>
									<Card.Title>Stats</Card.Title>
								</Card.Header>
								<Card.Body>
									<dl className="row">
										<dt className="col-sm-3">Story Count</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="story_count" propValue={category.story_count} /></dd>

										<dt className="col-sm-3">Last Applied</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="last_applied" propValue={category.last_applied} /></dd>
									</dl>
								</Card.Body>
							</Card>
						</Col>
					)}
				</Row>
			)}

			<Row>
				<Col md={12}>
					<Card className="category-markers mt-4 mb-4">
						<Card.Header>
							<Card.Title>Markers</Card.Title>
						</Card.Header>
						<Card.Body>
							<HotTable
								undo
								filters
								data={categoryMarkers || []}
								dropdownMenu={{
									items: {
										filter_by_value: {
											// Hide the filter by value menu item from all columns but the
											// second one
											hidden() {
												return this.getSelectedRangeLast()!.to.col !== 1;
											},
										},
										filter_by_condition: {
											// Hide the filter by condition menu on the second column
											hidden() {
												return this.getSelectedRangeLast()!.to.col === 1;
											},
										},
										filter_action_bar: {
											hidden() {
												return false;
											},
										},
									},
								}}
								afterChange={handleAfterChange}
								colHeaders={[
									'Text',
									'Language',
									'Weight',
								]}
								ref={hotRef}
								columns={[
									{
										type: 'text',
										condition: 'custom',
										renderer: (instance, td, row, col, prop, value, cellProperties) => {
											Handsontable.renderers.TextRenderer(
												instance,
												td,
												row,
												col,
												prop,
												value?.toUpperCase()?.trim(),
												cellProperties,
											);
										},
									},
									{
										type: 'select',
										selectOptions: languageOptions,
										strict: true,
										allowInvalid: false,
										trimDropdown: true,
										renderer: (instance, td, row, col, prop, value, cellProperties) => {
											Handsontable.renderers.DropdownRenderer(
												instance,
												td,
												row,
												col,
												prop,
												value ? `${iso6391.getName(value)} (${value})` : value,
												cellProperties,
											);
										},

									},
									{
										type: 'numeric',
										condition: 'custom',
										renderer: (instance, td, row, col, prop, value, cellProperties) => {
											Handsontable.renderers.NumericRenderer(instance, td, row, col, prop, value, cellProperties);
											if (value < 0) {
												if (!td.classList.contains('danger')) {
													td.classList.add('danger');
												}

												if (td.classList.contains('success')) {
													td.classList.remove('success');
												}
											} else if (value >= 10) {
												if (!td.classList.contains('success')) {
													td.classList.add('success');
												}

												if (td.classList.contains('danger')) {
													td.classList.remove('danger');
												}
											}
										},
									},
								]}
								height="auto"
								licenseKey="non-commercial-and-evaluation"
								stretchH="all"
								minSpareRows={1}
								minSpareCols={0}
								// Do not allow sorting if there are any changes, this will mess up the
								// changed rows detector
								columnSorting={!changedRows.length}
							/>
						</Card.Body>
						<Card.Footer>
							<Button
								onClick={exportToCSV}
								variant="outline-secondary"
							>
								<FontAwesomeIcon icon={faDownload} /> Export to CSV
							</Button>
							<Button
								onClick={() => setShowSaveChangesModal(true)}
								disabled={changedRows.length === 0}
								variant="success"
								className="ms-2 float-end"
							>
								<FontAwesomeIcon icon={faSave} /> Save Changes
							</Button>

						</Card.Footer>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
