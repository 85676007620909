import { Distributor } from '@newstex/types/distributor';
import { useState } from 'react';
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAPI } from '~/providers/api-provider';

interface CreateDistributorModalProps {
	show: boolean;
	onClose: (saved: boolean) => void;
}

export function CreateDistributorModal({
	show,
	onClose,
}: CreateDistributorModalProps) {
	const navigate = useNavigate();
	const api = useAPI();
	const [newDistributor, setNewDistributor] = useState<Partial<Distributor>>({});
	const [error, setError] = useState<string | null>(null);
	const [saving, setSaving] = useState(false);

	const handleSave = async () => {
		if (!newDistributor.name?.trim()) {
			setError('Name is required');
			return;
		}

		setSaving(true);
		setError(null);

		try {
			const response = await api.fetchWithAuth<Distributor>('resources/Distributor', {
				method: 'POST',
				body: JSON.stringify(newDistributor),
			});

			if (response) {
				onClose(true);
				toast.success('Distributor created successfully', {
					onClick: () => {
						navigate(`/distributor/${response.$id}`);
					},
				});
			} else {
				console.error('Failed to save distributor');
				toast.error(`Failed to save distributor: ${response}`);
			}
		} catch (err) {
			console.error('Error saving distributor:', err);
			setError(String(err));
		} finally {
			setSaving(false);
		}
	};

	// Update queue name when title changes
	const handleNameChange = (name: string) => {
		setNewDistributor({
			...newDistributor,
			name,
		});
	};

	return (
		<Modal show={show} onHide={() => onClose(false)} size="xl">
			<Modal.Header closeButton>
				<Modal.Title>Create New Distributor</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error && <Alert className="text-center mb-3" variant="danger">{error}</Alert>}
				<Form>
					<Container fluid>
						<Row className="mb-3">
							<Col>
								<Form.Group>
									<Form.Label>Name</Form.Label>
									<Form.Control
										type="text"
										value={newDistributor.name || ''}
										onChange={(e) => handleNameChange(e.target.value)}
										required
									/>
								</Form.Group>
							</Col>
						</Row>
					</Container>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onClose(false)} disabled={saving}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleSave} disabled={saving}>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
