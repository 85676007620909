import {
	faBroom,
	faDownload,
	faEdit,
	faGear,
	faPlus,
	faPlusSquare,
	faRightFromBracket,
	faSave,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dashboard, DashboardItem } from '@newstex/types/dashboard';
import { ChartConfig, Parameter, Report } from '@newstex/types/report';
import { kebab as kebabCase, title as titleCase } from 'case';
import React, {
	createRef,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	Badge,
	Button,
	Card,
	Container,
	Form,
	Modal,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Responsive, WidthProvider } from 'react-grid-layout';
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { AccessGroupSelect } from '~/components/access-group-select';
import DataTable, { DataTableMethods } from '~/components/data-table';
import { RefreshButton } from '~/components/refresh-button';
import ReportChart from '~/components/report-chart';
import { RequirePermissions } from '~/components/require-permissions';
import { useSetPageTitle } from '~/hooks/use-set-page-title';
import { useAPI } from '~/providers/api-provider';
import { useConfirm } from '~/providers/confirm';

const ResponsiveGridLayout = WidthProvider(Responsive);

const MIN_WIDTH = 2;
const MIN_HEIGHT = 6;
const TABLE_MIN_HEIGHT = 12;
const ROW_HEIGHT = 25;

interface ReportOption {
	value: string;
	label: string;
	description?: string;
	category?: string;
}

interface CombinedParameter extends Parameter {
	reportIds: string[]; // IDs of reports that use this parameter
}

export function DashboardPage() {
	const api = useAPI();
	const navigate = useNavigate();
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [showMetadataModal, setShowMetadataModal] = useState(false);
	const [showAddReportModal, setShowAddReportModal] = useState(false);
	const [showReportConfigModal, setShowReportConfigModal] = useState<string | null>(null);
	const [tempChartConfig, setTempChartConfig] = useState<ChartConfig | null>(null);
	const [dashboard, setDashboard] = useState<Dashboard | null>(null);
	const [originalDashboard, setOriginalDashboard] = useState<Dashboard | null>(null);
	const [reports, setReports] = useState<Record<string, Report>>({});
	const [reportData, setReportData] = useState<Record<string, any>>({});
	const [availableReports, setAvailableReports] = useState<Report[]>([]);
	const [loadingReportId, setLoadingReportId] = useState<string | null>(null);
	const [metadataForm, setMetadataForm] = useState({
		name: '',
		description: '',
		columns: 12,
		access_groups: [],
	});
	const [combinedParameters, setCombinedParameters] = useState<Record<string, CombinedParameter>>({});
	const [paramValues, setParamValues] = useState<Record<string, string>>({});
	const { confirmDialog } = useConfirm();
	const tableRefs = useRef<Record<string, React.RefObject<DataTableMethods>>>({});

	// Set a default page title, will be updated when dashboard loads
	useSetPageTitle(dashboard?.name || 'Dashboard', [dashboard?.name]);

	const fetchDashboard = async (refresh = false) => {
		if (!id || id === 'new') return;

		setLoading(true);
		try {
			const dashboardResponse = await api.fetchWithAuth<{ items: Dashboard[] }>(
				`dashboards/${id}`,
				refresh ? { cache: 'reload' } : undefined,
			);
			if (dashboardResponse?.items?.[0]) {
				const dashboardData = dashboardResponse.items[0];
				setDashboard(dashboardData);
				setOriginalDashboard(JSON.parse(JSON.stringify(dashboardData))); // Deep copy for comparison

				// Fetch all reports used in the dashboard
				const reportIds = dashboardData.items.map((item) => item.reportId);
				const reportPromises = reportIds.map((reportId) => api.fetchWithAuth<{ items: Report[] }>(`reports/${reportId}`));
				const reportResponses = await Promise.all(reportPromises);
				const reportMap: Record<string, Report> = {};
				for (const [index, reportResponse] of reportResponses.entries()) {
					if (reportResponse?.items?.[0]) {
						reportMap[reportIds[index]] = reportResponse.items[0];
					}
				}
				setReports(reportMap);

				// Fetch data for each report
				const dataPromises = reportIds.map((reportId) => {
					const item = dashboardData.items.find((i) => i.reportId === reportId);
					// Combine global parameter values with item-specific ones
					const params = new URLSearchParams();
					if (item?.parameterValues) {
						for (const [key, value] of Object.entries(item.parameterValues)) {
							params.append(key, String(value));
						}
					}
					for (const [key, value] of Object.entries(paramValues)) {
						// Only add global param if not overridden by item-specific one
						if (!item?.parameterValues?.[key]) {
							params.append(key, value);
						}
					}
					const queryString = params.toString();
					return api.fetchWithAuth<any>(`reports/${reportId}/data${queryString ? `?${queryString}` : ''}`);
				});
				const dataResponses = await Promise.all(dataPromises);
				const dataMap: Record<string, any> = {};
				for (const [index, dataResponse] of dataResponses.entries()) {
					dataMap[reportIds[index]] = dataResponse;
				}
				setReportData(dataMap);
			}
		} catch (error) {
			console.error('Failed to fetch dashboard:', error);
		} finally {
			setLoading(false);
		}
	};

	// Fetch available reports for adding to dashboard
	const fetchAvailableReports = async () => {
		try {
			const response = await api.fetchWithAuth<{ items: Report[] }>('reports');
			setAvailableReports(response?.items || []);
		} catch (error) {
			console.error('Failed to fetch reports:', error);
		}
	};

	useEffect(() => {
		if (id && id !== 'new') {
			fetchDashboard();
		} else {
			setHasChanges(true);
			setLoading(false);
			setDashboard({
				name: '',
				description: '',
				items: [],
				columns: 12, // Add default columns value
				access_groups: [], // Add default access groups
			});
			setShowMetadataModal(true);
		}
	}, [api, id]);

	// Check for changes whenever dashboard is updated
	useEffect(() => {
		if (dashboard?.id === 'new') {
			setHasChanges(true);
		} else if (dashboard && originalDashboard) {
			const dashboardStr = JSON.stringify(dashboard);
			const originalStr = JSON.stringify(originalDashboard);
			setHasChanges(dashboardStr !== originalStr);
		}
	}, [dashboard, originalDashboard]);

	useEffect(() => {
		if (dashboard) {
			setMetadataForm({
				name: dashboard.name,
				description: dashboard.description || '',
				columns: dashboard.columns || 12,
				access_groups: dashboard.access_groups || [],
			});
		}
	}, [dashboard, showMetadataModal]);

	// Initialize refs for each table when reports change
	useEffect(() => {
		if (!dashboard?.items) return;
		for (const item of dashboard.items) {
			if (!tableRefs.current[item.reportId]) {
				tableRefs.current[item.reportId] = createRef<DataTableMethods>();
			}
		}
	}, [dashboard?.items]);

	// Function to collect and combine parameters from all reports
	const updateCombinedParameters = () => {
		const combined: Record<string, CombinedParameter> = {};

		for (const [reportId, report] of Object.entries(reports)) {
			if (!report.parameters) continue;

			for (const param of report.parameters) {
				if (combined[param.name]) {
					// Parameter already exists, add this report to its list
					combined[param.name].reportIds.push(reportId);
				} else {
					// New parameter, create it with this report
					combined[param.name] = {
						...param,
						reportIds: [reportId],
					};
				}
			}
		}

		setCombinedParameters(combined);
	};

	// Update combined parameters when reports change
	useEffect(() => {
		updateCombinedParameters();
	}, [reports]);

	// Initialize parameter values from URL or defaults
	useEffect(() => {
		if (!dashboard) return;

		const newParamValues: Record<string, string> = {};
		const urlParams = Object.fromEntries(searchParams);

		// First try to get values from URL
		for (const param of Object.values(combinedParameters)) {
			if (urlParams[param.name]) {
				newParamValues[param.name] = urlParams[param.name];
			} else if (param.default !== undefined) {
				newParamValues[param.name] = String(param.default);
				// Add default to URL if not present
				searchParams.set(param.name, String(param.default));
			}
		}

		setParamValues(newParamValues);

		// Update URL with defaults if needed
		if (Object.keys(newParamValues).length > Object.keys(urlParams).length) {
			setSearchParams(searchParams, { replace: true });
		}
	}, [dashboard, combinedParameters, searchParams]);

	// Update URL when parameters change
	const handleParameterChange = (name: string, value: string) => {
		setParamValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	// Handle form submission with URL parameters
	const handleParameterSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		// Update URL parameters
		for (const [key, value] of Object.entries(paramValues)) {
			if (value) {
				searchParams.set(key, value);
			} else {
				searchParams.delete(key);
			}
		}
		setSearchParams(searchParams);

		// Fetch updated data
		fetchDashboard(true);
	};

	const handleSave = async () => {
		if (!dashboard) return;
		setSaving(true);
		try {
			if (!dashboard.id || dashboard.id === 'new') {
				// Creating a new dashboard
				const response = await api.fetchWithAuth('dashboards', {
					method: 'POST',
					body: JSON.stringify(dashboard),
				});
				if (response?.items?.[0]) {
					navigate(`/dashboards/${response.items[0].id}`);
				}
			} else {
				// Updating existing dashboard
				await api.fetchWithAuth(`dashboards/${dashboard.id}`, {
					method: 'POST',
					body: JSON.stringify(dashboard),
				});
				setOriginalDashboard(JSON.parse(JSON.stringify(dashboard))); // Update original after save
				setHasChanges(false);
				setIsEditing(false);
			}
		} catch (error) {
			console.error('Failed to save dashboard:', error);
		} finally {
			setSaving(false);
		}
	};

	const handleDelete = async () => {
		if (!dashboard || !window.confirm('Are you sure you want to delete this dashboard?')) return;

		try {
			await api.fetchWithAuth(`dashboards/${dashboard.id}`, {
				method: 'DELETE',
			});
			navigate('/dashboards');
		} catch (error) {
			console.error('Failed to delete dashboard:', error);
		}
	};

	const handleAddReport = async (reportId: string) => {
		if (!dashboard) return;
		const report = availableReports.find((r) => r.id === reportId);
		if (!report) return;

		// Check if report is already in dashboard
		if (dashboard.items.some((item) => item.reportId === reportId)) {
			return;
		}

		setLoadingReportId(reportId);

		try {
			// Fetch all the data first
			const reportResponse = await api.fetchWithAuth<{ items: Report[] }>(`reports/${reportId}`);
			if (!reportResponse?.items?.[0]) {
				throw new Error('Failed to fetch report');
			}

			// Fetch the report data
			const data = await api.fetchWithAuth<any>(`reports/${reportId}/data`);

			// Calculate the next available position
			const existingPositions = dashboard.items.map((item) => ({
				x: item.x,
				y: item.y,
				w: item.w,
				h: item.h,
			}));

			// Find the first available position
			let nextX = 0;
			let nextY = 0;
			let found = false;

			// Try each row until we find a spot
			while (!found) {
				// Check if this position overlaps with any existing items
				const overlaps = existingPositions.some((pos) => {
					return (nextX < pos.x + pos.w
						&& nextX + MIN_WIDTH > pos.x
						&& nextY < pos.y + pos.h
						&& nextY + MIN_HEIGHT > pos.y);
				});

				if (!overlaps) {
					found = true;
				} else {
					// Try the next position
					nextX += MIN_WIDTH;
					if (nextX + MIN_WIDTH > dashboard.columns) {
						nextX = 0;
						nextY += MIN_HEIGHT;
					}
				}
			}

			// Create the new item with guaranteed minimum size
			const newItem: DashboardItem = {
				reportId,
				x: nextX,
				y: nextY,
				w: MIN_WIDTH,
				h: report.chart.type === 'table' || report.chart.showTable ? TABLE_MIN_HEIGHT : MIN_HEIGHT,
			};

			// Update all states at once
			setReports((prev) => ({
				...prev,
				[reportId]: reportResponse.items[0],
			}));
			setReportData((prev) => ({
				...prev,
				[reportId]: data,
			}));
			setDashboard((prev) => ({
				...prev!,
				items: [...prev!.items, newItem],
			}));
		} catch (error) {
			console.error('Failed to add report:', error);
		} finally {
			setLoadingReportId(null);
		}
	};

	const handleRemoveReport = (reportId: string) => {
		if (!dashboard) return;
		setDashboard({
			...dashboard,
			items: dashboard.items.filter((item) => item.reportId !== reportId),
		});
	};

	const handleLayoutChange = (layout: any[]) => {
		if (!dashboard) return;

		const updatedItems = dashboard.items.map((item) => {
			const layoutItem = layout.find((l) => l.i === item.reportId);
			const report = reports[item.reportId];
			const isTable = report?.chart.type === 'table' || report?.chart.showTable;

			if (layoutItem) {
				return {
					...item,
					x: layoutItem.x,
					y: layoutItem.y,
					w: Math.max(layoutItem.w, MIN_WIDTH),
					h: Math.max(layoutItem.h, isTable ? TABLE_MIN_HEIGHT : MIN_HEIGHT),
				};
			}
			return item;
		});

		// Only update if there are actual changes
		const hasLayoutChanges = JSON.stringify(updatedItems) !== JSON.stringify(dashboard.items);

		if (hasLayoutChanges) {
			setDashboard((prev) => ({
				...prev!,
				items: updatedItems,
			}));
		}
	};

	const handleClearAll = () => {
		if (!dashboard || !window.confirm('Are you sure you want to remove all reports from this dashboard?')) return;

		setDashboard({
			...dashboard,
			items: [],
		});
		setReports({});
		setReportData({});
	};

	const handleConfigureReport = (reportId: string) => {
		const report = reports[reportId];
		const item = dashboard?.items.find((i) => i.reportId === reportId);
		if (!report || !item) return;

		// Start with report defaults
		const defaultConfig: ChartConfig = {
			type: report.chart.type,
			legend: {
				display: typeof report.chart.legend === 'object' ? report.chart.legend.display ?? true : report.chart.legend ?? true,
				position: typeof report.chart.legend === 'object' ? report.chart.legend.position || 'right' : 'right',
			},
			showTable: report.chart.showTable || report.chart.type === 'table',
		};

		// Apply any dashboard overrides
		const overrides = item.chartOverrides || {};
		setTempChartConfig({
			...defaultConfig,
			...overrides,
		});
		setShowReportConfigModal(reportId);
	};

	const handleSaveReportConfig = async () => {
		if (!dashboard || !showReportConfigModal || !tempChartConfig) return;

		const updatedDashboard: Dashboard = {
			...dashboard,
			items: dashboard.items.map((item: DashboardItem) => (item.reportId === showReportConfigModal
				? {
					...item,
					chartOverrides: tempChartConfig,
				} satisfies DashboardItem
				: item)),
		};

		setSaving(true);
		try {
			await api.fetchWithAuth(`dashboards/${dashboard.id}`, {
				method: 'POST',
				body: JSON.stringify(updatedDashboard),
			});
			setDashboard(updatedDashboard);
			setOriginalDashboard(JSON.parse(JSON.stringify(updatedDashboard))); // Update original after save
			setHasChanges(false);
		} catch (error) {
			console.error('Failed to save dashboard:', error);
		} finally {
			setSaving(false);
			setShowReportConfigModal(null);
			setTempChartConfig(null);
		}
	};

	const handleSaveMetadata = () => {
		if (!dashboard || !metadataForm.name) return;

		setDashboard({
			...dashboard,
			name: metadataForm.name,
			description: metadataForm.description,
			columns: metadataForm.columns,
			access_groups: metadataForm.access_groups,
		});
		setShowMetadataModal(false);
	};

	const getInputType = (paramType: string): string => {
		if (paramType === 'number') return 'number';
		if (paramType === 'date') return 'date';
		return 'text';
	};

	const getInputWidth = (paramType: string): string => {
		return paramType === 'date' ? '150px' : '200px';
	};

	const getLegendDisplay = (legend: ChartConfig['legend']): boolean => {
		if (typeof legend === 'object') {
			return legend.display ?? true;
		}
		return !!legend;
	};

	const getLegendPosition = (legend: ChartConfig['legend']): 'top' | 'right' | 'bottom' | 'left' => {
		if (typeof legend === 'object') {
			return legend.position || 'right';
		}
		return 'right';
	};

	const renderParameterInput = (param: CombinedParameter, name: string, value: string) => {
		if (param.type === 'boolean') {
			return (
				<Form.Check
					type="checkbox"
					checked={value === 'true'}
					onChange={(e) => handleParameterChange(name, e.target.checked.toString())}
					className="mb-0"
				/>
			);
		}

		if (param.options) {
			return (
				<Form.Select
					value={value || ''}
					onChange={(e) => handleParameterChange(name, e.target.value)}
					required={param.required}
					size="sm"
					style={{ width: 'auto' }}
				>
					<option value="">Select...</option>
					{param.options.map((opt, i) => (
						<option key={i} value={opt.value}>
							{opt.label}
						</option>
					))}
				</Form.Select>
			);
		}

		return (
			<Form.Control
				type={getInputType(param.type)}
				value={value || ''}
				onChange={(e) => handleParameterChange(name, e.target.value)}
				required={param.required}
				placeholder={`Enter ${param.label || name}`}
				size="sm"
				style={{ width: getInputWidth(param.type) }}
			/>
		);
	};

	const renderConfigParameterInput = (param: Parameter, value: string) => {
		const handleValueChange = (newValue: string) => {
			setDashboard((prev) => ({
				...prev!,
				items: prev!.items.map((i) => (i.reportId === showReportConfigModal
					? {
						...i,
						parameterValues: {
							...i.parameterValues,
							[param.name]: newValue,
						},
					}
					: i)),
			}));
		};

		if (param.type === 'boolean') {
			return (
				<Form.Check
					type="checkbox"
					checked={value === 'true'}
					onChange={(e) => handleValueChange(e.target.checked.toString())}
				/>
			);
		}

		if (param.options) {
			return (
				<Form.Select
					value={value}
					onChange={(e) => handleValueChange(e.target.value)}
					required={param.required}
				>
					<option value="">Use Global Value</option>
					{param.options.map((opt, i) => (
						<option key={i} value={opt.value}>
							{opt.label}
						</option>
					))}
				</Form.Select>
			);
		}

		return (
			<Form.Control
				type={getInputType(param.type)}
				value={value}
				onChange={(e) => handleValueChange(e.target.value)}
				required={param.required}
				placeholder={'Use Global Value'}
			/>
		);
	};

	if (!dashboard) {
		return (
			<Container fluid>
				<h1 className="text-center">Dashboard not found</h1>
			</Container>
		);
	}

	return (
		<Container fluid>
			<div className="title-wrapper pt-30">
				<div className="row align-items-center">
					<div className="col-md-6">
						<div className="title">
							<h2>
								{dashboard.name}
							</h2>
						</div>
					</div>
					<div className="col-md-6">
						<div className="breadcrumb-wrapper">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<Link to="/dashboards">Dashboards</Link>
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										{dashboard.name}
									</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-end align-items-center">
					{isEditing && (
						<>
							<OverlayTrigger
								placement="top"
								overlay={(props) => (
									<Tooltip {...props}>Add Report</Tooltip>
								)}
							>
								<Button
									size="sm"
									variant="outline-success"
									onClick={() => setShowAddReportModal(true)}
									disabled={loadingReportId !== null}
									className="me-2"
								>
									<FontAwesomeIcon icon={faPlus} />
								</Button>
							</OverlayTrigger>
							<RequirePermissions permissions={['admin']}>
								<OverlayTrigger
									placement="top"
									overlay={(props) => (
										<Tooltip {...props}>Dashboard Settings</Tooltip>
									)}
								>
									<Button
										size="sm"
										variant="outline-secondary"
										onClick={() => setShowMetadataModal(true)}
										className="me-2"
									>
										<FontAwesomeIcon icon={faGear} />
									</Button>
								</OverlayTrigger>
								{dashboard.items.length > 0 && (
									<OverlayTrigger
										placement="top"
										overlay={(props) => (
											<Tooltip {...props}>Clear All Reports</Tooltip>
										)}
									>
										<Button
											size="sm"
											variant="outline-danger"
											onClick={async () => {
												const result = await confirmDialog({
													title: 'Clear All Reports',
													message: 'Are you sure you want to remove all reports from this dashboard?',
													details: 'This action cannot be undone.',
												});
												if (result !== false) {
													handleClearAll();
												}
											}}
											className="me-2"
										>
											<FontAwesomeIcon icon={faBroom} />
										</Button>
									</OverlayTrigger>
								)}
								<OverlayTrigger
									placement="top"
									overlay={(props) => (
										<Tooltip {...props}>Delete Dashboard</Tooltip>
									)}
								>
									<Button
										size="sm"
										variant="outline-danger"
										onClick={async () => {
											const result = await confirmDialog({
												title: 'Delete Dashboard',
												message: 'Are you sure you want to delete this dashboard?',
												details: 'This action cannot be undone.',
											});
											if (result !== false) {
												handleDelete();
											}
										}}
										className="me-2"
									>
										<FontAwesomeIcon icon={faTrash} />
									</Button>
								</OverlayTrigger>
							</RequirePermissions>
						</>
					)}

					{/* Action Buttons */}
					{hasChanges && (
						<OverlayTrigger
							placement="top"
							overlay={(props) => (
								<Tooltip {...props}>Save Changes</Tooltip>
							)}
						>
							<Button
								variant="outline-success"
								size="sm"
								className="me-2"
								onClick={handleSave}
								disabled={saving}
							>
								<FontAwesomeIcon icon={faSave} />
							</Button>
						</OverlayTrigger>
					)}

					{!hasChanges && isEditing && (
						<OverlayTrigger
							placement="top"
							overlay={(props) => (
								<Tooltip {...props}>Exit Edit Mode</Tooltip>
							)}
						>
							<Button
								variant="outline-secondary"
								size="sm"
								className="me-2"
								onClick={() => setIsEditing(false)}
							>
								<FontAwesomeIcon icon={faRightFromBracket} />
							</Button>
						</OverlayTrigger>
					)}

					{!hasChanges && !isEditing && (
						<RequirePermissions permissions={['admin']}>
							<OverlayTrigger
								placement="top"
								overlay={(props) => (
									<Tooltip {...props}>Edit Dashboard</Tooltip>
								)}
							>
								<Button
									variant="outline-secondary"
									size="sm"
									className="me-2"
									onClick={() => setIsEditing(true)}
								>
									<FontAwesomeIcon icon={faEdit} />
								</Button>
							</OverlayTrigger>
						</RequirePermissions>
					)}
					<div className="ms-2">
						<RefreshButton
							refreshHandler={() => fetchDashboard(true)}
							size="sm"
							loading={loading}
							setLoading={setLoading}
						/>
					</div>
				</div>
			</div>

			{/* Parameters Panel */}
			{Object.keys(combinedParameters).length > 0 && (
				<Card className="mb-4 rounded-0">
					<Card.Body className="py-2 bg-primary-light">
						<Form onSubmit={handleParameterSubmit}>
							<div className="d-flex align-items-center flex-wrap gap-3">
								{Object.entries(combinedParameters).map(([name, param]) => (
									<div key={name} className="d-flex align-items-center">
										<Form.Label className="mb-0 me-2" style={{ minWidth: 'max-content' }}>
											{param.label || titleCase(name)}
											{param.required && <span className="text-danger">*</span>}:
										</Form.Label>
										{renderParameterInput(param, name, paramValues[name] || '')}
									</div>
								))}
								<Button
									type="submit"
									variant="outline-success"
									size="sm"
									disabled={loading}
									className="ms-auto"
								>
									{loading ? 'Loading...' : 'Update'}
								</Button>
							</div>
						</Form>
					</Card.Body>
				</Card>
			)}

			<ResponsiveGridLayout
				className="layout"
				layouts={{
					lg: [
						...dashboard.items.map((item) => {
							const report = reports[item.reportId];
							const isTable = report?.chart.type === 'table' || report?.chart.showTable;
							return {
								i: item.reportId,
								x: item.x,
								y: item.y,
								w: Math.max(item.w, MIN_WIDTH),
								h: Math.max(item.h, isTable ? TABLE_MIN_HEIGHT : MIN_HEIGHT),
								minW: MIN_WIDTH,
								minH: isTable ? TABLE_MIN_HEIGHT : MIN_HEIGHT,
								static: !isEditing,
							};
						}),
						...(dashboard.items.length === 0 ? [{
							i: 'add-report',
							x: 0,
							y: 0,
							w: 4,
							h: 3,
							static: true,
						}] : []),
					],
				}}
				breakpoints={{
					lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0,
				}}
				cols={{
					lg: dashboard.columns, md: 10, sm: 6, xs: 4, xxs: 2,
				}}
				rowHeight={ROW_HEIGHT}
				margin={[20, 20]}
				isDraggable={isEditing}
				isResizable={isEditing}
				onLayoutChange={(layout) => handleLayoutChange(layout.filter((l) => l.i !== 'add-report'))}
			>
				{dashboard.items.map((item) => {
					const report = reports[item.reportId];
					const data = reportData[item.reportId];
					if (!report || !data) return null;

					const effectiveChartType = item.chartOverrides?.type || report.chart.type;
					const showTable = effectiveChartType === 'table' || item.chartOverrides?.showTable;

					return (
						<div key={item.reportId} className="bg-white p-3 rounded shadow-sm overflow-auto">
							<div className="d-flex justify-content-between align-items-center mb-3">
								<h5 className="mb-0" title={report.description}>{report.name}</h5>
								{isEditing ? (
									<Button
										variant="link"
										size="sm"
										className="p-0 text-muted"
										onClick={(e) => {
											e.stopPropagation();
											handleConfigureReport(item.reportId);
										}}
										onMouseDown={(e) => e.stopPropagation()}
									>
										<FontAwesomeIcon icon={faEdit} />
									</Button>
								) : (
									<Link
										to={`/reports/${item.reportId}`}
										className="btn btn-link btn-sm text-muted p-0"
									>
										View Report
									</Link>
								)}
							</div>
							{report.parameters && report.parameters.length > 0 && (
								<div className="text-muted small">
									{report.parameters.map((param) => (
										<div key={param.name} data-id={param.name}>
											{param.label || titleCase(param.name)}: {
												item.parameterValues?.[param.name]
												|| paramValues[param.name]
												|| report.parameters?.[param.name]?.default
												|| 'Not Set'
											}
										</div>
									))}
								</div>
							)}

							{effectiveChartType !== 'table' && (
								<ReportChart
									report={{
										...report,
										chart: {
											...report.chart,
											...item.chartOverrides,
										},
									}}
									data={data}
								/>
							)}
							{showTable && data.items && data.items.length > 0 && (
								<div className={effectiveChartType !== 'table' ? 'mt-4 pb-4' : 'pb-4'}>
									<div className="d-flex justify-content-between align-items-center mb-2">
										<div></div>
										<Button
											variant="outline-secondary"
											size="sm"
											onClick={() => tableRefs.current[item.reportId]?.current?.exportToCSV(`${report.name} - ${new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })}`)}
										>
											<FontAwesomeIcon icon={faDownload} className="me-1" /> Export CSV
										</Button>
									</div>
									<DataTable
										ref={tableRefs.current[item.reportId]}
										items={data.items}
										columns={Object.keys(data.items[0]).map((key) => ({
											id: key,
											header: titleCase(key),
											accessorKey: key,
										}))}
										striped
										defaultPageSize={10}
										className="table-sm"
									/>
								</div>
							)}
						</div>
					);
				})}
				{dashboard.items.length === 0 && (
					<div key="add-report" className="bg-white p-3 rounded shadow-sm">
						<Button
							variant="outline-primary"
							className="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
							onClick={() => setShowAddReportModal(true)}
							disabled={loadingReportId !== null}
						>
							<FontAwesomeIcon icon={faPlusSquare} size="2x" />
							<div className="mt-2">
								{loadingReportId ? 'Adding Report...' : 'Add Your First Report'}
							</div>
						</Button>
					</div>
				)}
			</ResponsiveGridLayout>

			{/* Dashboard Metadata Modal */}
			<Modal show={showMetadataModal} onHide={() => setShowMetadataModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Dashboard Settings</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className="mb-3">
						<Form.Label>Name</Form.Label>
						<Form.Control
							type="text"
							value={metadataForm.name}
							onChange={(e) => setMetadataForm((prev) => ({ ...prev, name: e.target.value }))}
							required
						/>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Description</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							value={metadataForm.description}
							onChange={(e) => setMetadataForm((prev) => ({ ...prev, description: e.target.value }))}
						/>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Grid Columns</Form.Label>
						<Form.Select
							value={metadataForm.columns}
							onChange={(e) => setMetadataForm((prev) => ({ ...prev, columns: parseInt(e.target.value, 10) }))}
						>
							<option value="6">6 Columns</option>
							<option value="8">8 Columns</option>
							<option value="12">12 Columns</option>
							<option value="16">16 Columns</option>
						</Form.Select>
						<Form.Text className="text-muted">
							Number of columns in the dashboard grid. More columns allow for finer control over report placement.
						</Form.Text>
					</Form.Group>

					<AccessGroupSelect
						value={metadataForm.access_groups}
						onChange={(access_groups) => setMetadataForm((prev) => ({ ...prev, access_groups }))}
						className="mb-3"
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowMetadataModal(false)}>
						Cancel
					</Button>
					<Button
						variant="outline-success"
						onClick={handleSaveMetadata}
						disabled={!metadataForm.name}
					>
						<FontAwesomeIcon icon={faSave} /> Save
					</Button>
				</Modal.Footer>
			</Modal>

			{/* Add Report Modal */}
			<Modal
				show={showAddReportModal}
				onHide={() => setShowAddReportModal(false)}
				onShow={fetchAvailableReports}
			>
				<Modal.Header closeButton>
					<Modal.Title>Add Report</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Select a report to add</Form.Label>
						<Typeahead
							id="add-report-select"
							onChange={(selected) => {
								const selectedOption = selected[0] as ReportOption;
								if (selectedOption && !loadingReportId) {
									handleAddReport(selectedOption.value);
									setShowAddReportModal(false);
								}
							}}
							options={availableReports
								.filter((report) => !dashboard.items.some((item) => item.reportId === report.id))
								.map((report) => ({
									value: report.id,
									label: report.name,
									description: report.description || '',
									category: report.category,
								}))}
							placeholder={loadingReportId ? 'Loading report...' : 'Search for a report...'}
							disabled={loadingReportId !== null}
							labelKey="label"
							renderMenuItemChildren={(option) => (
								<div>
									<div className="d-flex align-items-center gap-2">
										<span>{(option as ReportOption).label}</span>
										{(option as ReportOption).category && (
											<Badge
												bg="light"
												text="dark"
												className={`report-${kebabCase((option as ReportOption).category.toLowerCase())}`}
											>
												{(option as ReportOption).category}
											</Badge>
										)}
									</div>
									{(option as ReportOption).description && (
										<small className="text-muted d-block">
											{(option as ReportOption).description}
										</small>
									)}
								</div>
							)}
						/>
					</Form.Group>
					{loadingReportId && (
						<div className="text-center mt-2">
							<small className="text-muted">
								Loading report data...
							</small>
						</div>
					)}
				</Modal.Body>
			</Modal>

			{/* Report Configuration Modal */}
			<Modal show={showReportConfigModal !== null} onHide={() => setShowReportConfigModal(null)}>
				<Modal.Header closeButton>
					<Modal.Title>Configure Report</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{showReportConfigModal && tempChartConfig && (
						<Form>
							<Form.Group className="mb-3">
								<Form.Label>Chart Type</Form.Label>
								<Form.Select
									value={tempChartConfig.type}
									onChange={(e) => setTempChartConfig((prev) => ({
										...prev!,
										type: e.target.value as any,
									}))}
								>
									<option value="line">Line Chart</option>
									<option value="bar">Bar Chart</option>
									<option value="table">Table</option>
								</Form.Select>
							</Form.Group>

							{tempChartConfig.type !== 'table' && (
								<>
									<Form.Group className="mb-3">
										<Form.Check
											type="checkbox"
											label="Show Table Below Chart"
											checked={tempChartConfig.showTable}
											onChange={(e) => setTempChartConfig((prev) => ({
												...prev!,
												showTable: e.target.checked,
											}))}
										/>
									</Form.Group>

									<Form.Group className="mb-3">
										<Form.Label>Legend</Form.Label>
										<div>
											<Form.Check
												type="checkbox"
												label="Show Legend"
												checked={getLegendDisplay(tempChartConfig.legend)}
												onChange={(e) => setTempChartConfig((prev) => ({
													...prev!,
													legend: {
														display: e.target.checked,
														position: getLegendPosition(prev!.legend),
													},
												}))}
												inline
											/>
											{getLegendDisplay(tempChartConfig.legend) && (
												<Form.Select
													value={getLegendPosition(tempChartConfig.legend)}
													onChange={(e) => setTempChartConfig((prev) => ({
														...prev!,
														legend: {
															display: true,
															position: e.target.value as 'top' | 'right' | 'bottom' | 'left',
														},
													}))}
													className="d-inline-block ms-2"
													style={{ width: 'auto' }}
												>
													<option value="top">Top</option>
													<option value="right">Right</option>
													<option value="bottom">Bottom</option>
													<option value="left">Left</option>
												</Form.Select>
											)}
										</div>
									</Form.Group>
								</>
							)}

							{/* Parameter Overrides */}
							{reports[showReportConfigModal]?.parameters?.length > 0 && (
								<>
									<hr />
									<h5>Parameter Overrides</h5>
									<p className="text-muted small">Override the global parameter values for this report.</p>
									{reports[showReportConfigModal].parameters.map((param, index) => {
										const item = dashboard?.items.find((i) => i.reportId === showReportConfigModal);
										const value = item?.parameterValues?.[param.name] ?? paramValues[param.name] ?? '';

										return (
											<Form.Group key={index} className="mb-3">
												<Form.Label>
													{param.label || titleCase(param.name)}
													{param.required && <span className="text-danger">*</span>}
												</Form.Label>
												{renderConfigParameterInput(param, value)}
											</Form.Group>
										);
									})}
								</>
							)}
						</Form>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowReportConfigModal(null)}>
						Cancel
					</Button>
					<Button variant="outline-danger" className="me-auto" onClick={async () => {
						const result = await confirmDialog({
							title: 'Remove Report',
							message: 'Are you sure you want to remove this report from the dashboard?',
							details: 'This action cannot be undone.',
						});
						if (result !== false) {
							handleRemoveReport(showReportConfigModal);
							setShowReportConfigModal(null);
						}
					}}>
						<FontAwesomeIcon icon={faTrash} /> Remove Report
					</Button>
					<Button variant="primary" onClick={handleSaveReportConfig} disabled={saving}>
						{saving ? 'Saving...' : 'Save Changes'}
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	);
}
