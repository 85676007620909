import * as Sentry from '@sentry/react';
import {
	Component,
	ErrorInfo,
	ReactNode,
	useState,
} from 'react';
import {
	Accordion,
	Button,
	Container,
	Modal,
} from 'react-bootstrap';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { useCacheStore } from '~/stores/cache-store';

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
	error?: Error;
}

function ErrorFallback({ error }: { error: Error }) {
	const [showModal, setShowModal] = useState(true);
	const isHomePage = window.location.pathname === '/';

	// Safely get user info from localStorage if available
	const getUserInfo = () => {
		try {
			const userInfoStr = localStorage.getItem('userInfo');
			if (userInfoStr) {
				const userInfo = JSON.parse(userInfoStr);
				// First try to use the name field, if not available construct from first/last name
				const name = userInfo.name
					|| (userInfo.firstName || userInfo.lastName
						? `${userInfo.firstName || ''} ${userInfo.lastName || ''}`.trim()
						: undefined);

				return {
					name,
					email: userInfo.email,
				};
			}
		} catch (e) {
			// Silently fail if localStorage access fails
			console.debug('Failed to get user info from localStorage:', e);
		}
		return {};
	};

	return (
		<Container fluid>
			<Modal
				show={showModal}
				centered
				size="xl"
				onHide={() => setShowModal(true)} // Prevent closing with escape key
			>
				<Modal.Header>
					<Modal.Title>Something went wrong</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="mb-3">{error.message}</div>

					<Accordion className="mb-3">
						<Accordion.Item eventKey="0">
							<Accordion.Header>Technical Details</Accordion.Header>
							<Accordion.Body>
								<pre className="bg-light p-3 rounded">
									<code>
										{error.stack || 'No stack trace available'}
										{'\n\nAdditional Error Properties:\n'}
										{Object.entries(error)
											.filter(([key]) => !['stack', 'message'].includes(key))
											.map(([key, value]) => `${key}: ${JSON.stringify(value, null, 2)}`)
											.join('\n')}
									</code>
								</pre>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</Modal.Body>
				<Modal.Footer>
					{!isHomePage && (
						<Button variant="primary" onClick={() => {
							window.location.href = '/';
						}}>
							Back to Home
						</Button>
					)}
					<Button
						variant="info"
						onClick={() => {
							setShowModal(false);
							const eventId = Sentry.captureException(error);
							const userInfo = getUserInfo();
							Sentry.showReportDialog({
								eventId,
								onClose: () => setShowModal(true),
								url: window.location.href,
								...userInfo,
							});
						}}
					>
						Submit Feedback
					</Button>
					<Button variant="danger" onClick={() => {
						localStorage.clear();
						useCacheStore().clear();
						window.location.reload();
					}}>
						Clear Cache and Reload
					</Button>
					<Button variant="secondary" onClick={() => window.location.reload()}>
						Reload
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	);
}

export class CustomErrorBoundary extends Component<Props, State> {
	public static getDerivedStateFromError(error: Error): State {
		return { hasError: true, error };
	}

	public state: State = {
		hasError: false,
	};

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo);
		Sentry.captureException(error);
	}

	public render() {
		if (this.state.hasError) {
			return <ErrorFallback error={this.state.error!} />;
		}

		return this.props.children;
	}
}

export function RouteErrorBoundary() {
	const error = useRouteError();

	if (isRouteErrorResponse(error)) {
		return (
			<Container fluid>
				<center className="p-5">
					<h1>{error.status} {error.statusText}</h1>
					<div>{error.data}</div>
				</center>
			</Container>
		);
	}

	return <ErrorFallback error={error as Error} />;
}
