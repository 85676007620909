import { z } from 'zod';

import { BaseObjectSchema } from './base';
import { UnixTimestampSchema } from './dates';
import type { StatusChoice } from './status';

const FEED_TYPES = ['HTTPFeed', 'S3Feed', 'YouTubeFeed'] as const;

export const FeedSchema = BaseObjectSchema.extend({
	$type: z.literal('Feed'),
	$id: z.string(),
	type: z.enum(FEED_TYPES),
	status: z.string() as z.ZodType<StatusChoice>,
	name: z.string(),
	content: z.string().optional(),

	priority: z.string().optional(),
	segment: z.number().optional(),
	next_read: UnixTimestampSchema.optional(),

	deleted: z.boolean().optional(),

	ft_max_stories: z.number().optional()
		.describe('Maximum number of stories to process with FT'),
	created_at: UnixTimestampSchema.optional(),
	created_by: z.string().optional(),
	modified_at: UnixTimestampSchema.optional(),
	modified_by: z.string().optional(),

	proc: z.string().optional(),
	parser: z.string().optional(),
	feed_uri: z.string().optional(),
	polling_delay: z.number().optional(),
	run_cat_engine: z.boolean().optional(),

	user_agent: z.string().optional(),

	run_digger: z.boolean().optional(),

	last_read: UnixTimestampSchema.optional(),

	/**
	 * Process each individual story using the FT Extraction API
	 */
	ft_process: z.boolean().optional(),

	process_updates: z.boolean().optional(),

	name_template: z.string().optional(),

	// Optional encoding override for this feed
	encoding: z.string().optional() as z.ZodType<BufferEncoding>,

	// If true, automatically extract tickers from the text of the story
	extract_tickers: z.boolean().optional(),

	gzip: z.boolean().optional(),

	/**
	 * Attempt to bypass Cloudflare's anti-bot page
	 */
	use_cloudscraper: z.boolean().optional(),

	/**
	 * Use the FT Feed API to bypass any known Bot Protection
	 */
	use_ftfeed: z.boolean().optional(),

	/**
	 * Use HTTP/2 instead of Fetch
	 */
	use_h2: z.boolean().optional(),

	/**
	 * If set, ignore any robots.txt directives
	 */
	bypass_robots: z.boolean().optional(),

	// Authentication support
	auth_type: z.enum(['basic', 'digest']).optional(),
	username: z.string().optional(),
	passwd: z.string().optional(),
	custom_headers: z.record(z.string()).optional(),

	s3_data: z.object({
		bucket: z.object({
			name: z.string(),
		}),
		object: z.object({
			key: z.string(),
		}),
	}).optional(),

	last_error: z.object({
		time: UnixTimestampSchema,
		error: z.string(),
	}).optional(),

	// YouTube ID
	yt_id: z.string().optional(),
	// YouTube User ID
	user_id: z.string().optional(),

	/**
	 * Last feed read's "Last-Modified" header
	 *
	 * This is passed along into the "If-Modified-Since" header on the next read
	 */
	last_read_last_modified_header: z.string().optional(),

	/**
	 * URL Prefix that's required to parse any stories
	 *
	 * Highly recommended when adding sitemap feeds, to prevent accidentally parsing items that are
	 * not blog posts
	 */
	required_prefix: z.string().optional(),

	/**
	 * Bug tracking ticket URL
	 *
	 * This is used to record an Asana or HubSpot task that's related to any errors being tracked for this feed.
	 */
	error_task_url: z.string().optional(),

	/**
	 * Number of new stories in the feed the last time we read it
	 *
	 * @category Story
	 * @calculated
	 */
	last_story_count: z.number().optional(),

	/**
	 * Last date a story was *received*
	 *
	 * @category Story
	 * @calculated
	 */
	last_received_date: UnixTimestampSchema.optional(),

	/**
	 * Last date a story was *published*
	 *
	 * @category Story
	 * @calculated
	 */
	last_post_date: UnixTimestampSchema.optional(),
});

export type Feed = z.infer<typeof FeedSchema>;

export function isFeed(obj: any, tableName?: string): obj is Feed {
	return Boolean(
		tableName === 'Feeds'
		|| tableName === 'Feed'
		|| obj?.$type === 'Feed'
		|| FEED_TYPES.includes(obj?.$type || ''),
	);
}

export const FeedIssueSchema = z.object({
	level: z.enum(['fatal', 'error', 'warning'])
		.describe('Severity level of the issue'),
	message: z.string()
		.describe('Description of the issue'),
	statusCode: z.number().optional()
		.describe('HTTP status code if applicable'),
	feedURL: z.string().optional()
		.describe('URL of the feed that had the issue'),
	story: z.object({
		id: z.string().optional()
			.describe('Story ID if available'),
		date: UnixTimestampSchema.optional()
			.describe('Story publication date'),
		external_id: z.string().optional()
			.describe('External ID of the story'),
		title: z.string().optional()
			.describe('Story title'),
		url: z.string().optional()
			.describe('Story URL'),
	}).optional()
		.describe('Story details if the issue is related to a specific story'),
});

export type FeedIssue = z.infer<typeof FeedIssueSchema>;
