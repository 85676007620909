import { faFileLines } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	ButtonProps,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export interface ViewPublicationsFilters {
	size?: ButtonProps['size'];
	variant?: ButtonProps['variant'];
	className?: string;
	hideTitle?: boolean;
	onClick?: () => void;
	status?: string;
	closed_sites_only?: boolean;
	country?: string;
	language?: string;
	topics?: string;
	last_post_date?: string;
	integration_completion_date?: string;
	content_type?: string;
	pub_country?: string;
	products?: string;
}

export default function ViewPublicationsButton({
	size, variant, className, hideTitle, onClick, ...filters
}: ViewPublicationsFilters) {
	const navigate = useNavigate();

	const routeToPublications = () => {
		const filterQueryParameters = new URLSearchParams();

		if (filters.status) {
			filterQueryParameters.set('status', filters.status);
		}

		if (filters.closed_sites_only) {
			filterQueryParameters.set('closed_sites_only', 'true');
		}

		if (filters.country) {
			filterQueryParameters.set('country', filters.country);
		}

		if (filters.language) {
			filterQueryParameters.set('language', filters.language);
		}

		if (filters.topics) {
			filterQueryParameters.set('topics', filters.topics);
		}

		if (filters.last_post_date) {
			filterQueryParameters.set('last_post_date', filters.last_post_date);
		}

		if (filters.integration_completion_date) {
			filterQueryParameters.set('integration_completion_date', filters.integration_completion_date);
		}

		if (filters.content_type) {
			filterQueryParameters.set('content_type', filters.content_type);
		}

		if (filters.pub_country) {
			filterQueryParameters.set('pub_country', filters.pub_country);
		}

		if (filters.products) {
			filterQueryParameters.set('products', filters.products);
		}

		navigate(`/publications?${filterQueryParameters.toString()}`);
		onClick?.();
	};

	return (
		<Button size={size} variant={variant || 'outline-secondary'} onClick={routeToPublications} className={className}>
			{hideTitle ? <OverlayTrigger placement="top" overlay={<Tooltip>View Publications</Tooltip>}>
				<FontAwesomeIcon icon={faFileLines} />
			</OverlayTrigger> : (
				<>
					<FontAwesomeIcon icon={faFileLines} />
					{' View Publications'}
				</>
			)}
		</Button>
	);
}
