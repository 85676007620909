import { Command, ModalFields } from './type';

export const GenerateStoryCommand: Command = {
	id: 'generate-story',
	name: 'Generate Story',
	description: 'Generate a new story using AI',
	icon: 'robot',
	handler: ({ setModalData }) => {
		const modalData: ModalFields = {
			id: 'generate-story-modal',
			title: 'Select Publication',
			fields: [
				{
					id: 'publication',
					title: 'Publication',
					placeholder: 'Select a publication',
					help: 'Select a publication to generate a story for',
				},
			],
		};
		setModalData(modalData);

		// Listen for the modal submit
		const handleModalSubmit = (event: CustomEvent) => {
			const publication = event.detail.publication;
			if (publication) {
				const generateEvent = new CustomEvent('open-generate-story', {
					detail: { publication },
				});
				window.dispatchEvent(generateEvent);
			}
			window.removeEventListener('modal-submit', handleModalSubmit as EventListener);
		};
		window.addEventListener('modal-submit', handleModalSubmit as EventListener);
	},
};
