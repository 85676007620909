import { Report } from '@newstex/types/report';
import { Results } from '@newstex/types/results';

import BarChart2 from './charts/BarChart2';
import PieChart from './charts/PieChart';

interface ReportChartProps {
	report: Report;
	className?: string;
	data?: Results<any> | null;
}

interface Dataset {
	label: string;
	data: number[];
	backgroundColor?: string;
	borderColor?: string;
	_colors?: string[]; // Used internally for pie/doughnut charts
}

interface ChartData {
	labels: string[];
	datasets: Dataset[];
}

/**
 * Transforms SQL query results into chart data format based on the chart configuration.
 */
function transformResultsToChartData(results: Results<any>, report: Report): ChartData {
	if (!results?.items?.length) {
		return {
			labels: [],
			datasets: [],
		};
	}

	const firstRow = results.items[0];
	const columns = Object.keys(firstRow);

	if (columns.length < 2) {
		throw new Error('Query results must have at least 2 columns (label and value)');
	}

	const labelColumn = columns[0];
	const valueColumn = columns[1];

	// For pie/doughnut charts, we want a single dataset with colors mapped to each slice
	if (report.chart?.type === 'pie' || report.chart?.type === 'doughnut') {
		// For pie charts, each dataset entry represents a slice with its color
		const data = results.items.map((row) => Number(row[valueColumn]));
		const labels = results.items.map((row) => String(row[labelColumn]));

		// Map each label to its corresponding dataset color
		const colors = labels.map((label) => {
			const dataset = report.chart?.datasets.find((d) => d.label === label);
			return dataset?.color || 'blue';
		});

		return {
			labels,
			datasets: [{
				label: 'Values',
				data,
				backgroundColor: 'unused', // We'll use the colors array directly in the PieChart
				_colors: colors, // Store colors for PieChart to use
			}],
		};
	}

	// For bar charts with group_by
	if (report.chart?.type === 'bar'
		&& report.chart?.group_by
	) {
		const chart = report.chart;
		const groupBy = chart.group_by as string;
		const stackBy = chart.stack_by as string;
		const valueField = chart.value_field as string;

		// Unique values for the group_by field
		const groups = [...new Set(results.items.map((item) => String(item[groupBy])))].sort();

		// Allow stacking by a column on the datasets
		if (stackBy && valueField) {
			// Unique values for the stack_by field
			const stacks = [...new Set(results.items.map((item) => String(item[stackBy])))].sort();

			// Create a map for quick lookup of values
			const dataMap = new Map<string, Map<string, number>>();
			for (const group of groups) {
				dataMap.set(group, new Map<string, number>());
				for (const stack of stacks) {
					dataMap.get(group)!.set(stack, 0);
				}
			}

			// Fill in the actual values
			for (const item of results.items) {
				const group = String(item[groupBy]);
				const stack = String(item[stackBy]);
				const value = Number(item[valueField]);
				if (!Number.isNaN(value)) {
					dataMap.get(group)?.set(stack, value);
				}
			}
			// Transform into chart.js format
			return {
				labels: groups,
				datasets: stacks.map((stack, index) => ({
					label: stack,
					data: groups.map((group) => dataMap.get(group)?.get(stack) || 0),
					backgroundColor: report.chart?.datasets[index % report.chart.datasets.length]?.color || 'blue',
				})),
			};
		}

		// Allow stacking all datasets
		const dataMap = new Map<string, Map<string, number>>();
		for (const group of groups) {
			dataMap.set(group, new Map<string, number>());
			for (const stack of report.chart.datasets) {
				dataMap.get(group)!.set(stack.key, 0);
			}
		}

		// Fill in the actual values
		for (const item of results.items) {
			const group = String(item[groupBy]);
			for (const stack of report.chart?.datasets || []) {
				const value = Number(item[stack.key]);
				if (!Number.isNaN(value)) {
					dataMap.get(group)?.set(stack.key, value);
				}
			}
		}
		console.log('DATA MAP', dataMap);
		console.log({
			labels: groups,
			datasets: report.chart?.datasets.map((stack) => ({
				label: stack.label,
				data: groups.map((group) => dataMap.get(group)?.get(stack.key) || 0),
				backgroundColor: stack.color,
			})),
		});
		// Transform into chart.js format
		return {
			labels: groups,
			datasets: report.chart?.datasets.map((stack) => ({
				label: stack.label,
				data: groups.map((group) => dataMap.get(group)?.get(stack.key) || 0),
				backgroundColor: stack.color,
				type: valueField && valueField === stack.key ? 'line' : 'bar',
			})),
		};
	}

	// Default behavior for other chart types
	return {
		labels: results.items.map((row) => String(row[labelColumn])),
		datasets: [{
			label: report.chart?.datasets[0]?.label || 'Dataset 1',
			data: results.items.map((row) => Number(row[valueColumn])),
			backgroundColor: report.chart?.datasets[0]?.color || 'blue',
		}],
	};
}

export default function ReportChart({ report, data, className }: ReportChartProps) {
	if (!data) {
		return <div>No data available</div>;
	}

	const chartData = transformResultsToChartData(data, report);
	// Handle different chart types
	switch (report.chart?.type) {
		case 'bar':
			return (
				<BarChart2
					className={className}
					labels={chartData.labels}
					datasets={chartData.datasets}
					stacked={true}
					legend={report.chart.legend}
				/>
			);

		case 'pie':
		case 'doughnut': {
			const dataset = chartData.datasets[0];
			if (!dataset?._colors) {
				throw new Error('Expected colors array for pie/doughnut chart');
			}
			return (
				<PieChart
					className={className}
					labels={chartData.labels}
					values={dataset.data}
					colors={dataset._colors}
					legend={report.chart.legend}
					showPercentage
				/>
			);
		}

		case 'line':
		case 'scatter':
		case 'radar':
			// For now, use the bar chart component for these types
			return (
				<BarChart2
					className={className}
					labels={chartData.labels}
					datasets={chartData.datasets}
					stacked={Boolean(report.chart?.stack_by)}
				/>
			);

		case 'table':
			// Table display is handled in the parent component
			return <></>;

		default:
			return <div>Unsupported chart type: {report.chart?.type}</div>;
	}
}
