import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { SearchHit } from '@newstex/search';
import type { Dashboard } from '@newstex/types/dashboard';
import { createColumnHelper } from '@tanstack/react-table';
import { title as titleCase } from 'case';
import { useMemo, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DataTable, { DataTableMethods } from '../data-table';
import { LoadMoreButton } from '../load-more-button';
import { PropertyDisplayValue } from '../property-display-value';

interface DashboardsTableProps {
	items: SearchHit<Dashboard>[];
	searchText?: string;
	hasMore?: boolean;
	onLoadMore?: () => void;
}

export function DashboardsTable({
	items,
	searchText,
	hasMore,
	onLoadMore,
}: DashboardsTableProps) {
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<SearchHit<Dashboard>>();

	const columns = useMemo(() => [
		columnHelper.accessor('name', {
			header: 'Name',
			enableSorting: true,
			cell: ({ row: { original }}) => (
				<Link to={`/dashboards/${original.id}`}>
					{original.name}
				</Link>
			),
		}),
		columnHelper.accessor('description', {
			header: 'Description',
			enableSorting: true,
			cell: ({ getValue }) => getValue() || '-',
		}),
		columnHelper.accessor('access_groups', {
			header: 'Access Groups',
			enableSorting: true,
			cell: ({ getValue }) => (
				<PropertyDisplayValue
					propName="access_groups"
					propValue={getValue()?.map((group) => titleCase(group))}
					typeName="Dashboard"
				/>
			),
		}),
		columnHelper.accessor('created_at', {
			header: 'Created',
			enableSorting: true,
			cell: ({ getValue }) => (
				<PropertyDisplayValue
					propName="created_at"
					propValue={getValue()}
					typeName="Dashboard"
				/>
			),
		}),
		columnHelper.accessor('updated_at', {
			header: 'Updated',
			enableSorting: true,
			cell: ({ getValue }) => (
				<PropertyDisplayValue
					propName="updated_at"
					propValue={getValue()}
					typeName="Dashboard"
				/>
			),
		}),
	], []);

	return (
		<>
			<DataTable
				columns={columns}
				items={items}
				ref={dataTableRef}
				searchText={searchText}
				striped
				sortable
			/>
			<LoadMoreButton show={hasMore} onClick={onLoadMore} />
			<div className="float-end pb-2">
				<Button variant="outline-secondary" size="sm" onClick={() => {
					dataTableRef.current?.exportToCSV('Dashboards');
				}}>
					<FontAwesomeIcon icon={faDownload} /> CSV ({items.length})
				</Button>
			</div>
		</>
	);
}
