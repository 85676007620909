import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { SiteConfig } from '@newstex/types/site-config';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DataTable, { DataTableMethods } from '../data-table';
import { LoadMoreButton } from '../load-more-button';
import { PropertyDisplayValue } from '../property-display-value';

export function SitesTable(
	{
		sites,
		searchText,
		hasMore,
		onLoadMore,
	}: {
		sites: SiteConfig[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void,
	},
) {
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<SiteConfig>();
	const columns = useMemo(() => [
		columnHelper.accessor('icon', {
			header: 'Icon',
			enableSorting: false,
			size: 20,
			cell: ({ getValue, row: { original }}) => {
				return <>
					{getValue() && <FontAwesomeIcon icon={getValue() as any} className="site-icon" />}
				</>;
			},
		}),
		columnHelper.accessor('name', {
			header: 'Name',
			enableSorting: !hasMore,
			cell: ({ getValue, row: { original }}) => {
				return <>
					<Link to={`/sites/${original.$id}`}>
						<PropertyDisplayValue propName="name" propValue={getValue()} />
					</Link>
					<br />
					<small className="text-muted">
						<Link
							target="_blank"
							to={`https://${original.$id}.newstex.blog`}
							className="text-muted"
						>
							{original.$id}.newstex.blog <FontAwesomeIcon icon={faExternalLinkAlt} />
						</Link>
					</small>
				</>;
			},
		}),
		columnHelper.accessor('content_filter', {
			header: 'Content',
			enableSorting: !hasMore,
		}),
		columnHelper.accessor('status', {
			header: 'Status',
			enableSorting: !hasMore,
		}),
		columnHelper.accessor('category', {
			header: 'Category',
			enableSorting: !hasMore,
		}),
	], [sites]);

	return (<>
		<DataTable
			columns={columns}
			items={sites || []}
			ref={dataTableRef}
			searchText={searchText}
			tableRowClassNameHelper={({ original }: { original: SiteConfig }) => {
				if (original.status === 'inactive') {
					return 'table-danger';
				}
				return '';
			}}
			nameAsc
			striped
			sortable
		/>
		<LoadMoreButton show={hasMore} onClick={onLoadMore} />
		<div className="float-end pb-2">
			<Button variant="outline-secondary" size="sm" onClick={() => {
				dataTableRef.current?.exportToCSV('Sites');
			}}>
				<FontAwesomeIcon icon={faDownload} /> CSV
			</Button>
		</div>
	</>);
}
