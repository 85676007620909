import {
	faChartLine,
	faCheck,
	faFileAlt,
	faRobot,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GenerateStoryAgent } from '@newstex/ai/agents/generate-story-agent';
import { SECFilingsAgent } from '@newstex/ai/agents/sec-filings-agent';
import { Publication } from '@newstex/types/content';
import { Story } from '@newstex/types/story';
import { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PublicationSelect } from '~/components/editors/publication-select';
import { useAI } from '~/providers/ai-provider';
import { useAPI } from '~/providers/api-provider';
import { useSearch } from '~/providers/search';

type AgentType = 'sec' | 'generic';

interface AgentOption {
	id: AgentType;
	name: string;
	description: string;
	icon: typeof faFileAlt;
}

const AGENT_OPTIONS: AgentOption[] = [
	{
		id: 'sec',
		name: 'SEC Filings',
		description: 'Generate a summary of recent SEC filings',
		icon: faChartLine,
	},
	{
		id: 'generic',
		name: 'Custom Topic',
		description: 'Generate a story about any topic',
		icon: faRobot,
	},
];

export interface GenerateStoryModalProps {
	show: boolean;
	onClose: (saved: boolean) => void;
	initialTopic?: string;
	stories?: Story[];
	searchQuery?: string;
	publication?: Publication;
	onStoryGenerated?: (story: Story) => void;
}

// Map of categories to publication IDs
const CATEGORY_PUBLICATION_MAP: Record<string, string> = {
	POLITICS: 'politics.newstex.blog',
	FINANCE: 'finance.newstex.blog',
	TECHNOLOGY: 'tech.newstex.blog',
	BUSINESS: 'business.newstex.blog',
};

export function GenerateStoryModal({
	show,
	onClose,
	initialTopic,
	stories,
	searchQuery,
	publication,
	onStoryGenerated,
}: GenerateStoryModalProps) {
	const navigate = useNavigate();
	const [selectedAgent, setSelectedAgent] = useState<AgentType | null>(null);
	const [topic, setTopic] = useState(initialTopic || searchQuery || '');
	const [isGenerating, setIsGenerating] = useState(false);
	const [currentStep, setCurrentStep] = useState<string | null>(null);
	const [currentStepDetails, setCurrentStepDetails] = useState<string | undefined>();
	const [agent, setAgent] = useState<SECFilingsAgent | GenerateStoryAgent | null>(null);
	const [generatedStory, setGeneratedStory] = useState<Story | null>(null);
	const [selectedPublication, setSelectedPublication] = useState<Publication | null>(publication || null);
	const [showPublicationSelect, setShowPublicationSelect] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const { searchClient } = useSearch();
	const ai = useAI();
	const api = useAPI();

	// If we have stories, start with the generic agent
	useEffect(() => {
		if (stories?.length) {
			setSelectedAgent('generic');
		}
	}, [stories]);

	// Try to guess the publication based on categories
	useEffect(() => {
		if (generatedStory && !selectedPublication) {
			const categories = generatedStory.provider_categories || [];
			// Find the first matching category
			const matchingCategory = Object.keys(CATEGORY_PUBLICATION_MAP).find(
				(category) => categories.some((c) => c.toUpperCase().includes(category)),
			);
			if (matchingCategory) {
				const publicationId = CATEGORY_PUBLICATION_MAP[matchingCategory];
				// Fetch the publication details
				api.fetchWithAuth<Publication>(`resources/Publication/${publicationId}`)
					.then((pub) => {
						if (pub) {
							setSelectedPublication(pub);
						}
					})
					.catch((err) => {
						console.error('Failed to fetch publication:', err);
					});
			}
		}
	}, [generatedStory, selectedPublication]);

	const handleGenerate = async () => {
		if (!searchClient) {
			toast.error('Search client not initialized');
			return;
		}

		setIsGenerating(true);
		try {
			let story: Story;
			setError(null);

			if (selectedAgent === 'sec') {
				const modelProvider = await ai.refreshCredentials();
				if (!modelProvider) {
					throw new Error('Failed to initialize model provider');
				}

				const newAgent = new SECFilingsAgent({
					modelProvider,
					resources: {
						searchStories: async (params) => {
							const resp = await searchClient.search({
								...params,
								indexName: 'Story',
							});
							return {
								hits: resp.hits,
								found: resp.found,
								facets: resp.facets,
							};
						},
						getStoryFile: async (s: Story, fileName: string) => {
							console.log('Getting story file', { story: s, fileName });
							const resp = await api.fetchWithAuthRaw(`resources/Story/${s.__id__}/${fileName}`);
							return resp.text();
						},
					},
					onProgress: (step: string, details?: string) => {
						setCurrentStep(step);
						setCurrentStepDetails(details);
					},
				});

				setAgent(newAgent);
				const result = await newAgent.generateFilingsSummary();
				if (!result) {
					throw new Error('No filings found to summarize');
				}

				if (!result.headline || !result.html_content) {
					throw new Error('Generated story is missing required fields');
				}
				story = {
					__type__: 'Story',
					__id__: '',
					headline: result.headline,
					html_content: result.html_content,
					excerpt: result.excerpt || '',
					provider_categories: result.provider_categories || [],
				};
			} else {
				const modelProvider = await ai.refreshCredentials();
				if (!modelProvider) {
					throw new Error('Failed to initialize model provider');
				}
				const newAgent = new GenerateStoryAgent({
					modelProvider,
					resources: {
						searchStories: async (params: {
							query: string;
							query_by?: string[];
							filter_by?: string;
							sort_by?: string;
							per_page?: number;
							page?: number;
							facet_by?: string[];
						}) => {
							const resp = await searchClient.search({
								...params,
								indexName: 'Story',
							});
							return {
								hits: resp.hits,
								found: resp.found,
								facets: resp.facets,
							};
						},
						getStoryFile: async (s: Story, fileName: string) => {
							console.log('Getting story file', { story: s, fileName });
							const resp = await api.fetchWithAuthRaw(`resources/Story/${s.__id__}/${fileName}`);
							return resp.text();
						},
					},
					onProgress: (step: string, details?: string) => {
						setCurrentStep(step);
						setCurrentStepDetails(details);
					},
				});

				setAgent(newAgent);
				try {
					const result = await newAgent.generateStory([{
						role: 'user' as const,
						content: stories?.length
							? `Generate a story summarizing these search results about "${topic}". Include relevant quotes and statistics from the source stories where appropriate.`
							: `Generate a story about: ${topic}`,
					}], stories);

					if (!result.headline || !result.html_content) {
						throw new Error('Generated story is missing required fields');
					}
					story = {
						__type__: 'Story',
						__id__: '',
						headline: result.headline,
						html_content: result.html_content,
						excerpt: result.excerpt || '',
						provider_categories: result.provider_categories || [],
					};
				} catch (err: any) {
					console.error('Error generating story:', err);
					toast.error('Failed to generate story');
					setError(err);
					setIsGenerating(false);
					return;
				}
			}

			setGeneratedStory(story);
			setSelectedAgent(null);
			if (onStoryGenerated) {
				onStoryGenerated(story);
			} else {
				setShowPublicationSelect(true);
			}
		} catch (err: any) {
			console.error('Error generating story:', err);
			toast.error('Failed to generate story');
			setError(err);
			setSelectedAgent(null);
		} finally {
			setIsGenerating(false);
			setCurrentStep(null);
		}
	};

	// Auto-generate SEC filings when selected
	useEffect(() => {
		if (selectedAgent && selectedAgent !== 'generic') {
			handleGenerate();
		}
	}, [selectedAgent]);

	const handleSave = async () => {
		if (!selectedPublication || !generatedStory) return;

		try {
			console.log('Saving story', { generatedStory, selectedPublication });
			const story: Story = {
				__type__: 'Story',
				__id__: '',
				headline: generatedStory.headline,
				excerpt: generatedStory.excerpt,
				html_content: generatedStory.html_content,
				provider_categories: generatedStory.provider_categories || [],
				external_id: `${new URL(selectedPublication.url || 'https://blog.newstex.com').hostname}-${Date.now()}`,
			};

			const response = await api.fetchWithAuth<Story>('resources/Story', {
				method: 'POST',
				body: JSON.stringify({ ...story, publication: selectedPublication.$id }),
			});

			if (response) {
				onClose(true);
				console.log('Story created successfully', response);
				toast.success('Story created successfully', {
					onClick: () => {
						navigate(`/stories/${response.__id__}`);
					},
				});
			}
		} catch (err) {
			console.error('Error saving story:', err);
			toast.error('Failed to save story');
		}
	};

	const renderProgressTracker = () => {
		if (!currentStep || !agent) return null;

		const steps = agent.steps;
		const currentStepIndex = steps.findIndex((s) => s.id === currentStep);

		return (
			<div className="mb-4">
				<div className="d-flex justify-content-between position-relative mb-2">
					{/* Progress bar background */}
					<div
						className="position-absolute bg-light"
						style={{
							height: '2px',
							width: '100%',
							top: '25px',
							zIndex: 0,
						}}
					/>
					{/* Active progress bar */}
					<div
						className="position-absolute bg-primary"
						style={{
							height: '2px',
							width: `${(currentStepIndex / (steps.length - 1)) * 100}%`,
							top: '25px',
							zIndex: 1,
							transition: 'width 0.5s ease-in-out',
						}}
					/>
					{/* Step indicators */}
					{steps.map((step, index) => {
						const isCompleted = index < currentStepIndex;
						const isActive = step.id === currentStep;
						let stepColor = 'text-muted';
						let bgColor = 'bg-light';
						if (isCompleted) {
							stepColor = 'text-success';
							bgColor = 'bg-success';
						} else if (isActive) {
							stepColor = 'text-primary';
							bgColor = 'bg-primary';
						}

						return (
							<div
								key={step.id}
								className="d-flex flex-column align-items-center position-relative"
								style={{ zIndex: 2, width: '80px' }}
							>
								<div
									className={`d-flex align-items-center justify-content-center rounded-circle ${bgColor}`}
									style={{
										width: '50px',
										height: '50px',
										border: '2px solid',
										borderColor: `var(--${bgColor}`,
									}}
								>
									{isCompleted ? (
										<FontAwesomeIcon icon={faCheck} className="text-white" />
									) : (
										<span className={isActive ? 'text-white' : 'text-muted'}>
											{index + 1}
										</span>
									)}
								</div>
								<div className="text-center mt-2">
									<div className={`small fw-bold ${stepColor}`}>
										{step.label}
									</div>
									{isActive && (
										<div className="small text-muted" style={{ fontSize: '0.75rem' }}>
											{step.description}
										</div>
									)}
								</div>
							</div>
						);
					})}
				</div>
				{currentStepDetails && (
					<div className="mt-2 text-center">
						<div className="small text-muted">{currentStepDetails}</div>
					</div>
				)}
			</div>
		);
	};

	const renderProgressView = () => {
		const newAgent = AGENT_OPTIONS.find((a) => a.id === selectedAgent);
		if (!newAgent) return null;

		return (
			<div className="py-4">
				<div className="text-center mb-4">
					<FontAwesomeIcon
						icon={newAgent.icon}
						size="3x"
						className="text-primary mb-4"
					/>
					<h4>{newAgent.name}</h4>
					<p className="text-muted">{newAgent.description}</p>
				</div>

				{renderProgressTracker()}

				{currentStep && (
					<div className="text-center mt-4">
						<div className="spinner-border spinner-border-sm text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				)}
			</div>
		);
	};

	const renderGenericAgentView = () => (
		<div className="py-4">
			{isGenerating ? (
				<>
					<div className="text-center mb-4">
						<FontAwesomeIcon
							icon={faRobot}
							size="3x"
							className="text-primary mb-4"
						/>
						<h4>Custom Topic</h4>
						<p className="text-muted">Generating a story about {topic}</p>
					</div>

					{renderProgressTracker()}

					<div className="text-center mt-4">
						<div className="spinner-border spinner-border-sm text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</>
			) : (
				<>
					<Row>
						<Col md={6}>
							<Form.Group className="mb-4">
								<Form.Label>Topic</Form.Label>
								<Form.Control
									value={topic}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTopic(e.target.value)}
									placeholder="Enter a topic to generate a story about..."
									disabled={isGenerating || !!stories?.length}
								/>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group className="mb-4">
								<Form.Label>Publication</Form.Label>
								<PublicationSelect
									value={selectedPublication}
									onChange={setSelectedPublication}
									filterBy='parent:a008000000DZvOEAA1 && status:Active'
								/>
							</Form.Group>
						</Col>
					</Row>
					<div className="d-flex justify-content-center">
						<Button
							onClick={handleGenerate}
							disabled={!topic || !selectedPublication || isGenerating}
							className="px-4"
						>
							{isGenerating ? (
								<>
									<span className="spinner-border spinner-border-sm me-2" role="status" />
									Generating...
								</>
							) : (
								'Generate Story'
							)}
						</Button>
					</div>
				</>
			)}
		</div>
	);

	const renderContent = () => {
		if (selectedAgent === 'generic') {
			return renderGenericAgentView();
		}

		if (selectedAgent) {
			return renderProgressView();
		}

		return (
			<div>
				<h5 className="mb-3">Select Story Type</h5>
				<Row className="g-4">
					{AGENT_OPTIONS.map((a) => (
						<Col key={a.id} md={6}>
							<Card
								className="h-100 cursor-pointer"
								onClick={() => !isGenerating && setSelectedAgent(a.id)}
								style={{ cursor: isGenerating ? 'not-allowed' : 'pointer' }}
							>
								<Card.Body className="d-flex flex-column align-items-center text-center p-4">
									<FontAwesomeIcon
										icon={a.icon}
										size="3x"
										className="mb-3 text-muted"
									/>
									<h5 className="mb-2">{a.name}</h5>
									<p className="text-muted mb-0">{a.description}</p>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
			</div>
		);
	};

	return (
		<Modal
			show={show}
			onHide={() => onClose(false)}
			size="lg"
			contentClassName="border-3 border-primary"
			backdrop="static"
		>
			<Modal.Header closeButton>
				<Modal.Title>Generate Story</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error && (
					<div className="alert alert-danger">
						{error.message || String(error)}
					</div>
				)}
				{!showPublicationSelect ? (
					<div className="space-y-4">
						{renderContent()}
					</div>
				) : (
					<div className="space-y-4">
						<div>
							<h5>Preview</h5>
							<div className="space-y-2">
								<h3 className="h4">{generatedStory?.headline}</h3>
								<p className="text-muted">{generatedStory?.excerpt}</p>
								<div className="border p-3 rounded bg-light">
									<div dangerouslySetInnerHTML={{ __html: generatedStory?.html_content || '' }} />
								</div>
								<div className="text-muted">
									Categories: {generatedStory?.provider_categories?.join(', ')}
								</div>
							</div>
						</div>
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				{selectedAgent && !showPublicationSelect && (
					<Button variant="link" onClick={() => {
						setSelectedAgent(null);
						setCurrentStep(null);
						setIsGenerating(false);
					}}>
						Back
					</Button>
				)}
				<Button variant="secondary" onClick={() => onClose(false)}>
					Cancel
				</Button>
				{showPublicationSelect && (
					<Button
						onClick={handleSave}
						disabled={!selectedPublication}
					>
						Save Story
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
}
