/**
 * Page Title Provider
 *
 * This module provides a centralized way to manage the page title throughout the application.
 *
 * There are two ways to set the page title:
 *
 * 1. Use the PageTitle component, which will automatically update the page title:
 *    ```tsx
 *    <PageTitle title="My Page Title" />
 *    ```
 *
 * 2. Use the useSetPageTitle hook in any component:
 *    ```tsx
 *    import { useSetPageTitle } from '~/hooks/use-set-page-title';
 *
 *    function MyComponent() {
 *      useSetPageTitle('My Page Title');
 *
 *      // For dynamic titles:
 *      useSetPageTitle(data?.title || 'Loading...', [data?.title]);
 *
 *      return <div>...</div>;
 *    }
 *    ```
 *
 * The page title will be displayed in the header, and the PageTitleProvider
 * will handle setting the default title based on the current route.
 */

import { create } from 'zustand';

// Define the state interface
interface PageTitleState {
	title: string;
	setTitle: (title: string) => void;
}

// Create the Zustand store
const usePageTitleStore = create<PageTitleState>((set) => ({
	title: 'NewsCore',
	setTitle: (title: string) => set({ title }),
}));

// Create a hook to use the store
export const usePageTitle = (): PageTitleState => {
	return usePageTitleStore();
};
