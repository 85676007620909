/* eslint-disable array-callback-return */
import { useSiteConfig } from '~/providers/site-config';

import CuratorSidebar from './sidebars/curator';
import DefaultSidebar from './sidebars/default';
import { SidebarProps } from './sidebars/shared';

/**
 * Sidebar Router
 */
export default function Sidebar({ sidebarShrink }: SidebarProps) {
	const siteConfig = useSiteConfig();
	console.log('Site Config', siteConfig);

	switch (siteConfig?.category || siteConfig?.$id) {
		case 'curator':
			return <CuratorSidebar sidebarShrink={sidebarShrink} />;
		default:
			return <DefaultSidebar sidebarShrink={sidebarShrink} />;
	}
}
