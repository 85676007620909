import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useBreakpoint } from '~/hooks/use-breakpoint';
import { useAIChat } from '~/providers/ai-chat-provider';
import { useUserInfo } from '~/providers/user-info';
import 'react-toastify/dist/ReactToastify.css';

import { AIChatContent, AIChatDisplayMode } from './ai-chat-content';
import Footer from './footer';
import Header from './header/index';
import Sidebar from './sidebar';
import SvgIcons from './SvgIcons';

const Layout = (pageProps: any) => {
	const [sidebarShrink, setSidebarShrink] = useState(false);
	const [pageHolderClass, setPageHolderClass] = useState('bg-gray-100');
	const [displayMode, setDisplayMode] = useState<AIChatDisplayMode>('side-panel');
	const [isTransitioning, setIsTransitioning] = useState(false);
	const userInfo = useUserInfo();
	const { visible, setVisible } = useAIChat();
	const { priority } = useBreakpoint();
	useEffect(() => {
		if (priority <= 3) {
			setSidebarShrink(true);
		} else {
			setSidebarShrink(false);
		}
	}, [priority]);

	useEffect(() => {
		// Check if we're in a browser environment and the user is an admin
		if (typeof window !== 'undefined' && userInfo?.auth_groups?.includes('admin')) {
			// If no response within 5 seconds, assume extension is not installed
			const timeoutId = setTimeout(() => {
				toast.warning(
					<div>
						<h6>The NewsCore Chrome extension is not installed.</h6>
						<div>Some features may be limited.</div>
						<div>Click here to install.</div>
					</div>,
					{
						autoClose: false,
						toastId: 'extension-missing',
						onClick: () => {
							window.open('https://chromewebstore.google.com/detail/newstex-hubspot/blcegcgjimpjhefnkgokgnbakbdkngac', '_blank');
						},
					},
				);
			}, 5000);

			// Setup message listener
			const messageHandler = (event: MessageEvent) => {
				if (event.data?.type === 'NEWSCORE_response') {
					console.log('Received response', event.data);
					// Extension returned a response, so clear the timeout
					clearTimeout(timeoutId);
					if (!event.data?.data?.ok) {
						// Extension is not working, so we need to show an error
						toast.error(
							<div>
								<h6>The NewsCore Chrome extension was detected, but not working.</h6>
								<div>Some features may be limited.</div>
								<small>
									<code>{JSON.stringify(event.data, null, 2)}</code>
								</small>
							</div>,
							{
								autoClose: false,
								toastId: 'extension-not-working',
							},
						);
					} else {
						toast.dismiss('extension-missing');
						// toast.info('The NewsCore Chrome extension was detected and is working.');
					}
				}
				// Always return true to allow the message to continue to the next listener
				return true;
			};

			window.addEventListener('message', messageHandler);

			// Send check status message
			window.postMessage({
				type: 'NEWSCORE_checkStatus',
				id: `check-status-${Date.now()}`,
			}, '*');

			// Cleanup
			return () => {
				window.removeEventListener('message', messageHandler);
				clearTimeout(timeoutId);
			};
		}
	}, [userInfo]);

	useEffect(() => {
		if (visible) {
			setPageHolderClass('with-ai-chat');
		} else {
			setPageHolderClass('bg-gray-100');
		}
	}, [visible]);

	const handleHide = () => {
		setVisible(false);
		setDisplayMode('side-panel'); // Reset to side panel when hiding
	};

	const handleDisplayModeChange = (mode: AIChatDisplayMode) => {
		setIsTransitioning(true);

		// Start transition
		if (mode === 'full-screen') {
			// When transitioning to full screen, first expand width, then slide
			requestAnimationFrame(() => {
				setDisplayMode(mode);
				// After transition completes, remove transitioning state
				setTimeout(() => {
					setIsTransitioning(false);
				}, 300); // Match transition duration
			});
		} else {
			// When transitioning to side panel, first slide, then reduce width
			setDisplayMode(mode);
			// After transition completes, remove transitioning state
			setTimeout(() => {
				setIsTransitioning(false);
			}, 300); // Match transition duration
		}
	};

	const getTransitionClass = () => {
		if (!isTransitioning) return '';
		return displayMode === 'full-screen' ? 'transitioning-to-full' : 'transitioning-from-full';
	};

	return (<>
		<div className={`app-container ${pageProps.className || ''} ${visible ? 'with-ai-chat' : ''} ${displayMode === 'full-screen' ? 'full-screen' : ''}`}>
			<ToastContainer
				position="bottom-right"
				newestOnTop
				closeOnClick
				className={visible ? 'chat-adjusted-toast' : ''}
			/>

			<div className="d-flex align-items-stretch">
				{!pageProps.hideSidebar && <Sidebar sidebarShrink={sidebarShrink} />}

				<div
					key={pageHolderClass}
					className={`page-holder flex-grow-1 ${pageHolderClass}`}
				>
					{!pageProps.hideHeader && (
						<Header />
					)}

					<div className="page-content">
						{pageProps.children}
					</div>

					{!pageProps.hideFooter && <Footer />}
				</div>

			</div>
			<SvgIcons />
		</div>
		{visible && (
			<div className={`ai-chat-container ${displayMode === 'full-screen' ? 'full-screen' : 'side-panel'} ${getTransitionClass()}`}>
				<AIChatContent
					onHide={handleHide}
					displayMode={displayMode}
					onDisplayModeChange={handleDisplayModeChange}
				/>
			</div>
		)}
	</>);
};

export default Layout;
