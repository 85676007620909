/**
 * RAG Resources
 */
import type { CollectionFieldSchmaForObject } from './types';

export const RAG: CollectionFieldSchmaForObject<any> = [
	{
		name: 'title',
		type: 'string',
		optional: true,
		sort: true,
		store: true,
		defaultSearchOrder: 0,
	},
	{
		name: 'question',
		type: 'string',
		optional: true,
		sort: true,
		store: true,
		defaultSearchOrder: 1,
	},
	{
		name: 'questions',
		type: 'string[]',
		optional: true,
		store: true,
		defaultSearchOrder: 1,
	},
	{
		name: 'answer',
		type: 'string',
		defaultSearchOrder: 2,
		store: true,
	},
	{
		name: 'access_groups',
		type: 'string[]',
		facet: true,
		optional: true,
		store: true,
	},
	{
		name: 'created_by',
		type: 'string',
		sort: true,
		facet: true,
		optional: true,
		store: true,
	},
	{
		name: 'category',
		type: 'string',
		sort: true,
		facet: true,
		optional: true,
		store: true,
	},
	{
		name: 'created_at',
		type: 'int64',
		sort: true,
		store: true,
		facet: true,
		range_index: true,
		optional: true,
	},
	{
		name: 'embedding',
		type: 'float[]',
		embed: {
			from: [
				'question',
				'answer',
			],
			model_config: {
				model_name: 'ts/all-MiniLM-L12-v2',
			},
		},
	},
];

/**
 * RAG Memory - Something a user asked the AI to save or remember
 */
export const RAGMemory: CollectionFieldSchmaForObject<any> = [
	{
		name: 'content',
		type: 'string',
		optional: true,
		sort: true,
		store: true,
		defaultSearchOrder: 0,
	},
	{
		name: 'link',
		type: 'string',
		optional: true,
		sort: true,
		store: true,
		defaultSearchOrder: 1,
	},
	{
		name: 'category',
		type: 'string',
		sort: true,
		facet: true,
		optional: true,
		store: true,
	},
	{
		name: 'created_at',
		type: 'int64',
		sort: true,
		store: true,
		facet: true,
		range_index: true,
		optional: true,
	},
	{
		name: 'created_by',
		type: 'string',
		sort: true,
		store: true,
		facet: true,
		optional: true,
	},
	{
		name: 'embedding',
		type: 'float[]',
		embed: {
			from: [
				'content',
			],
			model_config: {
				model_name: 'ts/all-MiniLM-L12-v2',
			},
		},
	},
];
