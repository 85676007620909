import { faChevronLeft, faChevronRight, faX } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { ModelSelector } from './ai-model-selector';
import { Thread } from './assistant-ui/thread';
import { ThreadListButton } from './assistant-ui/thread-list';

export type AIChatDisplayMode = 'side-panel' | 'full-screen';

export interface AIChatContentProps {
	onHide: () => void;
	displayMode: AIChatDisplayMode;
	onDisplayModeChange: (mode: AIChatDisplayMode) => void;
}

export function AIChatContent({ onHide, displayMode, onDisplayModeChange }: AIChatContentProps) {
	const handleResponse = () => {
		const composer = document.getElementById('ai-chat-composer');
		if (composer) {
			composer.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
		}
	};

	useEffect(() => {
		document.addEventListener('ai-chat-response', handleResponse);
		return () => {
			document.removeEventListener('ai-chat-response', handleResponse);
		};
	}, []);

	return (
		<div
			className='ai-chat'
			key={`assistant-ui-${displayMode}`}
			id={`assistant-ui-${displayMode}`}
		>
			<div className="ai-chat-header">
				<div className="d-flex justify-content-between align-items-center p-3">
					<h5 className="mb-0">
						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip>Switch to {displayMode === 'side-panel' ? 'full screen' : 'side panel'} view</Tooltip>}
						>
							<Button variant="link" onClick={() => {
								onDisplayModeChange(displayMode === 'side-panel' ? 'full-screen' : 'side-panel');
							}} className="me-2">
								<FontAwesomeIcon icon={displayMode === 'side-panel' ? faChevronLeft : faChevronRight} />
							</Button>
						</OverlayTrigger>
						AI Chat
						<ModelSelector />
					</h5>
					<div>
						<ThreadListButton />
						<Button variant="link" onClick={onHide} className="p-0">
							<FontAwesomeIcon icon={faX} />
						</Button>
					</div>
				</div>
			</div>
			<div className="ai-chat-body">
				<Thread />
			</div>
		</div>
	);
}
