/**
 * Report Creation Agent
 *
 * This agent helps create or modify reports from plain-text descriptions.
 */
import type { Logger } from '@newstex/core/logger';
import { KnowledgeBase } from '@newstex/types/rag';
import { ReportSchema } from '@newstex/types/report';
import { z } from 'zod';

import { Agent, AgentContext } from './agent';

export interface ReportAgentContext extends AgentContext {
	report?: Partial<z.infer<typeof ReportSchema>>;
}

export interface ReportAgentInput {
	description: string;
	knowledgeBase?: KnowledgeBase;
}

// Use a subset of the ReportSchema for the AI output
export const ReportAgentSchema = ReportSchema.pick({
	name: true,
	database: true,
	category: true,
	description: true,
	query: true,
	chart: true,
	parameters: true,
}).extend({
	message: z.string().describe('A markdown-formatted human-readable message explaining the changes or asking for clarification. Keep it short and concise.'),
});

export type ReportAgentOutput = z.infer<typeof ReportAgentSchema>;

export interface Message {
	role: 'user' | 'assistant' | 'system';
	content: string;
	details?: ReportAgentOutput;
}

const SEARCH_AGENT_SYSTEM_PROMPT = `You are part of an AI Agent system designed to help create reports
by converting plain text descriptions into structured report configurations.

Valid Databases:
	- NewsCore (PSQL database, for reports about Publishers, Publications, and Deliveries)
	- NewsCrunch (PSQL database, for Revenue/Royalty reports)
	- DMARC (AWS Timestream Database, for Email analysis)

You are given a description of a report, and your output will be used to query a knowledge base.

**IMPORTANT**: Your text output must be ONLY the plain text search query to pass into the knowledge base search engine.

DO NOT RETURN ANYTHING OTHER THAN THE PLAIN TEXT QUERY.
`;

export class ReportAgent extends Agent {
	private messages: Message[] = [];
	private existingReport?: z.infer<typeof ReportSchema>;

	protected systemPrompt = `You are an expert SQL analyst and data visualization specialist.
Your task is to help create reports by converting plain text descriptions into structured report configurations.

${this.existingReport ? 'You are modifying an existing report. Consider the current configuration when making changes.' : ''}

Given a description, you should:
1. Create a clear, concise name for the report
2. Refine the description to be more technical and precise
3. Write an appropriate SQL query to fetch the required data
4. Choose the most suitable chart type for visualizing the data
5. Define any necessary parameters for the query
6. Structure the datasets appropriately for the chosen chart type

When provided with knowledge base context, use it to:
- Understand domain-specific terminology
- Follow established patterns and conventions
- Maintain consistency with existing reports
- Apply relevant business rules and requirements

For each response:
- Write a clear, conversational message explaining your choices
- Ask questions if you need clarification
- Highlight any assumptions you've made
- Suggest improvements or alternatives

Use your expertise to:
- Write efficient SQL queries using PostgreSQL syntax
- Choose appropriate chart types for the data visualization
- Select meaningful colors for datasets (use hex colors)
- Create clear parameter labels
- Ensure the report name is descriptive but concise

Available Chart Types:
- bar: For comparing values across categories
- line: For showing trends over time
- pie: For showing parts of a whole
- scatter: For showing correlation between variables
- doughnut: Similar to pie, but with a hole in the center
- radar: For comparing multiple variables
- table: For showing raw data in tabular format

Parameter Types:
- string: Text input
- number: Numeric input
- date: Date picker
- boolean: True/false toggle
- enum: Selection from a list of options`;

	constructor(context: ReportAgentContext = {}, logger?: Logger | Console) {
		super(context, logger);
		this.existingReport = context.report;
	}

	private formatUserMessage(msg: Message, isInitial: boolean): { role: 'user' | 'assistant' | 'system'; content: string } {
		if (msg.role !== 'user') {
			return {
				role: 'assistant',
				content: msg.content,
			};
		}

		let content = msg.content;

		if (isInitial) {
			const contextParts = [`Please help me ${this.existingReport ? 'modify' : 'create'} a report based on this description: "${msg.content}"`];

			if (this.existingReport) {
				contextParts.push(`\n\nCurrent report configuration:\n${JSON.stringify(this.existingReport, null, 2)}`);
			}

			content = contextParts.join('');
		}

		return {
			role: 'user',
			content,
		};
	}

	/**
	 * Search for existing reports
	 */
	protected async searchReports(query: string): Promise<z.infer<typeof ReportSchema>[]> {
		if (!this.resources?.listAll) {
			this.logger.warn('No listAll function found in resources');
			return [];
		}
		this.logger.info(`Searching reports for query: ${query}`);
		const reports = await this.resources.listAll<z.infer<typeof ReportSchema>>('Report');
		const resp = await this.generateObject(
			z.object({
				reportIDs: z.array(z.string()).describe('A list of report IDs that are similar to the query'),
			}),
			[
				'You are a similarity search engine. You are given a query and a list of reports.',
				'You need to return a list of report IDs that are similar to the query.',
				'Below is a list of reports by ID and name:',
				...Object.entries(reports).map(([id, report]) => `${id}: "${report.name}"`),
				'***RETURN ONLY THE LIST OF REPORT IDs, NO OTHER TEXT***',
			].join('\n'),
			[{
				role: 'user',
				content: query,
			}],
		);
		this.logger.info('Similar reports:', { resp });
		const matchingReports: z.infer<typeof ReportSchema>[] = [];
		for (const reportID of resp.reportIDs) {
			const report = reports[reportID];
			if (report) {
				matchingReports.push(report);
			}
		}
		this.logger.info(`Found ${matchingReports.length} reports similar to the query`, { matchingReports });
		return matchingReports;
	}

	summarizeReport(report: z.infer<typeof ReportSchema>): string {
		return [
			'========================================',
			`Name: **${report.name}**`,
			'Description:',
			`> ${report.description}`,
			'',
			'Query:',
			'```sql',
			report.query,
			'```',
			'',
			`Chart: ${report.chart?.type}`,
			'',
			'Parameters:',
			'```json',
			JSON.stringify(report.parameters, null, 2),
			'```',
			'========================================',
		].join('\n');
	}

	async generateReport(input: ReportAgentInput): Promise<ReportAgentOutput> {
		// Add knowledge base context if provided
		const context: string[] = [];
		if (input.knowledgeBase) {
			context.push('Here is some relevant context from our knowledge base:');
			context.push(`## ${input.knowledgeBase.title}`);
			context.push(input.knowledgeBase.answer);
			context.push('---');
		} else if (this.context.searchClient) {
			const query = await this.generateText(
				SEARCH_AGENT_SYSTEM_PROMPT,
				[{
					role: 'user',
					content: input.description,
				}],
			);
			const knowledgeBase = await this.searchKnowledgeBase(query, 'RAG-SQL');
			if (knowledgeBase.length > 0) {
				context.push('Here is some relevant context from our knowledge base:');
				context.push(`## ${knowledgeBase[0].title}`);
				context.push(knowledgeBase[0].answer);
				context.push('---');
			}
		}

		// Add user message to history
		this.messages.push({
			role: 'user',
			content: input.description,
		});

		// First, lets check for any existing reports with a similar name or description
		const similarReports = await this.searchReports(input.description);

		if (similarReports.length > 0) {
			context.push('---');
			context.push('---');
			context.push('The following similar existing reports were found:');
			for (const report of similarReports) {
				context.push(this.summarizeReport(report));
				context.push('---');
			}
		}

		const result = await this.generateObject(
			ReportAgentSchema,
			`${this.systemPrompt}\n\n${context.join('\n')}`,
			this.messages.map((msg, index) => this.formatUserMessage(msg, index === 0)),
		);

		// Add assistant's response to history
		this.messages.push({
			role: 'assistant',
			content: result.message,
			details: result,
		});

		return result;
	}

	async updateReport(feedback: string): Promise<ReportAgentOutput> {
		// Add user's feedback to history
		this.messages.push({
			role: 'user',
			content: feedback,
		});

		const result = await this.generateObject(
			ReportAgentSchema,
			this.systemPrompt,
			this.messages.map((msg, index) => this.formatUserMessage(msg, index === 0)),
		);

		// Add assistant's response to history
		this.messages.push({
			role: 'assistant',
			content: result.message,
			details: result,
		});

		return result;
	}

	getHistory(): Message[] {
		return this.messages;
	}

	clearHistory(): void {
		this.messages = [];
	}
}
