import { bedrock } from '@ai-sdk/amazon-bedrock';
import { sleep } from '@newstex/utils/sleep';
import { CoreMessage, generateText, streamText } from 'ai';
import EventEmitter from 'eventemitter3';
import type { EventEmitter as EventEmitterType } from 'eventemitter3';

import { DEFAULT_MODEL } from './bedrock';
import {
	CompressedContext,
	ExtendedChatMessage,
	ThreadMetadata,
	ToolCallState,
	ToolContent,
} from './compressor';
import { AI_CHAT_PROMPT } from './prompts/chat';
import { AIToolsContext, type AIToolsContextArgs } from './tools';

/**
 * Parameters for streaming text in chat interactions
 * @extends Parameters<typeof streamText>[0]
 */
export type StreamTextParams = Parameters<typeof streamText>[0] & {
	/** Signal for aborting the stream */
	signal?: AbortSignal;
};

export type ChatMessage = CoreMessage;
export type StreamTextResult = ReturnType<typeof streamText>;
export { streamText, tool, generateText } from 'ai';

/**
 * Parameters for chat interactions
 * @interface
 */
export type ChatParams = {
	/** Array of messages in the chat conversation */
	messages: CoreMessage[];
	/** Optional model provider, defaults to Amazon Bedrock */
	modelProvider?: typeof bedrock;
	/** Optional model to use for the chat */
	model?: Parameters<typeof bedrock>[0];
	/** Optional callback for handling chunks of the response */
	onChunk?: StreamTextParams['onChunk'];
	/** Optional callback when the chat finishes */
	onFinish?: StreamTextParams['onFinish'];
	/** Optional callback when a step finishes */
	onStepFinish?: StreamTextParams['onStepFinish'];
	/** Optional signal for aborting the chat */
	signal?: AbortSignal;
} & Parameters<typeof bedrock>[1];

/**
 * Compresses and manages chat context by organizing messages into threads,
 * tracking tool states, and maintaining conversation coherence while staying
 * within token limits.
 */
export class ContextCompressor extends EventEmitter<AIEvents> {
	/**
	 * Markers that indicate the start of a new thread in user messages
	 * These are common phrases that users typically use to start a new task or request
	 */
	private static readonly THREAD_DETECTION_MARKERS = [
		'lets', 'can you', 'please', 'how do', 'what is',
		'help me', 'i need', 'could you',
	];

	/**
	 * Markers that indicate a complete transition to a new topic
	 * When these markers are used, the previous thread is marked as completed
	 */
	private static readonly TOPIC_TRANSITION_MARKERS = [
		'instead', 'however', 'but', 'alternatively',
		'moving on', 'next', 'now', 'regarding',
		'returning to',
	];

	/**
	 * Markers that indicate starting or returning to a concurrent task
	 * When these markers are used, multiple threads can remain active
	 */
	private static readonly CONCURRENT_TASK_MARKERS = [
		'also', 'back to',
	];

	/**
	 * Creates a new ContextCompressor instance
	 * @param maxTokens Maximum number of tokens to allow in the compressed context
	 * @param minImportance Minimum importance score for messages to be included in compression
	 */
	constructor(
		private maxTokens: number = 4000,
		private minImportance: number = 0.3,
	) {
		super();
	}

	/**
	 * Sanitizes chat messages by normalizing content format and handling special cases
	 * @param messages Array of chat messages to sanitize
	 * @returns Array of sanitized chat messages
	 */
	private sanitizeMessages(messages: ChatMessage[]): ChatMessage[] {
		const sanitized = messages.map((msg) => {
			const sanitizedMsg = { ...msg };

			// Handle array content (tool messages)
			if (Array.isArray(msg.content)) {
				if (msg.role === 'tool') {
					// For tool messages, keep the ToolContent array structure but ensure valid content
					sanitizedMsg.content = msg.content
						.filter((content: any) => {
							if (!content || typeof content !== 'object') return false;
							// Ensure required fields exist
							if (!content.type || !content.toolName) return false;
							return true;
						})
						.map((content: any) => {
							const sanitizedContent: any = {
								...content,
								// Ensure type is correct
								type: content.type === 'tool_call' ? 'tool-call' : content.type,
								// Ensure toolCallId exists
								toolCallId: content.toolCallId || `tool_${Date.now()}_${Math.random().toString(36).slice(2)}`,
								// Ensure toolName exists
								toolName: content.toolName,
							};

							// Handle special case for useCanvas tool
							if (content.toolName === 'useCanvas') {
								sanitizedContent.result = [{
									type: 'text',
									text: typeof content.result === 'string'
										? content.result
										: JSON.stringify(content.result),
								}];
							} else {
								// For other tools, ensure result exists
								sanitizedContent.result = content.result ?? null;
							}

							return sanitizedContent;
						});
				} else {
					// For non-tool messages with array content, join into a string
					const textParts = msg.content
						.filter((part) => part && typeof part === 'object' && 'text' in part && part.text)
						.map((part) => (part as any).text);
					sanitizedMsg.content = textParts.length > 0 ? textParts.join('\n') : 'Empty message';
				}
				return sanitizedMsg;
			}

			// Handle string content
			if (typeof msg.content === 'string') {
				sanitizedMsg.content = msg.content.trim() || 'Empty message';
				return sanitizedMsg;
			}

			// Handle object content
			if (typeof msg.content === 'object' && msg.content !== null) {
				// Handle tool calls in object format
				const content = msg.content as Record<string, any>;
				if ('type' in content && content.type === 'tool_call') {
					const sanitizedContent: any = {
						...content,
						type: 'tool-call',
						toolCallId: content.toolCallId || `tool_${Date.now()}_${Math.random().toString(36).slice(2)}`,
					};

					// Handle special case for useCanvas tool
					if (content.toolName === 'useCanvas') {
						sanitizedContent.result = [{
							type: 'text',
							text: typeof content.result === 'string'
								? content.result
								: JSON.stringify(content.result),
						}];
					}

					sanitizedMsg.content = sanitizedContent;
				} else {
					sanitizedMsg.content = JSON.stringify(msg.content);
				}
				return sanitizedMsg;
			}

			// Default fallback
			sanitizedMsg.content = 'Invalid message content';
			return sanitizedMsg;
		}).filter((msg) => {
			// Remove messages with empty array content
			if (Array.isArray(msg.content) && msg.content.length === 0) {
				return false;
			}
			return true;
		});

		// Ensure the last message is not an assistant message
		if (sanitized.length > 0 && sanitized[sanitized.length - 1].role === 'assistant') {
			return sanitized.slice(0, -1);
		}

		return sanitized as ChatMessage[];
	}

	/**
	 * Estimates the token count for an array of messages
	 * Uses a simple heuristic where 1 token ≈ 4 characters for English text
	 * @param messages Array of messages to count tokens for
	 * @returns Estimated token count
	 */
	private caculateTokenCount(messages: ChatMessage[]): number {
		// Estimate token count based on a simple heuristic
		// This is a rough approximation - actual token count depends on the tokenizer used by the model
		let tokenCount = 0;

		for (const msg of messages) {
			// Base tokens for message structure (role, etc.)
			tokenCount += 4;

			// Count tokens in content
			if (typeof msg.content === 'string') {
				// Rough estimate: 1 token ≈ 4 characters for English text
				tokenCount += Math.ceil(msg.content.length / 4);
			} else if (Array.isArray(msg.content)) {
				// Handle array content
				for (const item of msg.content) {
					if (typeof item === 'string') {
						tokenCount += Math.ceil(String(item).length / 4);
					} else if (typeof item === 'object' && item !== null) {
						// Estimate tokens for serialized object
						tokenCount += Math.ceil(JSON.stringify(item).length / 4);
					}
				}
			} else if (typeof msg.content === 'object' && msg.content !== null) {
				// Estimate tokens for serialized object
				tokenCount += Math.ceil(JSON.stringify(msg.content).length / 4);
			}

			// Add tokens for any metadata
			if ('metadata' in msg && msg.metadata) {
				tokenCount += Math.ceil(JSON.stringify(msg.metadata).length / 6);
			}
		}

		return tokenCount;
	}

	/**
	 * Compresses the chat context by organizing messages into threads and maintaining
	 * coherence while staying within token limits
	 * @param messages Array of chat messages to compress
	 * @returns Compressed context with messages and metadata
	 */
	compressContext(messages: ChatMessage[]): CompressedContext {
		// Only compress if we're significantly over the token limit
		const tokenCount = this.caculateTokenCount(messages);
		if (tokenCount < this.maxTokens * 1.1) { // Add 10% buffer
			const threads = this.identifyThreads(messages);
			const toolStates = this.extractToolStates(messages, threads);
			return {
				messages,
				metadata: {
					threads,
					toolStates,
				},
			};
		}

		// Sanitize messages before compression
		const sanitizedMessages = this.sanitizeMessages(messages);

		// Identify and track conversation threads
		const threads = this.identifyThreads(sanitizedMessages);

		// Track tool calls and their states
		const toolStates = this.extractToolStates(sanitizedMessages, threads);

		// Compress the context while maintaining thread coherence
		const compressedMessages = this.compressWithThreads(sanitizedMessages, threads, toolStates);

		return {
			messages: compressedMessages,
			metadata: {
				threads,
				toolStates,
			},
		};
	}

	/**
	 * Identifies and tracks conversation threads in the message history
	 * Handles transitions between topics and concurrent tasks
	 * @param messages Array of messages to analyze
	 * @returns Array of identified thread metadata
	 */
	private identifyThreads(messages: ChatMessage[]): ThreadMetadata[] {
		const threads: ThreadMetadata[] = [];
		let currentThread: ThreadMetadata | null = null;

		for (let i = 0; i < messages.length; i++) {
			const msg = messages[i];
			const previousMessages = messages.slice(0, i);

			if (this.isThreadStarter(msg, previousMessages)) {
				const content = typeof msg.content === 'string' ? msg.content.toLowerCase() : '';
				const topic = this.extractTopic(msg);
				const existingThread = threads.find((t) => this.isSimilarTopic(t.topic, topic));

				// Check if this is a transition to a new topic
				const isTransition = ContextCompressor.TOPIC_TRANSITION_MARKERS.some(
					(marker) => content.includes(marker.toLowerCase()),
				);

				// Check if this is a concurrent task
				const isConcurrent = ContextCompressor.CONCURRENT_TASK_MARKERS.some(
					(marker) => content.includes(marker.toLowerCase()),
				);

				if (existingThread) {
					// If transitioning to a new topic (but not concurrent), mark current thread as completed
					if (isTransition && !isConcurrent && currentThread && currentThread !== existingThread) {
						currentThread.state = 'completed';
						currentThread.endTime = this.getMessageTimestamp(msg as ExtendedChatMessage);
					}
					// Reactivate the existing thread
					existingThread.state = 'active';
					existingThread.endTime = undefined;
					currentThread = existingThread;
				} else {
					// If transitioning to a new topic (but not concurrent), mark current thread as completed
					if (isTransition && !isConcurrent && currentThread) {
						currentThread.state = 'completed';
						currentThread.endTime = this.getMessageTimestamp(msg as ExtendedChatMessage);
					}
					// Start new thread
					currentThread = {
						id: `thread_${threads.length + 1}`,
						topic,
						startTime: this.getMessageTimestamp(msg as ExtendedChatMessage),
						state: 'active',
						toolCalls: new Set(),
						importance: 1.0, // Default importance for new threads
					};
					threads.push(currentThread);
				}
			}
		}

		return threads;
	}

	/**
	 * Extracts and tracks the state of tool calls within threads
	 * @param messages Array of messages to analyze
	 * @param threads Array of thread metadata
	 * @returns Map of tool call IDs to their states
	 */
	private extractToolStates(
		messages: ChatMessage[],
		threads: ThreadMetadata[],
	): Map<string, ToolCallState> {
		const toolStates = new Map<string, ToolCallState>();

		for (const msg of messages) {
			if (msg.role === 'tool' && Array.isArray(msg.content)) {
				for (const content of msg.content) {
					if (content.type === 'tool-result' && content.toolCallId) {
						const toolState: ToolCallState = {
							id: content.toolCallId,
							toolName: content.toolName,
							result: content.result,
							timestamp: this.getMessageTimestamp(msg as ExtendedChatMessage),
							threadId: this.findThreadForTool(content.toolCallId, threads),
							importance: 1,
							status: (content as any).status || 'success',
						};
						toolStates.set(content.toolCallId, toolState);
					}
				}
			}
		}

		return toolStates;
	}

	/**
	 * Compresses messages while maintaining thread coherence and importance
	 * @param messages Array of messages to compress
	 * @param threads Array of thread metadata
	 * @param toolStates Map of tool states
	 * @returns Array of compressed messages
	 */
	private compressWithThreads(
		messages: ChatMessage[],
		threads: ThreadMetadata[],
		toolStates: Map<string, ToolCallState>,
	): ChatMessage[] {
		if (messages.length === 0) return [];

		// Calculate importance scores for each message
		const messageScores = messages.map((msg, index) => {
			let score = 0;

			// Higher importance for first and last messages
			if (index === 0 || index === messages.length - 1) score += 1;

			// Higher importance for user messages
			if (msg.role === 'user') score += 0.5;

			// Higher importance for messages with code blocks
			if (typeof msg.content === 'string' && msg.content.includes('```')) score += 0.8;

			// Higher importance for messages with tool calls
			if (msg.role === 'tool') score += 0.7;

			// Higher importance for messages in active threads
			const thread = threads.find((t) => {
				const msgTime = this.getMessageTimestamp(msg as ExtendedChatMessage);
				return msgTime >= t.startTime && (!t.endTime || msgTime <= t.endTime);
			});
			if (thread?.state === 'active') score += 0.6;

			return { msg, score };
		});

		// Sort messages by importance
		messageScores.sort((a, b) => b.score - a.score);

		// Keep messages until we hit the token limit
		const compressedMessages: ChatMessage[] = [];
		let tokenCount = 0;

		for (const { msg } of messageScores) {
			const msgTokens = this.caculateTokenCount([msg]);
			if (tokenCount + msgTokens <= this.maxTokens) {
				compressedMessages.push(msg);
				tokenCount += msgTokens;
			}
		}

		// Sort back into chronological order
		compressedMessages.sort((a, b) => {
			const timeA = this.getMessageTimestamp(a as ExtendedChatMessage);
			const timeB = this.getMessageTimestamp(b as ExtendedChatMessage);
			return timeA - timeB;
		});

		return compressedMessages;
	}

	/**
	 * Determines if a message should start a new thread
	 * @param msg Message to check
	 * @param previousMessages Array of previous messages for context
	 * @returns True if the message should start a new thread
	 */
	private isThreadStarter(msg: ChatMessage, previousMessages: ChatMessage[]): boolean {
		if (msg.role !== 'user') {
			return false;
		}

		const content = typeof msg.content === 'string' ? msg.content.toLowerCase() : '';

		// Check for thread detection markers
		const hasMarker = ContextCompressor.THREAD_DETECTION_MARKERS.some(
			(marker) => content.includes(marker.toLowerCase()),
		);

		// Check for topic transition or concurrent task markers
		const hasTransition = ContextCompressor.TOPIC_TRANSITION_MARKERS.some(
			(marker) => content.includes(marker.toLowerCase()),
		);
		const hasConcurrent = ContextCompressor.CONCURRENT_TASK_MARKERS.some(
			(marker) => content.includes(marker.toLowerCase()),
		);

		// Consider it a thread starter if:
		// 1. It's the first message
		// 2. It has a thread marker and no recent similar topic
		// 3. It has a transition or concurrent task marker
		return previousMessages.length === 0
			|| (hasMarker && !this.hasRecentSimilarTopic(content, previousMessages))
			|| hasTransition
			|| hasConcurrent;
	}

	/**
	 * Checks if a topic has been discussed recently
	 * @param content Content to check
	 * @param previousMessages Array of previous messages to check against
	 * @returns True if a similar topic was found in recent messages
	 */
	private hasRecentSimilarTopic(content: string, previousMessages: ChatMessage[]): boolean {
		// Look at the last 3 messages for similar topics
		const recentMessages = previousMessages.slice(-3);
		const topic = this.extractTopic({ role: 'user', content } as ChatMessage);

		return recentMessages.some((msg) => {
			const msgContent = typeof msg.content === 'string' ? msg.content : '';
			return this.isSimilarTopic(
				topic,
				this.extractTopic({ role: 'user', content: msgContent } as ChatMessage),
			);
		});
	}

	/**
	 * Compares two topics for similarity
	 * Uses word overlap and task identifiers to determine similarity
	 * @param topic1 First topic to compare
	 * @param topic2 Second topic to compare
	 * @returns True if the topics are similar
	 */
	private isSimilarTopic(topic1: string, topic2: string): boolean {
		// Normalize topics for comparison
		const normalize = (text: string) => text.toLowerCase().replace(/[^\w\s]/g, '');
		const words1 = new Set(normalize(topic1).split(/\s+/));
		const words2 = new Set(normalize(topic2).split(/\s+/));

		// Check for significant word overlap
		let commonWords = 0;
		let significantMatch = false;

		// Look for task identifiers (A, B, C, etc.)
		const taskPattern = /\btask\s+([a-z])\b/i;
		const task1 = topic1.match(taskPattern)?.[1]?.toLowerCase();
		const task2 = topic2.match(taskPattern)?.[1]?.toLowerCase();
		if (task1 && task2 && task1 === task2) {
			return true;
		}

		for (const word of words1) {
			if (words2.has(word)) {
				if (word.length > 3) { // Only count significant words
					commonWords++;
					// Words like "task", "help", "work" are less significant
					if (!['task', 'help', 'work', 'lets', 'can', 'with'].includes(word)) {
						significantMatch = true;
					}
				}
			}
		}

		// Return true if we have either:
		// 1. One significant matching word
		// 2. Two or more common words (even if not significant)
		return significantMatch || commonWords >= 2;
	}

	/**
	 * Extracts the main topic from a message by removing common prefixes
	 * @param msg Message to extract topic from
	 * @returns Cleaned topic string
	 */
	private extractTopic(msg: ChatMessage): string {
		const content = typeof msg.content === 'string' ? msg.content : '';
		// Remove common prefixes that don't contribute to the topic
		return content.replace(/^(lets|can you|please|how do|what is|help me|i need|could you)\s+/i, '')
			.replace(/^(instead|however|but|alternatively|moving on|next|now|regarding|also|back to|returning to)\s+/i, '')
			.trim();
	}

	/**
	 * Calculates the importance score for a thread
	 * @param msg Message to calculate importance for
	 * @returns Importance score between 0 and 1
	 */
	private calculateThreadImportance(msg: ChatMessage): number {
		let importance = 0.5; // Base importance

		// Increase importance based on various factors
		if (msg.role === 'user') importance += 0.2;
		if (this.containsCodeBlocks(msg)) importance += 0.15;
		if (this.isToolCall(msg)) importance += 0.25;
		if (this.containsErrorContext(msg)) importance += 0.3;

		return Math.min(1, importance);
	}

	/**
	 * Calculates the importance score for a tool call
	 * @param toolCall Tool call to calculate importance for
	 * @param threads Array of threads for context
	 * @returns Importance score between 0 and 1
	 */
	private calculateToolImportance(
		toolCall: ToolCallState,
		threads: ThreadMetadata[],
	): number {
		let importance = 0.5;

		// Tools in active threads are more important
		const thread = threads.find((t) => t.toolCalls.has(toolCall.id));
		if (thread?.state === 'active') importance += 0.3;

		// Recent tool calls are more important
		const age = Date.now() - toolCall.timestamp;
		if (age < 300000) importance += 0.2; // Last 5 minutes

		// Error states need preservation
		if (toolCall.status === 'error') importance += 0.25;

		return Math.min(1, importance);
	}

	/**
	 * Generates a context summary for a thread
	 * @param thread Thread to generate context for
	 * @param toolStates Map of tool states
	 * @returns Formatted context string
	 */
	private generateThreadContext(
		thread: ThreadMetadata,
		toolStates: Map<string, ToolCallState>,
	): string {
		let context = `Active thread: ${thread.topic}\n`;

		// Add relevant tool call summaries
		const threadTools = Array.from(thread.toolCalls)
			.map((id) => toolStates.get(id))
			.filter((tool): tool is ToolCallState => !!tool);

		if (threadTools.length > 0) {
			context += '\nRelevant tool calls:\n';
			for (const tool of threadTools) {
				let status: string;
				if (tool.status === 'success') {
					status = 'completed successfully';
				} else if (tool.status === 'error') {
					status = `failed (${tool.error?.message})`;
				} else {
					status = 'pending';
				}
				context += `- ${tool.toolName}: ${status}\n`;
			}
		}

		return context;
	}

	/**
	 * Determines if a message should be included in the compressed context
	 * @param msg Message to check
	 * @param activeThreads Array of active threads
	 * @param toolStates Map of tool states
	 * @returns True if the message should be included
	 */
	private shouldIncludeMessage(
		msg: ChatMessage,
		activeThreads: ThreadMetadata[],
		toolStates: Map<string, ToolCallState>,
	): boolean {
		// Always include system messages
		if (msg.role === 'system') return true;

		// Include all messages from active threads
		if (this.isInActiveThread(msg, activeThreads)) return true;

		// Include important tool calls and their results
		if (this.isToolCall(msg)) {
			const toolId = this.extractToolCallId(msg);
			const toolState = toolStates.get(toolId);
			if (toolState && toolState.importance >= this.minImportance) return true;
		}

		// Include messages with code blocks
		if (this.containsCodeBlocks(msg)) return true;

		// Include messages with error context
		if (this.containsErrorContext(msg)) return true;

		// Include recent messages (within last 5 minutes)
		const msgTime = this.getMessageTimestamp(msg as ExtendedChatMessage);
		if (Date.now() - msgTime < 300000) return true;

		return false;
	}

	/**
	 * Checks if a message belongs to an active thread
	 * @param msg Message to check
	 * @param activeThreads Array of active threads
	 * @returns True if the message is in an active thread
	 */
	private isInActiveThread(msg: ChatMessage, activeThreads: ThreadMetadata[]): boolean {
		const msgTime = this.getMessageTimestamp(msg as ExtendedChatMessage);

		for (const thread of activeThreads) {
			if (msgTime >= thread.startTime && (!thread.endTime || msgTime <= thread.endTime)) {
				return true;
			}
		}
		return false;
	}

	/**
	 * Truncates messages to stay within the maximum token limit
	 * @param messages Array of messages to truncate
	 * @returns Truncated array of messages
	 */
	private truncateToMaxTokens(messages: ChatMessage[]): ChatMessage[] {
		// Simple truncation for now - could be made more sophisticated
		// by calculating actual token counts
		return messages.slice(-Math.floor(this.maxTokens / 20));
	}

	/**
	 * Checks if a message contains a tool call
	 * @param msg Message to check
	 * @returns True if the message is a tool call
	 */
	private isToolCall(msg: ChatMessage): boolean {
		return msg.role === 'tool' || (
			typeof msg.content === 'object'
			&& msg.content !== null
			&& 'toolCallId' in msg.content
		);
	}

	/**
	 * Extracts the tool call ID from a message
	 * @param msg Message to extract from
	 * @returns Tool call ID or empty string
	 */
	private extractToolCallId(msg: ChatMessage): string {
		if (typeof msg.content === 'object' && msg.content !== null && 'toolCallId' in msg.content) {
			return msg.content.toolCallId as string;
		}
		return '';
	}

	/**
	 * Parses a message into a tool call state
	 * @param msg Message to parse
	 * @returns Parsed tool call state
	 */
	private parseToolCall(msg: ChatMessage): ToolCallState {
		let toolContent: ToolContent & { status?: 'error' | 'success' };
		const content = msg.content;

		if (typeof content === 'object' && content !== null) {
			if (Array.isArray(content)) {
				// Handle array content
				const toolPart = content.find((part) => typeof part === 'object' && 'toolCallId' in part);
				if (toolPart && typeof toolPart === 'object') {
					toolContent = {
						toolCallId: (toolPart as any).toolCallId || '',
						toolName: (toolPart as any).toolName || '',
						result: (toolPart as any).result,
						status: (toolPart as any).status,
					};
				} else {
					toolContent = { toolCallId: '', toolName: '', result: null };
				}
			} else {
				// Handle object content
				toolContent = content as (ToolContent & { status?: 'error' | 'success' });
			}
		} else {
			toolContent = { toolCallId: '', toolName: '', result: null };
		}

		const extendedMsg = msg as ExtendedChatMessage;
		return {
			id: toolContent.toolCallId,
			toolName: toolContent.toolName,
			result: toolContent.result,
			status: toolContent.status || (toolContent.result instanceof Error ? 'error' : 'success'),
			timestamp: this.getMessageTimestamp(extendedMsg),
			importance: 0.5,
		};
	}

	/**
	 * Gets the timestamp from a message
	 * @param msg Message to get timestamp from
	 * @returns Timestamp in milliseconds
	 */
	private getMessageTimestamp(msg: ExtendedChatMessage | ChatMessage | undefined): number {
		if (!msg) return Date.now();

		// First try to get timestamp from ExtendedChatMessage
		if ('timestamp' in msg && msg.timestamp instanceof Date) {
			return msg.timestamp.getTime();
		}

		// If no timestamp found, use metadata if available
		if ('metadata' in msg && msg.metadata?.timestamp instanceof Date) {
			return msg.metadata.timestamp.getTime();
		}

		// Default to current time if no timestamp found
		return Date.now();
	}

	/**
	 * Checks if a message contains code blocks
	 * @param msg Message to check
	 * @returns True if the message contains code blocks
	 */
	private containsCodeBlocks(msg: ChatMessage): boolean {
		const content = msg.content.toString();
		return content.includes('```') || content.includes('`');
	}

	/**
	 * Checks if a message contains error context
	 * @param msg Message to check
	 * @returns True if the message contains error context
	 */
	private containsErrorContext(msg: ChatMessage): boolean {
		const content = msg.content.toString().toLowerCase();
		return content.includes('error') || content.includes('failed') || content.includes('exception');
	}

	/**
	 * Determines if a tool state is relevant to the current context
	 * @param state Tool state to check
	 * @param activeThreads Array of active threads
	 * @returns True if the tool state is relevant
	 */
	private isToolStateRelevant(
		state: ToolCallState,
		activeThreads: ThreadMetadata[],
	): boolean {
		return state.importance >= this.minImportance
			|| activeThreads.some((t) => t.toolCalls.has(state.id));
	}

	/**
	 * Creates a system message for a tool state
	 * @param state Tool state to create message for
	 * @returns System message containing tool state
	 */
	private createToolStateMessage(state: ToolCallState): ChatMessage {
		return {
			role: 'system',
			content: `Tool call result (${state.toolName}): ${JSON.stringify(state.result)}`,
			metadata: { type: 'tool_state', id: state.id },
		} as ExtendedChatMessage;
	}

	/**
	 * Finds the thread ID that a tool call belongs to
	 * @param toolId Tool call ID to find thread for
	 * @param threads Array of threads to search
	 * @returns Thread ID or undefined
	 */
	private findThreadForTool(toolId: string, threads: ThreadMetadata[]): string | undefined {
		for (const thread of threads) {
			if (thread.toolCalls.has(toolId)) {
				return thread.id;
			}
		}
		return undefined;
	}
}
type AIChatRequestParams = Omit<ChatParams, 'modelProvider' | 'model'> & { prompt?: string | null };

interface AIEvents {
	error: (error: Error) => void;
}

export class AIChatContext extends EventEmitter<AIEvents> implements EventEmitterType {
	public toolContext: AIToolsContext;
	public modelProvider: typeof bedrock;
	public model: Parameters<typeof bedrock>[0];
	private contextCompressor: ContextCompressor;
	private refreshCredentials: () => Promise<void>;

	constructor({
		modelProvider = bedrock,
		model = DEFAULT_MODEL,
		// Claude can handle up to 200k tokens, so we use a number less than that
		// to account for the system prompt and other overhead
		maxTokens = 150000,
		refreshCredentials,
		...args
	}: {
		modelProvider?: typeof bedrock;
		model?: Parameters<typeof bedrock>[0];
		maxTokens?: number;
		refreshCredentials: () => Promise<void>;
	} & AIToolsContextArgs) {
		super();

		this.modelProvider = modelProvider;
		this.model = model;
		this.toolContext = new AIToolsContext(args);
		this.contextCompressor = new ContextCompressor(maxTokens);
		this.refreshCredentials = refreshCredentials;
	}

	streamChat({
		messages,
		prompt,
		onChunk,
		onFinish,
		onStepFinish,
		signal,
		...settings
	}: AIChatRequestParams) {
		const compressed = this.contextCompressor.compressContext(messages);

		// Ensure no assistant messages are in final position
		const finalMessages = compressed.messages.filter((msg, index) => {
			if (index === compressed.messages.length - 1) {
				return msg.role !== 'assistant';
			}
			return true;
		});

		// Add retry logic for network errors
		const MAX_RETRIES = 3;
		const RETRY_DELAY = 1000; // 1 second

		const attemptStream = async (attempt = 0): Promise<ReturnType<typeof streamText>> => {
			try {
				return streamText({
					model: this.modelProvider(this.model, settings),
					system: prompt ? `${AI_CHAT_PROMPT}\n\n${prompt}` : AI_CHAT_PROMPT,
					messages: finalMessages,
					tools: this.toolContext.tools,
					toolChoice: 'auto',
					maxSteps: 5,
					signal,
					onError: async (error: any) => {
						console.error('***STREAM ERROR', error);

						// Check if it's a security token expired error
						if (error.message?.toLowerCase().includes('security token expired') && attempt < MAX_RETRIES) {
							console.log('***RETRYING STREAM');
							// Trigger a refresh of the credentials
							await this.refreshCredentials();
							// Wait before retrying
							await sleep(RETRY_DELAY * 2 ** attempt);
							attemptStream(attempt + 1);
						} else {
							this.emit('error', error instanceof Error ? error : new Error(String(error)));
						}
					},
					onChunk: onChunk as any,
					onFinish: onFinish as any,
					onStepFinish: onStepFinish as any,
				} as Parameters<typeof streamText>[0] & { signal?: AbortSignal });
			} catch (error: any) {
				console.error('***CATCH STREAM ERROR', error);

				// Check if it's a security token expired error
				if (error.message?.toLowerCase().includes('security token expired') && attempt < MAX_RETRIES) {
					// Wait before retrying
					await sleep(RETRY_DELAY * 2 ** attempt);
					return attemptStream(attempt + 1);
				}

				// Check if it's a network error
				if (error.message?.toLowerCase().includes('network') && attempt < MAX_RETRIES) {
					// Wait before retrying
					await sleep(RETRY_DELAY * 2 ** attempt);
					return attemptStream(attempt + 1);
				}

				// If it's not an error we can handle, emit it, then throw it
				this.emit('error', error instanceof Error ? error : new Error(String(error)));
				throw error;
			}
		};

		return attemptStream();
	}

	/**
	 * Generate a chat response without streaming
	 */
	async doChat({
		messages,
		prompt = null,
		onChunk,
		onFinish,
		onStepFinish,
		signal,
		...settings
	}: AIChatRequestParams) {
		// Compress the context before sending
		// DISABLED FOR NOW
		// const compressed = this.contextCompressor.compressContext(messages);
		const compressed = { messages };

		// Ensure no assistant messages are in final position
		const finalMessages = compressed.messages.filter((msg, index) => {
			if (index === compressed.messages.length - 1) {
				return msg.role !== 'assistant';
			}
			return true;
		});

		// Add retry logic for network errors
		const MAX_RETRIES = 3;
		const RETRY_DELAY = 1000; // 1 second

		const modelName = this.model.toLowerCase();

		const attemptGenerate = async (attempt = 0) => {
			try {
				return await generateText({
					model: this.modelProvider(this.model, settings),
					system: prompt ? `${AI_CHAT_PROMPT}\n\n${prompt}` : AI_CHAT_PROMPT,
					messages: finalMessages,
					// DeepSeek models don't support tools
					tools: modelName.includes('deepseek') ? undefined : this.toolContext.tools,
					toolChoice: 'auto',
					maxSteps: 5,
					onStepFinish: onStepFinish as any,
				});
			} catch (error: any) {
				console.error('***CATCH GENERATE ERROR', error);
				// Check if it's a network error
				if (error.message?.toLowerCase().includes('network') && attempt < MAX_RETRIES) {
					// Wait before retrying
					await sleep(RETRY_DELAY * 2 ** attempt);
					return attemptGenerate(attempt + 1);
				}
				throw error;
			}
		};

		return attemptGenerate();
	}

	/**
	 * Intelligently chooses between streaming and non-streaming chat based on model capabilities
	 * @param params Chat parameters
	 * @returns Stream result or generated text result
	 */
	async chat(params: AIChatRequestParams) {
		// Determine if the model supports streaming
		const modelName = this.model.toLowerCase();
		const supportsStreaming = modelName.includes('claude');

		if (supportsStreaming) {
			// Use streaming for supported models
			return this.streamChat(params);
		}

		// Use standard generation for other models
		const result = await this.doChat(params);
		return {
			textStream: new ReadableStream({
				async start(controller) {
					controller.enqueue(result.text);
					controller.close();
				},
			}),
			finishReason: result.finishReason,
		};
	}
}
