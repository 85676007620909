import { z } from 'zod';

import { UnixTimestampSchema } from './dates';
import { UserGroupSchema } from './user';

/**
 * Supported chart types for visualization.
 * Includes all standard Chart.js types plus a special 'table' type for tabular data display.
 */
export const ChartTypeSchema = z.enum([
	'bar',
	'line',
	'pie',
	'scatter',
	'doughnut',
	'radar',
	'table', // Special case - not a Chart.js type but for tabular display
]).describe('The type of visualization to use for displaying the data');

/**
 * Color names and their corresponding hex values, ordered by distinctiveness.
 * The first colors are the most distinct from each other, providing maximum contrast
 * for the primary data series in charts.
 */
export const COLOR_NAMES = new Map([
	['#D35400', 'Earthy Orange'],
	['#145A32', 'Forest Green'],
	['#CD6155', 'Coral Red'],
	['#34495E', 'Dark Slate'],
	['#6C3483', 'Royal Purple'],
	['#F4D03F', 'Sunflower'],
	['#1B4F72', 'Navy Blue'],
	['#E67E22', 'Pumpkin'],
	['#5D4037', 'Coffee Brown'],
	['#F39C12', 'Golden Yellow'],
	['#7E5109', 'Caramel'],
	['#B9770E', 'Bronze'],
]);

/**
 * Default color palette for charts - Earth tones for a more natural, professional look
 */
export const COLORS = [...COLOR_NAMES.keys()];

/**
 * Legacy color mapping to hex values
 */
const LEGACY_COLORS: Record<string, string> = {
	primary: '#D35400', // Map to our new primary earthy orange
	blue: '#1B4F72', // Map to our navy blue
	indigo: '#34495E', // Map to dark slate
	purple: '#633974', // Map to deep purple
	pink: '#D35400', // Map to earthy orange (closest match)
	pinkLighter: '#D35400', // Map to earthy orange (closest match)
	red: '#6E2C00', // Map to deep brown
	orange: '#D35400', // Map to earthy orange
	yellow: '#7D6608', // Map to golden brown
	green: '#145A32', // Map to forest green
	teal: '#0B5345', // Map to deep teal
	cyan: '#1B4F72', // Map to navy blue
	white: '#D35400', // Map to earthy orange (default)
};

/**
 * Transform any color value into a valid hex color
 */
const transformColor = (color: unknown): string => {
	if (typeof color !== 'string') {
		return COLORS[Math.floor(Math.random() * COLORS.length)];
	}

	// If it's already a valid hex color, return it
	if (/^#[0-9A-Fa-f]{6}$/.test(color)) {
		return color;
	}

	// Check if it's a legacy named color
	if (color in LEGACY_COLORS) {
		return LEGACY_COLORS[color];
	}

	// For any other invalid value, return a random color from our palette
	return COLORS[Math.floor(Math.random() * COLORS.length)];
};

/**
 * Predefined color palette for consistent styling across the application.
 * These colors are designed to work well together and provide good contrast.
 */
export const ColorSchema = z.any()
	.transform(transformColor)
	.pipe(
		z.string()
			.regex(/^#[0-9A-Fa-f]{6}$/, 'Must be a valid hex color code (e.g. #FF0000)'),
	)
	.describe('Hex color code for the dataset');

/**
 * Predefined gradient styles for enhanced visual effects.
 * These gradients are specifically designed for chart backgrounds and fills.
 */
const GradientSchema = z.enum([
	'whiteBlue',
	'pinkBlue',
	'primaryWhite',
]).describe('Predefined gradient style for enhanced visual effects');

/**
 * Configuration for a single dataset within a chart.
 * Controls the appearance and behavior of one data series.
 */
const DatasetConfigSchema = z.object({
	/** Display label for the dataset in legends and tooltips */
	label: z.string().describe('Label displayed in legends and tooltips'),
	/** Optional Key to use for the dataset */
	key: z.string().optional().describe('Key to use for the dataset'),
	/** Base color for the dataset */
	color: ColorSchema,
	/** Optional gradient effect to apply to the dataset */
	gradient: GradientSchema.optional().describe('Gradient effect to apply to the dataset'),
	/** Whether the dataset should be initially hidden */
	hidden: z.boolean().optional().describe('Whether to initially hide this dataset'),
	/** Width of the dataset border in pixels */
	borderWidth: z.number().optional().describe('Border width in pixels'),
	/** Percentage of the available width allocated for bars in bar charts */
	barPercentage: z.number().optional().describe('Percentage of available width for bars (0-1)'),
	/** Whether to fill the area under lines in line charts */
	fill: z.boolean().optional().describe('Whether to fill area under lines in line charts'),
	/** Curve smoothing factor for line charts (0 = no smoothing, 1 = maximum smoothing) */
	tension: z.number().optional().describe('Line smoothing factor (0 = none, 1 = maximum)'),
	/**
	 * Total calculation method, for use in tables
	 *
	 * This is the method to use for the total row in tables
	 */
	total: z.enum(['sum', 'avg', 'min', 'max', 'count']).optional().describe('Total calculation method for tables'),
	/** Format type for the dataset values */
	format: z.enum(['number', 'currency', 'date']).nullish().describe('Format type for the dataset values'),
}).describe('Configuration for a single data series in the chart');

/**
 * Configuration for a chart axis (x or y).
 * Controls the appearance and behavior of axis labels, grid lines, and scaling.
 */
const AxisConfigSchema = z.object({
	/** Title displayed for the axis */
	title: z.string().optional().describe('Title displayed for the axis'),
	/** Whether to show or hide the axis */
	display: z.boolean().optional().describe('Whether to show or hide the axis'),
	/** Whether to show grid lines for this axis */
	grid: z.boolean().optional().describe('Whether to show grid lines'),
	/** Minimum value for the axis scale */
	min: z.number().optional().describe('Minimum value for the axis scale'),
	/** Maximum value for the axis scale */
	max: z.number().optional().describe('Maximum value for the axis scale'),
	/** Step size between ticks on the axis */
	stepSize: z.number().optional().describe('Step size between axis ticks'),
	/** Formatting options for axis labels */
	format: z.object({
		/** Type of formatting to apply to the values */
		type: z.enum(['number', 'currency', 'percentage', 'date'])
			.describe('Type of formatting to apply to axis values'),
		/** Number of decimal places for numerical values */
		precision: z.number().optional().describe('Number of decimal places for numerical values'),
		/** Locale string for number/date formatting (e.g., 'en-US') */
		locale: z.string().optional().describe('Locale string for number/date formatting (e.g., "en-US")'),
	}).optional().describe('Formatting options for axis labels'),
}).describe('Configuration for a chart axis');

/**
 * Complete configuration for a chart visualization.
 * Defines how the chart should be rendered and behave.
 */
export const ChartConfigSchema = z.object({
	/** Type of chart to display */
	type: ChartTypeSchema,

	/**
	 * Field to group data by (for bar/line charts)
	 *
	 * For tables - This is grouping for ROWS
	 */
	group_by: z.string().optional().describe('Field to group data by (x-axis)'),
	/**
	 * Field to stack by (for stacked bar charts)
	 *
	 * For tables - This is grouping for COLUMNS
	 */
	stack_by: z.string().optional().describe('Field to stack by (creates multiple bars/lines)'),
	/**
	 * Field to sub-group columns by (for tables only)
	 *
	 * This creates a nested grouping under the stack_by field for tables
	 */
	sub_column_by: z.string().optional().describe('Field to sub-group columns by (tables only)'),
	/**
	 * Field containing the values to plot
	 *
	 * For tables, if specified this will be the TOTAL column for rows
	 */
	value_field: z.string().optional().describe('Field containing the values to plot (y-axis)'),

	/**
	 * Format to apply to values in tables
	 *
	 * This is used for formatting both cell values and totals in tables
	 */
	value_format: z.enum(['number', 'currency', 'date']).optional().describe('Format to apply to values in tables'),

	/**
	 * Array of dataset configurations
	 *
	 * For tables, this controls what Columns appear. Leave empty to show all.
	 */
	datasets: z.array(DatasetConfigSchema).describe('Array of dataset configurations'),

	/**
	 * Configuration for the x-axis
	 *
	 * For tables, this is the column headers
	 */
	xAxis: AxisConfigSchema.optional().describe('Configuration for the x-axis'),

	/**
	 * Configuration for the y-axis
	 */
	yAxis: AxisConfigSchema.optional().describe('Configuration for the y-axis'),

	/**
	 * Whether to show the chart legend
	 */
	legend: z.union([
		z.boolean(),
		z.object({
			display: z.boolean().optional(),
			position: z.enum(['top', 'bottom', 'left', 'right']).optional(),
		}),
	]).transform((val) => {
		if (typeof val === 'boolean') {
			return {
				display: val,
			};
		}
		return val;
	}).optional().describe('Configuration for the chart legend'),
	/** Width/height ratio of the chart */
	aspectRatio: z.number().optional().describe('Width/height ratio of the chart'),
	/** Whether to animate the chart when data changes */
	animation: z.boolean().optional().describe('Whether to animate chart updates'),
	/** Whether to show a table below the chart */
	showTable: z.boolean().optional().describe('Whether to show a table below the chart'),
	/**
	 * How to show totals in a table
	 */
	totals: z.object({
		columns: z.enum(['sum', 'avg', 'min', 'max', 'count']).optional().describe('How to show totals on a column in a table. Leave blank to disable.'),
		rows: z.enum(['sum', 'avg', 'min', 'max', 'count']).optional().describe('How to show totals on a row in a table. Leave blank to disable.'),
	}).optional().describe('Control showing totals on rows and columns in a table.'),

}).describe('Complete configuration for chart visualization');

/**
 * Configuration for a dynamic parameter that can be used in SQL queries.
 * Parameters allow for interactive filtering and customization of reports.
 */
const ParameterSchema = z.object({
	/** Unique identifier for the parameter */
	name: z.string().describe('Unique identifier for the parameter'),
	/** Data type of the parameter */
	type: z.enum(['string', 'number', 'date', 'boolean'])
		.describe('Data type of the parameter value'),
	/** Display label for the parameter in the UI */
	label: z.string().optional().describe('Display label shown in the UI'),
	/** Default value when no user input is provided */
	default: z.any().optional().describe('Default value when no user input is provided'),
	/** Whether the parameter must have a value */
	required: z.boolean().optional().default(false).describe('Whether the parameter is required'),
	/** Predefined options for dropdown-style parameters */
	options: z.array(z.object({
		/** Display text for the option */
		label: z.string().describe('Display text for the option'),
		/** Value to use when option is selected */
		value: z.any().describe('Value to use when option is selected'),
	})).optional().describe('Predefined options for dropdown parameters'),
	/** Source of options - manual (default) or database */
	optionsSource: z.enum(['manual', 'database']).optional().default('manual')
		.describe('Source of dropdown options - manually entered or from database'),
	/** Table name for database-sourced options */
	optionsTable: z.string().optional()
		.describe('Table name for database-sourced options'),
	/** Column name for database-sourced options */
	optionsColumn: z.string().optional()
		.describe('Column name for database-sourced options'),
	/** Label column name for database-sourced options */
	optionsLabelColumn: z.string().optional()
		.describe('Column name for labels when using database-sourced options'),
}).describe('Configuration for a dynamic SQL parameter');

/**
 * Available databases for reports
 */
export const DatabaseSchema = z.enum([
	'NewsCore', // Default SQL database
	'NewsCrunch', // SQL database for Revenue/Royalty reports
	'DMARC', // Timestream database for DMARC data
	'Analytics', // Timestream database for User Analytics data
	'StoryCounts', // Timestream database for StoryCounts data
]).describe('The database to use for this report');

/**
 * Complete configuration for a report.
 * A report combines a query with visualization settings and metadata.
 */
export const ReportSchema = z.object({
	/** Unique identifier for the report */
	id: z.string().describe('Unique identifier for the report'),
	/** Display name of the report */
	name: z.string().describe('Display name of the report'),
	/** Category for organizing reports */
	category: z.string().optional().describe('Category for organizing reports'),
	/** Database to use for this report */
	database: DatabaseSchema.default('NewsCore').describe('The database to use for this report'),
	/** Detailed description of what the report shows */
	description: z.string().optional().describe('Detailed description of what the report shows'),
	/** Icon identifier for visual recognition */
	icon: z.string().optional().describe('Icon identifier for visual recognition'),
	/** User groups that have access to this report (empty array means admin only) */
	access_groups: z.array(UserGroupSchema)
		.optional()
		.default(['admin'])
		.describe('User groups that have access to this report (empty array means admin only)'),

	// Query configuration
	/** SQL/Query that generates the report data */
	query: z.string().describe('Query that generates the report data'),
	/** Array of parameters that can be used in the query */
	parameters: z.array(ParameterSchema).optional()
		.describe('Array of parameters that can be used in the query'),
	/** How often the report should refresh its data (in seconds) */
	refresh_interval: z.number().optional()
		.describe('How often the report should refresh its data (in seconds)'),

	// Display configuration
	/** How the data should be visualized */
	chart: ChartConfigSchema.optional(),

	// Access control
	/** Array of role IDs that have permission to view this report */
	roles: z.array(z.string()).optional()
		.describe('Array of role IDs that have permission to view this report'),
	/** ID of the user who created the report */
	created_by: z.string().optional()
		.describe('ID of the user who created the report'),
	/** Unix timestamp (in seconds) when the report was created */
	created_at: UnixTimestampSchema.optional()
		.describe('Unix timestamp when the report was created'),
	/** Unix timestamp (in seconds) when the report was last modified */
	updated_at: UnixTimestampSchema.optional()
		.describe('Unix timestamp when the report was last modified'),
	/** ID of the user who last modified the report */
	updated_by: z.string().optional()
		.describe('ID of the user who last modified the report'),
}).describe('Complete configuration for a SQL-based report with visualization settings');

// Create a schema for serializing back to JSON/API format
export const ReportSerializeSchema = z.object({
	...ReportSchema.omit({ created_at: true, updated_at: true }).shape,
	created_at: UnixTimestampSchema.optional()
		.describe('Unix timestamp when the report was created'),
	updated_at: UnixTimestampSchema.optional()
		.describe('Unix timestamp when the report was last modified'),
}).describe('Report schema for serialization to JSON/API format');

/**
 * Type representing a complete report configuration with Date objects.
 */
export type Report = z.infer<typeof ReportSchema>;

/**
 * Type representing a serialized report with numeric timestamps.
 */
export type SerializedReport = z.infer<typeof ReportSerializeSchema> & {
	created_at?: number;
	updated_at?: number;
};

/**
 * Type representing the available chart types.
 */
export type ChartType = z.infer<typeof ChartTypeSchema>;

/**
 * Type representing the complete chart configuration.
 */
export type ChartConfig = z.infer<typeof ChartConfigSchema>;

/**
 * Type representing the configuration for a single dataset within a chart.
 */
export type DatasetConfig = z.infer<typeof DatasetConfigSchema>;

/**
 * Type representing a dynamic parameter configuration.
 */
export type Parameter = z.infer<typeof ParameterSchema>;

/**
 * Type representing the available colors in the application's palette.
 */
export type Color = z.infer<typeof ColorSchema>;

/**
 * Type representing the available gradient styles.
 */
export type Gradient = z.infer<typeof GradientSchema>;

/**
 * Type representing the serialized report.
 */
export type ReportSerialized = z.infer<typeof ReportSerializeSchema>;

/**
 * Type representing the available colors in the application's palette.
 */
export type ReportColors = z.infer<typeof ColorSchema>;

/**
 * Type representing the available total types for a dataset.
 */
export type SumType = DatasetConfig['total'];

/**
 * Type representing the available format types for a dataset.
 */
export type FormatType = DatasetConfig['format'];
