import { faBan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Content, Publisher } from '@newstex/types/content';
import { Results } from '@newstex/types/results';
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

interface ExcludedClientsListProps {
	content?: Content;
}

export function ExcludedClientsList({ content }: ExcludedClientsListProps) {
	const api = useAPI();
	const [excludedDistributorNames, setExcludedDistributorNames] = useState<string[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchDistributorNames = async () => {
			if (!content) {
				setExcludedDistributorNames([]);
				setLoading(false);
				return;
			}

			try {
				// Get all excluded distributors from the current content and its parent chain
				const allExcludedDistributors = new Set<string>();
				let currentContent = content;

				// Traverse up the parent chain to collect all excluded distributors
				while (currentContent) {
					if (currentContent.excluded_distributors) {
						for (const id of currentContent.excluded_distributors) {
							allExcludedDistributors.add(id);
						}
					}

					if (!currentContent.parent) break;

					// Fetch the parent content
					const parentResp = await api.fetchWithAuth<Results<Publisher>>(`resources/Publisher/${currentContent.parent}`);
					if (!parentResp?.items?.[0]) break;

					currentContent = parentResp.items[0];
				}

				// If we found any excluded distributors, fetch their names
				if (allExcludedDistributors.size > 0) {
					const distributorPromises = Array.from(allExcludedDistributors).map(async (id) => {
						const resp = await api.fetchWithAuth(`resources/Distributor/${id}`);
						return resp?.items?.[0]?.name;
					});

					const names = (await Promise.all(distributorPromises)).filter(Boolean);
					setExcludedDistributorNames(names);
				} else {
					setExcludedDistributorNames([]);
				}
			} catch (error) {
				console.error('Error fetching excluded distributor names:', error);
				setExcludedDistributorNames([]);
			}

			setLoading(false);
		};

		fetchDistributorNames();
	}, [content?.$id]);

	if (loading || excludedDistributorNames.length === 0) {
		return null;
	}

	return (
		<Alert variant="warning" className="text-center">
			<FontAwesomeIcon icon={faBan} className="me-2" />
			This {content?.$type.toLowerCase()} is excluded from the following distributor{excludedDistributorNames.length > 1 ? 's' : ''}:
			<strong className="ms-2">{excludedDistributorNames.join(', ')}</strong>
			<FontAwesomeIcon icon={faBan} className="ms-2" />
		</Alert>
	);
}
