import type { Dashboard as DashboardType } from '@newstex/types/dashboard';

import type { CollectionFieldSchmaForObject } from './types';

export const Dashboard: CollectionFieldSchmaForObject<DashboardType & { owner_emails?: string[] }> = [
	{
		name: 'name',
		type: 'string',
		store: true,
		sort: true,
		defaultSearchOrder: 1,
	},
	{
		name: 'description',
		type: 'string',
		store: true,
		optional: true,
		defaultSearchOrder: 2,
	},
	{
		name: 'access_groups',
		type: 'string[]',
		store: true,
		facet: true,
		optional: true,
	},
	{
		name: 'columns',
		type: 'int32',
		store: true,
		optional: true,
	},
	{
		name: 'refresh_interval',
		type: 'int32',
		store: true,
		optional: true,
	},
	{
		name: 'created_by',
		type: 'string',
		store: true,
		optional: true,
		facet: true,
	},
	{
		name: 'created_at',
		type: 'int64',
		store: true,
		facet: true,
		range_index: true,
		optional: true,
	},
	{
		name: 'updated_at',
		type: 'int64',
		store: true,
		facet: true,
		range_index: true,
		optional: true,
	},
	{
		name: 'updated_by',
		type: 'string',
		store: true,
		optional: true,
	},
	/** For Authorization purposes */
	{
		name: 'owner_emails',
		type: 'string[]',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
];
