import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import Joyride, {
	ACTIONS,
	EVENTS,
	STATUS,
	Step,
} from 'react-joyride';
import { useAnalytics } from '~/providers/analytics-provider';

export default function Tour({
	steps,
	id,
	onClick,
	className,
}: {
	steps: Step[];
	id: string;
	onClick?: () => void;
	className?: string;
}) {
	const [showTutorial, setShowTutorial] = useState(false);
	const [tutorialStepIndex, setTutorialStepIndex] = useState(0);
	const analytics = useAnalytics();

	return (<>
		<Button
			id={id}
			variant="link"
			size="lg"
			className={`p-0 ${className || ''}`}
			onClick={() => {
				if (onClick) {
					onClick();
				}
				setTutorialStepIndex(0);
				setShowTutorial(true);
			}}
		>
			<FontAwesomeIcon
				className="text-muted"
				icon={faInfoCircle}
				title="Tutorial"
			/>
		</Button>
		{createPortal(
			<Joyride
				steps={steps}
				run={showTutorial}
				stepIndex={tutorialStepIndex}
				callback={(data) => {
					const {
						action, index, status, type,
					} = data;

					if (action === ACTIONS.CLOSE) {
						setShowTutorial(false);
						setTutorialStepIndex(0);
					}

					if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
					// Update state to advance the tour
						setTutorialStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
					} else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)) {
					// You need to set our running state to false, so we can restart if we click start again.
						setShowTutorial(false);
					}

					// Track analytics
					if (type === EVENTS.STEP_BEFORE && data.step) {
						analytics.trackEvent('Tutorial Step Viewed', {
							id: String(data.step.target),
							name: String(data.step.title || `Step ${index + 1}`),
							path: window.location.pathname,
						});
					}
				}}
				continuous={true}
				showSkipButton={true}
				showProgress={true}
				scrollOffset={400}
				styles={{
					tooltip: {
						zIndex: 9000,
					},
				}}
			/>,
			document.body,
		)}
	</>
	);
}
