import type { Step } from 'react-joyride';

/**
 * Tutorial for the "My Publications" table on the dashboard
 */
export function getMyPubsSteps({
	publisherCount,
}: {
	publisherCount: number;
}): Step[] {
	const tutorialSteps: Step[] = [
		{
			target: '#my-publications',
			title: 'My Publications',
			placement: 'top',
			content: <div>
				<p>This is a list of your publications.</p>
			</div>,
			disableBeacon: true,
		},
		{
			target: '#new-publication-button',
			title: 'New Publication',
			placement: 'left',
			content: <div>
				<p>
					You can also submit a new publication to Newstex by clicking here.
				</p>
				<p>
					Publications are typically reviewed within 5 business days, and once approved,
					will appear in the list of publications.
				</p>
			</div>,
		},
		{
			target: '#my-publications .prop-value.status',
			title: 'Publication Status',
			placement: 'left',
			content: <div>
				<p>
					Each publication has a status, which indicates whether it is active, pending, or inactive.
				</p>
				<ul>
					<li>
						<b>Active</b> - The publication is currently being made available to content platforms.
					</li>
					<li>
						<b>Pending</b> - The publication is pending review.
					</li>
					<li>
						<b>Inactive</b> - The publication has been archived and is not currently being made available to
						content platforms.
					</li>
				</ul>
			</div>,
		},
		{
			target: '#my-publications .prop-value.active_clients_count',
			title: 'Active Clients',
			placement: 'left',
			content: <div>
				<p>
					This is the list of clients that are currently receiving content from this publication.
				</p>
			</div>,
		},
		{
			target: '#my-publications .prop-value.last_post_date',
			title: 'Last Post Date',
			placement: 'left',
			content: <div>
				<p>
					This is the publication date of the most recent post we have received from this publication.
				</p>
			</div>,
		},
	];

	// Only include this step if the user has access to multiple publishers
	if (publisherCount > 1) {
		tutorialSteps.push({
			target: '#select-publisher-dropdown',
			title: 'Select Publisher',
			placement: 'left',
			content: <div>
				<p>
					Since you have access to multiple publishers, your view is aggregated across all of them.
					You can show details for a specific publisher by selecting one from the dropdown.
				</p>
			</div>,
		});
	}

	return tutorialSteps;
}
