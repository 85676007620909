import { User } from '@newstex/types/user';
import React, { useEffect, useState } from 'react';
import {
	Button,
	ButtonGroup,
	Form,
	Modal,
	ToggleButton,
} from 'react-bootstrap';
import { useSettingsModal } from '~/providers/settings-modal-provider';
import { useUserInfo } from '~/providers/user-info';

import { RequirePermissions } from '../require-permissions';

const SettingsModal: React.FC = () => {
	const userInfo = useUserInfo();
	const { isOpen, closeModal } = useSettingsModal();
	const [theme, setTheme] = useState<'light' | 'dark'>(userInfo?.theme || 'light');

	useEffect(() => {
		setTheme(userInfo?.theme || 'light');
	}, [userInfo?.theme]);

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		// Handle form submission logic here
		const formData = new FormData(event.currentTarget as HTMLFormElement);
		const updates: Partial<User> = {};
		for (const key of ['name', 'email', 'phone', 'theme']) {
			const value = formData.get(key);
			if (value && value !== userInfo?.[key]) {
				updates[key] = value as string;
			}
		}

		if (Object.keys(updates).length > 0) {
			userInfo?.update(updates);
		}
		closeModal();
	};

	return (
		<Modal show={isOpen} onHide={closeModal}>
			<Modal.Header closeButton>
				<Modal.Title>User Settings</Modal.Title>
			</Modal.Header>
			<Form onSubmit={handleSubmit}>
				<Modal.Body>
					<Form.Group className="mb-3" controlId="formName">
						<Form.Label>Name</Form.Label>
						<Form.Control type="text" placeholder="Enter name" name="name" defaultValue={userInfo?.name} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="formEmail">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" placeholder="Enter email" name="email" defaultValue={userInfo?.email} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="formPhone">
						<Form.Label>Phone Number</Form.Label>
						<Form.Control type="tel" placeholder="Enter phone number" name="phone" defaultValue={userInfo?.phone} />
					</Form.Group>
					{/* Not yet ready for everyone, so limited to just admins for now */}
					<RequirePermissions permissions={['admin']}>
						<Form.Group className="mb-3" controlId="formTheme">
							<Form.Label>Theme</Form.Label>
							<ButtonGroup className="w-100">
								<ToggleButton
									id="lightTheme"
									type="radio"
									name="theme"
									value="light"
									checked={theme === 'light'}
									onChange={(e) => {
										setTheme('light');
										document.body.setAttribute('data-bs-theme', 'light');
									}}
									variant="outline-primary"
								>
									Light Mode
								</ToggleButton>

								<ToggleButton
									id="darkTheme"
									type="radio"
									name="theme"
									value="dark"
									checked={theme === 'dark'}
									onChange={(e) => {
										setTheme('dark');
										document.body.setAttribute('data-bs-theme', 'dark');
									}}
									variant="outline-primary"
								>
									Dark Mode
								</ToggleButton>
							</ButtonGroup>
						</Form.Group>
					</RequirePermissions>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={closeModal}>
						Close
					</Button>
					<Button variant="primary" type="submit">
						Save Changes
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default SettingsModal;
