import React, { ReactNode } from 'react';
import { create } from 'zustand';

// Define the state interface
interface SettingsModalState {
	isOpen: boolean;
	openModal: () => void;
	closeModal: () => void;
	toggleModal: () => void;
}

// Create the Zustand store
const useSettingsModalStore = create<SettingsModalState>((set) => ({
	isOpen: false,
	openModal: () => set({ isOpen: true }),
	closeModal: () => set({ isOpen: false }),
	toggleModal: () => set((state) => ({ isOpen: !state.isOpen })),
}));

// Create a Provider component
export const SettingsModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	return <>{children}</>;
};

// Create a hook to use the store
export const useSettingsModal = (): SettingsModalState => {
	return useSettingsModalStore();
};
