/**
 * Edit Delivery Modal
 */
import type { Delivery } from '@newstex/types';
import type { Distributor } from '@newstex/types/distributor';
import { useEffect, useRef, useState } from 'react';
import {
	Alert,
	Button,
	Col,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { DeliveryForm } from '~/components/editors/delivery-form';
import { useAPI } from '~/providers/api-provider';

export interface EditDeliveryModalProps {
	item?: Delivery;
	show: boolean;
	onClose: (saved: boolean) => void;
}

export default function EditDeliveryModal({
	item,
	show,
	onClose,
}: EditDeliveryModalProps) {
	const api = useAPI();
	const commentRef = useRef<HTMLInputElement>(null);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState<any>();
	const [updates, setUpdates] = useState<Partial<Delivery>>({});
	const [distributor, setDistributor] = useState<Distributor | null>(null);

	useEffect(() => {
		if (show) {
			setUpdates({});

			// If we have a recipient/distributor ID, fetch the distributor details
			if (item?.distributor) {
				api.getItem<Distributor>({
					$type: 'Distributor',
					$id: item.distributor,
				}).then(setDistributor).catch(console.error);
			} else if (item?.recipient) {
				// For backward compatibility with older records that use recipient field
				api.getItem<Distributor>({
					$type: 'Distributor',
					$id: item.recipient,
				}).then(setDistributor).catch(console.error);
			} else {
				setDistributor(null);
			}
		}
	}, [show, item?.$id, api, item?.distributor, item?.recipient]);

	// Handle distributor change
	const handleDistributorChange = (newDistributor: Distributor | null) => {
		setDistributor(newDistributor);
		if (newDistributor) {
			// Update both for compatibility
			setUpdates({
				...updates,
				distributor: newDistributor.$id,
				recipient: newDistributor.$id,
			});
		} else {
			// Remove both when cleared
			const { distributor: unusedDistributor, recipient: unusedRecipient, ...rest } = updates;
			setUpdates(rest);
		}
	};

	const doUpdate = async () => {
		if (item) {
			setError(null);

			if (!commentRef.current?.value) {
				commentRef.current?.focus();
				commentRef.current?.select();
				commentRef.current?.setCustomValidity('Please enter a comment');
				return;
			}
			setSaving(true);
			// Calculate changes ONLY
			const changes = Object.keys(updates).reduce((acc, key) => {
				if (item[key] !== updates[key]) {
					acc[key] = updates[key];
				}
				return acc;
			}, {});
			console.log('Saving', item, commentRef.current?.value, changes);
			try {
				await api.updateItem<Delivery>(item, {
					$comment: commentRef.current?.value,
					...changes,
				});
				onClose(true);
			} catch (err) {
				console.error(err);
				setError(err);
			}
			setTimeout(() => {
				setSaving(false);
				onClose(true);
			}, 2000);
		}
	};

	return (
		<Modal show={item && show} onHide={() => onClose(false)} size="xl" fullscreen="xl-down">
			<Modal.Header closeButton>
				<Modal.Title>Edit Delivery <i>{item?.name}</i></Modal.Title>
			</Modal.Header>
			<Form onSubmit={(e) => {
				e.preventDefault();
				doUpdate();
			}}>
				<Modal.Body>
					{error && <Alert variant="danger">{error.message || String(error)}</Alert>}
					<div className={saving ? 'opacity-50' : ''}>
						<DeliveryForm
							delivery={{ ...item, ...updates }}
							onChange={setUpdates}
							distributor={distributor}
							onDistributorChange={handleDistributorChange}
							error={error}
							showProductEditor
						/>
						<Row className="mt-3 pt-3" style={{ borderTop: 'dashed 1px #ccc' }}>
							<Col md={3} className="text-end fw-bold">
								<Form.Label>Comment</Form.Label>
							</Col>
							<Col md={9}>
								<Form.Control
									type="text"
									name="$comment"
									ref={commentRef}
									required
									placeholder="Required: Enter a comment"
								/>
							</Col>
						</Row>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => onClose(false)} disabled={saving}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={doUpdate}
						disabled={saving || !updates || Object.keys(updates).length === 0}
					>
						Submit
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}
