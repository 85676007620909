import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Content } from '@newstex/types/content';
import type { RevenuesAndEarningsByPeriodsAndClients } from '@newstex/types/revenue';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';
import { useUserInfo } from '~/providers/user-info';

import BarChart, { BarChartProps } from './charts/BarChart';
import DataTable, { DataTableMethods } from './data-table';
import LoadingSpinner from './LoadingSpinner';
import { RequirePermissions, hasPermission } from './require-permissions';

interface RevenueStatsProps {
	content?: Content;
}

/**
 * Revenue Stats widget for a given publisher or publication
 */
export const RevenueStats: React.FC<RevenueStatsProps> = ({ content }) => {
	const [loading, setLoading] = useState(true);
	const [revenueStats, setRevenueStats] = useState<RevenuesAndEarningsByPeriodsAndClients>();
	const [revenueBarChartData, setRevenueBarChartData] = useState<Pick<BarChartProps, 'datasets' | 'labels'>>();
	const [columns, setColumns] = useState<any[]>([]);
	const api = useAPI();
	const userInfo = useUserInfo();
	const tableRef = useRef<DataTableMethods>();

	let providerEarningsHeader = 'Earnings to Provider';
	const newstexRevenueHeader = 'Revenue to Newstex';

	if (!hasPermission(['admin'], userInfo)) {
		providerEarningsHeader = 'Earnings';
	}

	useEffect(() => {
		let cancelled = false;
		setRevenueBarChartData(undefined);
		const loadRevenueStats = async (nextToken?: string) => {
			if (cancelled) {
				return;
			}
			setLoading(true);
			const searchParams = new URLSearchParams({});
			if (content?.newstex_id) {
				searchParams.append('content', content.newstex_id);
				if (nextToken) {
					searchParams.append('NextToken', nextToken);
				}
			}

			const revenueStatsResp = await api.fetchWithAuth<RevenuesAndEarningsByPeriodsAndClients>(`revenue?${searchParams}`);
			if (cancelled) {
				return;
			}

			if (revenueStatsResp.nextToken) {
				setTimeout(loadRevenueStats, 1000, revenueStatsResp.nextToken);
			}

			if (revenueStatsResp && Object.keys(revenueStatsResp).length > 1) {
				const labels: string[] = Object.keys(revenueStatsResp).filter(((label) => label !== 'totals'));
				const datasets: BarChartProps['datasets'] = [];

				// Then create the datasets, making sure there is a value for each day
				for (const [month, items] of Object.entries(revenueStatsResp)) {
					if (month === 'totals') {
						continue;
					}
					for (const [label, item] of Object.entries(items)) {
						let dataset = datasets.find((d) => d.label === label);
						if (!dataset) {
							// Populate initially with all zeros
							dataset = {
								label,
								data: labels.map(() => 0),
							};
							if (label === 'total') {
								dataset.type = 'line';
								dataset.borderWidth = 2;
								dataset.color = 'green';
								// Insert to the FRONT of the list so this appears on top of the barcharts
								datasets.unshift(dataset);
							} else {
								datasets.push(dataset);
							}
						}

						const labelIndex = labels.findIndex((l) => l === month);
						if (item.newstexRevenue) {
							dataset.data[labelIndex] = item.newstexRevenue;
						} else if (item.providerEarning) {
							dataset.data[labelIndex] = item.providerEarning;
						}
					}
				}

				if (!cancelled) {
					setRevenueBarChartData({
						labels: labels.map((label) => ({
							name: label === 'total' ? 'Total' : label,
						})),
						datasets: datasets.map((d) => ({
							...d,
							label: d.label === 'total' ? 'Total' : d.label,
						})),
					});
					if (!columns?.length) {
						const columnHelper = createColumnHelper<{
							name: string;
							providerEarning: number;
							newstexRevenue: number;
						}>();
						const cols: any[] = [
							columnHelper.accessor('name', {
								header: 'Client Name',
								enableSorting: true,
							}),
							columnHelper.accessor('providerEarning', {
								header: providerEarningsHeader,
								enableSorting: true,
								cell: ({ getValue }) => {
									const value = getValue();
									if (value && typeof value === 'number') {
										return value.toLocaleString('en-US', {
											style: 'currency',
											currency: 'USD',
										});
									}
									return value;
								},
							}),
						];

						if (hasPermission(['admin'], userInfo)) {
							cols.push(columnHelper.accessor('newstexRevenue', {
								header: newstexRevenueHeader,
								enableSorting: true,
								cell: ({ getValue }) => {
									const value = getValue();
									if (value && typeof value === 'number') {
										return value.toLocaleString('en-US', {
											style: 'currency',
											currency: 'USD',
										});
									}
									return value;
								},
							}));
						}
						setColumns(cols);
					}
				}
			}

			setRevenueStats(revenueStatsResp);
			setLoading(false);
		};

		setLoading(true);
		loadRevenueStats();
		return () => {
			cancelled = true;
		};
	}, [content, api, userInfo]);

	return (
		<div>

			<div>
				{loading && (
					<LoadingSpinner
						hideTitle={true}
						loading={loading}
					/>
				)}
				{!loading && !revenueBarChartData && (
					<center>No revenue stats</center>
				)}

				{revenueBarChartData && !loading && (
					<BarChart
						{...revenueBarChartData}
					/>
				)}

				{revenueStats?.totals && !loading && (<>
					{columns.length > 0 && revenueStats.totals && (
						<DataTable
							className="table-bordered mt-4"
							ref={tableRef}
							items={Object.entries(revenueStats.totals).filter(([key]) => key !== 'totals').map(([key, value]) => ({
								name: key,
								...value,
							}))}
							columns={columns}
							defaultSort="name"
							nameAsc
							striped
							sortable
							pagination
						/>
					)}
					{revenueStats.totals?.total && (
						<center>
							<dl>
								<dt>{providerEarningsHeader}</dt>
								<dd>{revenueStats.totals.total.providerEarning?.toLocaleString('en-US', {
									style: 'currency',
									currency: 'USD',
								})}</dd>

								<RequirePermissions permissions={['admin']}>
									<dt>{newstexRevenueHeader}</dt>
									<dd>{revenueStats.totals.total.newstexRevenue?.toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}</dd>
								</RequirePermissions>
							</dl>
						</center>
					)}
				</>
				)}
			</div>
			<div className="text-end">
				{!loading && revenueStats?.totals && (
					<Button
						variant="outline-secondary"
						size="sm"
						onClick={() => tableRef?.current?.exportToCSV(`Revenue Stats - ${new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })}`)}
					>
						<FontAwesomeIcon icon={faDownload} /> Export CSV
					</Button>
				)}
			</div>
		</div>
	);
};
