import type { ClientStoryCounts } from '@newstex/types/client-story-counts';
import { Distributor } from '@newstex/types/distributor';
import { Results } from '@newstex/types/results';
import { useEffect, useState } from 'react';
import { useAPI } from '~/providers/api-provider';

import BarChart, { BarChartProps } from './charts/BarChart';
import LoadingSpinner from './LoadingSpinner';

interface ClientStoryCountsComponentProps {
	distributor: Distributor;
}

export function ClientStoryCountsChart({ distributor }: ClientStoryCountsComponentProps) {
	const [loading, setLoading] = useState(true);
	const [chartData, setChartData] = useState<Pick<BarChartProps, 'datasets' | 'labels'>>();
	const api = useAPI();

	useEffect(() => {
		let cancelled = false;

		const fetchData = async () => {
			if (!distributor?.code) return;

			setLoading(true);
			try {
				const response = await api.fetchWithAuth<Results<ClientStoryCounts>>(`resources/ClientStoryCounts?recipient_id=${distributor.code}`);

				if (cancelled) return;

				if (response.items?.length) {
					// Sort items by period
					const sortedItems = [...response.items].sort((a, b) => a.period.localeCompare(b.period));

					// Create labels and datasets
					const labels = sortedItems.map((item) => ({
						name: new Date(item.period).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
					}));

					setChartData({
						labels,
						datasets: [
							{
								label: 'Successful Stories',
								data: sortedItems.map((item) => item.count),
								color: 'blueSky',
							},
							{
								label: 'Failed Stories',
								data: sortedItems.map((item) => item.error_count || 0),
								type: 'line',
								color: 'red',
							},
						],
					});
				}
			} catch (error) {
				console.error('Failed to fetch client story counts:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();

		return () => {
			cancelled = true;
		};
	}, [api, distributor?.code]);

	if (loading) {
		return <LoadingSpinner loading={loading} />;
	}

	if (!chartData) {
		return <div className="text-center">No story count data available</div>;
	}

	return (
		<BarChart
			{...chartData}
			stacked={true}
			title="Story Counts"
			legendPosition="bottom"
			formatValue={(value) => value.toLocaleString()}
		/>
	);
}
