import type { StatusChoice } from '@newstex/types/status';
import { Badge } from 'react-bootstrap';

export type ExtendedStatusChoice = StatusChoice | 'Approved' | 'Rejected' | 'Pending';

const BG_STATUS_MAPPING: Record<ExtendedStatusChoice, string> = {
	Active: 'success',
	Approved: 'success',
	Inactive: 'danger',
	Archived: 'danger',
	Rejected: 'danger',
	Removed: 'danger',
	Deleted: 'warning',
	Standby: 'warning',
	Pending: 'info',
	Lead: 'info',
};

const chooseStatus = (statusList: string[]) => {
	if (statusList.includes('Approved')) return 'Approved';
	if (statusList.includes('Rejected')) return 'Rejected';
	return 'Pending';
};

export function StatusBadge(props: {
	indicator?: boolean,
	status?: ExtendedStatusChoice | string[],
	name?: string,
	className?: string,
}) {
	if (props?.status) {
		const status = Array.isArray(props.status) ? chooseStatus(props.status) : props.status;

		const statusClassName = BG_STATUS_MAPPING[status || 'Lead'];
		return (
			<Badge bg={`${statusClassName}-subtle`} text="dark" className={props.className}>
				<b>{props.name || props.status}</b>
				{props.indicator ? <span className={`indicator right text-${statusClassName}`} /> : <></>}
			</Badge>
		);
	}
	return <></>;
}
