import React from 'react';

interface DateTimeProps {
	ts?: number | string;
	dateOnly?: boolean;
	inline?: boolean;
	timeOnly?: boolean;
	simple?: boolean;
}

export const DateTime: React.FC<DateTimeProps> = (props) => {
	if (!props.ts) {
		return null;
	}
	let d: Date | null = null;

	if (typeof props.ts === 'number') {
		if (props.ts < 10000000000) {
			d = new Date(props.ts * 1000);
		} else {
			d = new Date(props.ts);
		}
	} else if (typeof props.ts === 'string') {
		if (props.ts.length === 10) {
			// NOTE: No time zone information, assume Local time
			d = new Date(`${props.ts}T00:00:00`);
		} else {
			d = new Date(props.ts);
		}
	}

	if (d != null) {
		return (
			<time dateTime={d.toISOString()}>
				{props.simple && d.toLocaleDateString('en-US') }
				{!props.simple && (<>
					{!props.timeOnly && d.toLocaleDateString('en-US', {
						month: 'long', day: 'numeric', year: 'numeric', timeZone: 'America/New_York',
					})}
					{props.inline && !props.dateOnly && <span>{!props.timeOnly && <> &mdash; </>}{
						d.toLocaleTimeString('en-US', {
							hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'America/New_York',
						})
					}</span>}
					{!props.dateOnly && !props.inline && <div className="text-muted">
						{d.toLocaleTimeString('en-US', {
							hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'America/New_York', timeZoneName: 'short',
						})}
					</div>}
				</>)}
			</time>
		);
	}

	return <>{props.ts}</>;
};
