import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import {
	Card,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AIChatCommand } from '~/commands/ai-chat';
import type { Command, ModalFields } from '~/commands/type';
import ModalDataEditor from '~/components/modal-data-editor';
import PageHeader from '~/components/PageHeader';
import { useAIChat } from '~/providers/ai-chat-provider';
import { useAI } from '~/providers/ai-provider';
import { useUserInfo } from '~/providers/user-info';

export const TOOLS: (Command & { public?: boolean })[] = [
	{
		id: 'qualify-leads',
		icon: 'table-cells',
		name: 'Qualify Leads',
		description: 'Qualify a list of leads (by URL or HubSpot List) using Technical and AI ranking. Can optionally sync to HubSpot.',
		handler: ({ navigate }) => {
			navigate('/tools/qualify-leads');
		},
	},
	{
		id: 'match',
		icon: 'folder-search',
		name: 'Match Tool',
		description: 'Match a list of names or domains against our system to find potential matches. Upload data via CSV or paste directly into the table.',
		handler: ({ navigate }) => {
			navigate('/tools/match');
		},
	},
	{
		id: 'feed-validator',
		icon: 'rss',
		name: 'Feed Validator',
		description: 'Validate and debug RSS/Atom feeds, checking for common issues and providing detailed diagnostics.',
		public: true,
		handler: ({ navigate }) => {
			navigate('/tools/feed-validator');
		},
	},
	AIChatCommand,
];

/**
 * Tools
 */
export function ToolsPage() {
	const { id } = useParams();
	const [modalData, setModalData] = useState<ModalFields | null>(null);
	const userInfo = useUserInfo();
	const navigate = useNavigate();
	const ai = useAI();
	const aiChat = useAIChat();
	const isAdmin = userInfo?.hasAuthGroup('admin');

	useEffect(() => {
		if (id?.length) {
			const tool = TOOLS.find((t) => t.id === id);
			if (tool?.handler) {
				// Only allow non-admins to access the feed validator
				if (!isAdmin && tool.id !== 'feed-validator') {
					navigate('/');
					return;
				}
				tool.handler({
					setModalData,
					navigate,
					ai,
					aiChat,
				});
			}
		}
	}, [id, isAdmin, navigate, ai, aiChat]);

	// Filter tools based on user permissions
	const availableTools = isAdmin ? TOOLS : TOOLS.filter((tool) => tool.id === 'feed-validator');

	return (
		<Container fluid>
			<PageHeader title="Tools" />
			<ModalDataEditor
				modalData={modalData}
				setModalData={setModalData}
				onClose={() => {
					navigate('/tools');
				}}
			/>

			<Row className="text-center">
				{availableTools.map((script) => (
					<Col key={script.id}>
						<Card className="h-100 hover-animate card">
							<Card.Header>
								<h4>
									{script.icon && <FontAwesomeIcon icon={script.icon} className="me-1" />}
									{script.name}
								</h4>
							</Card.Header>
							<Card.Body>
								{script.description}
							</Card.Body>
							<Link className="stretched-link" to={`/tools/${script.id}`} />
						</Card>
					</Col>
				))}
			</Row>
		</Container>
	);
}
