import { z } from 'zod';

import { UnixTimestampSchema } from './dates';
import type { TypedObjectName } from './reference';

/**
 * Shared properties between both old and new style objects
 */
export const SharedObjectBaseSchema = z.object({
	/** Name
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID |Display Name |
	 * |----------------|--------------|-------------|
	 * |Blog            |_name_        |Name         |
	 * |Publisher       |_name_        |Name         |
	 * |Account         |_name_        |Name         |
	 */
	name: z.string().optional(),
	/**
	 * Sortable Name
	 * @calculated
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID   |Display Name    |
	 * |----------------|----------------|----------------|
	 * |Blog            |_name_sortable_ |Name (Sortable) |
	 * |Publisher       |_name_sortable_ |Name (Sortable) |
	 * |Account         |_name_sortable_ |Name (Sortable) |
	 */
	sortable_name: z.string().optional(),

	/**
	 * Deleted
	 *
	 * Deleted records are hidden from Search, and will eventually be PURGED
	 * @see purge_by
	 */
	deleted: z.boolean().optional(),

	/**
	 * Date to purge the record (DynamoDB TTL)
	 */
	purge_by: UnixTimestampSchema.optional(),

	/**
	 * Created At
	 * @calcualted
	 */
	created_at: UnixTimestampSchema.optional(),
	/**
	 * Created By
	 * @calculated
	 */
	created_by: z.string().optional(),
	/**
	 * Modified At
	 * @calculated
	 */
	modified_at: UnixTimestampSchema.optional(),
	/**
	 * Modified By
	 * @calculated
	 */
	modified_by: z.string().optional(),

	/**
	 * Change ID, sync'd to the History record when changes are made
	 */
	$transaction_id: z.string().optional(),

	/**
	 * Change comment, sync'd to the History record when changes are made
	 */
	$comment: z.string().optional(),
});

/**
 * Old style object (like Story)
 */
export const LegacyBaseObjectSchema = SharedObjectBaseSchema.extend({
	__type__: z.string(),
	__id__: z.string().optional(),
});

/**
 * New style object (Stored in individual tables)
 */
export const BaseObjectSchema = SharedObjectBaseSchema.extend({
	$type: z.string(),
	$id: z.string().optional(),
});

export const TypedObjectSchema = z.union([BaseObjectSchema, LegacyBaseObjectSchema]);

// Export original types using z.infer
export type SharedObjectBase = z.infer<typeof SharedObjectBaseSchema>;
export type LegacyBaseObject = z.infer<typeof LegacyBaseObjectSchema>;
export type BaseObject = z.infer<typeof BaseObjectSchema>;
export type TypedObject = z.infer<typeof TypedObjectSchema>;

/**
 * Check if an object is of a specific type
 * @param typeName Type of object to check for
 * @param obj
 * @returns True if `obj` is a `typeName
 */
export function isTypeOf<T extends TypedObject>(typeName: TypedObjectName<T>, obj: any): obj is T {
	return obj.__type__ === typeName || obj.$type === typeName;
}

export function isBaseObjectType(obj: any): obj is BaseObject {
	return '$type' in obj;
}

export function isLegacyBaseObjectType(obj: any): obj is LegacyBaseObject {
	return '__type__' in obj;
}

export function getTypeName<T extends TypedObject>(obj: T, errorOnFailure = true): TypedObjectName<T> {
	if (isBaseObjectType(obj)) {
		return obj.$type as TypedObjectName<T>;
	}

	if (isLegacyBaseObjectType(obj)) {
		return obj.__type__ as TypedObjectName<T>;
	}

	if (errorOnFailure) {
		throw new Error('Invalid object');
	}

	return undefined;
}
