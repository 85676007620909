import { ErrorResponse, isErrorType } from '@newstex/types/error';
import { Report, SumType } from '@newstex/types/report';
import { Results } from '@newstex/types/results';
import { title as titleCase } from 'case';
import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import DataTable, { DataTableMethods } from '~/components/data-table';
import { formatDatePeriod, formatDateYYYYMMDD } from '~/lib/utils';

interface ReportDataTableProps {
	report: Report;
	data: Results | ErrorResponse;
	tableRef?: React.MutableRefObject<DataTableMethods>;
	defaultPageSize?: number;
	className?: string;
}

interface ReportTableCell {
	getValue: () => any;
}

type ReportDataRow = Record<string, string | number>;

// Base interface for all columns
interface BaseReportColumn {
	id: string;
	header: string;
	enableSorting?: boolean;
	meta?: {
		align?: 'left' | 'right';
	};
}

// Interface for leaf columns (columns that display data)
interface ReportLeafColumn extends BaseReportColumn {
	accessorKey: string;
	default?: string | number;
	cell?: (info: ReportTableCell) => React.ReactNode;
}

// Interface for group columns (columns that contain other columns)
interface ReportGroupColumn extends BaseReportColumn {
	columns: ReportLeafColumn[];
}

// Type that represents either a group column or a leaf column
type ReportColumn = ReportLeafColumn | ReportGroupColumn;

// Type guard to check if a column is a group column
function isGroupColumn(column: ReportColumn): column is ReportGroupColumn {
	return 'columns' in column;
}

const ReportDataTable: React.FC<ReportDataTableProps> = ({
	data, report, tableRef, defaultPageSize = 20, className = '',
}) => {
	const [totals, setTotals] = useState<Record<string, number[]>>({});
	const [columns, setColumns] = useState<ReportColumn[]>([]);
	const [tableData, setTableData] = useState<ReportDataRow[]>([]);
	const [footerElement, setFooterElement] = useState<React.JSX.Element | null>(null);

	// Early return if data is an error
	if (isErrorType(data)) {
		return (
			<Alert variant="danger">
				<Alert.Heading>Error Loading Report Data</Alert.Heading>
				<p className="mb-0">{data.message || 'Unknown error'}</p>
				{data.code && <small className="d-block mt-2 text-muted">Error Code: {data.code}</small>}
			</Alert>
		);
	}

	const formatValue = (value: any, format: string) => {
		switch (format) {
			case 'currency':
				return new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}).format(value);
			case 'date':
				// Handle month/year format
				if (typeof value === 'string' && value.match(/^\d{4}-\d{2}$/)) {
					return formatDatePeriod(value);
				}
				return new Date(value).toLocaleDateString('en-US', { timeZone: 'America/New_York' });
			case 'number':
				return new Intl.NumberFormat('en-US').format(value);
			default:
				return value;
		}
	};

	// Helper function to perform aggregation based on SumType
	const aggregateValues = (values: number[], type: SumType | '' | undefined): number => {
		if (!values || values.length === 0) return 0;

		switch (type) {
			case 'sum':
				return values.reduce((acc, v) => acc + (v || 0), 0);
			case 'avg':
				return values.reduce((acc, v) => acc + (v || 0), 0) / values.length;
			case 'min':
				return Math.min(...values);
			case 'max':
				return Math.max(...values);
			case 'count':
				return values.length;
			case undefined:
			case '':
			default:
				// Default to sum if type is not specified or invalid
				return values.reduce((acc, v) => acc + (v || 0), 0);
		}
	};

	// Helper function to get descriptive header for aggregate columns
	const getAggregateHeader = (type: SumType | '' | undefined, valueFieldName: string | undefined): string => {
		let prefix: string; // Default to Total for sum or undefined type
		switch (type) {
			case 'avg': prefix = 'Average'; break;
			case 'min': prefix = 'Minimum'; break;
			case 'max': prefix = 'Maximum'; break;
			case 'count': prefix = 'Count'; break;
			case 'sum':
			default:
				prefix = 'Total'; break;
		}
		// Only add the value field name if the type is NOT 'count'
		return (type === 'count' || !valueFieldName) ? prefix : `${prefix} ${titleCase(valueFieldName)}`;
	};

	// Helper to find the right format to use for values
	const getValueFormat = () => {
		// First check if there's a value_format defined at the chart level
		if (report.chart.value_format) {
			return report.chart.value_format;
		}

		// Then try to find dataset matching value_field
		const valueDataset = report.chart.datasets.find((d) => d.key === report.chart.value_field);
		if (valueDataset?.format) {
			return valueDataset.format;
		}

		// Check if value_field exists in any dataset
		if (report.chart.value_field) {
			// Look for exact match in dataset key or label
			const valueFieldDataset = report.chart.datasets.find(
				(d) => d.key === report.chart.value_field || d.label === report.chart.value_field,
			);
			if (valueFieldDataset?.format) {
				return valueFieldDataset.format;
			}
		}

		return 'number';
	};

	// Calculate totals based on the dataset configuration
	const calculateTotals = (accessorKey: string) => {
		// Get the values array for this accessor
		const values = totals[accessorKey];
		if (!values?.length) return '';

		// Calculate the total based on totals configuration
		const totalType = report.chart?.totals?.columns || 'sum';
		let value = 0;

		switch (totalType) {
			case 'sum':
				value = values.reduce((acc, v) => acc + (v || 0), 0);
				break;
			case 'avg':
				value = values.reduce((acc, v) => acc + (v || 0), 0) / values.length;
				break;
			case 'min':
				value = Math.min(...values);
				break;
			case 'max':
				value = Math.max(...values);
				break;
			case 'count':
				value = values.length;
				break;
			default:
				break;
		}

		if (value !== 0 || totalType === 'count') { // Ensure count 0 is displayed
			// Force format to 'number' if column aggregation is 'count'
			const formatToUse = totalType === 'count' ? 'number' : getValueFormat();
			return formatValue(value, formatToUse);
		}

		return '';
	};

	useEffect(() => {
		if (!data?.items?.length) {
			setColumns([]);
			setTableData([]);
			setFooterElement(null);
			return;
		}

		// Set columns
		let newColumns: ReportColumn[] = [];

		// First, add the row header column (group_by column)
		if (report.chart.group_by) {
			const groupByDataset = report.chart.datasets.find((d) => d.key === report.chart.group_by);
			newColumns.push({
				id: report.chart.group_by,
				header: groupByDataset?.label || titleCase(report.chart.group_by),
				accessorKey: report.chart.group_by,
				enableSorting: true,
				meta: {
					align: 'left',
				},
			});
		}

		// Check if we need to stack columns
		if (report.chart.stack_by) {
			// Get unique values for stack_by and sub_column_by if present
			const stackByValues = new Set<string>();
			const columnGroups: Record<string, Set<string>> = {};

			// First pass: collect all unique values and sort them
			for (const row of data.items) {
				let stackKey = row[report.chart.stack_by]; // Use stack_by directly
				if (typeof stackKey === 'string' && stackKey.match(/^\\d{4}-\\d{2}-\\d{2} 00:00:00/)) {
					stackKey = formatDateYYYYMMDD(stackKey);
				}
				stackByValues.add(String(stackKey)); // Ensure stackKey is a string

				if (report.chart.sub_column_by) {
					let subColumnKey = row[report.chart.sub_column_by];
					if (typeof subColumnKey === 'string' && subColumnKey.match(/^\\d{4}-\\d{2}-\\d{2} 00:00:00/)) {
						subColumnKey = formatDateYYYYMMDD(subColumnKey);
					}

					const stackKeyStr = String(stackKey); // Ensure stackKey is a string for indexing
					if (!columnGroups[stackKeyStr]) {
						columnGroups[stackKeyStr] = new Set();
					}
					columnGroups[stackKeyStr].add(String(subColumnKey)); // Ensure subColumnKey is a string
				}
			}

			// Sort the values if they're dates
			const sortedStackValues = Array.from(stackByValues).sort((a, b) => {
				// Assuming YYYY-MM-DD or YYYY-MM format
				if (a.match(/^\\d{4}-\\d{2}(-\\d{2})?$/) && b.match(/^\\d{4}-\\d{2}(-\\d{2})?$/)) {
					return b.localeCompare(a); // Reverse chronological order
				}
				return a.localeCompare(b);
			});

			report.chart.datasets.find(
				(d) => d.key === report.chart.value_field || d.label === report.chart.value_field,
			);
			const valueFormat = getValueFormat(); // Use the helper to get the final format

			// Create column headers based on stacking and sub-columns
			if (report.chart.sub_column_by) {
				// For each stack value, create a column group with sub-columns
				for (const stackKey of sortedStackValues) {
					// Get sub-column keys and sort them
					const subColumnKeys = columnGroups[stackKey] ? Array.from(columnGroups[stackKey]) : [];
					const sortedSubColumns = subColumnKeys.sort((a, b) => a.localeCompare(b));

					const subColumns: ReportLeafColumn[] = sortedSubColumns.map((subKey): ReportLeafColumn => ({
						id: `${stackKey}_${subKey}`,
						header: subKey, // Use the actual subKey value as header
						accessorKey: `${stackKey}_${subKey}`,
						enableSorting: true,
						default: valueFormat === 'number' || valueFormat === 'currency' ? 0 : '',
						meta: {
							align: 'right', // Align numbers right
						},
						cell: (info: ReportTableCell) => {
							const value = info.getValue();
							if (value === undefined || value === null || value === '') return '';
							// Use the determined valueFormat for all cells in stacked/sub-column setup
							const formattedValue = formatValue(value, valueFormat);
							return <span>{formattedValue}</span>;
						},
					}));

					// Add the group total column
					const groupTotalColumn: ReportLeafColumn = {
						id: `${stackKey}_Total`,
						// Use helper for dynamic header
						header: getAggregateHeader(report.chart.totals?.rows, report.chart.value_field),
						accessorKey: `${stackKey}_Total`, // Accessor for the pre-calculated row-group total
						enableSorting: true,
						meta: {
							align: 'right',
						},
						cell: (info: ReportTableCell) => {
							const value = info.getValue();
							if (value === undefined || value === null || value === '') return '';
							// Force format to 'number' if row aggregation is 'count'
							const formatToUse = report.chart.totals?.rows === 'count' ? 'number' : valueFormat;
							const formattedValue = formatValue(value, formatToUse);
							return <span style={{ fontWeight: 'bold' }}>{formattedValue}</span>; // Make total bold
						},
					};

					const columnGroup: ReportGroupColumn = {
						id: stackKey,
						header: stackKey,
						enableSorting: false, // Disable sorting for parent columns
						columns: [...subColumns, groupTotalColumn], // Add sub-columns and the total column
					};
					newColumns.push(columnGroup);
				}
			} else {
				// Original single-level column logic (only stack_by)
				for (const stackKey of sortedStackValues) {
					const leafColumn: ReportLeafColumn = {
						id: stackKey,
						header: stackKey,
						accessorKey: stackKey,
						enableSorting: true,
						default: valueFormat === 'number' || valueFormat === 'currency' ? 0 : '',
						meta: {
							align: 'right', // Align numbers right
						},
						cell: (info: ReportTableCell) => {
							const value = info.getValue();
							if (value === undefined || value === null || value === '') return '';
							const formattedValue = formatValue(value, valueFormat);
							return <span>{formattedValue}</span>;
						},
					};
					newColumns.push(leafColumn);
				}
			}
		} else {
			// Handle non-stacked columns based on datasets (excluding group_by if already added)
			for (const dataset of report.chart.datasets) {
				// Skip the group_by column as we've already added it
				if (dataset.key === report.chart.group_by) {
					continue;
				}

				const leafColumn: ReportLeafColumn = {
					id: dataset.key,
					header: dataset.label || titleCase(dataset.key),
					accessorKey: dataset.key,
					enableSorting: true,
					meta: {
						align: 'right', // Align numbers right by default for other columns
					},
					cell: (info: ReportTableCell) => {
						const value = info.getValue();
						if (value === undefined || value === null || value === '') return '';
						const formattedValue = formatValue(value, dataset.format); // Use dataset's format
						return <span>{formattedValue}</span>;
					},
				};
				newColumns.push(leafColumn);
			}

			// Fallback if no datasets defined but data exists
			if (!report.chart?.datasets?.length && data.items.length > 0) {
				newColumns = Object.keys(data.items[0]).map((key): ReportLeafColumn => ({
					id: key,
					header: titleCase(key),
					accessorKey: key,
					enableSorting: true,
					meta: {
						align: key === report.chart?.group_by ? 'left' : 'right',
					},
				}));
			}
		}

		setColumns(newColumns);

		// Set table data
		let newTableData: ReportDataRow[] = [];
		if (!report.chart?.stack_by) {
			newTableData = data.items;
		} else if (report.chart.group_by) {
			const tableGroups: Record<string, Record<string, number>> = {};
			const totalsByStack: Record<string, number[]> = {};

			// Group and Stack the data
			for (const row of data.items) {
				const groupKey = row[report.chart.group_by];
				let stackKey = row[report.chart.stack_by];
				if (stackKey.match(/^\d{4}-\d{2}-\d{2} 00:00:00/)) {
					stackKey = formatDateYYYYMMDD(stackKey);
				}

				// Make sure we're using the configured value_field and not a hardcoded key
				const valueKey = report.chart.value_field;
				let value: number;

				// Try to parse value as a number
				if (valueKey && row[valueKey] !== undefined) {
					if (typeof row[valueKey] === 'number') {
						value = row[valueKey];
					} else if (typeof row[valueKey] === 'string') {
						value = parseFloat(row[valueKey]);
						// If parsing fails or yields NaN, use 0
						if (Number.isNaN(value)) value = 0;
					} else {
						value = 0;
					}
				} else {
					value = 0;
				}

				if (!tableGroups[groupKey]) {
					tableGroups[groupKey] = {};
				}

				// Handle sub-column grouping if present
				if (report.chart.sub_column_by) {
					let subColumnKey = row[report.chart.sub_column_by];
					if (subColumnKey.match(/^\d{4}-\d{2}-\d{2} 00:00:00/)) {
						subColumnKey = formatDateYYYYMMDD(subColumnKey);
					}
					const combinedKey = `${stackKey}_${subColumnKey}`;

					if (!tableGroups[groupKey][combinedKey]) {
						tableGroups[groupKey][combinedKey] = 0;
					}
					tableGroups[groupKey][combinedKey] += value;

					// Track totals for the combined key
					if (value && typeof value === 'number') {
						if (!totalsByStack[combinedKey]) {
							totalsByStack[combinedKey] = [];
						}
						totalsByStack[combinedKey].push(value);
					}
				} else {
					// Original single-level grouping logic
					if (!tableGroups[groupKey][stackKey]) {
						tableGroups[groupKey][stackKey] = 0;
					}
					tableGroups[groupKey][stackKey] += value;

					if (value && typeof value === 'number') {
						if (!totalsByStack[stackKey]) {
							totalsByStack[stackKey] = [];
						}
						totalsByStack[stackKey].push(value);
					}
				}
			}
			setTotals(totalsByStack);

			// Convert grouped data back into rows
			newTableData = Object.entries(tableGroups).map(([groupKey, groupValues]) => {
				const row: ReportDataRow = {
					[report.chart.group_by]: groupKey,
				};

				// Handle column data assignment and calculate group totals
				for (const column of newColumns) {
					if (column.id === report.chart.group_by) {
						continue;
					}

					if (isGroupColumn(column)) {
						const stackKey = column.id;
						const groupValuesForTotal: number[] = []; // Collect values for aggregation

						for (const subColumn of column.columns) {
							if (subColumn.id === `${stackKey}_Total`) continue; // Skip the total col itself

							const value = groupValues[subColumn.accessorKey];
							let numericValue: number;
							if (typeof value === 'number') {
								numericValue = value;
							} else if (subColumn.default === 0) {
								numericValue = 0;
							} else {
								numericValue = undefined;
							}
							row[subColumn.accessorKey] = numericValue !== undefined ? numericValue : subColumn.default || '';

							if (typeof numericValue === 'number') {
								groupValuesForTotal.push(numericValue);
							}
						}
						// Store the calculated aggregate total for this row's group
						const groupRowTotal = aggregateValues(groupValuesForTotal, report.chart.totals?.rows);
						row[`${stackKey}_Total`] = groupRowTotal;
					} else {
						// Handle non-grouped columns (e.g., if only stack_by is used)
						const value = groupValues[column.accessorKey];
						row[column.accessorKey] = value !== undefined ? value : column.default || '';
					}
				}
				return row;
			});

			// After creating the newTableData array, calculate and add the OVERALL row total if enabled
			if (report.chart?.totals?.rows) {
				const valueFormat = getValueFormat(); // Use the determined value format

				newTableData = newTableData.map((row) => {
					const valuesForOverallTotal: number[] = [];
					// Iterate through the row data, collecting numeric values whose keys don't end in _Total
					// and are not the group_by key.
					for (const [key, value] of Object.entries(row)) {
						if (key !== report.chart.group_by && !key.endsWith('_Total') && typeof value === 'number') {
							valuesForOverallTotal.push(value);
						}
					}
					// Calculate the overall total using the specified row aggregation method
					const overallRowTotal = aggregateValues(valuesForOverallTotal, report.chart.totals?.rows);

					return {
						...row,
						total: overallRowTotal, // Store raw overall total
					};
				});

				// Add the *overall* total column definition if it doesn't exist
				if (!newColumns.find((col) => col.id === 'total')) {
					newColumns.push({
						id: 'total',
						// Use helper for dynamic header
						header: getAggregateHeader(report.chart.totals?.rows, report.chart.value_field),
						accessorKey: 'total',
						enableSorting: true,
						meta: {
							align: 'right',
						},
						cell: (info: ReportTableCell) => {
							const value = info.getValue();
							if (value === undefined || value === null || value === '') return '';
							// Force format to 'number' if row aggregation is 'count'
							const formatToUse = report.chart.totals?.rows === 'count' ? 'number' : valueFormat;
							const formattedValue = formatValue(value, formatToUse);
							// Apply bold style to the overall total value
							return <span style={{ fontWeight: 'bold' }}>{formattedValue}</span>;
						},
					});
				}
			}

			// Populate totals state for group totals and overall total AFTER newTableData is finalized
			const finalTotals = { ...totalsByStack };
			for (const row of newTableData) {
				for (const key in row) {
					if ((key.endsWith('_Total') || key === 'total') && typeof row[key] === 'number') {
						if (!finalTotals[key]) {
							finalTotals[key] = [];
						}
						finalTotals[key].push(row[key] as number);
					}
				}
			}
			setTotals(finalTotals); // Update totals state with group and overall totals included
		}

		setTableData(newTableData);
	}, [data?.items, report.chart]);

	// Effect for managing footer - reinstated for Grand Totals
	useEffect(() => {
		if (!data?.items?.length || !report.chart?.totals?.columns) {
			setFooterElement(null);
			return;
		}

		const footerRows: React.JSX.Element[] = [];

		// Add grand totals row if columns totals are enabled
		const totalsRow = (
			<tr key="grand-totals" className="totals">
				{/* Cell for the group_by column header */}
				<td>Grand Total</td>

				{/* Iterate through all defined columns (excluding group_by) */}
				{columns.slice(1).map((column) => {
					if (isGroupColumn(column)) {
						// For group columns, render a TD for each sub-column (including the group total)
						return column.columns.map((subColumn) => (
							<td key={subColumn.id} style={{ textAlign: 'right' }}>
								{/* Use calculateTotals which reads from the updated totals state */}
								{calculateTotals(subColumn.accessorKey)}
							</td>
						));
					}

					if (column.accessorKey) {
						// For non-group leaf columns (like the overall total column)
						return (
							<td key={column.id} style={{ textAlign: 'right' }}>
								{calculateTotals(column.accessorKey)}
							</td>
						);
					}
					// Placeholder for columns without accessors (shouldn't happen here)
					// This acts as the implicit else case
					return <td key={column.id}></td>;
				})}
			</tr>
		);
		footerRows.push(totalsRow);

		setFooterElement(<>{footerRows}</>);
	}, [
		columns, report.chart?.totals?.columns, totals, tableData,
		getValueFormat, calculateTotals, // Break dependencies onto new line
	]); // Added dependencies

	if (!data?.items?.length) {
		return (
			<div className="text-center p-4">
				<em>No data available</em>
			</div>
		);
	}

	return (
		<>
			<style>
				{`
					.group-totals td {
						border-top: 1px solid #dee2e6;
						background-color: #f8f9fa;
					}
					.totals td {
						border-top: 2px solid #dee2e6;
						font-weight: bold;
					}
				`}
			</style>
			<DataTable
				className={className || 'table-bordered'}
				ref={tableRef}
				columns={columns}
				items={tableData}
				defaultPageSize={defaultPageSize}
				striped
				sortable
				pagination
				footer={footerElement}
			/>
		</>
	);
};

export default ReportDataTable;
