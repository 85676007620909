import useWindowSize from './use-window-size';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | '3xl';

/**
 * Hook to detect the current bootstrap breakpoint
 * Matches the breakpoints defined in SCSS:
 * ```scss
 * $grid-breakpoints: (
 *   xs: 0,
 *   sm: 576px,
 *   md: 768px,
 *   lg: 992px,
 *   xl: 1200px,
 *   xxl: 1400px,
 *   3xl: 2000px,
 * ) !default;
 * ```
 * @returns The current breakpoint ('xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | '3xl')
 *
 * Usage:
 * ```tsx
 * const { breakpoint } = useBreakpoint();
 * const isMobile = !breakpoint || ['xs', 'sm'].includes(breakpoint);
 * ```
 */
export const useBreakpoint = (): { breakpoint: Breakpoint, priority: number } => {
	const { width } = useWindowSize();

	// Handle SSR case where window is not defined
	if (typeof width !== 'number') return { breakpoint: 'lg', priority: 3 };

	if (width < 576) return { breakpoint: 'xs', priority: 0 };
	if (width < 768) return { breakpoint: 'sm', priority: 1 };
	if (width < 992) return { breakpoint: 'md', priority: 2 };
	if (width < 1200) return { breakpoint: 'lg', priority: 3 };
	if (width < 1450) return { breakpoint: 'xl', priority: 4 };
	if (width < 2000) return { breakpoint: 'xxl', priority: 5 };
	return { breakpoint: '3xl', priority: 6 };
};

export default useBreakpoint;
