import { MenuLink, SidebarFooter, SidebarProps } from './shared';

export default function Sidebar({ sidebarShrink }: SidebarProps) {
	return (<>
		<div
			className={`sidebar ${sidebarShrink ? 'shrink show' : ''}`}
			id="sidebar"
		>
			<div className="sidebar-content">
				<h6 className="sidebar-heading">
					CURATOR
					{window.location.hostname?.includes('-dev.') && (
						<span className="badge bg-warning text-dark ms-2">Dev</span>
					)}
					{window.location.hostname?.includes('-local.') && (
						<span className="badge bg-danger text-light ms-2">Local</span>
					)}
				</h6>
				<ul className="list-unstyled">
					<li className="sidebar-list-item">
						<MenuLink to="/" icon="house" title="HOME" id="home" sidebarShrink={sidebarShrink} />
					</li>
				</ul>
				<SidebarFooter sidebarShrink={sidebarShrink} />
			</div>
		</div>
	</>);
}
