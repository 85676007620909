import { faChevronsRight, faInfoCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RevenueAndEarningsForContact } from '@newstex/types/revenue';
import type { Step } from 'react-joyride';

/**
 * Tutorial for the Publisher Dashboard
 */
export function getPublisherDashboardSteps({
	revenue,
	publisherCount,
}: {
	revenue: RevenueAndEarningsForContact;
	publisherCount: number;
}): Step[] {
	const tutorialSteps: Step[] = [
		{
			target: 'body',
			title: 'Welcome to Newstex',
			content: <div>
				<p>
					This is your Publisher Dashboard, where you can see your revenue, earnings, and other important metrics.
				</p>
				<p>
					If you accidentally close this tutorial, click the
					<FontAwesomeIcon icon={faInfoCircle} className="ms-1 me-1" />
					icon above to get back here.
				</p>
			</div>,
			placement: 'center',
			disableBeacon: true,
		},
		{
			target: '#sidebar-menu-home',
			title: 'Dashboard',
			content: <div>
				<p>
					The sidebar navigation menu is your main way to navigate the Newstex Platform.
					Click on the Dashboard link to get back here.
				</p>
			</div>,
			placement: 'right',
		},
		{
			target: '#kpi-current-earnings',
			title: 'Latest Reporting Period',
			placement: 'right',
			content: <div>
				<p>
					These are the earnings from the most recent reporting period that has been processed by Newstex.
				</p>
				<p>
					Earnings are reported within 90 days following the end of each reporting period.
				</p>
				{revenue?.currentMonthEarnings === 0 && (
					<p>
						You had no earnings for this period.
					</p>
				)}
			</div>,
		},
		{
			target: '#download-statement-button',
			title: 'Download Statement',
			placement: 'right',
			content: <div>
				<p>Click the download button to download your earnings statement for this period.</p>
			</div>,
		},
		{
			target: '#kpi-beginning-balance',
			title: 'Beginning Balance',
			placement: 'right',
			content: <div>
				<p>This is the balance of unpaid content fees at the beginning of the period.</p>
				<p>
					Revenue is only paid once you have met the $25 minimum threshold.
					Any fees below this amount are carried forward to the next period.
				</p>
				{revenue?.currentBeginningOfMonthBalance === 0 && (
					<p>
						You had no unpaid balance after the previous period, so your beginning balance is $0.
					</p>
				)}
			</div>,
		},
		{
			target: '#kpi-amount-to-be-paid',
			title: 'Amount to be Paid',
			placement: 'left',
			content: <div>
				<p>This is the amount of content fees that will be paid for this period.</p>
				<p>
					This is the sum of your Earnings for the current period, PLUS the beginning balance,
					unless you have not yet met the $25 minimum threshold.
				</p>
				{revenue?.currentMonthAmountPaid === 0 && revenue?.currentMonthEndOfMonthBalance > 0 && (
					<p>
						You have not yet met the $25 minimum threshold, so your balance will be carried
						forward to the next period.
					</p>
				)}
			</div>,
		},
		{
			target: '#kpi-end-balance',
			title: 'End Balance',
			placement: 'left',
			content: <div>
				<p>
					This is the balance of unpaid content fees at the end of the period that will
					be carried forward to the next period.
				</p>
				{revenue?.currentMonthEndOfMonthBalance === 0 && (
					<p>
						You have met the $25 minimum threshold, so your balance will be paid out.
					</p>
				)}
			</div>,
		},
		{
			target: '#revenue-chart',
			title: 'Earnings History',
			content: <div>
				<p>This is a chart of your earnings history.</p>
				<p>
					Earnings are shown in half-year periods, with the current period being highlighted.
				</p>
				<p>
					Please note that some providers only report earnings every 6 months, so this chart
					best represents your content's overall performance.
				</p>
			</div>,
		},
		{
			target: '#monthly-earnings-summary',
			title: 'Monthly Earnings History',
			placement: 'top',
			content: <div>
				<p>This is a summary of your earnings by month.</p>
				<p>
					You can expand this table by clicking on the <FontAwesomeIcon icon={faChevronsRight} /> icon.
				</p>
				<p>
					You can download a detailed earnings statement for any period by clicking the
					download button in any row of the expanded table.
				</p>
			</div>,
		},
		{
			target: '#monthly-earnings-summary-download',
			title: 'Download Monthly Earnings Summary',
			placement: 'left',
			content: <div>
				<p>
					You can download the monthly earnings summary as a CSV file by clicking here.
				</p>
			</div>,
		},
		{
			target: '#my-publications .expand-icon',
			title: 'My Publications',
			content: <div>
				<p>This is a list of your publications.</p>
				<p>
					Click on the <FontAwesomeIcon icon={faChevronsRight} /> icon to expand the list of publications.
				</p>
			</div>,
		},
	];

	// Only include this step if the user has access to multiple publishers
	if (publisherCount > 1) {
		tutorialSteps.push({
			target: '#select-publisher-dropdown',
			title: 'Select Publisher',
			placement: 'left',
			content: <div>
				<p>
					Since you have access to multiple publishers, your view is aggregated across all of them.
					You can show details for a specific publisher by selecting one from the dropdown.
				</p>
			</div>,
		});
	}

	tutorialSteps.push({
		target: '#tutorial-button',
		title: 'Tutorial',
		placement: 'right',
		content: <div>
			<p>
				If you forget how to use this platform, you can always click the tutorial button above.
			</p>
			<p>
				Look for more
				<FontAwesomeIcon icon={faInfoCircle} className="ms-1 me-1" />
				icons throughout the platform to get help on different features.
			</p>
		</div>,
	});

	return tutorialSteps;
}
