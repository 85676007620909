import { UserGroup } from '@newstex/types/user';
import { Col, Form, Row } from 'react-bootstrap';

const USER_GROUPS = ['admin', 'publisher', 'distributor', 'user', 'newscrunch'] as const;

interface AccessGroupSelectProps {
	value: UserGroup[];
	onChange: (groups: UserGroup[]) => void;
	label?: string;
	className?: string;
	disabled?: boolean;
}

export function AccessGroupSelect({
	value, onChange, label = 'Access Control', className, disabled,
}: AccessGroupSelectProps) {
	const handleAccessGroupChange = (group: UserGroup | 'admin', checked: boolean) => {
		if (group === 'admin') {
			onChange(checked ? [] : ['user']);
			return;
		}

		if (checked) {
			onChange([...value, group]);
		} else {
			onChange(value.filter((g) => g !== group));
		}
	};

	return (
		<Row className={className}>
			<Col md={3} className="text-end fw-bold">
				<Form.Label>{label}</Form.Label>
			</Col>
			<Col md={9}>
				<div>
					<Form.Check
						type="checkbox"
						id="access-group-admin"
						label="Admin Only"
						checked={!value.length}
						onChange={(e) => handleAccessGroupChange('admin', e.target.checked)}
						className="mb-2 fw-bold"
						disabled={disabled}
					/>
					<hr className="my-2"/>
					{USER_GROUPS.filter((g) => !g.includes('admin') && !g.includes('newscrunch')).map((group) => (
						<Form.Check
							key={group}
							type="checkbox"
							id={`access-group-${group}`}
							label={group.charAt(0).toUpperCase() + group.slice(1)}
							checked={value.includes(group)}
							onChange={(e) => handleAccessGroupChange(group, e.target.checked)}
							disabled={disabled || !value.length}
						/>
					))}
				</div>
				<Form.Text className="text-muted">
					{disabled
						? 'This report is configured to require a specific access group. You cannot change it here.'
						: 'Select "Admin Only" to restrict access to administrators, or choose specific user groups that should have access.'
					}
				</Form.Text>
			</Col>
		</Row>
	);
}
