import type { LanguageCode } from 'iso-639-1';
import { z } from 'zod';

/**
 * ISO 639 Language Code
 *
 * Supports both `en` and `en-US` formats
 */
export const ISOLanguageCodeSchema = z.string()
	.regex(/^[a-z]{2}(-[A-Z]{2})?$/)
	.describe('ISO 639-1 language code, optionally with region (e.g. "en" or "en-US")');

export type ISOLanguageCode = z.infer<typeof ISOLanguageCodeSchema> & (LanguageCode | `${LanguageCode}-${string}`);
