import { SearchHit } from '@newstex/search';
import { User } from '@newstex/types/user';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useSearch } from '~/providers/search';

export interface NewUserOption {
	__isNew__: true;
	email: string;
	id?: string;
}

// Type to represent either a User or a new user option
export type UserSelectValue = User | NewUserOption | null;

interface UserSelectProps {
	value: UserSelectValue;
	onChange: (user: UserSelectValue) => void;
	// Manual filter after searching
	filter?: (user: SearchHit<User>) => boolean;
	// Search filter string to filter by
	// See: https://typesense.org/docs/27.1/api/search.html#filter-parameters
	filterBy?: string;
	placeholder?: string;
}

export function UserSelect({
	value, onChange, filter, filterBy, placeholder = 'Search for a user or enter an email address...',
}: UserSelectProps) {
	const { searchClient } = useSearch();

	const loadOptions = async (inputValue: string) => {
		if (!searchClient) return [];

		const results = await searchClient.search<User>({
			indexName: 'User',
			query: inputValue || '*',
			filter_by: filterBy,
			per_page: 100,
			include_fields: 'id,name,email,phone,distributor',
		});

		let users = results.hits.sort((a, b) => (a.name || '').localeCompare(b.name || ''));

		if (filter) {
			users = users.filter(filter);
		}

		return users.map((user) => ({
			value: user.id,
			label: `${user.name || 'Unknown'} (${user.email || 'No email'})`,
			data: user,
		}));
	};

	// Format selected value for display
	const formatValue = () => {
		if (!value) return null;

		// Handle new user case
		if ('__isNew__' in value) {
			return {
				value: value.email,
				label: `Create user: ${value.email}`,
				__isNew__: true,
			};
		}

		// Handle existing user case
		return {
			value: value.id,
			label: `${value.name} (${value.email})`,
			data: value,
		};
	};

	// Handle the onChange event from react-select
	const handleChange = (option: any) => {
		if (!option) {
			onChange(null);
			return;
		}

		// Handle the "create new" case
		if (option.__isNew__) {
			onChange({
				__isNew__: true,
				email: option.value,
			});
			return;
		}

		// Handle existing user selection
		onChange(option.data || null);
	};

	// Function to validate if an input is a valid email to create a new user
	const isValidNewOption = (inputValue: string) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(inputValue);
	};

	return (
		<AsyncCreatableSelect
			value={formatValue()}
			onChange={handleChange}
			loadOptions={loadOptions}
			defaultOptions
			isClearable
			placeholder={placeholder}
			formatCreateLabel={(inputValue) => (
				<>
					<b>Create user:</b> {inputValue}
				</>
			)}
			isValidNewOption={isValidNewOption}
			createOptionPosition="first"
		/>
	);
}
