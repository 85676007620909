import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Parameter, Report } from '@newstex/types/report';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

import ParametersConfigModal from '../modals/parameters-config-modal';

interface EditParametersButtonProps {
	report: Report;
	onSave: (parameters: Parameter[]) => Promise<void>;
	variant?: string;
	size?: 'sm' | 'lg';
}

export default function EditParametersButton({
	report,
	onSave,
	variant = 'outline-primary',
	size,
}: EditParametersButtonProps) {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button
				variant={variant}
				size={size}
				onClick={() => setShowModal(true)}
			>
				<FontAwesomeIcon icon={faGear} /> Configure Parameters
			</Button>

			<ParametersConfigModal
				show={showModal}
				onClose={(saved) => {
					setShowModal(false);
				}}
				report={report}
				onSave={onSave}
			/>
		</>
	);
}
