/**
 * Select Edit Field
 */
import { TypeNameToTypeMap } from '@newstex/types/infer-types';
import { useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import PropertyDisplayName from '../property-display-name';

import { EditTextFieldProps } from './text';

export interface EditableSelectFieldProps<T extends keyof TypeNameToTypeMap> extends EditTextFieldProps<T> {
	options: readonly string[] | { value: string; label: string }[],
	allowNew?: boolean;
}

export function Option<T extends keyof TypeNameToTypeMap>({ option }: {
	option: EditableSelectFieldProps<T>['options'][number],
}) {
	if (typeof option === 'string') {
		return <option key={option} value={option}>{option}</option>;
	}
	return <option key={option.value} value={option.value}>{option.label}</option>;
}

export default function EditableSelectField<T extends keyof TypeNameToTypeMap>({
	typeName,
	propKey,
	item,
	updates,
	setUpdates,
	options,
	allowNew = false,
	required,
	placeholder = 'Select or enter a value...',
}: EditableSelectFieldProps<T>) {
	const [isNewValue, setIsNewValue] = useState(false);
	const value = updates?.[propKey] ?? item?.[propKey];
	const selected = value ? [String(value)] : [];

	const tsOptions = useMemo(() => {
		return options.map((option) => {
			if (typeof option === 'string') {
				return {
					value: option,
					label: option,
				};
			}
			return option;
		});
	}, [options]);

	return (
		<Row className="mb-3">
			<Col md={3} className="text-end fw-bold">
				<Form.Label><PropertyDisplayName propKey={propKey} typeName={typeName} /></Form.Label>
			</Col>
			<Col md={9}>
				<Typeahead
					id={`editable-select-${String(propKey)}`}
					allowNew={allowNew}
					clearButton
					onChange={(val) => {
						console.log('selected', val);
						const newValue = val[0];
						if (
							newValue
							&& typeof newValue === 'object'
							&& 'id' in newValue
							&& newValue.id?.startsWith('new-id')
							&& 'label' in newValue
						) {
							setIsNewValue(true);
						} else {
							setIsNewValue(false);
						}

						if (!setUpdates) return;

						if (typeof newValue === 'string') {
							setUpdates({
								...updates,
								[propKey]: newValue,
							} as Partial<TypeNameToTypeMap[T]>);
						} else if (newValue && 'value' in newValue) {
							setUpdates({
								...updates,
								[propKey]: newValue.value?.trim(),
							} as Partial<TypeNameToTypeMap[T]>);
						} else if (newValue && 'id' in newValue) {
							if (newValue.id?.startsWith('new-id') && 'label' in newValue) {
								setUpdates({
									...updates,
									[propKey]: newValue.label?.trim(),
								} as Partial<TypeNameToTypeMap[T]>);
							}
						} else if (!newValue) {
							setUpdates({
								...updates,
								[propKey]: '',
							} as Partial<TypeNameToTypeMap[T]>);
						} else {
							console.error('Invalid value type:', newValue);
						}
					}}
					labelKey="label"
					options={tsOptions}
					placeholder={placeholder}
					selected={selected}
					inputProps={{ required }}
				/>
				{isNewValue && updates && updates[propKey] && (
					<Form.Text className="text-muted">
						New value: <code>{String(updates[propKey])}</code> will be created.
					</Form.Text>
				)}
			</Col>
		</Row>
	);
}
