import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import { RefreshButton } from './refresh-button';

/**
 * A native search box with hooks
 */
export function SearchBox({
	loading,
	refineHook,
	refreshHook,
	initialQuery = '',
	searchAsYouType = true,
	disableSearch = false,
	extraButtons,
}: {
	loading?: boolean,
	refineHook?: (query: string) => void;
	refreshHook?: () => Promise<void>;
	initialQuery?: string;
	searchAsYouType?: boolean;
	disableSearch?: boolean;
	extraButtons?: React.ReactNode;
}) {
	const [inputValue, setInputValue] = useState(initialQuery);
	const inputRef = useRef<HTMLInputElement>(null);

	function refine(query: string) {
		if (!disableSearch) {
			refineHook?.(query);
		}
	}

	function setQuery(newQuery: string) {
		setInputValue(newQuery);

		if (inputRef.current) {
			inputRef.current.value = newQuery;
		}

		if (searchAsYouType && !disableSearch) {
			refine(newQuery);
		}
	}

	function onReset() {
		setQuery('');

		if (!searchAsYouType && !disableSearch) {
			refine('');
		}
	}

	function onChange(event: React.ChangeEvent<HTMLInputElement>) {
		setInputValue(event.currentTarget.value);
		if (searchAsYouType && !disableSearch) {
			refine(event.currentTarget.value);
		}
	}

	function onSubmit(event: React.KeyboardEvent<any>) {
		if (!searchAsYouType && !disableSearch) {
			refine(inputValue);
		}
	}

	useEffect(() => {
		if (disableSearch) {
			if (inputValue) {
				setQuery('');
			}
		} else {
			setQuery(inputValue || '');
		}
	}, [inputRef.current, disableSearch]);

	return (
		<InputGroup className="mb-3">
			<InputGroup.Text>
				<FontAwesomeIcon icon={faSearch} />
			</InputGroup.Text>
			<Form.Control
				placeholder={disableSearch ? 'Search Disabled' : 'Search'}
				aria-label="Search"
				aria-describedby="basic-addon1"
				autoFocus
				ref={inputRef}
				disabled={disableSearch || loading}
				onChange={onChange}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						return onSubmit(event);
					}

					if (event.key === 'Escape') {
						return onReset();
					}
				}}
			/>
			{refreshHook && <RefreshButton refreshHandler={refreshHook} loading={loading} />}
			{extraButtons}
		</InputGroup>
	);
}
