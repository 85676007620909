import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

export type TooltipIconButtonProps = ComponentPropsWithoutRef<typeof Button> & {
	tooltip: string;
	side?: 'top' | 'bottom' | 'left' | 'right';
};

export const TooltipIconButton = forwardRef<
HTMLButtonElement,
TooltipIconButtonProps
>(({
	children, tooltip, side = 'bottom', className, ...rest
}, ref) => {
	return (
		<OverlayTrigger
			placement={side}
			overlay={
				<Tooltip>{tooltip}</Tooltip>
			}
		>
			<Button
				{...rest}
				className={className}
				ref={ref}
			>
				{children}
			</Button>
		</OverlayTrigger>
	);
});

TooltipIconButton.displayName = 'TooltipIconButton';
