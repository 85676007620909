/*
 * Frontend specific utility functions
 */
import omit from 'object.omit';

export function sleep(ms: number) {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
}

export function convertToDate(date: Date | string | number): Date {
	if (typeof date === 'number') {
		if (date < 10000000000) {
			// Unix timestamp in seconds
			return new Date(date * 1000);
		}
		return new Date(date);
	}

	if (typeof date === 'string') {
		return new Date(date);
	}
	return date;
}

export function formatDateString(date: Date | string | number, options?: Intl.DateTimeFormatOptions) {
	if (!date) {
		return '';
	}
	return convertToDate(date).toLocaleString('en-US', {
		// Default to Eastern time
		timeZone: 'America/New_York',
		...(options || {}),
	});
}

export function formatDateYYYYMMDD(date: Date | string | number) {
	if (!date) {
		return '';
	}
	const d = convertToDate(date);
	const year = d.getFullYear();
	const month = String(d.getMonth() + 1).padStart(2, '0');
	const day = String(d.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
}

/**
 * Convert a period string (YYYY-MM) to a formatted date string
 * @param period - The period string (YYYY-MM)
 * @param locale - Optional locale to use for formatting (en-US for example)
 * @returns The formatted date string (locale specific)
 */
export function formatDatePeriod(period: string, opts?: Partial<Intl.DateTimeFormatOptions> & {
	locale?: Intl.LocalesArgument,
}) {
	if (!period) {
		return '';
	}
	// Handle periods of any format as long as it's a string with YYYY-MM (sometimes it's YYYY-MM-DD)
	const [year, month] = period.split('-');
	// Use the 2nd of the month to avoid issues with timezones
	return new Date(`${year}-${month}-02T00:00:00`).toLocaleDateString(opts?.locale || 'en-US', {
		month: 'short',
		year: 'numeric',
		...(omit(opts || {}, 'locale')),
	});
}

/**
 * Generate a filename from the given search parameters
 *
 * Used for CSV export filenames
 * @param searchParams - The search parameters
 * @param type - The type of the search
 * @returns The filename
 */
export function generateFileNameFromFilters(searchParams: URLSearchParams, type: string) {
	const filters = Array.from(searchParams.entries())
		.filter(([key]) => key !== 'q' && key !== 'mode' && key !== 'sort_by' && key !== 'page')
		.map(([key, value]) => `${key}-${value}`)
		.join('_');
	return `${type}_${filters}_${formatDateYYYYMMDD(new Date())}`;
}
