import { IconDefinition, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { kebab as kebabCase } from 'case';
import { FC, useEffect, useState } from 'react';
import {
	Badge,
	Button,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import Joyride, {
	ACTIONS,
	EVENTS,
	STATUS,
	Step,
} from 'react-joyride';
import { Link } from 'react-router-dom';
import { useAnalytics } from '~/providers/analytics-provider';
import { usePageTitle } from '~/providers/page-title-provider';

import { ObjectHistoryButton } from './object-history-button';
import { RequirePermissions } from './require-permissions';

export interface Breadcrumb {
	label: string;
	href?: string;
}

export const PageTitle: FC<{
	title: string,
	info?: string,
	infoIcon?: IconDefinition,
	count?: number,
	breadcrumbs?: Breadcrumb[],
	// Custom formatted breadcrumbs
	customBreadcrumbs?: React.ReactNode,
	children?: React.ReactNode,
	item?: any, // The item to show history for
	disableBreadcrumbs?: boolean,
	className?: string,
	tutorial?: Step[],
}> = (props) => {
	const [showTutorial, setShowTutorial] = useState(false);
	const [tutorialStepIndex, setTutorialStepIndex] = useState(0);
	const { setTitle } = usePageTitle();
	const analytics = useAnalytics();

	// Update the page title in the store when this component mounts or title changes
	useEffect(() => {
		setTitle(props.title);
	}, [props.title, setTitle]);

	useEffect(() => {
		// If we have a tutorial and a title, check to see if we have ever shown this tutorial before
		// to this user.
		if (props.tutorial && props.title) {
			const tutorialId = `tutorial-${kebabCase(props.title)}`;
			const hasShownTutorial = localStorage.getItem(tutorialId);
			if (!hasShownTutorial) {
				localStorage.setItem(tutorialId, 'true');
				setShowTutorial(true);
				setTutorialStepIndex(0);
			}
		}
	}, [props.title, props.tutorial, analytics]);

	return (
		<>
			{props.tutorial && (
				<Joyride
					steps={props.tutorial}
					run={showTutorial}
					stepIndex={tutorialStepIndex}
					callback={(data) => {
						const {
							action, index, origin, status, type,
						} = data;

						if (action === ACTIONS.CLOSE) {
							setShowTutorial(false);
							setTutorialStepIndex(0);
						}

						if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
							// Update state to advance the tour
							setTutorialStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
						} else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)) {
							// You need to set our running state to false, so we can restart if we click start again.
							setShowTutorial(false);
						}

						// Track analytics
						if (type === EVENTS.STEP_BEFORE && data.step) {
							analytics.trackEvent('Tutorial Step Viewed', {
								id: String(data.step.target),
								name: String(data.step.title || `Step ${index + 1}`),
								path: window.location.pathname,
							});
						}
					}}
					continuous={true}
					showSkipButton={true}
					showProgress={true}
					scrollOffset={400}
					styles={{
						tooltip: {
							zIndex: 9000,
						},
					}}
				/>
			)}
			<div className={`page-header d-flex justify-content-between align-items-center ${props.className || ''}`}>
				<h1 className="page-heading">
					<span>
						{props.title}
						{props.info && (
							<OverlayTrigger
								placement="bottom"
								overlay={
									<Tooltip>
										{props.info}
									</Tooltip>
								}
							>
								<FontAwesomeIcon
									className="ms-2 text-muted"
									size="2xs"
									icon={props.infoIcon || faInfoCircle}
									title={props.info}
								/>
							</OverlayTrigger>
						)}
						{props.tutorial && (
							<Button
								id="tutorial-button"
								variant="link"
								size="lg"
								className="p-0"
								onClick={() => {
									setShowTutorial(true);
									setTutorialStepIndex(0);
								}}
							>
								<FontAwesomeIcon
									className="text-muted"
									icon={faInfoCircle}
									title="Tutorial"
								/>
							</Button>
						)}
					</span>
					{props.item && (
						<RequirePermissions permissions={['admin']}>
							<span className="ms-2">
								<ObjectHistoryButton item={props.item} size={28} />
							</span>
						</RequirePermissions>
					)}
					{props.count && (
						<Badge pill bg="secondary" className="ms-2">{props.count.toLocaleString('en')}</Badge>
					)}
				</h1>
				<div className="d-flex flex-column">
					{!props.disableBreadcrumbs && (
						<>
							{props.customBreadcrumbs ? (
								props.customBreadcrumbs
							) : (
								<div className="breadcrumb-wrapper">
									<nav aria-label="breadcrumb">
										<ol className="breadcrumb">
											{props.breadcrumbs?.map((breadcrumb, index) => (
												<li className="breadcrumb-item" key={index}>
													{breadcrumb.href ? (
														<Link to={breadcrumb.href}>{breadcrumb.label}</Link>
													) : (
														breadcrumb.label
													)}
												</li>
											))}
											<li className="breadcrumb-item active" aria-current="page">
												{props.title}
											</li>
										</ol>
									</nav>
								</div>
							)}
						</>
					)}
				</div>
				{props.children}
			</div>
		</>
	);
};
