import { convertToDate } from '@newstex/core/date';
import { z } from 'zod';

/**
 * Unix timestamp in seconds since epoch
 */
export type UnixTimestamp = number;

/**
 * ISO 8601 timestamp in format YYYY-MM-DDTHH:MM:SSZ
 */
export type ISO8601Timestamp = string;

/**
 * ISO date in format YYYY-MM-DD
 */
export type ISODate = string;

/**
 * Standard period options for date-based grouping and filtering
 */
export type DatePeriod = 'day' | 'week' | 'month' | 'year';

/**
 * Helper function to convert a Date to a Unix timestamp
 * @param date The Date object to convert
 * @returns Unix timestamp in seconds since epoch
 */
export function dateToUnixTimestamp(date: Date | UnixTimestamp): UnixTimestamp {
	if (date instanceof Date) {
		return Math.floor(date.getTime() / 1000);
	}
	return date;
}

/**
 * Helper function to convert a Unix timestamp to a Date
 * @param timestamp Unix timestamp in seconds since epoch
 * @returns Date object
 */
export function unixTimestampToDate(timestamp: Date | UnixTimestamp): Date {
	if (typeof timestamp === 'number') {
		return new Date(timestamp * 1000);
	}
	return timestamp;
}

/**
 * Schema for a Unix Timestamp (both date and time).
 *
 * Accepts either a numeric Unix timestamp or a Date object.
 * Always returns a numeric Unix timestamp (seconds since epoch).
 */
export const UnixTimestampSchema = z.preprocess((val: unknown) => {
	if (!val) {
		return val;
	}

	if (val instanceof Date) {
		return Math.floor(val.getTime() / 1000);
	}

	if (typeof val === 'number') {
		return val;
	}
	try {
		return convertToDate(String(val)).unix();
	} catch {
		throw new Error('Invalid timestamp');
	}
}, z.number());
