import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Dashboard } from '@newstex/types/dashboard';
import { title as titleCase } from 'case';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { RequirePermissions } from '~/components/require-permissions';
import { SearchFacetConfig, SearchWithFacets } from '~/components/search-with-facets';
import { DashboardsTable } from '~/components/tables/dashboards-table';

export const DashboardFacetConfig: SearchFacetConfig[] = [
	{
		attribute: 'access_groups',
		title: 'Access Groups',
		type: 'Menu',
		showMore: true,
		displayValue: (value) => {
			if (value === '') return 'All';
			return titleCase(value);
		},
	},
];

export const DashboardSearchPage = () => {
	const navigate = useNavigate();

	return (
		<SearchWithFacets<Dashboard>
			indexName="Dashboard"
			title="Dashboards"
			queryBy={['name', 'description']}
			facets={DashboardFacetConfig}
			resultsTable={DashboardsTable}
			actions={
				<RequirePermissions permissions={['admin']}>
					<Button
						size="sm"
						variant="outline-success"
						onClick={() => navigate('/dashboards/new')}
					>
						<FontAwesomeIcon icon={faPlus} /> New Dashboard
					</Button>
				</RequirePermissions>
			}
		/>
	);
};
