import './scss/style.default.scss';
import { useSiteConfig } from './providers/site-config';
import { useUserInfo } from './providers/user-info';
import { AdminDashboard } from './routes/dashboards/admin-dashboard';
import { CuratorDashboard } from './routes/dashboards/curator-dashboard';
import { DistributorDashboard } from './routes/dashboards/distributor-dashboard';
import { PublisherDashboard } from './routes/dashboards/publisher-dashboard';
import { UserDashboard } from './routes/dashboards/user-dashboard';

export default function Default() {
	const siteConfig = useSiteConfig();
	const userInfo = useUserInfo();

	if (siteConfig?.category === 'curator') {
		return <CuratorDashboard />;
	}

	if (userInfo?.auth_groups?.includes('admin')) {
		return <AdminDashboard />;
	}

	if (userInfo?.auth_groups?.includes('publisher')) {
		return <PublisherDashboard />;
	}

	if (userInfo?.auth_groups?.includes('distributor')) {
		return <DistributorDashboard />;
	}

	return <UserDashboard />;
}
