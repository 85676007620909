import '@assistant-ui/react-markdown/styles/dot.css';

import { useContentPart } from '@assistant-ui/react';
import {
	CodeHeaderProps,
	MarkdownTextPrimitive,
	unstable_memoizeMarkdownComponents as memoizeMarkdownComponents,
} from '@assistant-ui/react-markdown';
import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';

import { TooltipIconButton } from './tooltip-icon-button';

const useCopyToClipboard = ({
	copiedDuration = 2000,
} = {}) => {
	const [isCopied, setIsCopied] = useState(false);

	const copyToClipboard = (value: string) => {
		if (!value) return;

		navigator.clipboard.writeText(value).then(() => {
			setIsCopied(true);
			setTimeout(() => setIsCopied(false), copiedDuration);
		});
	};

	return { isCopied, copyToClipboard };
};

const CodeHeader: FC<CodeHeaderProps> = ({ language, code }) => {
	const { isCopied, copyToClipboard } = useCopyToClipboard();
	const onCopy = () => {
		if (!code || isCopied) return;
		copyToClipboard(code);
	};

	return (
		<div className="flex items-center justify-between rounded-t-lg bg-black/75 px-4 py-2">
			<span className="lowercase [&>span]:text-xs">{language}</span>
			<TooltipIconButton tooltip="Copy" onClick={onCopy}>
				{!isCopied && <FontAwesomeIcon icon={faCopy} />}
				{isCopied && <FontAwesomeIcon icon={faCheck} />}
			</TooltipIconButton>
		</div>
	);
};

const defaultComponents = memoizeMarkdownComponents({
	h1: ({ className, ...props }) => (
		<h1 className={`mb-8 scroll-m-20 text-4xl font-extrabold tracking-tight last:mb-0 ${className || ''}`} {...props} />
	),
	h2: ({ className, ...props }) => (
		<h2 className={`mb-4 mt-8 scroll-m-20 text-3xl font-semibold tracking-tight first:mt-0 last:mb-0 ${className || ''}`} {...props} />
	),
	h3: ({ className, ...props }) => (
		<h3 className={`mb-4 mt-6 scroll-m-20 text-2xl font-semibold tracking-tight first:mt-0 last:mb-0 ${className || ''}`} {...props} />
	),
	h4: ({ className, ...props }) => (
		<h4 className={`mb-4 mt-6 scroll-m-20 text-xl font-semibold tracking-tight first:mt-0 last:mb-0 ${className || ''}`} {...props} />
	),
	h5: ({ className, ...props }) => (
		<h5 className={`my-4 text-lg font-semibold first:mt-0 last:mb-0 ${className || ''}`} {...props} />
	),
	h6: ({ className, ...props }) => (
		<h6 className={`my-4 font-semibold first:mt-0 last:mb-0 ${className || ''}`} {...props} />
	),
	p: ({ className, ...props }) => (
		<p className={`mb-5 mt-5 leading-7 first:mt-0 last:mb-0 ${className || ''}`} {...props} />
	),
	a: ({ className, ...props }) => (
		<Link className={`text-primary font-medium underline underline-offset-4 ${className || ''}`} to={props.href || ''} {...props} />
	),
	blockquote: ({ className, ...props }) => (
		<blockquote className={`border-l-2 pl-6 italic ${className || ''}`} {...props} />
	),
	ul: ({ className, ...props }) => (
		<ul className={`my-5 ml-6 list-disc [&>li]:mt-2 ${className || ''}`} {...props} />
	),
	ol: ({ className, ...props }) => (
		<ol className={`my-5 ml-6 list-decimal [&>li]:mt-2 ${className || ''}`} {...props} />
	),
	hr: ({ className, ...props }) => (
		<hr className={`my-5 border-b ${className || ''}`} {...props} />
	),
	table: ({ className, ...props }) => (
		<table className={`my-5 w-full border-separate border-spacing-0 overflow-y-auto ${className || ''}`} {...props} />
	),
	th: ({ className, ...props }) => (
		<th className={`bg-muted px-4 py-2 text-left font-bold first:rounded-tl-lg last:rounded-tr-lg [&[align=center]]:text-center [&[align=right]]:text-right ${className || ''}`} {...props} />
	),
	td: ({ className, ...props }) => (
		<td className={`border-b border-l px-4 py-2 text-left last:border-r [&[align=center]]:text-center [&[align=right]]:text-right ${className || ''}`} {...props} />
	),
	tr: ({ className, ...props }) => (
		<tr className={`m-0 border-b p-0 first:border-t [&:last-child>td:first-child]:rounded-bl-lg [&:last-child>td:last-child]:rounded-br-lg ${className || ''}`} {...props} />
	),
	sup: ({ className, ...props }) => (
		<sup className={`[&>a]:text-xs [&>a]:no-underline ${className || ''}`} {...props} />
	),
	pre: ({ className, ...props }) => (
		<pre className={`overflow-x-auto rounded-b-lg bg-black p-4 text-white ${className || ''}`} {...props} />
	),
	code: function Code({
		node, inline, className, children, ...props
	}: any) {
		const match = /language-(\w+)/.exec(className || '');
		const { isCopied, copyToClipboard } = useCopyToClipboard();

		return !inline && match ? (
			<div className="relative group">
				<button
					onClick={() => copyToClipboard(String(children).replace(/\n$/, ''))}
					className={`absolute right-3 top-3 hover:bg-zinc-700 rounded-md p-2 ${isCopied ? 'text-green-400' : ''}`}
				>
					<FontAwesomeIcon icon={isCopied ? faCheck : faCopy} />
				</button>
				<SyntaxHighlighter
					{...props}
					style={oneDark}
					language={match[1]}
					PreTag="div"
				>
					{String(children).replace(/\n$/, '')}
				</SyntaxHighlighter>
			</div>
		) : (
			<code {...props} className={className}>
				{children}
			</code>
		);
	},
	CodeHeader,
});

const MarkdownTextImpl = () => {
	const content = useContentPart();
	console.log('MarkdownTextImpl', content);
	return (
		<MarkdownTextPrimitive
			remarkPlugins={[remarkGfm]}
			className="aui-md"
			components={defaultComponents}
		/>
	);
};

export const MarkdownText = memo(MarkdownTextImpl);
