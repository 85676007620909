import { IconName } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import LoadingSpinner from '../LoadingSpinner';

export interface KPIProps {
	id?: string;
	icon?: IconName;
	color: string;
	number: string;
	name: string;
	footer?: ReactNode;
	changePct?: number;
	indicator?: IconName;
	/**
	 * Prefix to add before the number
	 */
	prefix?: string;
	/**
	 * Location to link button clicks to
	 */
	link?: string;

	/**
	 * If set to true, we'll use a loading spinner in palce of the number
	 */
	loading?: boolean;
}

export default function KPIStatsWidget(props: KPIProps) {
	return (
		<div className="kpi-widget position-relative" id={props.id}>
			<div className="kpi-title">
				{props.name}
				{props.icon && (
					<FontAwesomeIcon className="ms-2 float-end" icon={['fal', props.icon]} />
				)}
			</div>
			<div className="kpi-value">
				{props.loading ? <LoadingSpinner hideTitle loading /> : (
					<>{props.prefix || ''}{props.number}</>
				)}
				{Boolean(props.changePct) && (
					<Badge
						pill
						bg={`${props.color}-subtle`}
						text={props.color}
						className="kpi-change ms-4 float-end"
					>
						{props.indicator && (
							<FontAwesomeIcon icon={props.indicator} className="me-1" />
						)}
						{(props.changePct * 100).toFixed(2)}%
					</Badge>
				)}
			</div>
			{props.footer && (
				<div className="kpi-footer">{props.footer}</div>
			)}
			{props.link && (
				<Link to={props.link} className="stretched-link" />
			)}
		</div>
	);
}
