/**
 * Convert a single facet to a filter string
 *
 * @param key - The key of the facet
 * @param values - The values of the facet
 * @param negated - Whether the facet is negated
 * @returns The filter string
 */
export function getFacetFilterString(key: string, values: string[], negated: boolean = false) {
	// Split comma-separated values
	const valueArray = values.flatMap((v) => v.split(','));

	// Boolean Values
	if (valueArray[0] === 'true' || valueArray[0] === 'false') {
		return `${key}:${negated ? '!=' : '='}${valueArray[0]}`;
	}

	// Date/Received At handling
	if (key === 'date' || key?.endsWith('_at') || key === 'last_login') {
		if (valueArray.length === 2) {
			return `${key}:${negated ? '!' : ''}=[${valueArray[0]}...${valueArray[1]}]`;
		}

		if (valueArray.length === 1) {
			if (valueArray[0].startsWith('[')) {
				return `${key}:${negated ? '!' : ''}${valueArray[0]}`;
			}
			return `${key}:${negated ? '<' : '>='}${valueArray[0]}`;
		}
	}

	// Segment handling
	if (key === 'segment') {
		if (valueArray.length === 2) {
			return `${key}:${negated ? '!' : ''}=[${valueArray[0]}...${valueArray[1]}]`;
		}

		if (valueArray.length === 1) {
			return `${key}:${negated ? '!=' : '='}${valueArray[0]}`;
		}
	}

	return `${key}:${negated ? '!' : ''}=[${valueArray.map((value) => `\`${value}\``).join(',')}]`;
}

/**
 * Convert a set of filters to a filter string
 *
 * @param filters - The filters to convert to a filter string
 * @param defaultFilters - Optional default filters to apply (by facet) if the filter is not set for
 * that specific facet
 * @returns The filter string to pass to the search client
 */
export function getFilterString(filters?: Record<string, string[]>, defaultFilters?: Record<string, string[]>) {
	const filtersToApply: Record<string, string[]> = { ...(filters || {}) };

	if (defaultFilters) {
		for (const [key, values] of Object.entries(defaultFilters)) {
			if (!filtersToApply[key]) {
				// Allow a default filter to be negated by prefixing a single value with '!'
				if (values.length === 1 && values[0][0] === '!') {
					filtersToApply[`!${key}`] = [values[0].slice(1)];
				} else {
					filtersToApply[key] = values;
				}
				console.log('Adding default filter', key, values, filtersToApply[key]);
			}
		}
	}

	if (!filtersToApply) return;

	return Object.entries(filtersToApply)
		.filter(([key]) => key !== 'sort_by' && key !== 'page' && key !== 'mode')
		.map(([key, values]) => {
			const isNegated = key.startsWith('!');
			const actualKey = isNegated ? key.slice(1) : key;
			return getFacetFilterString(actualKey, values, isNegated);
		})
		.join(' && ');
}
