import type {
	AssistantRuntime,
	ChatModelAdapter,
	ChatModelRunOptions,
	ChatModelRunResult,
	ThreadMessage,
} from '@assistant-ui/react';
import { useLocalRuntime } from '@assistant-ui/react';
import { AI_CHAT_PROMPT } from '@newstex/ai/prompts/chat';
import { useEffect, useState } from 'react';
import { AIContextType, useAI } from '~/providers/ai-provider';

// Define a type for tool calls
interface ToolCall {
	id: string;
	type: 'tool-call';
	tool: string;
	args: Record<string, any>;
	status: 'running' | 'completed' | 'failed';
	result?: any;
	error?: string;
}

// Define a type for text content
interface TextContent {
	type: 'text';
	text: string;
	id?: string;
}

class AIRuntimeAdapter implements ChatModelAdapter {
	constructor(private chat: AIContextType) {}

	async* run(options: ChatModelRunOptions): AsyncGenerator<ChatModelRunResult, void> {
		try {
			// Track the accumulated text that hasn't been assigned to a content item yet
			let pendingText = '';
			const content: any[] = [];
			const steps: any[] = [];

			// Create initial text content
			let activeTextContent: TextContent = {
				type: 'text',
				text: '',
			};
			content.push(activeTextContent);

			// Tool calls map to track their state
			const toolCalls = new Map<string, { toolCall: ToolCall, contentIndex: number }>();

			// Initial state yield
			yield {
				status: { type: 'running' },
				content: [...content],
				metadata: {
					custom: {},
					steps: [],
				},
			};

			const result = await this.chat.chat({
				messages: options.messages,
				prompt: AI_CHAT_PROMPT,
				signal: options.abortSignal,
				onStepFinish: (step) => {
					console.log('AIRuntimeAdapter onStepFinish', step);
					steps.push(step);

					if (step.response?.id) {
						// Add ID to the active text content if it exists
						activeTextContent.id = step.response.id;
					}

					// Handle tool calls
					const updatedContent = [...content];
					let contentChanged = false;

					for (const toolResult of (step.toolResults || [])) {
						console.log('AIRuntimeAdapter toolResult', toolResult);

						// Generate a unique ID for this tool call
						const cmdName = toolResult.toolName || toolResult.name || 'tool';
						const cmdParams = toolResult.args || toolResult.params || {};
						const toolId = toolResult.toolCallId || toolResult.id
							|| `tool-${cmdName}-${JSON.stringify(cmdParams)}`;

						// Check if we've already added this tool call to our content
						const existingTool = toolCalls.get(toolId);

						if (existingTool) {
							// Update the existing tool call
							const { toolCall, contentIndex } = existingTool;

							// Only update if status is changing
							if (toolCall.status !== 'completed') {
								toolCall.status = 'completed';
								toolCall.result = toolResult.result;
								updatedContent[contentIndex] = { ...toolCall };
								contentChanged = true;
							}
						} else {
							// First, update the current text content with any pending text
							if (pendingText.length > 0) {
								activeTextContent.text += pendingText;
								pendingText = '';
							}

							// Create a new tool call
							const newToolCall: ToolCall = {
								id: toolId,
								type: 'tool-call',
								tool: cmdName,
								args: cmdParams,
								status: 'running', // Start as running
								result: undefined,
							};

							// Add the tool call to content
							updatedContent.push(newToolCall);

							// Create a new text content for future text
							activeTextContent = {
								type: 'text',
								text: '',
								id: step.response?.id,
							};
							updatedContent.push(activeTextContent);

							contentChanged = true;

							// Register the tool call in our tracking map
							toolCalls.set(toolId, {
								toolCall: newToolCall,
								contentIndex: updatedContent.length - 2, // Index of the tool call
							});

							// If tool already has results, immediately follow with completed version
							if (toolResult.result) {
								// Update to completed in the next event loop
								setTimeout(() => {
									const tool = toolCalls.get(toolId);
									if (tool) {
										tool.toolCall.status = 'completed';
										tool.toolCall.result = toolResult.result;

										// Yield updated content
										this.yieldUpdate(content, steps, toolCalls);
									}
								}, 0);
							}
						}
					}

					// If content changed due to tool calls, yield the update
					if (contentChanged) {
						// Update our content reference
						content.length = 0; // Clear existing content
						content.push(...updatedContent); // Replace with updated content

						this.yieldUpdate(content, steps, toolCalls);
					}
				},
			});

			if (options.abortSignal?.aborted) {
				throw new Error('Aborted');
			}

			if (!result) {
				throw new Error('No response from chat');
			}

			// Stream the text chunks
			for await (const chunk of result.textStream) {
				pendingText += chunk;

				// Update the active text content
				activeTextContent.text += pendingText;
				pendingText = '';

				yield {
					status: { type: 'running' },
					content: [...content],
					metadata: {
						custom: {
							toolCalls: Array.from(toolCalls.values()).map((entry) => entry.toolCall),
						},
						steps,
					},
				};
			}

			// Final state with complete content
			yield {
				status: { type: 'complete', reason: 'stop' },
				content: [...content],
				metadata: {
					custom: {
						toolCalls: Array.from(toolCalls.values()).map((entry) => entry.toolCall),
					},
					steps,
				},
			};
		} catch (error) {
			console.error('Error in run:', error);
			yield {
				status: { type: 'incomplete', reason: 'error' },
				content: [{ type: 'text', text: '' }],
				metadata: {
					custom: {},
					steps: [],
					unstable_data: [{ error: error instanceof Error ? error.message : String(error) }],
				},
			};
		}
	}

	// Helper method to yield updates consistently
	private yieldUpdate(
		content: any[],
		steps: any[],
		toolCalls: Map<string, { toolCall: ToolCall, contentIndex: number }>,
	): void {
		// This method doesn't actually yield since it's not a generator
		// It's meant to be used for the pattern of creating consistent update objects
		// The caller will need to handle the actual yielding

	}
}

// Runtime options types
interface LocalRuntimeOptions {
	initialThreads?: Record<string, any>;
	initialMessages?: ThreadMessage[];
	onThreadCreate?: (threadId: string) => void;
	onThreadSwitch?: (threadId: string) => void;
	onThreadRemove?: (threadId: string) => void;
}

interface AIRuntimeOptions extends LocalRuntimeOptions {
	system?: string;
	tools?: Record<string, any>;
}

export function useAIRuntime(options: AIRuntimeOptions = {}): AssistantRuntime {
	const ai = useAI();
	const [adapter, setAdapter] = useState<AIRuntimeAdapter | null>(null);

	useEffect(() => {
		setAdapter(new AIRuntimeAdapter(ai));
	}, [ai.ctx]);

	// Split options into local runtime options and other options
	const {
		system,
		tools,
		initialThreads,
		initialMessages,
		onThreadCreate,
		onThreadSwitch,
		onThreadRemove,
		...otherOptions
	} = options;

	const localRuntimeOptions = {
		initialThreads,
		initialMessages,
		onThreadCreate,
		onThreadSwitch,
		onThreadRemove,
	};

	// Use the local runtime with our adapter
	return useLocalRuntime(adapter, localRuntimeOptions);
}
