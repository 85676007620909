import { faBookOpen } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	ButtonProps,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export interface ViewStoriesFilters {
	size?: ButtonProps['size'];
	variant?: ButtonProps['variant'];
	className?: string;
	hideTitle?: boolean;
	onClick?: () => void;
	publication?: string;
	content?: string;
	category?: string;
	product?: string;
}

export default function ViewStoriesButton({
	size, variant, className, hideTitle, onClick, ...filters
}: ViewStoriesFilters) {
	const navigate = useNavigate();

	const routeToStories = () => {
		if (filters.publication && !filters.content && !filters.category) {
			navigate(`/content/${filters.publication}/stories`);
			return;
		}

		const filterQueryParameters = new URLSearchParams();
		if (filters.content) {
			filterQueryParameters.set('content', filters.content);
		}

		if (filters.category) {
			filterQueryParameters.set('categories', filters.category);
		}

		if (filters.product) {
			filterQueryParameters.set('products', filters.product);
		}
		navigate(`/stories?${filterQueryParameters.toString()}`);
		onClick?.();
	};

	return (
		<Button size={size} variant={variant || 'outline-secondary'} onClick={routeToStories} className={className}>
			{hideTitle ? <OverlayTrigger placement="top" overlay={<Tooltip>View Stories</Tooltip>}>
				<FontAwesomeIcon icon={faBookOpen} />
			</OverlayTrigger> : (
				<>
					<FontAwesomeIcon icon={faBookOpen} />
					{' View Stories'}
				</>
			)}
		</Button>
	);
}
