import { Lambda } from '@aws-sdk/client-lambda';
import { UserError } from '@newstex/core/errors';
import { DetectFeedResponse } from '@newstex/types/detect-feed';
import { isErrorType } from '@newstex/types/error';
import { AWSLambdaFunctions } from '@newstex/types/responses/info';
import { sleep } from '@newstex/utils/sleep';
import omit from 'object.omit';
import React, {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { toast } from 'react-toastify';

import { useUserInfo } from './user-info';
import type { UserInfo } from './user-info';

interface InvokeLambdaOptions {
	functionName: AWSLambdaFunctions;
	payload: any;
	attempt?: number;
	abortController?: AbortController;
}

interface DetectFeedRequestOptions {
	url?: string;
	feed_url?: string;
	classify?: boolean;
}

interface AWSContextType {
	lambdaClient: Lambda | null;
	invokeLambda: (opts: InvokeLambdaOptions) => Promise<any>;
	refreshCredentials: () => Promise<void>;
	detect: (opts: DetectFeedRequestOptions, abortController?: AbortController) => Promise<DetectFeedResponse>;
}

const AWSContext = createContext<AWSContextType | null>(null);

export function AWSProvider({ children }: { children: React.ReactNode }) {
	const userInfo = useUserInfo();
	const [lambdaClient, setLambdaClient] = useState<Lambda | null>(null);
	const lastRefreshRef = useRef<Date | null>(null);

	const setupLambdaClient = (aws: UserInfo['aws']) => {
		if (!aws?.AccessKeyId || !aws?.SecretAccessKey || !aws?.SessionToken) {
			console.error('No AWS credentials found', aws);
			toast.error('No AWS credentials found, please refresh your browser', {
				toastId: 'aws-credentials-not-found',
				theme: 'dark',
				autoClose: false,
				onClick: () => {
					window.location.reload();
				},
			});
			return null;
		}

		const client = new Lambda({
			region: 'us-east-1',
			credentials: {
				accessKeyId: aws.AccessKeyId,
				secretAccessKey: aws.SecretAccessKey,
				sessionToken: aws.SessionToken,
			},
		});
		setLambdaClient(client);
		return client;
	};

	const refreshCredentials = async () => {
		if (!userInfo?.refresh) {
			throw new Error('No refresh function available');
		}

		const now = new Date();
		if (lastRefreshRef.current && now.getTime() - lastRefreshRef.current.getTime() < 1000 * 60) {
			console.trace('Already refreshing AWS credentials', {
				lastRefresh: lastRefreshRef.current,
				diff: now.getTime() - lastRefreshRef.current.getTime(),
			});
			return;
		}

		console.trace('Refreshing AWS credentials', { lastRefresh: lastRefreshRef.current, now });
		toast.warning('AWS credentials expired, refreshing...', {
			toastId: 'aws-credentials-expired',
		});
		lastRefreshRef.current = now;
		const updatedInfo = await userInfo.refresh();

		if (!updatedInfo?.aws) {
			throw new Error('No AWS credentials found after refresh');
		}

		setupLambdaClient(updatedInfo.aws);
		await sleep(1000);
	};

	const invokeLambda = async ({
		functionName,
		payload,
		attempt = 0,
		abortController,
	}: InvokeLambdaOptions): Promise<any> => {
		let client = lambdaClient;
		if (!client) {
			if (!userInfo?.aws) {
				console.trace('FATAL: **** NO AWS CREDENTIALS ****', { userInfo });
				await refreshCredentials();
				throw new Error('No AWS credentials found');
			}
			client = setupLambdaClient(userInfo.aws);
		}

		if (!userInfo?.functions || !userInfo.functions[functionName]) {
			throw new Error(`Invalid Lambda function name: ${functionName}`);
		}

		try {
			const response = await client.invoke({
				FunctionName: userInfo.functions[functionName],
				Payload: JSON.stringify(payload),
			});

			if (abortController?.signal.aborted) {
				console.debug('Aborting invokeLambda');
				return;
			}

			if (response.StatusCode !== 200) {
				console.error('Lambda invocation failed', response);
				throw new Error(`Lambda invocation failed with status ${response.StatusCode}`);
			}

			if (response.FunctionError) {
				console.error('Lambda function error', response);
				if (response.Payload) {
					console.error('Lambda function error payload', response.Payload.transformToString());
				}
				throw new Error(`Lambda function error: ${response.FunctionError}`);
			}

			if (!response.Payload) {
				console.debug('No payload returned from Lambda');
				return null;
			}

			const result = JSON.parse(response.Payload.transformToString());
			return result;
		} catch (error: any) {
			console.error('Error invoking Lambda', attempt, error);
			if (abortController?.signal.aborted) {
				throw error;
			}

			if (String(error).toLowerCase().includes('expired') && attempt < 2) {
				await refreshCredentials();
				console.log('Refreshed AWS credentials, retrying Lambda invocation');
				return invokeLambda({
					functionName, payload, attempt: attempt + 1, abortController,
				});
			}

			if (attempt < 3) {
				return invokeLambda({
					functionName, payload, attempt: attempt + 1, abortController,
				});
			}
			throw error;
		}
	};

	const detect = async (
		req: DetectFeedRequestOptions,
		abortController?: AbortController,
	): Promise<DetectFeedResponse> => {
		if (!req?.url && !req?.feed_url) {
			throw new UserError({
				statusCode: 400,
				code: 'BadRequest',
				message: 'Invalid Request, must provide a feed_url or url',
			});
		}

		const requestObj = { ...req };
		for (const propName of ['url', 'feed_url'] as const) {
			if (requestObj[propName]) {
				if (requestObj[propName]
					&& !requestObj[propName].startsWith('https://')
					&& !requestObj[propName].startsWith('http://')) {
					requestObj[propName] = `https://${requestObj[propName]}`;
				}
			}
		}

		let output: DetectFeedResponse = { ...requestObj };

		if (requestObj.feed_url) {
			if (requestObj.feed_url === 'https://wordpress.com/blog/feed/') {
				throw new UserError({
					statusCode: 400,
					code: 'REDIRECTS_TO_AUTOMATTIC_FEED',
					message: 'Redirects to Automattic Feed',
				});
			}
			const resp = await invokeLambda({ functionName: 'parseFeed', payload: requestObj, abortController });
			if (isErrorType(resp)) {
				console.error('DETECT: parseFeed error', resp);
				return detect(omit(requestObj, ['feed_url']), abortController);
			}
			output = resp;
		} else if (requestObj.url) {
			const resp = await invokeLambda({ functionName: 'parseHTML', payload: requestObj, abortController });
			if (isErrorType(resp)) {
				throw new UserError({
					statusCode: 400,
					code: resp.code || 'DETECTION_FAILED',
					message: resp.message || 'Failed to detect feed',
				});
			}
			output = resp;
		}

		if (requestObj.classify && output.articles?.length) {
			console.debug('Classify Articles', { articles: output.articles.length });
			const promises: Promise<any>[] = [];
			for (let i = 0; i < Math.min(output.articles.length, 5); i++) {
				if (abortController?.signal.aborted) {
					console.debug('Aborting classifyArticles');
					break;
				}
				const article = output.articles[i];
				promises.push(
					invokeLambda({ functionName: 'addCategories',
						payload: {
							$type: 'DetectedArticle',
							...article,
						},
						abortController })
						.then((storyWithCategories) => invokeLambda({ functionName: 'classifyStory', payload: storyWithCategories, abortController }))
						.then((classifiedStory) => {
							output!.articles![i] = classifiedStory;
						}),
				);
			}

			if (abortController?.signal.aborted) {
				console.debug('Aborting classifyArticles after invocation');
				return output;
			}

			await Promise.all(promises);

			if (abortController?.signal.aborted) {
				console.debug('Aborting classifyArticles after promises');
				return output;
			}

			return invokeLambda({ functionName: 'classifyFeed', payload: output, abortController });
		}

		if (output.articles?.length) {
			for (const article of output.articles) {
				if (!article.stats && article.fulltext) {
					article.stats = {
						word_count: article.fulltext.split(' ').length,
						line_count: article.fulltext.split('\n').length,
						character_count: article.fulltext.length,
					};
				}
			}
		}

		return output;
	};

	useEffect(() => {
		if (userInfo?.aws) {
			setupLambdaClient(userInfo.aws);
		}
	}, [userInfo?.aws]);

	return <AWSContext.Provider value={{
		lambdaClient, invokeLambda, refreshCredentials, detect,
	}}>{children}</AWSContext.Provider>;
}

export function useAWS() {
	const context = useContext(AWSContext);
	if (!context) {
		throw new Error('useAWS must be used within AWSProvider');
	}
	return context;
}
