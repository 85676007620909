import { faCopy, faDownload, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HotTable, { HotTableClass } from '@handsontable/react';
import Handsontable from 'handsontable';
import HyperFormula from 'hyperformula';
import { FC, useRef } from 'react';
import {
	Button,
	OverlayTrigger,
	Table,
	Tooltip,
} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { toast } from 'react-toastify';
import remarkGfm from 'remark-gfm';
import { useUserInfo } from '~/providers/user-info';

interface MarkdownTextBlockProps {
	children: string;
	customTableComponent?: (props: any) => React.ReactNode;
	locked?: boolean;
	onUpdateContent?: (newContent: string) => Promise<void>;
}

// Component for copyable code blocks
const CopyableCodeBlock = ({
	node, className, children, ...props
}: any) => {
	const match = /language-(\w+)/.exec(className || '');
	const inline = node?.position?.start?.line === node?.position?.end?.line;

	const handleCopy = () => {
		navigator.clipboard.writeText(String(children).replace(/\n$/, ''));
		toast.success('Copied to clipboard');
	};

	return inline ? (
		<code className={className} {...props}>
			{children}
		</code>
	) : (
		<>
			<Button
				variant="outline-secondary"
				onClick={handleCopy}
				className="float-end"
				size="sm"
			>
				<FontAwesomeIcon icon={faCopy} />
			</Button>
			{ match ? (
				<SyntaxHighlighter
					style={solarizedlight}
					language={match[1]}
					PreTag="div"
					{...props}
				>
					{String(children).replace(/\n$/, '')}
				</SyntaxHighlighter>
			) : (
				<code className={className} {...props}>
					{children}
				</code>
			)}
		</>
	);
};

// Helper function to convert markdown table to data array
function convertToTableData(data: React.ReactNode | React.ReactNode[]): any {
	// Handle single node case
	if (!Array.isArray(data)) {
		return [];
	}

	const tableData: string[][] = [];

	// Process each table section (thead/tbody)
	for (const section of data as any[]) {
		if (!section?.props?.children) {
			continue;
		}

		// Handle rows
		const rows = Array.isArray(section.props.children)
			? section.props.children
			: [section.props.children];

		for (const row of rows) {
			if (!row?.props?.children) {
				continue;
			}

			// Handle cells
			const cells = Array.isArray(row.props.children)
				? row.props.children
				: [row.props.children];

			const rowData: string[] = [];

			for (const cell of cells) {
				// Extract text content from th/td cells
				if (cell?.props?.children) {
					rowData.push(String(cell.props.children));
				}
			}

			if (rowData.length > 0) {
				tableData.push(rowData);
			}
		}
	}

	return tableData;
}

/**
 * Handson Table Block, allows tables to be exported, as well as modified
 */
function EditableTableBlock({ children, saveChanges }: React.PropsWithChildren<{
	saveChanges: (data: any) => void
}>) {
	if (!children) {
		return null;
	}

	if (!Array.isArray(children)) {
		return <Table>{children}</Table>;
	}
	const hotRef = useRef<HotTableClass | null>(null);

	const tableData = convertToTableData(children) || [];
	const data = tableData.slice(1);
	const headers = tableData?.[0]?.map((title: string) => ({
		title,
	}));

	const exportToCSV = () => {
		const hot = hotRef.current?.hotInstance;
		if (hot) {
			const exportPlugin = hot.getPlugin('exportFile');
			exportPlugin.downloadFile('csv', {
				bom: false,
				columnDelimiter: ',',
				columnHeaders: true,
				fileExtension: 'csv',
				filename: `Table-${new Date().toISOString().split('T')[0].split('-').join('')}`,
				mimeType: 'text/csv',
				rowDelimiter: '\r\n',
			});
		}
	};

	return (
		<div className="kb-table">
			<HotTable
				id="kb-hot-table"
				undo
				filters
				dropdownMenu
				formulas={{
					engine: HyperFormula,
				}}
				data={data}
				ref={hotRef}
				colHeaders
				rowHeaders
				columns={headers}
				height="auto"
				licenseKey="non-commercial-and-evaluation"
				stretchH="all"
				autoWrapRow
				autoWrapCol
				persistentState
				minSpareRows={1}
				minSpareCols={0}
				columnSorting
				manualColumnMove
				contextMenu={{
					items: {
						row_above: {
							name: 'Insert row above',
						},
						row_below: {
							name: 'Insert row below',
						},
						separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
						col_left: {
							name: 'Insert column left',
						},
						col_right: {
							name: 'Insert column right',
						},
						remove_col: {
							name: 'Remove column',
						},
						separator2: Handsontable.plugins.ContextMenu.SEPARATOR,
						undo: {
							name: 'Undo',
						},
						redo: {
							name: 'Redo',
						},
					},
				}}
			/>
			<div className="mt-2 table-tools float-end">
				<OverlayTrigger
					trigger={['hover', 'focus']}
					placement="top"
					overlay={<Tooltip>Save Table Changes</Tooltip>}
				>
					<Button
						onClick={() => {
							saveChanges(
								hotRef
									.current?.hotInstance
									?.getData()
									.filter((row: any[]) => row.some((cell: any) => !!cell)),
							);
						}}
						variant="outline-success"
						className="me-2"
					>
						<FontAwesomeIcon icon={faSave} />
					</Button>
				</OverlayTrigger>

				<OverlayTrigger
					trigger={['hover', 'focus']}
					placement="top"
					overlay={<Tooltip>Export Table as CSV</Tooltip>}
				>
					<Button onClick={exportToCSV} variant="outline-secondary" className="me-2">
						<FontAwesomeIcon icon={faDownload} />
					</Button>
				</OverlayTrigger>
			</div>
			<div className="clearfix" />
		</div>);
}

export const MarkdownTextBlock: FC<MarkdownTextBlockProps> = ({
	children,
	customTableComponent,
	locked = false,
	onUpdateContent,
}) => {
	const { hasAuthGroup } = useUserInfo();
	const isAdmin = hasAuthGroup('admin');
	const canEdit = isAdmin && !locked && !!onUpdateContent;

	if (!children) {
		return null;
	}

	// Default table component with editing capabilities if allowed
	const defaultTableComponent = (props: any) => {
		if (canEdit) {
			return <EditableTableBlock {...props} saveChanges={(data: any) => {
				// Create changes for the document and update the table
				const tableMarkdown = data.map((row: any[]) => `|${row.join('|')}|`).join('\r\n');

				// Find the table in the markdown and replace it
				const lines = children.split('\n');
				// Ignore the header row
				const tableStartIndex = props.node.position.start.line + 1;
				const tableEndIndex = props.node.position.end.line;

				if (tableStartIndex >= 0) {
					// Replace table content
					const newContent = [
						...lines.slice(0, tableStartIndex),
						tableMarkdown,
						...lines.slice(tableEndIndex),
					].join('\n');

					// Update the content
					if (onUpdateContent) {
						onUpdateContent(newContent).catch((error) => {
							console.error('Failed to update content:', error);
							toast.error('Failed to update table');
						});
					}
				}
			}} />;
		}
		return <Table {...props} />;
	};

	return (
		<ReactMarkdown
			remarkPlugins={[remarkGfm]}
			components={{
				code: CopyableCodeBlock,
				table: customTableComponent || defaultTableComponent,
			}}
		>
			{children}
		</ReactMarkdown>
	);
};
