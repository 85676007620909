import { arrayEqual } from '@newstex/core/array-utils';
import { STATUSES } from '@newstex/core/enums';
import { Distributor } from '@newstex/types/distributor';
import { useEffect, useRef, useState } from 'react';
import {
	Alert,
	Button,
	Container,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

import EditableMultiValueField from '../multi-value';
import { ProductEditor } from '../products';
import EditableSelectField from '../select';
import EditableTextField from '../text';

export interface EditDistributorModalProps {
	item?: Distributor,
	show: boolean,
	onClose: (saved: boolean) => void,
}

export default function EditDistributorModal({
	item,
	show,
	onClose,
}: EditDistributorModalProps) {
	const api = useAPI();
	const commentRef = useRef<HTMLInputElement>(null);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState<any>();
	const [updates, setUpdates] = useState<Partial<Distributor>>({});

	// Reset updates when modal is opened with a new item
	useEffect(() => {
		if (show) {
			setUpdates({});
		}
	}, [show, item?.$id]);

	const doUpdate = async () => {
		if (item) {
			setError(null);

			if (!commentRef.current?.value) {
				commentRef.current?.focus();
				commentRef.current?.select();
				commentRef.current?.setCustomValidity('Please enter a comment');
				return;
			}
			setSaving(true);
			try {
				await api.updateItem<Distributor>(item, {
					$comment: commentRef.current?.value,
					...updates,
				});
				onClose(true);
			} catch (err) {
				console.error(err);
				setError(err);
			}
			setTimeout(() => {
				setSaving(false);
				onClose(true);
			}, 2000);
		}
	};

	return (
		<Modal show={item && show} onHide={() => onClose(false)} size="xl" fullscreen="xl-down">
			<Modal.Header closeButton>
				<Modal.Title>Edit Distributor <i>{item?.name}</i></Modal.Title>
				{error && <Alert variant="danger">{error.message || String(error)}</Alert>}
			</Modal.Header>
			<Form onSubmit={(e) => {
				e.preventDefault();
				doUpdate();
			}}>
				<Modal.Body>
					<Container fluid className={saving ? 'opacity-50' : ''}>
						<Row className="mb-3">
							<EditableTextField
								typeName="Distributor"
								propKey="name"
								item={item}
								updates={updates}
								setUpdates={setUpdates}
							/>
						</Row>
						<Row className="mb-3">
							<EditableTextField
								typeName="Distributor"
								propKey="code"
								item={item}
								updates={updates}
								setUpdates={setUpdates}
							/>
						</Row>
						<Row className="mb-3">
							<EditableTextField
								typeName="Distributor"
								propKey="recipient_name"
								item={item}
								updates={updates}
								setUpdates={setUpdates}
							/>
						</Row>
						<Row className="mb-3">
							<EditableSelectField
								typeName="Distributor"
								propKey="status"
								item={item}
								updates={updates}
								setUpdates={setUpdates}
								options={STATUSES}
							/>
						</Row>
						<Row className="mb-3">
							<EditableMultiValueField
								typeName="Distributor"
								propKey="alt_names"
								item={item}
								updates={updates}
								setUpdates={setUpdates}
								trimValues
							/>
						</Row>
						<Row className="mb-3">
							<ProductEditor
								products={item?.products}
								onChange={(products) => {
									const productIds = products.map((p) => p.$id);
									if (!arrayEqual(productIds, item?.products || [], true)) {
										setUpdates({
											...updates,
											products: productIds,
										});
									}
								}}
							/>
						</Row>
						<Row className="mt-3 pt-3" style={{ borderTop: 'dashed 1px #ccc' }}>
							<Form.Group>
								<Form.Label>Comment</Form.Label>
								<Form.Control type="text" name="$comment" ref={commentRef} required placeholder="Required: Enter a comment" />
							</Form.Group>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => onClose(false)} disabled={saving}>
						Cancel
					</Button>
					<Button variant="primary" onClick={() => {
						doUpdate();
					}} disabled={saving || !updates || Object.keys(updates).length === 0}>
						Submit
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}
