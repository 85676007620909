import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { QualificationResult } from '@newstex/ai/agents/qualification-agent';
import type { Publication } from '@newstex/types';
import { useState } from 'react';
import {
	Alert,
	Badge,
	Button,
	Card,
	Col,
	Row,
	Spinner,
} from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

import { RequirePermissions } from './require-permissions';

interface Props {
	/**
	 * The publication to qualify
	 */
	publication: Publication;
}

function getScoreClassName(score: number) {
	if (score > 2) return 'success';
	if (score > 0) return 'warning';
	return 'danger';
}

export function AIQualificationResults({ publication }: Props) {
	const api = useAPI();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string>();
	const [results, setResults] = useState<QualificationResult>();

	const generateResults = async (refresh = false) => {
		if (!publication.$id) {
			setError('Publication ID is required');
			return;
		}

		setLoading(true);
		setError(undefined);
		try {
			const qualifyResult = await api.fetchWithAuth(`resources/Publication/${publication.$id}/qualify`, {
				cache: refresh ? 'no-store' : 'force-cache',
			});
			setResults(qualifyResult);
		} catch (err) {
			setError(err instanceof Error ? err.message : 'An error occurred while generating qualification results');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			{!results && !loading && (
				<RequirePermissions permissions={['admin', 'premium']}>
					<div className="text-center p-4">
						<Button
							variant="outline-success"
							onClick={() => generateResults(false)}
							disabled={loading}
						>
							<FontAwesomeIcon icon={faRobot} className="me-2" />
							Generate AI Qualification Results
						</Button>
					</div>
				</RequirePermissions>
			)}

			{loading && (
				<div className="text-center p-4">
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
					<p className="mt-2">Generating qualification results...</p>
					<p className="mt-2 text-muted">This may take a few minutes.</p>
				</div>
			)}

			{error && (
				<Alert variant="danger">
					{error}
				</Alert>
			)}

			{results && (
				<div>
					<Row>
						<Col md={8}>
							<Card>
								<Card.Header>
									<h5 className="mb-0">Client Qualification Scores</h5>
								</Card.Header>
								<Card.Body>
									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th>Client</th>
													<th>Score</th>
													<th>Reason</th>
												</tr>
											</thead>
											<tbody>
												{Object.entries(results.scores)
													.sort(([, a], [, b]) => b.score - a.score)
													.map(([client, score]: [string, QualificationResult['scores'][string]]) => (
														<tr key={client}>
															<td>{client}</td>
															<td>
																<Badge bg={getScoreClassName(score.score)}>
																	{score.score}
																</Badge>
															</td>
															<td>{score.reason}</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</Card.Body>
							</Card>
						</Col>
						<Col md={4}>
							<Card>
								<Card.Header>
									<h5 className="mb-0">Matched Criteria</h5>
								</Card.Header>
								<Card.Body>
									<ul className="list-unstyled mb-0">
										{results.matched_criteria.map((criteria: string) => (
											<li key={criteria} className="mb-2">
												<FontAwesomeIcon icon={faRobot} className="me-2 text-success" />
												{criteria}
											</li>
										))}
									</ul>
								</Card.Body>
							</Card>

							<Card className="mt-3">
								<Card.Header>
									<h5 className="mb-0">Improvement Recommendations</h5>
								</Card.Header>
								<Card.Body>
									<ul className="list-unstyled mb-0">
										{results.improvements.map((improvement: string) => (
											<li key={improvement} className="mb-2">
												<FontAwesomeIcon icon={faRobot} className="me-2 text-primary" />
												{improvement}
											</li>
										))}
									</ul>
								</Card.Body>
							</Card>

							<RequirePermissions permissions={['admin']}>
								<div className="text-center mt-3">
									<Button
										variant="outline-primary"
										size="sm"
										onClick={() => generateResults(true)}
										disabled={loading}
									>
										<FontAwesomeIcon icon={faRobot} className="me-2" />
										Regenerate Results
									</Button>
								</div>
							</RequirePermissions>
						</Col>
					</Row>
				</div>
			)}
		</div>
	);
}
