// content/:id/stories
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Content,
	Publication,
	Publisher,
	isPublication,
	isPublisher,
} from '@newstex/types/content';
import { Results } from '@newstex/types/results';
import { Story } from '@newstex/types/story';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import {
	Card,
	Col,
	Container,
	Form,
	Row,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { CreateStoryButton, NEWSTEX_PARENT_ID } from '~/components/create-story-button';
import { ExportStoriesButton } from '~/components/export-stories-button';
import { HubSpotLink } from '~/components/hubspot-link';
import LoadingSpinner from '~/components/LoadingSpinner';
import { ParentBreadcrumb } from '~/components/parent-breadcrumb';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { StatusBadge } from '~/components/status-badge';
import StoryActions from '~/components/story/story-actions';
import { StoryStats } from '~/components/story/story-stats';
import { StoriesTable } from '~/components/tables/stories-table';
import { useAPI } from '~/providers/api-provider';

export function ContentStoryPage() {
	const params = useParams();
	const api = useAPI();
	const [stories, setStories] = useState<Story[]>();
	const [pub, setPub] = useState<Content>();
	const [loading, setLoading] = useState(true);
	const [searchText, setSearchText] = useState<string>('');
	const [children, setChildren] = useState<Content[]>([]);
	const columnHelper = createColumnHelper<Story & { id: string }>();

	const columns = useMemo(() => [
		columnHelper.accessor('headline', {
			header: 'Headline',
			enableSorting: false,
			cell: ({ getValue, row: { original }}) => {
				return (
					<div className="d-flex align-items-center">
						<div className="pt-1">
							<Link to={`/stories/${original.__id__}`}>
								<strong>{getValue()}</strong>
							</Link>
							<br />
							<span className="text-muted text-sm">
								<PropertyDisplayValue
									propName="permalink"
									propValue={original.permalink}
									className="text-secondary"
								/>
							</span>
						</div>
					</div>
				);
			},
		}),
		columnHelper.accessor('external_id', {
			header: 'External ID',
			enableSorting: false,
			cell: ({ getValue }) => {
				return <span className="text-muted">{getValue()}</span>;
			},
		}),
		columnHelper.accessor('permalink', {
			header: 'Permalink',
			enableSorting: false,
			cell: ({ getValue }) => {
				return <span className="text-muted">{getValue()}</span>;
			},
		}),
		columnHelper.accessor('source', {
			header: 'Source',
			enableSorting: true,
		}),
		columnHelper.accessor('date', {
			header: 'Date',
			enableSorting: true,
			cell: ({ getValue }) => {
				return (
					<div className="d-flex align-items-center justify-content-between">
						<span className="me-3">
							<PropertyDisplayValue
								propName="date"
								propValue={getValue()}
							/>
						</span>
						<FontAwesomeIcon icon={faSearch} className="me-2 opacity-5" />
					</div>
				);
			},
		}),
		columnHelper.accessor('received_at', {
			header: 'Received',
			enableSorting: true,
			cell: ({ getValue }) => {
				return (
					<div className="d-flex align-items-center justify-content-between">
						<span className="me-3">
							<PropertyDisplayValue
								propName="received_at"
								propValue={getValue()}
							/>
						</span>
					</div>
				);
			},
		}),
		columnHelper.accessor('categories', {
			header: 'Categories',
			enableSorting: false,
			cell: ({ getValue }) => {
				return (
					<div className="d-flex align-items-center justify-content-between">
						<span className="me-3">
							<PropertyDisplayValue
								propName="categories"
								propValue={getValue()}
							/>
						</span>
					</div>
				);
			},
		}),
		columnHelper.display({
			id: 'actions',
			cell: ({ row: { original }}) => {
				return <StoryActions story={original} />;
			},
		}),
	], [columnHelper]);

	useEffect(() => {
		let cancelled = false;
		const addStoriesForPublication = async (id: string, nextToken?: string) => {
			if (cancelled) {
				return;
			}
			const searchParams = new URLSearchParams({
				publication: id,
				sort: 'desc',
			});

			if (nextToken) {
				searchParams.set('nextToken', nextToken);
			}
			const resp = await api.fetchWithAuth(`resources/Story?${searchParams}`);
			if (cancelled) {
				return;
			}
			setStories((prev) => {
				if (prev && !cancelled) {
					return [...prev, ...resp.items].sort((a, b) => {
						if (a.received_at && b.received_at) {
							return b.received_at.localeCompare(a.received_at);
						}
						return 0;
					}).slice(0, 100);
				}
				return resp.items;
			});
		};

		const fetchData = async () => {
			if (cancelled) {
				return;
			}
			setLoading(true);
			setStories([]);
			if (params.id && !cancelled) {
				let currentPub = pub;
				// Load content and stories
				if (!pub || pub.$id !== params.id) {
					const pubResp = await api.fetchWithAuth<Results<Publication>>(`resources/Publication/${params.id}`);
					if (cancelled) {
						return;
					}

					if (pubResp.items?.[0]) {
						currentPub = pubResp.items?.[0];
						setPub(currentPub);
					} else {
						// Fall back to looking this up as a Publisher
						const publisherResp = await api.fetchWithAuth<Results<Publisher>>(`resources/Publisher/${params.id}`);
						if (publisherResp.items?.[0]) {
							currentPub = publisherResp.items?.[0];
							setPub(currentPub);
						}
					}
				}

				if (currentPub && isPublisher(currentPub)) {
					const childrenResp = await api.fetchWithAuth<Results<Publication>>(`resources/Publication?parent=${params.id}`);
					if (cancelled) {
						return;
					}
					setChildren(childrenResp?.items || []);
					for (const child of childrenResp?.items || []) {
						if (child.$id) {
							await addStoriesForPublication(child.$id);
						}

						if (cancelled) {
							return;
						}
					}
				} else {
					await addStoriesForPublication(params.id);
					if (cancelled) {
						return;
					}
				}
			}
			setLoading(false);
		};
		fetchData();
		return () => {
			cancelled = true;
			setStories([]);
			setLoading(false);
		};
	}, [params]);

	return (
		<Container fluid>
			<Row>
				<div className="title-wrapper pt-30">
					<div className="row align-items-center">
						<div className="col-md-6">
							<div className="title">
								<h2>
									{pub?.name}
									&nbsp;<StatusBadge status={pub?.status} />
									&nbsp;<HubSpotLink item={pub} size={28} />
								</h2>
								<h6 className="text-muted">
									{pub?.$type}
									{pub?.newstex_id && (
										<>&nbsp; &mdash; &nbsp; <PropertyDisplayValue propName="newstex_id" propValue={pub?.newstex_id} /></>
									)}
								</h6>

							</div>
						</div>
						<div className="col-md-6">
							<div className="breadcrumb-wrapper">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<ParentBreadcrumb obj={pub} />
										<li className="breadcrumb-item active">
											<Link to={`/${pub?.$type?.toLowerCase()}s/${pub?.$id}`}>
												{pub?.name}
												{pub?.newstex_id && (
													<>
														{' '}
														[{pub?.newstex_id}]
													</>
												)}
											</Link>
										</li>
										<li className="breadcrumb-item active">
											<a>Stories</a>
										</li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</Row>
			<hr />
			<Row>
				<Col md={12}>
					<div className="float-end">
						{isPublication(pub) && (<>
							<ExportStoriesButton content={pub} className="ms-2" />
						</>)}

						{pub && isPublication(pub) && pub.parent === NEWSTEX_PARENT_ID && (
							<>
								<CreateStoryButton publication={pub} className="ms-2" />
							</>
						)}
					</div>
					{ pub && isPublication(pub) && <StoryStats content={pub} />}
					{ pub && isPublisher(pub) && children && <StoryStats contents={children} />}
				</Col>
			</Row>
			<Row>
				<Card className="card-table mb-4">
					<Card.Header>
						<Form className="d-inline-block" style={{
							width: '50%',
							marginBottom: '1rem',
						}}>
							<Form.Control
								type="text"
								placeholder={'Search Headline, external ID, or permalink'}
								onChange={(e) => setSearchText(e.target.value)}
							/>
						</Form>

						<div className="float-end">
							<Link
								className="btn btn-outline-secondary btn-sm"
								to={`/stories?${new URLSearchParams({
									content: pub?.newstex_id || '',
								}).toString()}`}
							>
								<FontAwesomeIcon
									icon={faSearch}
									className="me-2 opacity-5"
								/>
								View in Search
							</Link>
						</div>
					</Card.Header>
					<Card.Body hidden={!stories?.length}>
						<StoriesTable
							hideColumns={['publication']}
							searchText={searchText}
							fallbackSearch={async (q) => {
								console.log('fallbackSearch', q);
								let resp = await api.fetchWithAuth(`resources/Story?${new URLSearchParams({
									external_id: q,
									publication: pub?.$id || '',
								}).toString()}`);
								if (!resp.items?.length) {
									console.log('fallbackSearch permalink', resp, q);
									resp = await api.fetchWithAuth(`resources/Story?${new URLSearchParams({
										permalink: q,
										publication: pub?.$id || '',
									}).toString()}`);
								}
								console.log('fallbackSearch resp', resp);
								return resp?.items || [];
							}}
							items={(stories || []).map((story) => ({
								...story,
								id: story.__id__,
								_score: 1,
								Content: {
									id: pub?.$id || '',
									name: pub?.name || '',
									newstex_id: pub?.newstex_id || '',
								},
							}))}
						/>
					</Card.Body>
					<Card.Body hidden={!loading}>
						<LoadingSpinner loading={loading} />
					</Card.Body>
				</Card>
			</Row>
		</Container>
	);
}
