import {
	Card,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const Docs = () => {
	return (
		<Container fluid>
			<Row className="text-center">
				<Col>
					<Card className="h-100 hover-animate card">
						<Card.Header>
							<h4>Developer Documentation</h4>
						</Card.Header>
						<Card.Body>
							Information about the codebase, how to develop on the system, and how to add new features.
						</Card.Body>
						<Link className="stretched-link" to="/docs/dev" />
					</Card>
				</Col>
				<Col>
					<Card className="h-100 hover-animate card">
						<Card.Header>
							<h4>Knowledge Base</h4>
						</Card.Header>
						<Card.Body>
							General Knowledge Base. Information about the system and how to use it.
						</Card.Body>
						<Link className="stretched-link" to="/docs/kb" />
					</Card>
				</Col>
			</Row>
		</Container>
	);
};
