/**
 * Edit Report Button
 */
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Report } from '@newstex/types/report';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

import EditReportModal from '../modals/edit-report-modal';

interface EditReportButtonProps {
	report: Report;
	onSave?: () => void;
	variant?: string;
	size?: 'sm' | 'lg';
}

export default function EditReportButton({
	report,
	onSave,
	variant = 'outline-primary',
	size,
}: EditReportButtonProps) {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button
				variant={variant}
				size={size}
				onClick={() => setShowModal(true)}
			>
				<FontAwesomeIcon icon={faPencilAlt} /> Edit
			</Button>

			<EditReportModal
				item={report}
				show={showModal}
				onClose={(saved) => {
					setShowModal(false);
					if (saved && onSave) {
						onSave();
					}
				}}
			/>
		</>
	);
}
