/**
 * Text Edit Field
 */
import { InferType, TypeNameToTypeMap } from '@newstex/types/infer-types';
import { Col, Form } from 'react-bootstrap';

import PropertyDisplayName from '../property-display-name';

export interface EditTextFieldProps<T extends keyof TypeNameToTypeMap> {
	typeName: T;
	propKey: keyof InferType<T>;
	placeholder?: string;
	item?: InferType<T>;
	updates?: Partial<InferType<T>>;
	setUpdates?: (updates: Partial<InferType<T>>) => void;
	multiLine?: boolean;
	readOnly?: boolean;
	showControlOnly?: boolean;
	required?: boolean;
}

export default function EditableTextField<T extends keyof TypeNameToTypeMap>({
	typeName,
	propKey,
	placeholder,
	multiLine,
	item,
	updates,
	setUpdates,
	readOnly,
	showControlOnly,
	required,
}: EditTextFieldProps<T>) {
	if (showControlOnly) {
		return (
			<Form.Control
				{...(multiLine ? {
					as: 'textarea',
					rows: Math.max(10, String(updates?.[propKey] || item?.[propKey] || '').split('\n').length),
				} : {})}
				name={String(propKey)}
				{...(updates ? {
					value: String(updates[propKey] || item?.[propKey] || ''),
				} : {})}
				placeholder={placeholder}
				disabled={readOnly}
				readOnly={readOnly}
				required={required}
			/>
		);
	}
	return (
		<>
			<Col md={3} className="text-end fw-bold">
				<Form.Label><PropertyDisplayName propKey={propKey} typeName={typeName} /></Form.Label>
			</Col>
			<Col md={9}>
				<Form.Control
					{...(multiLine ? {
						as: 'textarea',
						rows: Math.max(10, String(updates?.[propKey] || item?.[propKey] || '').split('\n').length),
					} : {})}
					name={String(propKey)}
					{...(updates ? {
						value: String(updates[propKey] || item?.[propKey] || ''),
					} : {})}
					placeholder={placeholder}
					onChange={(e) => {
						if (!readOnly && setUpdates) {
							setUpdates({
								...updates,
								[propKey]: e.target.value,
							});
						}
					}}
					disabled={readOnly}
					readOnly={readOnly}
					required={required}
				/>
			</Col>
		</>
	);
}
