import type { SearchHit } from '@newstex/search/client';
import type { Distributor } from '@newstex/types/distributor';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';

import DataTable, { DataTableMethods } from '../data-table';
import { LoadMoreButton } from '../load-more-button';
import { PropertyDisplayValue } from '../property-display-value';

export function DistributorsTable(
	{
		items,
		searchText,
		hasMore,
		onLoadMore,
	}: {
		items: SearchHit<Distributor>[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void,
	},
) {
	const distributors = items;
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<Distributor>();
	const columns = useMemo(() => [
		columnHelper.accessor('name', {
			header: 'Name',
			enableSorting: !hasMore,
			cell: ({ getValue, row: { original }}) => {
				return <>
					<Link to={`/distributors/${original.$id}`}>
						<PropertyDisplayValue propName="name" propValue={getValue()} />
					</Link>
				</>;
			},
		}),
		columnHelper.accessor('products', {
			header: 'Products',
			enableSorting: true,
			cell: ({ getValue }) => {
				return <>
					<PropertyDisplayValue propName="products" propValue={getValue()} />
				</>;
			},
		}),
		columnHelper.accessor('deliveries', {
			header: 'Deliveries',
			enableSorting: true,
			cell: ({ getValue }) => {
				return <>
					<PropertyDisplayValue propName="deliveries" propValue={getValue()} />
				</>;
			},
		}),
	], [distributors]);

	return (<>
		<DataTable
			columns={columns}
			items={distributors || []}
			ref={dataTableRef}
			searchText={searchText}
			tableRowClassNameHelper={({ original }: { original: Distributor }) => {
				return '';
			}}
			nameAsc
			striped
			sortable
		/>
		<LoadMoreButton show={hasMore} onClick={onLoadMore} />
	</>);
}
