import type { SearchIndexName } from '@newstex/search';
import type { SearchParams } from 'typesense/lib/Typesense/Documents';
import { z } from 'zod';

import { BaseObjectSchema, isBaseObjectType } from './base';
import { UnixTimestampSchema } from './dates';

export const AlertStatusSchema = z.enum(['OK', 'WARNING', 'CRITICAL', 'NO_DATA', 'DISABLED']);

export const AlertStatus = {
	OK: 1,
	WARNING: 2,
	CRITICAL: 3,
	NO_DATA: 4,
	DISABLED: -1,
} as const;

export type AlertStatusValue = typeof AlertStatus[keyof typeof AlertStatus];

export const AlertSchema = z.preprocess(
	(data: any) => {
		// Handle both id and $id fields
		if (data?.id && !data.$id) {
			return { ...data, $id: data.id };
		}
		return data;
	},
	BaseObjectSchema.extend({
		$type: z.literal('Alert'),
		$id: z.string().optional(),
		description: z.string().optional(),
		status: z.nativeEnum(AlertStatus).optional(),
		collection: z.string() as z.ZodType<SearchIndexName>,
		search: z.any() as z.ZodType<SearchParams>,
		schedule: z.string(),
		expected: z.object({
			min: z.number().optional(),
			max: z.number().optional(),
			diff: z.number().optional(),
		}),
		check_field: z.string().optional(),
		last_run: UnixTimestampSchema.optional(),
		last_values: z.array(z.object({
			value: z.number(),
			timestamp: UnixTimestampSchema,
		})).optional(),
		emails: z.array(z.string().email()).optional(),
	}),
);

// Export original types using z.infer
export type Alert = z.infer<typeof AlertSchema>;

export function isAlert(obj: any, typeHint?: string): obj is Alert {
	return isBaseObjectType(obj) && (obj.$type === 'Alert' || typeHint === 'Alert');
}

// ... existing code ...
export function getAlertStatusLabel(status: Alert['status']): string {
	// Simple lookup using the reverse mapping
	return Object.entries(AlertStatus)
		.find(([_, value]) => value === Number(status) || value === status)?.[0] || 'Unknown';
}
