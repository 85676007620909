/**
 * Edit Button
 */
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAlert } from '@newstex/types/alert';
import { TypedObject } from '@newstex/types/base';
import { isCategory } from '@newstex/types/category';
import { isContent } from '@newstex/types/content';
import { isDelivery } from '@newstex/types/delivery';
import { isDistributor } from '@newstex/types/distributor';
import { isFeed } from '@newstex/types/feed';
import { isProduct } from '@newstex/types/product';
import { isKnowledgeBase } from '@newstex/types/rag';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useUserInfo } from '~/providers/user-info';

import { EditAlertModal } from './editors/modals/edit-alert-modal';
import EditCategoryModal from './editors/modals/edit-category-modal';
import EditContentModal from './editors/modals/edit-content-modal';
import EditDeliveryModal from './editors/modals/edit-delivery-modal';
import EditDistributorModal from './editors/modals/edit-distributor-modal';
import EditFeedModal from './editors/modals/edit-feed-modal';
import EditRAGModal from './editors/modals/edit-kb-modal';
import EditProductModal from './editors/modals/edit-product-modal';

export interface EditButtonProps<T extends TypedObject> {
	item?: T,
	size?: 'sm' | 'lg';
	typeHint?: string;
	refreshHandler: (item: T) => void;
}

export default function EditButton<T extends TypedObject>({
	item,
	size,
	typeHint,
	refreshHandler,
}: EditButtonProps<T>) {
	const userInfo = useUserInfo();
	if (!userInfo?.auth_groups?.includes('admin')) {
		return null;
	}
	const [showEditModal, setShowEditModal] = useState(false);

	const onClose = (saved: boolean) => {
		setShowEditModal(false);
		if (saved && item) {
			refreshHandler(item);
		}
	};
	console.log('Edit', { item, typeHint });

	return (
		<>
			{item && <>
				<Button size={size} variant="outline-secondary" onClick={() => setShowEditModal(true)}>
					<FontAwesomeIcon icon={faEdit} /> Edit
				</Button>
				{ isFeed(item, typeHint) && <EditFeedModal item={item} show={showEditModal} onClose={onClose} />}
				{ isCategory(item, typeHint) && <EditCategoryModal item={item} show={showEditModal} onClose={onClose} />}
				{ isContent(item, typeHint) && <EditContentModal item={item} show={showEditModal} onClose={onClose} />}
				{ isDelivery(item, typeHint) && <EditDeliveryModal item={item} show={showEditModal} onClose={onClose} />}
				{ isKnowledgeBase(item, typeHint) && <EditRAGModal item={item} show={showEditModal} onClose={onClose} />}
				{ isProduct(item, typeHint) && <EditProductModal item={item} show={showEditModal} onClose={onClose} />}
				{ isAlert(item, typeHint) && <EditAlertModal item={item} show={showEditModal} onClose={onClose} />}
				{ isDistributor(item, typeHint) && <EditDistributorModal item={item} show={showEditModal} onClose={onClose} />}
			</>
			}
		</>
	);
}
