import { type Delivery, STORY_TEMPLATES } from '@newstex/types/delivery';
import { CreateDeliveryButton } from '~/components/create-delivery-button';
import { SearchFacetConfig, SearchWithFacets } from '~/components/search-with-facets';
import { DeliveriesTable } from '~/components/tables/deliveries-table';

export const DeliveryFacetConfig: SearchFacetConfig[] = [
	{
		attribute: 'status',
		title: 'Status',
		options: [
			{ label: 'All', value: '' },
			{ label: 'Active', value: 'Active' },
			{ label: 'Inactive', value: 'Inactive' },
			{ label: 'Standby', value: 'Standby' },
			{ label: 'Archived', value: 'Archived' },
		],
	},
	{
		attribute: 'type',
		title: 'Type',
		options: [
			{ label: 'All', value: '' },
			{ label: 'SFTP', value: 'SFTP' },
			{ label: 'HTTP', value: 'HTTP' },
			{ label: 'S3', value: 'S3' },
			{ label: 'FTP', value: 'FTP' },
		],
	},
	{
		attribute: 'story_template',
		title: 'Format',
		options: [
			{ label: 'All', value: '' },
			...STORY_TEMPLATES.map((template) => ({ label: template?.toUpperCase(), value: template })),
		],
	},
	/*
	{
		attribute: 'distributor',
		title: 'Distributor',
	},
	{
		attribute: 'products',
		title: 'Products',
	},
	*/
];

export const DeliverySearchPage = () => {
	const fallbackSearch = async (q: string): Promise<Delivery[]> => {
		console.log('Fallback Search', q);
		return [];
	};

	return (
		<SearchWithFacets<Delivery>
			indexName="Delivery"
			title="Deliveries"
			info="Deliveries are the connections between Newstex and a distributor's system."
			queryBy={['name', 'username', 'host', 'type']}
			resultsTable={DeliveriesTable}
			fallbackSearch={fallbackSearch}
			facets={DeliveryFacetConfig}
			actions={<CreateDeliveryButton />}
		/>
	);
};
