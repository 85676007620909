import { faLock } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserGroup } from '@newstex/types/user';
import { title as titleCase } from 'case';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SearchFacetConfig, SearchWithFacets } from '~/components/search-with-facets';
import { UsersTable } from '~/components/tables/users-table';
import { getDescriptionForAuthGroup, isAdminGroup } from '~/lib/auth-groups';

const UserFacetConfig: SearchFacetConfig[] = [
	{
		attribute: 'auth_groups',
		title: <OverlayTrigger placement="right" overlay={<Tooltip>
			Auth Groups provide permissions to the user.
			<div className="mt-2">Users may have <b>multiple</b> auth groups.</div>
		</Tooltip>}>
			<span>Auth Groups</span>
		</OverlayTrigger>,
		displayValue: (value) => {
			if (value) {
				return <OverlayTrigger placement="right" overlay={<Tooltip>
					{isAdminGroup(value as UserGroup) && <h6 className="text-danger">
						This group is restricted to Newstex internal users.
					</h6>}
					<span>{getDescriptionForAuthGroup(value as UserGroup)}</span>
				</Tooltip>}>
					<span>
						{titleCase(value)}
						{isAdminGroup(value as UserGroup) && <FontAwesomeIcon className="ms-2 text-danger" icon={faLock} />}
					</span>
				</OverlayTrigger>;
			}
			return value;
		},
	},
	{
		attribute: 'role',
		title: <OverlayTrigger placement="right" overlay={<Tooltip>
			Role is an informational field that describes the role of the user in the company.
			<div className="mt-2">Users may only belong to <b>one</b> role.</div>
		</Tooltip>}>
			<span>Role</span>
		</OverlayTrigger>,
		displayValue: (value) => {
			if (value) {
				return titleCase(value);
			}
			return value;
		},
	},
	{
		attribute: 'domain',
		title: 'Domain',
	},
	{
		attribute: 'last_login',
		title: 'Last Login',
		type: 'Date',
	},
	{
		attribute: 'archived',
		title: 'Archived',
		options: [
			{ value: 'false', label: 'No', default: true },
			{ value: 'true', label: 'Yes' },
		],
	},
];

export const UserManagementPage = () => {
	return (
		<SearchWithFacets
			indexName="User"
			title="Users"
			info="Manage user permissions and roles."
			queryBy={['email', 'auth_groups']}
			defaultSort="last_login:desc"
			facets={UserFacetConfig}
			resultsTable={UsersTable}
		/>
	);
};
