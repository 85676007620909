export { bedrock, createAmazonBedrock } from '@ai-sdk/amazon-bedrock';

export const ALL_MODELS = {
	'Claude 3.7 Sonnet': 'arn:aws:bedrock:us-east-1:003615880258:inference-profile/us.anthropic.claude-3-7-sonnet-20250219-v1:0',
	'Claude 3.5 Sonnet': 'arn:aws:bedrock:us-east-1:003615880258:inference-profile/us.anthropic.claude-3-5-sonnet-20241022-v2:0',
	'Llama 3.3 Instruct': 'arn:aws:bedrock:us-east-1:003615880258:inference-profile/us.meta.llama3-3-70b-instruct-v1:0',
	'Llama 3.1 Instruct': 'arn:aws:bedrock:us-east-1:003615880258:inference-profile/us.meta.llama3-1-70b-instruct-v1:0',
	'DeepSeek-R1': 'arn:aws:bedrock:us-east-1:003615880258:inference-profile/us.deepseek.r1-v1:0',
} as const;

export const DEFAULT_MODEL = ALL_MODELS['Claude 3.7 Sonnet'];
export const BACKUP_MODEL = ALL_MODELS['Claude 3.5 Sonnet'];
export const DEFAULT_EMBEDDING_MODEL = 'text-embedding-ada-002';
