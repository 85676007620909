import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Spinner } from 'react-bootstrap';

export interface ToolCallUIProps {
	id?: string;
	name: string;
	params: any;
	status: 'running' | 'complete' | 'failed';
	result?: any;
	error?: string;
}

export const ToolCallUI: FC<ToolCallUIProps> = ({
	name,
	params,
	status,
	result,
	error,
}) => {
	// Ensure params are properly displayed, even if empty
	const displayParams = params && Object.keys(params).length > 0
		? params
		: { message: 'No parameters' };

	return (
		<details className="tool-call-card">
			<summary className="d-flex align-items-center justify-content-between py-2">
				<div className="fw-bold">
					{name}
				</div>
				<div>
					{status === 'running' && (
						<Spinner
							animation="border"
							size="sm"
							variant="primary"
							className="me-1"
						/>
					)}
					{status === 'complete' && (
						<FontAwesomeIcon
							icon={faCircleCheck}
							className="text-success me-1"
						/>
					)}
					{status === 'failed' && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className="text-danger me-1"
						/>
					)}
					<span className="text-muted small">
						{(() => {
							if (status === 'running') return 'Running';
							if (status === 'complete') return 'Completed';
							return 'Failed';
						})()}
					</span>
				</div>
			</summary>
			<div className="py-2 px-3">
				<code className="d-block bg-light p-2 rounded">
					{JSON.stringify(displayParams, null, 2)}
				</code>

				{status === 'running' && (
					<div className="progress mt-2">
						<div
							className="progress-bar progress-bar-striped progress-bar-animated"
							style={{ width: '50%' }}
						/>
					</div>
				)}

				{/* result && status === 'complete' && (
					<div className="mt-3 border-top pt-2">
						<div className="text-success fw-bold mb-1">Result:</div>
						<code className="d-block bg-light p-2 rounded">
							{typeof result === 'object'
								? JSON.stringify(result, null, 2)
								: result}
						</code>
					</div>
				)} */}

				{error && status === 'failed' && (
					<div className="mt-3 border-top pt-2">
						<div className="text-danger fw-bold mb-1">Error:</div>
						<code className="d-block bg-light p-2 rounded">
							{error}
						</code>
					</div>
				)}
			</div>
		</details>
	);
};

// Function to determine tool call status from tool result data
export function getToolCallStatus(toolResult: any): 'running' | 'complete' | 'failed' {
	if (!toolResult) return 'running';

	if (toolResult.status?.type) {
		return toolResult.status.type;
	}

	if (toolResult.status === 'complete' || toolResult.status === 'success') {
		return 'complete';
	}

	if (toolResult.status === 'error' || toolResult.status === 'failed') {
		return 'failed';
	}

	return 'running';
}

// Generic tool call component to be used as a Fallback in MessagePrimitive.Content
export const GenericToolCall: FC<any> = (props) => {
	// Ensure we have a valid name or fall back to defaults
	const name = props.name || props.tool || 'Tool Call';

	// Extract params from various possible locations
	const params = props.params || props.args || props.arguments || {};

	// Determine current status
	const toolStatus = getToolCallStatus(props);

	// Extract result
	const result = props.result || props.output || props.response;

	// Extract error
	const error = props.error || props.errorMessage;

	return (
		<ToolCallUI
			key={props.id}
			id={props.id}
			name={name}
			params={params}
			status={toolStatus}
			result={result}
			error={error}
		/>
	);
};
