import { DatePeriod } from '@newstex/types/dates';
import type { GetMetricStatsRequest } from '@newstex/types/requests/get-metric-stats';
import omit from 'object.omit';
import { useEffect, useState } from 'react';
import { useAPI } from '~/providers/api-provider';

import BarChart, { BarChartProps } from '../charts/BarChart';

export interface MetricStatConfig extends Omit<BarChartProps['datasets'][number], 'data'> {
	query: GetMetricStatsRequest;
	link?: string;
}

// Metric Stat Configs
const METRIC_STAT_CONFIGS: MetricStatConfig[] = [
	{
		label: 'Feeds Read',
		color: 'blue800',
		yAxisID: 'default',
		query: {
			namespace: 'FeedReader',
			metricName: 'Feed Read',
			dimensions: {
				service: 'NewsCrawler',
			},
		},
	},
	{
		label: 'New Stories',
		color: 'blue600',
		yAxisID: 'default',
		query: {
			namespace: 'FeedReader',
			metricName: 'New Story',
			dimensions: {
				service: 'NewsCrawler',
			},
		},
	},
	{
		label: 'Story Processed',
		color: 'blueSky',
		yAxisID: 'default',
		query: {
			namespace: 'Story',
			metricName: 'Story Received',
			dimensions: {
				service: 'ProcessStory',
			},
		},
	},
	{
		label: 'Story Submission Errors',
		type: 'line',
		yAxisID: 'default',
		color: 'red',
		query: {
			namespace: 'FeedReader',
			metricName: 'Story Submission Error',
			dimensions: {
				service: 'NewsCrawler',
			},
		},
	},
	{
		label: 'Stories Delivered',
		type: 'line',
		color: 'purple',
		query: {
			namespace: 'Story',
			metricName: 'Story Delivered',
			dimensions: {
				service: 'Paperboy',
			},
		},
	},
];

function formatDateString(date: Date, period: 'day' | 'week' | 'month' | 'year') {
	if (period === 'month') {
		// Always format with Eastern time
		return date.toLocaleString('en-US', { month: 'short', year: 'numeric', timeZone: 'America/New_York' });
	}

	return date.toISOString().split('T')[0];
}

const MAX_PERIODS_MAP: Record<'day' | 'week' | 'month', number> = {
	day: 31,
	week: 12,
	month: 13,
};

export function MetricStats() {
	const [loading, setLoading] = useState(true);
	const [period, setPeriod] = useState<DatePeriod>('day');
	const [stats, setStats] = useState<BarChartProps['datasets']>([]);
	const [dataLabels, setLabels] = useState<BarChartProps['labels']>([]);
	const api = useAPI();
	useEffect(() => {
		const isCancelled = { current: false };
		// Create a list of dates for the last 30 days, starting at tomorrow (to include all of today)
		const labels: BarChartProps['labels'] = [];
		const today = new Date();
		let oldestDate = new Date();
		const endDate = new Date();
		// End date should be one period in the future (so we get all of this period)
		if (period === 'month') {
			today.setDate(1);
			endDate.setDate(1);
			endDate.setMonth(endDate.getMonth() + 1);
		} else {
			endDate.setDate(endDate.getDate() + 1);
		}
		for (let i = 0; i < MAX_PERIODS_MAP[period]; i++) {
			const pastDate = new Date(today);
			if (period === 'month') {
				pastDate.setMonth(pastDate.getMonth() - i);
				labels.push({
					name: formatDateString(pastDate, period),
				});
			} else if (period === 'week') {
				pastDate.setDate(today.getDate() - i * 7);
				labels.push({
					name: formatDateString(pastDate, period),
				});
			} else {
				pastDate.setDate(today.getDate() - i);
				labels.push({
					name: pastDate.toISOString().split('T')[0],
					backgroundColor: (period === 'day' && (pastDate.getDay() === 0 || pastDate.getDay() === 6)) ? 'rgba(0,0,0,0.1)' : undefined,
				});
			}
			oldestDate = pastDate;
		}
		labels.reverse();
		const startTime = oldestDate.toISOString().split('T')[0];
		const endTime = endDate.toISOString().split('T')[0];
		if (isCancelled.current) {
			return;
		}

		// Format the labels
		if (period === 'day') {
			setLabels([
				...labels.slice(0, labels.length - 1).map((label) => ({
					...label,
					name: new Date(label.name).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
				})),
				{
					name: 'Today',
					backgroundColor: (period === 'day' && (today.getDay() === 0 || today.getDay() === 6)) ? 'rgba(0,0,0,0.1)' : undefined,
				},
			]);
		} else {
			setLabels([
				...labels.map((label) => ({
					...label,
					name: period === 'month' ? label.name : new Date(label.name).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
				})),
			]);
		}

		const fetchData = async () => {
			const datasets: BarChartProps['datasets'] = [];
			for (const config of METRIC_STAT_CONFIGS) {
				const response = await api.getMetricStats({
					...config.query,
					period,
					startTime,
					endTime,
				});
				if (isCancelled.current) {
					return;
				}

				if (response?.Datapoints) {
					const data: number[] = labels.map((ts) => {
						const point = response.Datapoints.find((p) => formatDateString(new Date(p.Timestamp), period) === ts.name);
						return point?.Sum ?? 0;
					});
					datasets.push({
						...omit(config, ['query', 'link']),
						data,
					});
				}
			}

			if (!isCancelled.current) {
				setStats(datasets);
			}
		};

		if (api) {
			setLoading(true);
			fetchData().then(() => {
				setLoading(false);
			});
		}
		return () => {
			isCancelled.current = true;
		};
	}, [api, period]);
	return (
		<>
			<BarChart
				loading={loading}
				labels={dataLabels}
				datasets={stats}
				setPeriod={setPeriod}
				period={period}
				legendPosition="bottom"
			/>
		</>
	);
}
