import React from 'react';
import { Spinner } from 'react-bootstrap';

interface LoadingSpinnerProps {
	loading: boolean;
	title?: string;
	hideTitle?: boolean;
	children?: React.ReactNode;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
	loading, title, hideTitle, children,
}) => {
	if (!loading) {
		return null;
	}

	return (
		<div className="text-center my-4">
			<Spinner animation="border" role="status" />
			{!hideTitle && <div className="mt-2">{title || 'Loading...'}</div>}
			{children}
		</div>
	);
};

export default LoadingSpinner;
