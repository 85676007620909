import { faEdit, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faUserTimes } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Distributor } from '@newstex/types/distributor';
import { Results } from '@newstex/types/results';
import { User } from '@newstex/types/user';
import { useEffect, useState } from 'react';
import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Dropdown,
	Form,
	Modal,
	Row,
	Spinner,
	Tab,
	Table,
	Tabs,
} from 'react-bootstrap';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { AttachUserRoleButton } from '~/components/attach-user-role-modal';
import { ClientStoryCountsChart } from '~/components/client-story-counts';
import { CreateDistributorDeliveryButton } from '~/components/distributor/create-distributor-delivery-button';
import EditButton from '~/components/edit-button';
import { EditDistributorReportModal } from '~/components/editors/modals/edit-distributor-report-modal';
import LoadingSpinner from '~/components/LoadingSpinner';
import { MarkdownTextBlock } from '~/components/markdown-text-block';
import { ObjectHistoryButton } from '~/components/object-history-button';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { RefreshButton } from '~/components/refresh-button';
import { RequirePermissions } from '~/components/require-permissions';
import { StatusBadge } from '~/components/status-badge';
import { PropertiesTable } from '~/components/tables/properties-table';
import { useAPI } from '~/providers/api-provider';
import { useSearch } from '~/providers/search';

export const DistributorPage = () => {
	const { id } = useParams<{ id: string }>();
	const api = useAPI();
	const searchContext = useSearch();
	const [distributor, setDistributor] = useState<Distributor | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const [relatedDeliveries, setRelatedDeliveries] = useState<any[]>([]);
	const [relatedUsers, setRelatedUsers] = useState<User[]>([]);
	const [loadingDeliveries, setLoadingDeliveries] = useState(false);
	const [loadingUsers, setLoadingUsers] = useState(false);
	const [editingQualification, setEditingQualification] = useState(false);
	const [qualificationCriteria, setQualificationCriteria] = useState('');
	const [savingQualification, setSavingQualification] = useState(false);
	const [editingUser, editUser] = useState<User | null>(null);
	const [editingReport, setEditingReport] = useState<'candidates' | 'inventory' | null>(null);

	const fetchRelatedDeliveries = async (dist: Distributor) => {
		if (!dist?.$id || !searchContext.searchClient) return;

		setLoadingDeliveries(true);
		try {
			// Use search system instead of direct API
			const searchResp = await searchContext.searchClient.search({
				indexName: 'Delivery',
				query: '',
				filter_by: `distributor:${dist.$id}`,
				per_page: 100,
			});

			setRelatedDeliveries(searchResp?.hits || []);
		} catch (err) {
			console.error('Failed to load related deliveries:', err);
		} finally {
			setLoadingDeliveries(false);
		}
	};

	const fetchRelatedUsers = async (dist: Distributor) => {
		if (!dist?.$id || !searchContext.searchClient) return;

		setLoadingUsers(true);
		try {
			// Use search system instead of direct API
			const searchResp = await searchContext.searchClient.search({
				indexName: 'User',
				query: '',
				filter_by: `distributor:${dist.$id}`,
				per_page: 100,
			});

			setRelatedUsers(searchResp?.hits || []);
		} catch (err) {
			console.error('Failed to load related users:', err);
		} finally {
			setLoadingUsers(false);
		}
	};

	const fetchDistributor = async (refresh = false) => {
		setLoading(true);
		try {
			const data = await api.fetchWithAuth<Results<Distributor>>(
				`resources/Distributor/${id}`,
				refresh ? { cache: 'reload' } : undefined,
			);

			// Normalize data to ensure candidates_reports and inventory_reports always exist
			const normalizedDistributor = {
				...data.items[0],
				candidates_reports: {
					frequency: data.items[0]?.candidates_reports?.frequency || 'Monthly',
					emails: data.items[0]?.candidates_reports?.emails || [],
					enabled: data.items[0]?.candidates_reports?.enabled ?? false,
				},
				inventory_reports: {
					frequency: data.items[0]?.inventory_reports?.frequency || 'Monthly',
					emails: data.items[0]?.inventory_reports?.emails || [],
					enabled: data.items[0]?.inventory_reports?.enabled ?? false,
				},
			};

			setDistributor(normalizedDistributor);
			setQualificationCriteria(normalizedDistributor?.qualification_criteria || '');

			// Fetch deliveries related to this distributor
			await Promise.all([
				fetchRelatedDeliveries(normalizedDistributor),
				fetchRelatedUsers(normalizedDistributor),
			]);
		} catch (err) {
			setError('Failed to load distributor');
		} finally {
			setLoading(false);
		}
	};

	const saveQualificationCriteria = async () => {
		if (!distributor) return;

		setSavingQualification(true);
		try {
			await api.updateItem<Distributor>(distributor, {
				qualification_criteria: qualificationCriteria,
			});
			setEditingQualification(false);
			await fetchDistributor(true);
		} catch (err) {
			console.error('Failed to update qualification criteria:', err);
		} finally {
			setSavingQualification(false);
		}
	};

	const updateQualificationCriteria = async (newContent: string) => {
		if (!distributor) return;

		setSavingQualification(true);
		try {
			await api.updateItem<Distributor>(distributor, {
				qualification_criteria: newContent,
			});
			await fetchDistributor(true);
		} catch (err) {
			console.error('Failed to update qualification criteria:', err);
			throw err;
		} finally {
			setSavingQualification(false);
		}
	};

	const updateUser = async (user: User, updates: Partial<User>) => {
		await api.updateItem<any>({
			$type: 'User',
			$id: user.id,
		}, updates);
		setTimeout(async () => {
			await fetchRelatedUsers(distributor);
		}, 1000);
	};

	const detachUser = async (user: User) => {
		console.log('detachUser', user);
		await api.updateItem<any>({
			$type: 'User',
			$id: user.id,
		}, {
			distributor: null,
			role: null,
		});
		setTimeout(async () => {
			await fetchRelatedUsers(distributor);
		}, 1000);
	};

	useEffect(() => {
		fetchDistributor();
	}, [id, api, searchContext.searchClient]);

	if (loading) return <LoadingSpinner loading={loading} />;
	if (error) return <Alert variant="danger">{error}</Alert>;

	return (
		<Container fluid>
			{editingUser && (
				<Modal show={editingUser !== null} onHide={() => editUser(null)}>
					<Modal.Header closeButton>
						<Modal.Title>Edit User {editingUser?.name}</Modal.Title>
					</Modal.Header>
					<Form onSubmit={(e) => {
						e.preventDefault();
						const form = e.currentTarget;
						const formData = new FormData(form);
						const role = formData.get('role') as User['role'];
						console.log('Update', {
							editingUser,
							role,
						});
						updateUser(editingUser, {
							role,
						});
						editUser(null);
					}}>
						<Modal.Body>
							<Form.Group controlId="role">
								<Form.Label>Role</Form.Label>
								<Form.Control as="select" name="role" defaultValue={editingUser?.role}>
									<option value="">-- Select Role --</option>
									<option value="tech">Technical</option>
									<option value="reporting">Reporting</option>
									<option value="support">Support</option>
								</Form.Control>
							</Form.Group>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => editUser(null)}>
								Cancel
							</Button>
							<Button variant="primary" type="submit">
								Save
							</Button>
						</Modal.Footer>
					</Form>
				</Modal>
			)}
			<div className="title-wrapper pt-30">
				<Row className="align-items-center">
					<Col md={6}>
						<div className="title">
							<h2>
								{distributor?.name}
								{distributor?.status && <StatusBadge status={distributor.status} className="ms-2" />}
								<RequirePermissions permissions={['admin']}>
									<span className="ps-1">
										<ObjectHistoryButton
											item={distributor}
										/>
									</span>
								</RequirePermissions>
							</h2>
							<h6 className="text-muted">
								Distributor
								{distributor?.code && ` (${distributor.code})`}
							</h6>
						</div>
					</Col>
					<Col md={6} className="text-end">
						<div className="float-end">
							<RequirePermissions permissions={['admin']}>
								<span className="ps-1">
									<EditButton
										item={{
											...distributor,
											$type: 'Distributor',
										}}
										size="sm"
										refreshHandler={() => fetchDistributor(true)}
									/>
								</span>
							</RequirePermissions>
							<span className="ps-1">
								<RefreshButton
									loading={loading}
									setLoading={setLoading}
									size="sm"
									refreshHandler={() => fetchDistributor(true)}
								/>
							</span>
						</div>
					</Col>
				</Row>
			</div>
			<hr />

			{distributor?.code && (
				<Row>
					<Col>
						<ClientStoryCountsChart
							distributor={distributor}
						/>
					</Col>
				</Row>
			)}

			<Row>
				<Col lg={12} xxl={9}>
					<Card>
						<Card.Body>
							<Tabs
								defaultActiveKey={searchParams.get('tab') || 'general'}
								id="distributor-tabs"
								className="mb-3"
								onSelect={(key) => {
									setSearchParams({
										tab: key || '',
									});
								}}
							>
								<Tab eventKey="general" title="General Info">
									<dl className="row properties-table">
										<dt className="col-sm-3">Name</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="name" propValue={distributor?.name} /></dd>

										<dt className="col-sm-3">Code</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="code" propValue={distributor?.code} /></dd>

										<dt className="col-sm-3">Status</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="status" propValue={distributor?.status} /></dd>

										<dt className="col-sm-3">NewscrunchID</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="newscrunch_id" propValue={distributor?.newscrunch_id} /></dd>

										<dt className="col-sm-3">Recipient Name</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="recipient_name" propValue={distributor?.recipient_name} /></dd>
									</dl>
								</Tab>

								<Tab eventKey="reports" title="Report Settings">
									<Row>
										<Col md={6}>
											<Card className="mb-3">
												<Card.Header className="d-flex justify-content-between align-items-center">
													<h5 className="mb-0">Candidates Reports</h5>
													<RequirePermissions permissions={['admin']}>
														<Button
															variant="link"
															size="sm"
															className="p-0 icon"
															onClick={() => setEditingReport('candidates')}
														>
															<FontAwesomeIcon icon={faEdit} />
														</Button>
													</RequirePermissions>
												</Card.Header>
												<Card.Body>
													<dl className="row properties-table">
														<dt className="col-sm-4">Frequency</dt>
														<dd className="col-sm-8">
															<PropertyDisplayValue
																propName="frequency"
																propValue={distributor?.candidates_reports?.frequency}
															/>
														</dd>

														<dt className="col-sm-4">Enabled</dt>
														<dd className="col-sm-8">
															<PropertyDisplayValue
																propName="enabled"
																propValue={distributor?.candidates_reports?.enabled}
															/>
														</dd>

														<dt className="col-sm-4">Recipients</dt>
														<dd className="col-sm-8">
															{distributor?.candidates_reports?.emails?.length ? (
																<ul className="list-unstyled">
																	{distributor.candidates_reports.emails.map((email, idx) => (
																		<li key={`candidate-email-${idx}`}>
																			<PropertyDisplayValue propName="email" propValue={email} />
																		</li>
																	))}
																</ul>
															) : (
																<i className="text-muted">No recipients configured</i>
															)}
														</dd>
													</dl>
												</Card.Body>
											</Card>
										</Col>
										<Col md={6}>
											<Card className="mb-3">
												<Card.Header className="d-flex justify-content-between align-items-center">
													<h5 className="mb-0">Inventory Reports</h5>
													<RequirePermissions permissions={['admin']}>
														<Button
															variant="link"
															size="sm"
															className="p-0 icon"
															onClick={() => setEditingReport('inventory')}
														>
															<FontAwesomeIcon icon={faEdit} />
														</Button>
													</RequirePermissions>
												</Card.Header>
												<Card.Body>
													<dl className="row properties-table">
														<dt className="col-sm-4">Frequency</dt>
														<dd className="col-sm-8">
															<PropertyDisplayValue
																propName="frequency"
																propValue={distributor?.inventory_reports?.frequency}
															/>
														</dd>

														<dt className="col-sm-4">Enabled</dt>
														<dd className="col-sm-8">
															<PropertyDisplayValue
																propName="enabled"
																propValue={distributor?.inventory_reports?.enabled}
															/>
														</dd>

														<dt className="col-sm-4">Recipients</dt>
														<dd className="col-sm-8">
															{distributor?.inventory_reports?.emails?.length ? (
																<ul className="list-unstyled">
																	{distributor.inventory_reports.emails.map((email, idx) => (
																		<li key={`inventory-email-${idx}`}>
																			<PropertyDisplayValue propName="email" propValue={email} />
																		</li>
																	))}
																</ul>
															) : (
																<i className="text-muted">No recipients configured</i>
															)}
														</dd>
													</dl>
												</Card.Body>
											</Card>
										</Col>
									</Row>
								</Tab>

								<Tab eventKey="ai-qualification" title="AI Qualification">
									<div className="d-flex justify-content-between align-items-center mb-3">
										<h5>AI Qualification Criteria</h5>
										<RequirePermissions permissions={['admin']}>
											{!editingQualification ? (
												<Button
													variant="outline-primary"
													size="sm"
													onClick={() => setEditingQualification(true)}
												>
													Edit Criteria
												</Button>
											) : (
												<div>
													<Button
														variant="success"
														size="sm"
														className="me-2"
														onClick={saveQualificationCriteria}
														disabled={savingQualification}
													>
														{savingQualification ? 'Saving...' : 'Save'}
													</Button>
													<Button
														variant="outline-secondary"
														size="sm"
														onClick={() => {
															setEditingQualification(false);
															setQualificationCriteria(distributor?.qualification_criteria || '');
														}}
														disabled={savingQualification}
													>
														Cancel
													</Button>
												</div>
											)}
										</RequirePermissions>
									</div>

									{editingQualification ? (
										<Form.Control
											as="textarea"
											rows={12}
											value={qualificationCriteria}
											onChange={(e) => setQualificationCriteria(e.target.value)}
											placeholder="Enter AI qualification criteria for this distributor..."
											className="font-monospace"
										/>
									) : (
										<div className="markdown-content p-3 bg-light border rounded">
											{distributor?.qualification_criteria ? (
												<MarkdownTextBlock
													locked={false}
													onUpdateContent={updateQualificationCriteria}
												>
													{distributor.qualification_criteria}
												</MarkdownTextBlock>
											) : (
												<Alert variant="info">
													No AI qualification criteria defined for this distributor.
												</Alert>
											)}
										</div>
									)}
								</Tab>

								<Tab eventKey="properties" title="All Properties">
									<PropertiesTable item={distributor} />
								</Tab>
							</Tabs>
						</Card.Body>
					</Card>
				</Col>

				<Col lg={12} xxl={3}>
					<Row>
						<Col>
							<h6>Products</h6>
							<hr />
							{distributor?.products && distributor.products.length > 0 ? (
								<ul className="list-group mb-4">
									{distributor.products.map((product, index) => (
										<li key={`product-${index}`} className="list-group-item">
											<PropertyDisplayValue propName="product" propValue={product} />
										</li>
									))}
								</ul>
							) : (
								<Alert variant="info">No products associated with this distributor</Alert>
							)}
						</Col>
					</Row>

					<Row>
						<Col>
							<div className="d-flex justify-content-between align-items-center">
								<h6>Deliveries</h6>
								<RequirePermissions permissions={['admin']}>
									<CreateDistributorDeliveryButton
										distributor={distributor}
										onDeliveryCreated={() => fetchDistributor(true)}
									/>
								</RequirePermissions>
							</div>
							<hr />
							{loadingDeliveries && (
								<Spinner animation="border" variant="primary" />
							)}
							{!loadingDeliveries && relatedDeliveries.length > 0 && (
								<Table striped>
									<thead>
										<tr>
											<th>Name</th>
											<th>Type</th>
											<th>Products</th>
											<th>Status</th>
										</tr>
									</thead>
									<tbody>
										{relatedDeliveries.map((delivery) => (
											<tr key={delivery.id}>
												<td>
													<Link to={`/delivery/${delivery.id}`}>
														{delivery.name}
													</Link>
												</td>
												<td><PropertyDisplayValue propName="type" propValue={delivery.type} /></td>
												<td>
													{delivery.products && delivery.products.length > 0 ? (
														<small>
															{delivery.products.map((product: string, idx: number) => (
																<div key={`delivery-product-${idx}`}>
																	<PropertyDisplayValue propName="product" propValue={product} />
																</div>
															))}
														</small>
													) : (
														<i className="text-muted small">None</i>
													)}
												</td>
												<td>
													<StatusBadge status={delivery.status} />
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							)}
							{!loadingDeliveries && relatedDeliveries.length === 0 && (
								<Alert variant="info">No deliveries associated with this distributor</Alert>
							)}
						</Col>
					</Row>

					<Row>
						<Col>
							<div className="d-flex justify-content-between align-items-center">
								<h6>Users</h6>
								<RequirePermissions permissions={['user-admin']}>
									<AttachUserRoleButton
										distributor={distributor}
										onUserAdded={() => fetchDistributor(true)}
									/>
								</RequirePermissions>
							</div>
							<hr />
							{loadingUsers && (
								<Spinner animation="border" variant="primary" />
							)}
							{!loadingUsers && relatedUsers.length > 0 ? (
								<Table striped>
									<thead>
										<tr>
											<th>User</th>
											<th>Last Login</th>
											<th>Role</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{relatedUsers.map((user, index) => (
											<tr key={`user-${index}`}>
												<td>
													<PropertyDisplayValue propName="name" propValue={user.name} />
													<div className="text-muted small">
														<PropertyDisplayValue propName="email" propValue={user.email} />
													</div>
												</td>
												<td>
													<PropertyDisplayValue propName="last_login" propValue={user.last_login} />
												</td>
												<td>
													<PropertyDisplayValue propName="role" propValue={user.role} />
												</td>
												<td>
													<RequirePermissions permissions={['user-admin']}>
														<Dropdown>
															<Dropdown.Toggle variant="link" size="sm" className="p-0 icon">
																<FontAwesomeIcon icon={faEllipsisVertical} />
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item onClick={(e) => {
																	e.preventDefault();
																	editUser(user);
																}}>
																	<FontAwesomeIcon icon={faEdit} className="me-2" />
																	Edit Role
																</Dropdown.Item>
																<Dropdown.Item onClick={(e) => {
																	e.preventDefault();
																	detachUser(user);
																}} className="text-danger">
																	<FontAwesomeIcon icon={faUserTimes} className="me-2" />
																	Detach User
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</RequirePermissions>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							) : (
								<Alert variant="info">No users assigned to this distributor</Alert>
							)}
						</Col>
					</Row>
				</Col>
			</Row>

			{/* Report Editor Modals */}
			<EditDistributorReportModal
				show={editingReport === 'candidates'}
				onClose={(saved) => {
					setEditingReport(null);
					if (saved) {
						fetchDistributor(true);
					}
				}}
				title="Candidates Reports"
				settings={distributor?.candidates_reports}
				onSave={async (settings) => {
					if (!distributor) return;
					await api.updateItem<Distributor>(distributor, {
						candidates_reports: settings,
					});
				}}
			/>

			<EditDistributorReportModal
				show={editingReport === 'inventory'}
				onClose={(saved) => {
					setEditingReport(null);
					if (saved) {
						fetchDistributor(true);
					}
				}}
				title="Inventory Reports"
				settings={distributor?.inventory_reports}
				onSave={async (settings) => {
					if (!distributor) return;
					await api.updateItem<Distributor>(distributor, {
						inventory_reports: settings,
					});
				}}
			/>
		</Container>
	);
};
