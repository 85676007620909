import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import { CreateDistributorModal } from './create-distributor-modal';

interface CreateDistributorButtonProps {
	onDistributorCreated?: () => void;
	size?: ButtonProps['size'];
	variant?: ButtonProps['variant'];
	className?: string;
}

export function CreateDistributorButton({
	onDistributorCreated,
	size,
	variant,
	className,
}: CreateDistributorButtonProps) {
	const [showCreateModal, setShowCreateModal] = useState(false);

	const handleCreateDistributor = () => {
		setShowCreateModal(true);
	};

	const handleCloseModal = (saved: boolean) => {
		setShowCreateModal(false);
		if (saved && onDistributorCreated) {
			onDistributorCreated();
		}
	};

	return (
		<>
			<Button
				size={size || 'sm'}
				variant={variant || 'outline-success'}
				className={className}
				onClick={handleCreateDistributor}
			>
				<FontAwesomeIcon icon={faPlus} className="me-1" />
				New Distributor
			</Button>

			<CreateDistributorModal
				show={showCreateModal}
				onClose={handleCloseModal}
			/>
		</>
	);
}
