import {
	faArchive,
	faEdit,
	faPersonToPortal,
	faTrash,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { User } from '@newstex/types/user';
import { sleep } from '@newstex/utils/sleep';
import { createColumnHelper } from '@tanstack/react-table';
import { title as titleCase } from 'case';
import { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { RequirePermissions, hasPermission } from '~/components/require-permissions';
import { useAPI } from '~/providers/api-provider';
import { useAuth } from '~/providers/auth';
import { useConfirm } from '~/providers/confirm';

import DataTable from '../data-table';
import { DateTime } from '../date-time';
import { UserEditModal } from '../editors/modals/user-edit-modal';
import { PropertyDisplayValue } from '../property-display-value';
import { RecordLink } from '../record-link';

interface UsersTableProps {
	items: User[];
	refresh: () => void;
	searchText?: string,
	hasMore?: boolean,
	onLoadMore?: () => void,
}

export function UsersTable({ items, refresh }: UsersTableProps) {
	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState<User | null>(null);
	const { confirmDialog } = useConfirm();
	const api = useAPI();
	const { assumeUser } = useAuth();

	const handleEditClick = (user: User) => {
		setSelectedUser(user);
		setShowEditModal(true);
	};

	const handleDeleteClick = (user: User) => {
		confirmDialog({
			title: `Delete User: ${user.email}`,
			message: [
				'Are you sure you want to delete this user?',
				'Deletion will permantently remove all records associated with this user.',
				'They may be able to sign up again with the same email address.',
			].join('\n'),
		}).then((confirmed) => {
			if (confirmed) {
				console.log('delete', user);
				api.fetchWithAuth(`/resources/User/${user.id}`, {
					method: 'PATCH',
					body: JSON.stringify({
						deleted: true,
					}),
				}).then((resp) => {
					toast.success('User deleted successfully');
				}).catch((err) => {
					console.error('Failed to delete user', err);
					toast.error(<div>
						<h6>Failed to delete user</h6>
						<pre>{err.message}</pre>
					</div>);
				});
			}
		});
	};

	const handleArchiveClick = (user: User) => {
		confirmDialog({
			title: `Archive User: ${user.email}`,
			message: [
				'Are you sure you want to archive this user?',
				'Archived users will still be able to sign in to the platform, but will be hidden from the user list.',
				'Archived user data will be retained for reporting purposes.',
			].join('\n'),
		}).then((confirmed) => {
			if (confirmed) {
				console.log('archive', user);
				api.fetchWithAuth(`/resources/User/${user.id}`, {
					method: 'PATCH',
					body: JSON.stringify({
						archived: true,
					}),
				}).then((resp) => {
					toast.success('User archived successfully');
				}).catch((err) => {
					console.error('Failed to archive user', err);
					toast.error(<div>
						<h6>Failed to archive user</h6>
						<pre>{err.message}</pre>
					</div>);
				});
			}
		});
	};

	const handleCloseModal = async () => {
		setShowEditModal(false);
		setSelectedUser(null);
		// Wait 2 seconds for indexing to complete
		await sleep(2000);
		refresh();
	};

	const columnHelper = createColumnHelper<User>();

	const columns = [
		columnHelper.accessor('email', {
			header: 'Name/Email',
			enableSorting: true,
			cell: ({ getValue, row: { original }}) => {
				return <>
					{original.name && <div>{original.name}</div>}
					<div><PropertyDisplayValue propName="email" propValue={getValue()}/></div>
				</>;
			},
		}),
		columnHelper.accessor('account', {
			header: 'Account',
			enableSorting: true,
			meta: {
				priority: 1,
			},
			cell: ({ row: { original }}) => {
				if (original.distributor) {
					return (<>
						<RecordLink
							id={original.distributor}
							typeName="Distributor"
						/>
						{original.role && (
							<OverlayTrigger placement="left" overlay={<Tooltip>
								This describes the role this user has in this company.
								It doesn't apply any permissions.
							</Tooltip>}>
								<div className="text-muted">
									{titleCase(original.role)}
								</div>
							</OverlayTrigger>
						)}
					</>);
				}

				if (original.owned_content?.length) {
					return <RecordLink
						id={original.owned_content[0]}
						typeName="Publisher"
					/>;
				}
				return '';
			},
		}),
		columnHelper.accessor('auth_groups', {
			header: 'Auth Groups',
			enableSorting: true,
			meta: {
				priority: 2,
			},
			cell: ({ getValue }) => (
				<PropertyDisplayValue
					propName="auth_groups"
					propValue={getValue()}
				/>
			),
		}),
		columnHelper.accessor('created_at', {
			header: 'Date Created',
			enableSorting: true,
			meta: {
				priority: 6,
			},
			cell: ({ getValue }) => <DateTime ts={getValue()} />,
		}),
		columnHelper.accessor('last_login', {
			header: 'Last Login',
			enableSorting: true,
			meta: {
				priority: 2,
			},
			cell: ({ getValue }) => <DateTime ts={getValue()} />,
		}),
		...(hasPermission(['user-admin']) ? [
			columnHelper.display({
				header: 'Actions',
				enableSorting: false,
				meta: {
					priority: 1,
				},
				cell: ({ row }) => (
					<RequirePermissions permissions={['user-admin']}>
						<Button variant="link" size="sm" onClick={() => handleEditClick(row.original)} className="text-secondary">
							<FontAwesomeIcon icon={faEdit} />
						</Button>
						{row.original.archived ? (
							<Button variant="link" size="sm" onClick={() => handleDeleteClick(row.original)} className="text-danger">
								<FontAwesomeIcon icon={faTrash} />
							</Button>
						) : (
							<Button variant="link" size="sm" onClick={() => handleArchiveClick(row.original)} className="text-danger">
								<FontAwesomeIcon icon={faArchive} />
							</Button>
						)}
						{row.original.email && !row.original.email?.endsWith('@newstex.com') && (
							<OverlayTrigger
								placement="top"
								overlay={<Tooltip>
									Run as {row.original.email}
								</Tooltip>}
							>
								<Button variant="link" size="sm" onClick={(e) => {
									e.preventDefault();
									assumeUser(row.original.email!);
									e.currentTarget.setAttribute('disabled', 'true');
								}} className="text-primary">
									<FontAwesomeIcon icon={faPersonToPortal} />
								</Button>
							</OverlayTrigger>
						)}
					</RequirePermissions>
				),
			}),
		] : []),
	];

	return (
		<>
			<DataTable
				columns={columns}
				items={items}
				tableRowClassNameHelper={({ original }: { original: User }) => {
					return original.archived ? 'table-danger' : '';
				}}
			/>

			<UserEditModal
				show={showEditModal}
				user={selectedUser}
				onClose={handleCloseModal}
			/>
		</>
	);
}
