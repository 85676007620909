import { UserGroupSchema } from './user';

export interface HasAccessGroups {
	access_groups?: typeof UserGroupSchema._def.values[number][];
}

/**
 * Get access level display information for any object that has access_groups
 */
export function getAccessInfo(obj: HasAccessGroups) {
	if (!obj.access_groups?.length || obj.access_groups.includes('admin')) {
		return {
			label: 'Admin Only',
			variant: 'danger',
			icon: 'lock',
		};
	}

	const groups = obj.access_groups.map((g) => g.charAt(0).toUpperCase() + g.slice(1));
	return {
		label: `Accessible by: ${groups.join(', ')}`,
		variant: groups.includes('User') ? 'success' : 'warning',
	};
}
