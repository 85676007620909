import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Report } from '@newstex/types/report';
import omit from 'object.omit';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReportFormModal from '~/components/modals/report-form-modal';

interface CloneReportButtonProps {
	report: Report;
	variant?: string;
	size?: 'sm' | 'lg';
	onSave?: () => void;
}

export default function CloneReportButton({
	report,
	variant = 'outline-primary',
	size,
	onSave,
}: CloneReportButtonProps) {
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();

	// Create a copy of the report for the modal
	const clonedReport: Partial<Report> = {
		...omit(report, ['id', 'created_at', 'updated_at']),
		name: `${report.name} (Copy)`,
	};

	const handleModalClose = (saved: boolean, newReport?: Report) => {
		setShowModal(false);
		if (saved) {
			if (onSave) onSave();
			if (newReport?.id) {
				navigate(`/reports/${newReport.id}`);
			}
		}
	};

	return (
		<>
			<Button
				variant={variant}
				size={size}
				onClick={() => setShowModal(true)}
			>
				<FontAwesomeIcon icon={faCopy} /> Clone
			</Button>

			<ReportFormModal
				show={showModal}
				onClose={handleModalClose}
				title="Clone Report"
				startingUpdates={clonedReport}
			/>
		</>
	);
}
