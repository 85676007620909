import type { Distributor } from '@newstex/types/distributor';
import { CreateDistributorButton } from '~/components/create-distributor-button';
import { SearchFacetConfig, SearchWithFacets } from '~/components/search-with-facets';
import { DistributorsTable } from '~/components/tables/distributors-table';

export const DistributorFacetConfig: SearchFacetConfig[] = [
	{
		attribute: 'deliveries',
		title: 'Deliveries',
	},
	{
		attribute: 'products',
		title: 'Products',
	},
];

export const DistributorSearchPage = () => {
	const fallbackSearch = async (q: string): Promise<Distributor[]> => {
		console.log('Fallback Search', q);
		return [];
	};

	return (
		<SearchWithFacets<Distributor>
			indexName="Distributor"
			title="Distributors"
			info="Distributors are clients that receive content from Newstex."
			queryBy={['name']}
			resultsTable={DistributorsTable}
			fallbackSearch={fallbackSearch}
			facets={DistributorFacetConfig}
			actions={<CreateDistributorButton />}
		/>
	);
};
