export const APPROVED_DIMENSION_NAMES = [
	'browser',
	'browser_version',
	'criteria',
	'device',
	'id',
	'language',
	'name',
	'os',
	'os_version',
	'period',
	'path',
	'provenance',
	'qualified',
	'referrer',
	'score',
	'screen_resolution',
	'session_id',
	'tag',
	'timezone',
	'url',
	'user_agent',
	'user_id',
	'user_name',
	'viewport',
	'from',
	'to',
	'comment',
	'mode',
] as const;
export type ApprovedDimensionName = (typeof APPROVED_DIMENSION_NAMES)[number];

export type EventData = Partial<Record<ApprovedDimensionName, string | number | boolean | null | undefined>>;

export function isDimensionName(name: string): name is ApprovedDimensionName {
	return APPROVED_DIMENSION_NAMES.includes(name as ApprovedDimensionName);
}
