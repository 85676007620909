import type { Product } from '@newstex/types/product';
import { SearchFacetConfig, SearchWithFacets } from '~/components/search-with-facets';
import { ProductsTableBase } from '~/components/tables/products-table';

export const ProductFacetConfig: SearchFacetConfig[] = [
	{
		attribute: 'group',
		title: 'Group',
		options: [
			{ label: 'Active', value: '' },
			{ label: 'Clients', value: 'clients' },
			{ label: 'Legacy', value: 'Legacy' },
			{ label: 'Commercial', value: 'commercial' },
			{ label: 'ACI', value: 'aci' },
			{ label: 'Test', value: 'test' },
			{ label: 'SPAM', value: 'spam' },
			{ label: 'Archived', value: 'archived' },
		],
	},
];

export const ProductSearchPage = () => {
	const fallbackSearch = async (q: string): Promise<Product[]> => {
		console.log('Fallback Search', q);
		return [];
	};

	return (
		<SearchWithFacets
			indexName="Product"
			title="Products"
			info="Products are used to group content and control distribution."
			resultsTable={ProductsTableBase}
			fallbackSearch={fallbackSearch}
			facets={ProductFacetConfig}
			defaultFilters={{
				group: ['!archived'],
			}}
		/>
	);
};
