import { BUILD_DATE, version } from '@newstex/core/version';
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function Footer() {
	return (
		<footer className="footer shadow align-self-end py-3 px-xl-5 w-100">
			<Container fluid>
				<div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
					<div className="fw-bold order-1 order-md-1">
						© 2023-{new Date().getFullYear()}
						&nbsp;<a
							className="ml-3 mr-3"
							target="_blank"
							href="https://newstex.com"
							aria-current="page">Newstex</a>
						&nbsp;
						All Rights Reserved
					</div>

					<div className="d-flex gap-2 order-3 order-md-2">
						<a
							target="_blank"
							href="https://newstex.com/privacy-policy"
						>Privacy</a>
						<span>&mdash;</span>
						<a
							target="_blank"
							href="https://newstex.com/cookie-policy"
						>Cookie Policy</a>
						<span>&mdash;</span>
						<a
							target="_blank"
							href="https://newstex.com/terms-of-use"
						>Terms of Use</a>
					</div>

					<div className="text-muted order-2 order-md-3">
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Version: {version}<br/>
									Build Date: {BUILD_DATE}
								</Tooltip>
							}
						>
							<div className="mb-0">Version {version}</div>
						</OverlayTrigger>
					</div>
				</div>
			</Container>
		</footer>
	);
}
