import {
	faDownload,
	faSpinner,
	faTrash,
	faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HotTable, HotTableClass } from '@handsontable/react';
import { calculateMatchScore } from '@newstex/core/websafe-utils';
import { Publication } from '@newstex/types/content';
import Handsontable from 'handsontable';
import { registerAllModules } from 'handsontable/registry';
import {
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import PieChart from '~/components/charts/PieChart';
import PageHeader from '~/components/PageHeader';
import { useSearch } from '~/providers/search';

// Register Handsontable modules
registerAllModules();

const BATCH_SIZE = 100; // Maximum number of concurrent searches

const DEFAULT_COLUMNS = [
	{
		data: 'name',
		title: 'Name',
		type: 'text',
	},
	{
		data: 'url',
		title: 'URL',
		type: 'text',
	},
	{
		data: 'newstex_id',
		title: 'Newstex ID',
		type: 'text',
		readOnly: true,
	},
	{
		data: 'status',
		title: 'Status',
		type: 'text',
		readOnly: true,
	},
	{
		data: 'website',
		title: 'Website',
		type: 'text',
		readOnly: true,
	},
	{
		data: 'confidence',
		title: 'Match Confidence',
		type: 'numeric',
		readOnly: true,
		numericFormat: {
			pattern: '0.00%',
		},
		renderer(
			instance: Handsontable.Core,
			td: HTMLTableCellElement,
			row: number,
			col: number,
			prop: string | number,
			value: any,
			cellProperties: Handsontable.CellProperties,
		) {
			if (value === null || value === undefined) {
				td.innerHTML = '';
			} else {
				td.innerHTML = `${(value * 100).toFixed(2)}%`;
			}
			return td;
		},
	},
];

const EMPTY_ROW = {
	name: '',
	url: '',
	newstex_id: '',
	status: '',
	website: '',
	confidence: 0,
};

// Create a new empty row each time
const createEmptyRow = () => ({ ...EMPTY_ROW });

const DEFAULT_DATA = Array(5).fill(null).map(() => createEmptyRow());

export function MatchToolPage() {
	const { searchClient } = useSearch();
	const hotRef = useRef<HotTableClass | null>(null);
	const [running, setRunning] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [data, setData] = useState<any[]>(DEFAULT_DATA);
	const [isDragging, setIsDragging] = useState(false);

	const findMatches = useCallback(async () => {
		if (!searchClient) {
			return DEFAULT_DATA;
		}

		setRunning(true);
		try {
			const rowsToProcess = data.filter((row) => {
				const name = (row.name || '').toLowerCase();
				const url = (row.url || '').toLowerCase();
				return (name && name !== 'name' && name !== 'publisher')
					|| (url && url !== 'url' && url !== 'url placeholder' && url !== 'website');
			});
			const results: typeof EMPTY_ROW[] = [];

			// Process in batches
			for (let i = 0; i < rowsToProcess.length; i += BATCH_SIZE) {
				const batch = rowsToProcess.slice(i, i + BATCH_SIZE);
				const batchResults = await Promise.all(
					batch.map(async (row) => {
						const result = await searchClient.search<Publication>({
							indexName: 'Publication',
							query: row.url || row.name || '',
							query_by: ['name', 'url'],
						});
						console.log('result', result);

						if (!result.hits?.length) {
							return {
								...row,
								status: 'No Match',
								confidence: 0,
							};
						}

						for (const match of result.hits) {
							// Calculate the confidence score using calculateMatchScore
							let confidence = 0;

							// Try matching just the URLs first
							if (row.url && match.url) {
								const urlMatch = calculateMatchScore(row.url, match.url);
								confidence = urlMatch / Math.max(row.url.length, match.url.length);
							}

							// Then try matching the title
							if (row.name && match.name) {
								const titleMatch = calculateMatchScore(row.name, match.name);
								confidence = Math.max(confidence, titleMatch / Math.max(row.name.length, match.name.length));
							}

							// Then try matching the provided title to the URL
							if (row.name && match.url) {
								const normalizedName = row.name.toLowerCase().replace(/[^a-z0-9]/g, '');
								const normalizedUrl = match.url.toLowerCase().replace(/[^a-z0-9]/g, '');
								const titleURLMatch = calculateMatchScore(normalizedName, normalizedUrl);
								confidence = Math.max(
									confidence,
									titleURLMatch / Math.max(normalizedName.length, normalizedUrl.length),
								);
							}

							// Approve anything with a confidence score of 0.9 or higher
							if (confidence > 0.9) {
								return {
									...row,
									publication_name: match.name,
									newscore_url: `https://admin.newstex.com/publication/${match.$id}`,
									newscore_id: match.$id,
									newstex_id: match.newstex_id,
									status: match.status,
									website: match.url,
									confidence,
								};
							}
						}

						// Otherwise, it's a no match
						return {
							...row,
							status: 'No Match',
						};
					}),
				);
				results.push(...batchResults);
			}

			// Ensure we always have at least 5 rows
			while (results.length < 5) {
				results.push(createEmptyRow());
			}

			setData(results);
		} finally {
			setRunning(false);
		}
	}, [searchClient, data]);

	// Helper function to properly parse CSV rows with quoted values
	const parseCsvRow = (row: string): string[] => {
		console.log('Parsing row:', row);
		const result: string[] = [];
		let inQuotes = false;
		let currentValue = '';

		for (let i = 0; i < row.length; i++) {
			const char = row[i];

			if (char === '"') {
				if (inQuotes && row[i + 1] === '"') {
					// Handle escaped quotes
					currentValue += '"';
					i++;
				} else {
					// Toggle quotes state
					inQuotes = !inQuotes;
				}
			} else if (char === ',' && !inQuotes) {
				// End of field
				result.push(currentValue);
				currentValue = '';
			} else {
				currentValue += char;
			}
		}

		// Add the last field and trim any quotes
		if (currentValue.startsWith('"') && currentValue.endsWith('"')) {
			currentValue = currentValue.slice(1, -1);
		}
		result.push(currentValue);

		// Trim all values
		const trimmedResult = result.map((val) => val.trim());
		console.log('Parsed result:', trimmedResult);
		return trimmedResult;
	};

	const processFile = useCallback((file: File) => {
		setUploading(true);
		const reader = new FileReader();
		reader.onload = (event) => {
			const csv = event.target?.result as string;

			// Split on any type of line ending and filter out empty lines
			const lines = csv.split(/\r?\n/).filter((line) => line.trim());

			// Skip empty file
			if (lines.length === 0) {
				console.log('Empty file');
				setUploading(false);
				return;
			}

			// Parse headers (first row)
			const headers = parseCsvRow(lines[0]).map((h) => h.trim().toLowerCase());
			const nameIndex = headers.findIndex((h) => h.includes('name') || h === 'title' || h === 'publisher');
			const urlIndex = headers.findIndex((h) => h.includes('url') || h === 'website' || h === 'domain');

			// If we can't find the required columns, try to use the first two columns
			const finalNameIndex = nameIndex >= 0 ? nameIndex : 0;
			const finalUrlIndex = urlIndex >= 0 ? urlIndex : 1;

			// Process data rows (skip header row)
			let processedRows = lines.slice(1)
				.map((line) => {
					console.log('Processing line:', line);
					const columns = parseCsvRow(line);
					console.log('Parsed columns:', columns);
					const name = columns[finalNameIndex]?.trim() || '';
					let url = columns[finalUrlIndex]?.trim() || '';
					if (url?.toLowerCase() === 'url placeholder' || url?.toLowerCase() === 'website' || url?.toLowerCase() === 'url') {
						url = '';
					}
					return {
						...createEmptyRow(),
						name,
						url,
					};
				})
				.filter((row) => {
					// Filter out rows with empty or placeholder data
					const name = row.name.toLowerCase();
					const url = row.url.toLowerCase();

					const isValidName = name !== ''
						&& name !== 'name'
						&& name !== 'publisher';

					const isValidUrl = url !== ''
						&& url !== 'url'
						&& url !== 'url placeholder'
						&& url !== 'website';

					// Row is valid if it has either a valid name or a valid URL
					const isValid = isValidName || isValidUrl;

					return isValid;
				});

			// Ensure we have at least 5 rows
			if (processedRows.length < 5) {
				const emptyRowsNeeded = 5 - processedRows.length;
				const emptyRows = Array(emptyRowsNeeded).fill(null).map(() => createEmptyRow());
				processedRows = [...processedRows, ...emptyRows];
			}

			setData(processedRows);
			setUploading(false);
		};
		reader.readAsText(file);
	}, []);

	const handleFileUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (!file) return;
		processFile(file);
	}, [processFile]);

	const handleDragOver = useCallback((e: React.DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(true);
	}, []);

	const handleDragLeave = useCallback((e: React.DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(false);
	}, []);

	const handleDrop = useCallback((e: React.DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(false);

		const file = e.dataTransfer.files[0];
		if (!file || !file.name.endsWith('.csv')) return;
		processFile(file);
	}, [processFile]);

	const exportData = useCallback(() => {
		const csv = data
			.filter((row) => row.name || row.url)
			.map((row) => Object.values(row).join(','))
			.join('\n');

		const blob = new Blob([csv], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = 'matches.csv';
		a.click();
		window.URL.revokeObjectURL(url);
	}, [data]);

	// Calculate summary data for the pie chart
	const summaryData = useMemo(() => {
		const validRows = data.filter((row) => row.name || row.url);
		const statusCounts = validRows.reduce((acc, row) => {
			const status = row.status || 'Pending';
			acc[status] = (acc[status] || 0) + 1;
			return acc;
		}, {} as Record<string, number>);

		const labels = Object.keys(statusCounts);
		const values = Object.values(statusCounts) as number[];
		const colors = labels.map((label) => {
			switch (label) {
				case 'Active': return '#4CAF50';
				case 'Lead': return '#FFC107';
				case 'Inactive': return '#F44336';
				case 'No Match': return '#9E9E9E';
				default: return '#9E9E9E';
			}
		});

		return {
			labels,
			values,
			colors,
		};
	}, [data]);

	return (
		<Container fluid>
			<PageHeader title="Match Tool" />
			{summaryData.values.length > 1 && (
				<Row className="mb-3">
					<Col md={6}>
						<Card>
							<Card.Header>
								<h5 className="mb-0">Match Summary</h5>
							</Card.Header>
							<Card.Body>
								<PieChart
									labels={summaryData.labels}
									values={summaryData.values}
									colors={summaryData.colors}
									aspectRatio={2}
									showPercentage={true}
								/>
							</Card.Body>
						</Card>
					</Col>
					<Col md={6}>
						<Card>
							<Card.Header>
								<h5 className="mb-0">Match Statistics</h5>
							</Card.Header>
							<Card.Body>
								<div className="d-flex flex-column gap-2">
									{['Active', 'Inactive', 'Lead', 'No Match'].map((status) => {
										const index = summaryData.labels.indexOf(status);
										const count = index >= 0 ? summaryData.values[index] : 0;
										const total = summaryData.values.reduce((sum, val) => sum + val, 0);
										const percentage = total > 0 ? ((count / total) * 100).toFixed(1) : '0.0';

										return (
											<div key={status} className="d-flex justify-content-between align-items-center border-bottom border-secondary pb-2">
												<span style={{ color: summaryData.colors[index] || '#9E9E9E', fontWeight: 500 }}>
													{status}
												</span>
												<span>
													{count.toLocaleString()} ({percentage}%)
												</span>
											</div>
										);
									})}
									<div className="d-flex justify-content-between align-items-center">
										<strong>Total</strong>
										<strong>{summaryData.values.reduce((sum, val) => sum + val, 0).toLocaleString()}</strong>
									</div>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			)}
			<Row className="mb-3">
				<Col>
					<Container fluid>
						<input
							type="file"
							accept=".csv"
							onChange={handleFileUpload}
							className="d-none"
							id="csv-upload"
						/>
						{uploading && (
							<Alert className="text-center" variant="info">
								<FontAwesomeIcon icon={faSpinner} spin className="me-2" />
								Uploading CSV...
								<FontAwesomeIcon icon={faSpinner} spin className="ms-2" />
							</Alert>
						)}
						{running && (
							<Alert className="text-center" variant="info">
								<FontAwesomeIcon icon={faSpinner} spin className="me-2" />
								Finding matches. Please wait...
								<FontAwesomeIcon icon={faSpinner} spin className="ms-2" />
							</Alert>
						)}
						<Row>
							<Col>
								<div className="d-flex justify-content-end mb-3">
									<Button
										variant="outline-primary"
										onClick={() => document.getElementById('csv-upload')?.click()}
										disabled={running}
										className="me-2"
									>
										<FontAwesomeIcon icon={faUpload} className="me-1" />
										Upload CSV
									</Button>
									<Button
										onClick={exportData}
										variant="outline-secondary"
										disabled={running}
										className="me-2"
									>
										<FontAwesomeIcon icon={faDownload} className="me-1" />
										Export to CSV
									</Button>
									<Button
										onClick={() => {
											setData(DEFAULT_DATA);
											const hot = hotRef.current?.hotInstance;
											if (hot) {
												hot.clear();
											}
										}}
										variant="outline-danger"
										className="me-2"
										disabled={running}
									>
										<FontAwesomeIcon icon={faTrash} className="me-1" />
										Clear
									</Button>
									<Button
										onClick={findMatches}
										variant="primary"
										disabled={running}
									>
										{running ? (
											<>
												<FontAwesomeIcon icon={faSpinner} spin className="me-1" />
												Matching...
											</>
										) : (
											'Find Matches'
										)}
									</Button>
								</div>
								<Card
									onDragOver={handleDragOver}
									onDragLeave={handleDragLeave}
									onDrop={handleDrop}
									className={`position-relative ${isDragging ? 'border-primary' : ''}`}
									style={{ height: 'calc(100vh - 200px)' }}
								>
									{isDragging && (
										<div
											className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center bg-primary bg-opacity-10"
											style={{ zIndex: 1000 }}
										>
											<div className="text-center">
												<FontAwesomeIcon icon={faUpload} size="3x" className="text-primary mb-3" />
												<h4 className="text-primary">Drop CSV file here</h4>
											</div>
										</div>
									)}
									<Card.Body className="p-0 h-100">
										<HotTable
											id="match-tool-table"
											ref={hotRef}
											data={data}
											colHeaders={DEFAULT_COLUMNS.map((col) => col.title)}
											columns={DEFAULT_COLUMNS}
											licenseKey="non-commercial-and-evaluation"
											stretchH="all"
											width="100%"
											height="100%"
											rowHeaders={true}
											filters={true}
											dropdownMenu={true}
											columnSorting={true}
											minSpareRows={1}
											contextMenu={{
												items: {
													row_above: {
														name: 'Insert row above',
													},
													row_below: {
														name: 'Insert row below',
													},
													separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
													remove_row: {
														name: 'Remove row',
													},
													separator2: Handsontable.plugins.ContextMenu.SEPARATOR,
													copy: {
														name: 'Copy',
													},
													cut: {
														name: 'Cut',
													},
													paste: {
														name: 'Paste',
														disabled() {
															return false;
														},
														callback() {
															// Handle paste
														},
													},
												},
											}}
										/>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>
		</Container>
	);
}
