import {
	ThreadListItemPrimitive,
	ThreadListPrimitive,
	useAssistantRuntime,
	useThreadListItem,
} from '@assistant-ui/react';
import { faArchive, faList, faPlus } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type FC, useEffect, useState } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { TooltipIconButton } from './tooltip-icon-button';

/**
 * ThreadListNew Component
 *
 * Renders a "New Thread" dropdown item that creates a new chat thread when clicked.
 * Uses the assistant runtime to handle thread creation and switching.
 */
const ThreadListNew: FC = () => {
	const runtime = useAssistantRuntime();
	return (
		<ThreadListPrimitive.New asChild>
			<Dropdown.Item className="d-flex align-items-center gap-2" onClick={() => {
				runtime.threads.switchToNewThread();
			}}>
				<FontAwesomeIcon icon={faPlus} />
				New Thread
			</Dropdown.Item>
		</ThreadListPrimitive.New>
	);
};

/**
 * ThreadListItemTitle Component
 *
 * Displays the title of an individual thread item and handles thread switching.
 * Uses both assistant runtime and thread item hooks to manage thread state and interactions.
 * Clicking the title switches the active thread to this thread.
 */
const ThreadListItemTitle: FC = () => {
	const runtime = useAssistantRuntime();
	const item = useThreadListItem();
	const [title, setTitle] = useState(item.title);

	useEffect(() => {
		const thread = runtime.threads.getById(item.id);
		const threadState = thread.getState();
		if (threadState.messages?.length) {
			// Find the first User message, and use the first 64 characters of the content as the title
			const userMessage = threadState.messages.find((message) => message.role === 'user');
			if (userMessage) {
				for (const part of userMessage.content) {
					if (part.type === 'text') {
						setTitle(part.text.slice(0, 64));
						break;
					}
				}
			}
		}
	}, [item]);

	return (
		<span
			id={item.id}
			className="me-2"
			onClick={() => {
				runtime.threads.switchToThread(item.id);
			}}
		>{title || 'New Chat'}</span>
	);
};

/**
 * ThreadListItemArchive Component
 *
 * Renders an archive button for each thread item.
 * Uses TooltipIconButton for consistent styling and user feedback.
 * The actual archive functionality is handled by ThreadListItemPrimitive.Archive.
 */
const ThreadListItemArchive: FC = () => {
	return (
		<ThreadListItemPrimitive.Archive asChild>
			<TooltipIconButton
				className="ms-auto"
				variant="link"
				color="danger"
				size="sm"
				tooltip="Archive thread"
			>
				<FontAwesomeIcon icon={faArchive} className="text-muted" />
			</TooltipIconButton>
		</ThreadListItemPrimitive.Archive>
	);
};

/**
 * ThreadListItem Component
 *
 * Represents a single thread item in the dropdown menu.
 * Combines the thread title and archive button in a flex container.
 * Uses ThreadListItemPrimitive.Root to handle thread-specific state and behaviors.
 */
const ThreadListItem: FC = () => {
	return (
		<ThreadListItemPrimitive.Root asChild>
			<Dropdown.Item className="d-flex align-items-center py-2">
				<ThreadListItemPrimitive.Trigger
					className="d-flex align-items-center flex-grow-1"
					asChild
				>
					<ThreadListItemTitle />
				</ThreadListItemPrimitive.Trigger>
				<ThreadListItemArchive />
			</Dropdown.Item>
		</ThreadListItemPrimitive.Root>
	);
};

/**
 * ThreadListItems Component
 *
 * Container component that renders the list of thread items.
 * Includes a divider and "New Thread" option at the bottom of the list.
 * Uses ThreadListPrimitive.Items to manage the collection of threads.
 */
const ThreadListItems: FC = () => {
	return (
		<>
			<ThreadListPrimitive.Items components={{ ThreadListItem }} />
			<Dropdown.Divider />
			<ThreadListNew />
		</>
	);
};

/**
 * ThreadListButton Component
 *
 * The main exported component that renders a dropdown button for thread management.
 * Features:
 * - Displays a list icon with a tooltip
 * - Shows all available threads in a dropdown menu
 * - Allows switching between threads
 * - Provides thread archival functionality
 * - Includes option to create new threads
 */
export const ThreadListButton: FC = () => {
	return (
		<ThreadListPrimitive.Root className="d-inline-block">
			<Dropdown className="d-inline-block">
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip>Threads</Tooltip>}
				>
					<Dropdown.Toggle variant="link" id="thread-list-dropdown">
						<FontAwesomeIcon icon={faList} />
					</Dropdown.Toggle>
				</OverlayTrigger>
				<Dropdown.Menu>
					<ThreadListItems />
				</Dropdown.Menu>
			</Dropdown>
		</ThreadListPrimitive.Root>
	);
};
