import { Delivery as DeliveryType } from '@newstex/types/delivery';

import type { CollectionFieldSchmaForObject } from './types';

export const Delivery: CollectionFieldSchmaForObject<DeliveryType> = [
	{
		name: 'products',
		type: 'string[]',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'queue_name',
		type: 'string',
		optional: true,
		store: true,
		facet: true,
		defaultSearchOrder: 3,
	},
	{
		name: 'recipient',
		type: 'string',
		optional: true,
		store: true,
		facet: true,
		defaultSearchOrder: 4,
	},
	{
		name: 'distributor',
		type: 'string',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'status',
		type: 'string',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'type',
		type: 'string',
		optional: true,
		store: true,
		facet: true,
		defaultSearchOrder: 30,
	},
	{
		name: 'story_template',
		type: 'string',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'products',
		type: 'string[]',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'name',
		type: 'string',
		store: true,
		defaultSearchOrder: 10,
	},
	{
		name: 'description',
		type: 'string',
		optional: true,
		store: true,
		defaultSearchOrder: 20,
	},
	{
		name: 'host',
		type: 'string',
		optional: true,
		store: true,
		defaultSearchOrder: 1,
	},
	{
		name: 'username',
		type: 'string',
		optional: true,
		store: true,
		defaultSearchOrder: 2,
	},
];
