/**
 * Report View Page
 */
import { faDownload } from '@fortawesome/pro-duotone-svg-icons';
import { faExclamationTriangle, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Parameter, Report } from '@newstex/types/report';
import { Results } from '@newstex/types/results';
import { kebab as kebabCase, title as titleCase } from 'case';
import {
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Alert,
	Badge,
	Button,
	Card,
	Col,
	Container,
	Form,
	Row,
	Spinner,
} from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CloneReportButton from '~/components/buttons/clone-report-button';
import EditChartButton from '~/components/buttons/edit-chart-button';
import EditParametersButton from '~/components/buttons/edit-parameters-button';
import EditReportButton from '~/components/buttons/edit-report-button';
import DataTable, { DataTableMethods } from '~/components/data-table';
import LoadingSpinner from '~/components/LoadingSpinner';
import { PageTitle } from '~/components/page-title';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { RefreshButton } from '~/components/refresh-button';
import ReportChart from '~/components/report-chart';
import { RequirePermissions } from '~/components/require-permissions';
import ReportDataTable from '~/components/tables/report-data-table';
import { useAPI } from '~/providers/api-provider';

interface DatabaseOptions {
	[key: string]: { value: string; label: string }[];
}

interface ParameterInputProps {
	param: Parameter;
	value: string;
	onChange: (value: string) => void;
	required: boolean;
	options?: { value: string; label: string }[];
}

function ParameterInput({
	param, value, onChange, required, options,
}: ParameterInputProps) {
	const getInputType = (paramType: string): string => {
		if (paramType === 'number') return 'number';
		if (paramType === 'date') return 'date';
		return 'text';
	};

	const handleClear = () => {
		onChange('');
	};

	if (param.type === 'boolean') {
		return (
			<Form.Check
				id={`param-${param.name}`}
				type="checkbox"
				label={param.label || titleCase(param.name)}
				checked={value === 'true'}
				onChange={(e) => onChange(e.target.checked.toString())}
				required={required}
			/>
		);
	}

	// Handle dropdown options
	if ((param.options && param.options.length > 0) || options?.length > 0) {
		return (
			<Form.Group className="mb-3">
				<Form.Label>{param.label || titleCase(param.name)}</Form.Label>
				<div className="d-flex">
					<Form.Select
						id={`param-${param.name}`}
						value={value || ''}
						onChange={(e) => onChange(e.target.value)}
						required={required}
						className="flex-grow-1"
					>
						<option value="">Select an option</option>
						{options
							? options.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))
							: param.options?.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
					</Form.Select>
					{value && (
						<Button
							variant="link"
							className="text-muted ms-2 px-2"
							onClick={handleClear}
							title="Clear value"
						>
							<FontAwesomeIcon icon={faTimes} />
						</Button>
					)}
				</div>
			</Form.Group>
		);
	}

	return (
		<Form.Group className="mb-3">
			<Form.Label>{param.label || titleCase(param.name)}</Form.Label>
			<div className="d-flex">
				<Form.Control
					id={`param-${param.name}`}
					type={getInputType(param.type)}
					value={value || ''}
					onChange={(e) => onChange(e.target.value)}
					required={required}
					className="flex-grow-1"
				/>
				{value && (
					<Button
						variant="link"
						className="text-muted ms-2 px-2"
						onClick={handleClear}
						title="Clear value"
					>
						<FontAwesomeIcon icon={faTimes} />
					</Button>
				)}
			</div>
		</Form.Group>
	);
}

export function ReportPage() {
	const api = useAPI();
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(true);
	const [report, setReport] = useState<Report | null>(null);
	const [data, setData] = useState<Results<any> | null>(null);
	const [error, setError] = useState<Error | null>(null);
	const [paramValues, setParamValues] = useState<Record<string, string>>(Object.fromEntries(searchParams.entries()));
	const [parameterOptions, setParameterOptions] = useState<DatabaseOptions>({});
	const [loadingOptions, setLoadingOptions] = useState(false);
	const tableRef = useRef<DataTableMethods>();

	// Function to fetch all parameter options in a single batch
	const fetchAllParameterOptions = async (parameters: Parameter[]) => {
		const databaseParameters = parameters.filter(
			(p) => p.optionsSource === 'database' && p.optionsTable && p.optionsColumn,
		);

		if (!databaseParameters.length) return;

		setLoadingOptions(true);
		try {
			const requests = databaseParameters.map((param) => {
				let url = `database/options?table=${encodeURIComponent(param.optionsTable!)}&column=${encodeURIComponent(param.optionsColumn!)}`;
				if (param.optionsLabelColumn) {
					url += `&labelColumn=${encodeURIComponent(param.optionsLabelColumn)}`;
				}
				return api.fetchWithAuth<Results<{ value: string; label?: string }>>(url)
					.then((response) => ({
						paramName: param.name,
						options: response.items?.map((item) => ({
							value: item.value,
							label: item.label || item.value,
						})) || [],
					}));
			});

			const results = await Promise.all(requests);
			const newOptions: DatabaseOptions = {};
			for (const { paramName, options } of results) {
				newOptions[paramName] = options;
			}
			setParameterOptions(newOptions);
		} catch (err) {
			console.error('Failed to fetch parameter options:', err);
			toast.error('Failed to load one or more parameter options');
		} finally {
			setLoadingOptions(false);
		}
	};

	// Initialize parameter values and fetch options when report changes
	useEffect(() => {
		if (report?.parameters) {
			const defaultValues: Record<string, string> = {};
			for (const param of report.parameters) {
				const urlValue = searchParams.get(param.name);
				if (urlValue !== null) {
					defaultValues[param.name] = urlValue;
				} else if (param.default !== undefined) {
					defaultValues[param.name] = String(param.default);
				}
			}
			setParamValues(defaultValues);
			fetchAllParameterOptions(report.parameters);
		}
	}, [report]);

	const loadData = async (nextToken?: string) => {
		setLoading(true);
		setError(null);
		try {
			const queryParams = new URLSearchParams(paramValues);
			if (nextToken) {
				queryParams.set('nextToken', nextToken);
			}
			const dataResponse = await api.fetchWithAuth<Results<any>>(
				`reports/${id}/data${queryParams ? `?${queryParams}` : ''}`,
				{
					headers: {
						'Cache-Control': 'no-cache',
					},
				},
			);

			if (!nextToken) {
				setData(dataResponse);
			} else if (dataResponse.items?.length) {
				setData((prev) => ({
					...prev,
					items: [...(prev?.items || []), ...dataResponse.items],
				}));
			}

			if (dataResponse.nextToken) {
				await loadData(dataResponse.nextToken);
			} else {
				setLoading(false);
			}
		} catch (err: any) {
			console.error('Failed to fetch report data:', err);
			if (nextToken && err.message?.toLowerCase().includes('invalid pagination token')) {
				console.error('Removing invalid pagination token');
				if (searchParams.get('nextToken')) {
					searchParams.delete('nextToken');
				}
				return loadData();
			}

			setError(err);
			toast.error(
				(
					<div>
						<b>Failed to fetch report data</b>
						<br />
						<code>{err.message || String(err)}</code>
					</div>
				), {
					toastId: 'report-data-error',
					autoClose: false,
					closeOnClick: true,
					onClick: (e) => {
						// Copy the error message to the clipboard
						navigator.clipboard.writeText(err.message || String(err));
						toast.info('Error message copied to clipboard');
						return e;
					},
				},
			);
			setLoading(false);
		}
	};

	const fetchReport = async (refresh = false) => {
		setError(null);
		try {
			const response = await api.fetchWithAuth<Results<Report>>(
				`reports/${id}`,
				refresh ? { cache: 'reload' } : {},
			);
			if (response?.items?.[0]) {
				setReport(response.items[0]);
				// Add query parameters if provided
				try {
					await loadData();
				} catch (err: any) {
					console.error('Failed to fetch report data:', err);
					setError(err);
					toast.error(
						(
							<div>
								<b>Failed to fetch report data</b>
								<br />
								<code>{err.message || String(err)}</code>
							</div>
						), {
							toastId: 'report-data-error',
							autoClose: false,
							closeOnClick: true,
							onClick: (e) => {
							// Copy the error message to the clipboard
								navigator.clipboard.writeText(err.message || String(err));
								toast.info('Error message copied to clipboard');
								return e;
							},
						},
					);
				}
			} else {
				setData(null);
				setReport(null);
			}
		} catch (err) {
			console.error('Failed to fetch report:', err);
			setError(err as Error);
		} finally {
			setLoading(false);
		}
	};

	// Memoize the chart data to prevent unnecessary re-renders
	const chartData = useMemo(() => {
		if (!report || !data) return null;
		return <ReportChart report={report} data={data} />;
	}, [report, data]);

	useEffect(() => {
		fetchReport();
	}, [api, id]);

	useEffect(() => {
		// Update the Parameters based on the query
		if (report?.query?.includes('{{')) {
			const parameters = report.query.match(/{{(\w+)}}/g)?.map((match) => match.slice(2, -2).trim());
			const newParameters = [];
			if (parameters?.length) {
				for (const parameter of parameters) {
					if (!report?.parameters?.find((p) => p.name === parameter)) {
						newParameters.push({
							name: parameter,
							label: titleCase(parameter),
							type: 'string',
							required: true,
						});
					}
				}
			}

			if (newParameters.length) {
				setReport({
					...report,
					parameters: [...(report?.parameters || []), ...newParameters],
				});
			}
		}
	}, [report?.query]);

	if (loading) {
		return (
			<Container fluid className="px-lg-1">
				<div className="text-center">
					<LoadingSpinner loading={loading} />
				</div>
			</Container>
		);
	}

	if (!report) {
		if (error) {
			return (
				<Container fluid className="px-lg-1">
					<Alert variant="danger" className="text-center">
						<Alert.Heading as="h1">Error loading report</Alert.Heading>
						<p className="mb-0">{error.message}</p>
					</Alert>
				</Container>
			);
		}
		return (
			<Container fluid className="px-lg-1">
				<h1 className="text-center">Report not found</h1>
			</Container>
		);
	}

	return (
		<Container fluid className="px-lg-1">
			<PageTitle
				title={report.name}
				breadcrumbs={[
					{
						label: 'Reports',
						href: '/reports',
					},
				]}
				item={{
					...report,
					$type: 'Report',
				}}
			>
				{error && <div className="mb-3 d-flex justify-content-center">
					<Alert variant="danger">
						<Alert.Heading className="text-center">
							<FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
							Error loading report
							<FontAwesomeIcon icon={faExclamationTriangle} className="ms-2" />
						</Alert.Heading>
						<p className="mb-0">{error.message}</p>
					</Alert>
				</div>}
				<div className="d-flex justify-content-end">
					<RequirePermissions permissions={['admin']}>
						<CloneReportButton
							report={report}
							size="sm"
							variant="outline-secondary"
							onSave={fetchReport}
						/>
						<div className="ms-2">
							<EditReportButton
								report={report}
								onSave={fetchReport}
								size="sm"
								variant="outline-secondary"
							/>
						</div>
					</RequirePermissions>
					<div className="ms-2">
						<RefreshButton
							refreshHandler={() => fetchReport(true)}
							size="sm"
							loading={loading}
							setLoading={setLoading}
						/>
					</div>
				</div>
			</PageTitle>

			<Row>
				<Col md={8}>
					<Card>
						<Card.Header>
							{Boolean(data?.items?.length) && (
								<Button
									variant="outline-secondary"
									size="sm"
									onClick={() => tableRef?.current?.exportToCSV(`${report.name} - ${new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })}`)}
								>
									<FontAwesomeIcon icon={faDownload} /> Export CSV
								</Button>
							)}

							<div className="d-flex justify-content-end float-end">
								<EditChartButton
									variant="outline-secondary"
									report={report}
									data={data || undefined}
									onSave={() => fetchReport(true)}
									size="sm"
								/>
							</div>
						</Card.Header>
						{data?.items?.length ? (<Card.Body>
							{/* Show the chart if it's not a table */}
							{report?.chart?.type !== 'table' && (<>
								{chartData}
								{/* Standard data table below charts that aren't tables */}
								<DataTable
									className="table-bordered"
									ref={tableRef}
									items={data.items}
									columns={Object.keys(data.items[0]).map((key) => ({
										id: key,
										header: titleCase(key),
										accessorKey: key,
										enableSorting: true,
									}))}
									striped
									sortable
									pagination
								/>
							</>
							)}
							{report?.chart?.type === 'table' && (<>
								<ReportDataTable
									report={report}
									data={data}
									tableRef={tableRef}
								/>
							</>)}
						</Card.Body>) : (<Card.Body>
							<div className="text-center p-4">
								<em>No data available</em>
							</div>
						</Card.Body>)}
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<Card.Header className="d-flex justify-content-between align-items-center">
							<h3 className="mb-0">Parameters</h3>
							<RequirePermissions permissions={['admin']}>
								<EditParametersButton
									report={report}
									onSave={async (parameters) => {
										try {
											await api.fetchWithAuth(`reports/${report.id}`, {
												method: 'POST',
												body: JSON.stringify({
													...report,
													parameters,
												}),
											});
											fetchReport(true);
										} catch (err) {
											console.error('Failed to save parameters:', err);
											throw err;
										}
									}}
									variant="outline-secondary"
									size="sm"
								/>
							</RequirePermissions>
						</Card.Header>
						{Boolean(report.parameters?.length) && (
							<Card.Body>
								<Form onSubmit={(e) => {
									e.preventDefault();
									setSearchParams(new URLSearchParams(paramValues), { replace: true });
									fetchReport(true);
								}}>
									{loadingOptions && (
										<div className="text-center mb-3">
											<Spinner animation="border" size="sm" className="me-2" />
											<span>Loading parameter options...</span>
										</div>
									)}
									{report.parameters?.map((param, index) => (
										<ParameterInput
											key={index}
											param={param}
											value={paramValues[param.name] || ''}
											onChange={(value) => setParamValues({
												...paramValues,
												[param.name]: value,
											})}
											required={!!param.required}
											options={parameterOptions[param.name]}
										/>
									))}
									<div className="d-flex justify-content-end">
										<Button
											type="submit"
											variant="primary"
											size="sm"
											disabled={loading || loadingOptions}
										>
											{loading ? 'Loading...' : 'Update Report'}
										</Button>
									</div>
								</Form>
							</Card.Body>
						)}
					</Card>

					<Card>
						<Card.Header>
							<div className="d-flex justify-content-between align-items-start">
								<h3 className="mb-0">Description</h3>
								{report.category && (
									<Badge
										bg="light"
										text="dark"
										className={`report-${kebabCase((report.category || '').toLowerCase())}`}
									>
										{report.category}
									</Badge>
								)}
							</div>
						</Card.Header>
						<Card.Body>
							<p>{report.description || 'No description provided.'}</p>
						</Card.Body>
						<Card.Footer className="d-flex justify-content-center">
							<PropertyDisplayValue propName="access_groups" propValue={report.access_groups} />
						</Card.Footer>
					</Card>

					<Card>
						<Card.Header>
							<h3>Query</h3>
						</Card.Header>
						<Card.Body>
							<pre className="bg-light p-3 rounded">
								<code>{report.query}</code>
							</pre>
						</Card.Body>
					</Card>

					<Card>
						<Card.Header>
							<h3>Metadata</h3>
						</Card.Header>
						<Card.Body>
							<dl className="row">
								<dt className="col-sm-4">Created By</dt>
								<dd className="col-sm-8">
									<PropertyDisplayValue propName="created_by" propValue={report.created_by} />
								</dd>

								<dt className="col-sm-4">Created At</dt>
								<dd className="col-sm-8">
									<PropertyDisplayValue propName="created_at" propValue={report.created_at} />
								</dd>

								<dt className="col-sm-4">Updated At</dt>
								<dd className="col-sm-8">
									<PropertyDisplayValue propName="updated_at" propValue={report.updated_at} />
								</dd>
							</dl>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
