import * as Sentry from '@sentry/react';
import { Container } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import { CustomErrorBoundary } from '~/components/error';
import { hasPermission } from '~/components/require-permissions';
import { Logout } from '~/providers/auth';

import Default from '../default';

import { AlertPage } from './alerts/get';
import { AlertSearchPage } from './alerts/search';
import { CategoryPage } from './category/get';
import { CategorySearchPage } from './category/search';
import { ContentPage } from './content/get';
import { PublicationSearchPage, PublisherSearchPage } from './content/search';
import { ContentStoryPage } from './content/stories';
import { DashboardPage } from './dashboards/get';
import { DashboardSearchPage } from './dashboards/search';
import { DeliveryPage } from './delivery/get';
import { DeliverySearchPage } from './delivery/search';
import { DistributorPage } from './distributor/get';
import { DistributorSearchPage } from './distributor/search';
import { Docs } from './docs';
import { FeedPage } from './feed/get';
import { FeedSearchPage } from './feed/search';
import { IconsPage } from './icons';
import { KnowledgeBasePage } from './kb/get';
import { KnowledgeBaseSearchPage } from './kb/search';
import { NewscrunchPage } from './newscrunch/default';
import { NewscrunchMatchingReportPage } from './newscrunch/matching-report';
import { ProductPage } from './products/get';
import { ProductSearchPage } from './products/search';
import { ReportPage } from './reports/get';
import { ReportSearchPage } from './reports/search';
import { RevenuePage } from './revenue/get';
import { RevenueListPage } from './revenue/list';
import { AdvancedSearchPage } from './search';
import { SitePage } from './sites/get';
import { SiteSearchPage } from './sites/search';
import { StoryPage } from './stories/get';
import { StorySearchPage } from './stories/search';
import { NewsletterSubscriptionPage } from './subscribers/get';
import { NewsletterSearchPage } from './subscribers/search';
import { ToolsPage } from './tools';
import { FeedValidatorPage } from './tools/feed-validator';
import { HubSpotToolsPage } from './tools/hubspot';
import { MatchToolPage } from './tools/match';
import { QualifyLeadsPage } from './tools/qualify';
import { UserManagementPage } from './users/search';

function NotFound() {
	return (
		<Container fluid>
			<center className="p-5">
				<h1>NOT FOUND</h1>
				<div>Whatever you're looking for, it's not here (yet)</div>
			</center>
		</Container>
	);
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default function NewstexRoutes() {
	return (
		<CustomErrorBoundary>
			<SentryRoutes>
				<Route path="/categories" Component={CategorySearchPage} />
				<Route path="/categories/:id" Component={CategoryPage} />
				<Route path="/category" Component={CategorySearchPage} />
				<Route path="/category/:id" Component={CategoryPage} />

				<Route path="/content" Component={PublisherSearchPage} />
				<Route path="/content/:id" Component={ContentPage} />
				<Route path="/content/:id/stories" Component={ContentStoryPage} />
				<Route path="/contents" Component={PublisherSearchPage} />
				<Route path="/contents/:id" Component={ContentPage} />
				<Route path="/contents/:id/stories" Component={ContentStoryPage} />

				<Route path="/publisher" Component={PublisherSearchPage} />
				<Route path="/publisher/:id" Component={ContentPage} />
				<Route path="/publisher/:id/stories" Component={ContentStoryPage} />
				<Route path="/publishers" Component={PublisherSearchPage} />
				<Route path="/publishers/:id" Component={ContentPage} />
				<Route path="/publishers/:id/stories" Component={ContentStoryPage} />

				<Route path="/publication" Component={PublicationSearchPage} />
				<Route path="/publication/:id" Component={ContentPage} />
				<Route path="/publication/:id/stories" Component={ContentStoryPage} />
				<Route path="/publications" Component={PublicationSearchPage} />
				<Route path="/publications/:id" Component={ContentPage} />
				<Route path="/publications/:id/stories" Component={ContentStoryPage} />

				<Route path="/logout" Component={Logout} />
				<Route path="/stories" Component={StorySearchPage} />
				<Route path="/stories/:id" Component={StoryPage} />
				<Route path="/story" Component={StorySearchPage} />
				<Route path="/story/:id" Component={StoryPage} />

				<Route path="/revenue" Component={RevenueListPage} />
				<Route path="/revenue/:id" Component={RevenuePage} />

				{/* Tools available to all users */}
				<Route path="/tools/feed-validator" Component={FeedValidatorPage} />

				{/* Admin-only routes */}
				{hasPermission(['admin']) && (
					<>
						<Route path="/alerts" Component={AlertSearchPage} />
						<Route path="/alerts/:id" Component={AlertPage} />
						<Route path="/delivery" Component={DeliverySearchPage} />
						<Route path="/delivery/:id" Component={DeliveryPage} />
						<Route path="/docs" Component={Docs} />
						<Route path="/docs/kb" Component={KnowledgeBaseSearchPage} />
						<Route path="/docs/kb/:id" Component={KnowledgeBasePage} />
						<Route path="/feed" Component={FeedSearchPage} />
						<Route path="/feed/:id" Component={FeedPage} />
						<Route path="/feeds" Component={FeedSearchPage} />
						<Route path="/feeds/:id" Component={FeedPage} />
						<Route path="/icons" Component={IconsPage} />
						{hasPermission(['newscrunch']) && (<>
							<Route path="/newscrunch" Component={NewscrunchPage} />
							<Route path="/newscrunch/matching-report" Component={NewscrunchMatchingReportPage} />
							<Route path="/newscrunch/:id" Component={NewscrunchPage} />
						</>)}
						<Route path="/product" Component={ProductSearchPage} />
						<Route path="/product/:id" Component={ProductPage} />
						<Route path="/products" Component={ProductSearchPage} />
						<Route path="/products/:id" Component={ProductPage} />
						<Route path="/reports" Component={ReportSearchPage} />
						<Route path="/reports/:id" Component={ReportPage} />
						<Route path="/dashboards" Component={DashboardSearchPage} />
						<Route path="/dashboards/new" Component={DashboardPage} />
						<Route path="/dashboards/:id" Component={DashboardPage} />
						<Route path="/dashboards/:id/edit" Component={DashboardPage} />
						<Route path="/tools" Component={ToolsPage} />
						<Route path="/tools/qualify-leads" Component={QualifyLeadsPage} />
						<Route path="/tools/match" Component={MatchToolPage} />
						<Route path="/tools/hubspot" Component={HubSpotToolsPage} />
						<Route path="/tools/hubspot/:id" Component={HubSpotToolsPage} />
						<Route path="/tools/:id" Component={ToolsPage} />
						<Route path="/search" Component={AdvancedSearchPage} />
						<Route path="sites" Component={SiteSearchPage} />
						<Route path="sites/:id" Component={SitePage} />
						<Route path="subscribers" Component={NewsletterSearchPage} />
						<Route path="subscribers/:id" Component={NewsletterSubscriptionPage} />
						<Route path="/distributors" Component={DistributorSearchPage} />
						<Route path="/distributors/:id" Component={DistributorPage} />
						{hasPermission(['admin', 'user-admin']) && (
							<>
								<Route path="/users" Component={UserManagementPage} />
							</>
						)}
					</>
				)}
				<Route path="/" Component={Default} />
				<Route path="*" Component={NotFound} />
			</SentryRoutes>
		</CustomErrorBoundary>
	);
}
