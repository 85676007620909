import { ALL_MODELS } from '@newstex/ai/bedrock';
import { Dropdown } from 'react-bootstrap';
import { useAIChat } from '~/providers/ai-chat-provider';

export function ModelSelector() {
	const { selectedModel, setSelectedModel } = useAIChat();
	const displayName = selectedModel === 'default' ? 'Default'
		: Object.entries(ALL_MODELS).find(([_, arn]) => arn === selectedModel)?.[0] || 'Default';

	return (
		<Dropdown className="d-inline-block ms-2">
			<Dropdown.Toggle variant="link" size="sm" className="text-muted">
				{displayName}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item
					key="default"
					active={selectedModel === 'default'}
					onClick={() => setSelectedModel('default')}
				>
					Default
				</Dropdown.Item>
				<Dropdown.Divider />
				{Object.entries(ALL_MODELS).map(([name, arn]) => (
					<Dropdown.Item
						key={arn}
						active={selectedModel === arn}
						onClick={() => setSelectedModel(arn)}
					>
						{name}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
}
