/**
 * useSetPageTitle - A hook to set the page title dynamically
 *
 * This hook is a simple way to set the page title from any component without
 * having to use the PageTitle component. It updates the title in the central
 * page title store, which is then displayed in the header.
 *
 * Basic usage:
 * ```tsx
 * function MyPage() {
 *   useSetPageTitle('My Page');
 *
 *   return <div>...</div>;
 * }
 * ```
 *
 * For dynamic titles (e.g., after data loading):
 * ```tsx
 * function ProductDetails() {
 *   const [product, setProduct] = useState(null);
 *
 *   // Load product data...
 *
 *   // Update title when product data changes
 *   useSetPageTitle(product?.name || 'Loading Product...', [product?.name]);
 *
 *   return <div>...</div>;
 * }
 */

import { useEffect } from 'react';
import { usePageTitle } from '~/providers/page-title-provider';

/**
 * Hook to set the page title
 *
 * @param title The title to set
 * @param dependencies Optional dependencies array to trigger title updates (defaults to [])
 */
export function useSetPageTitle(title: string, dependencies: React.DependencyList = []) {
	const { setTitle } = usePageTitle();

	useEffect(() => {
		setTitle(title);

		// Optional: Update document title too
		document.title = `${title} | NewsCore`;

		return () => {
			// Optionally reset the title when the component unmounts
			// This is usually not needed since the next page will set its own title
		};
	}, [title, setTitle, ...dependencies]);
}

/**
 * For direct title updates outside of React components,
 * use the usePageTitle hook directly in a component
 * that can trigger the update.
 */
