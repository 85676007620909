/**
 * Boolean Edit Field
 */
import { TypeNameToTypeMap } from '@newstex/types/infer-types';
import { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';

import PropertyDisplayName from '../property-display-name';

import { EditTextFieldProps } from './text';

export interface EditBooleanFieldProps<T extends keyof TypeNameToTypeMap> extends EditTextFieldProps<T> {
	details?: string,
}

export default function EditableBooleanField<T extends keyof TypeNameToTypeMap>({
	typeName,
	propKey,
	item,
	updates,
	setUpdates,
	details,
}: EditBooleanFieldProps<T>) {
	const [checked, setChecked] = useState<boolean>();

	useEffect(() => {
		if (updates?.[propKey] !== undefined) {
			setChecked(Boolean(updates[propKey]));
		} else if (item?.[propKey] !== undefined) {
			setChecked(Boolean(item?.[propKey]));
		} else {
			setChecked(false);
		}
	}, [updates, item]);

	return (
		<>
			<Col md={3} className="text-end fw-bold">
				<Form.Label><PropertyDisplayName propKey={propKey} typeName={typeName} /></Form.Label>
			</Col>
			<Col md={3}>
				<Form.Check
					type="switch"
					name={String(propKey)}
					checked={checked}
					onChange={(e) => {
						if (setUpdates) {
							setUpdates({
								...updates,
								[propKey]: e.target.checked,
							});
						}
					}} />
				{details && <Form.Text className="text-muted">{details}</Form.Text>}
			</Col>
		</>
	);
}
