import { Distributor as DistributorType } from '@newstex/types/distributor';

import type { CollectionFieldSchmaForObject } from './types';

export const Distributor: CollectionFieldSchmaForObject<DistributorType> = [
	{
		name: '$id',
		type: 'string',
		store: true,
	},
	{
		name: 'name',
		type: 'string',
		store: true,
		defaultSearchOrder: 1,
	},
	{
		name: 'code',
		type: 'string',
		optional: true,
		store: true,
		facet: true,
		defaultSearchOrder: 2,
	},
	{
		name: 'alt_names',
		type: 'string[]',
		optional: true,
		store: true,
		defaultSearchOrder: 3,
	},
	{
		name: 'status',
		type: 'string',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'deliveries',
		type: 'string[]',
		optional: true,
		store: true,
		facet: true,
	},
];
