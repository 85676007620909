import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { EarningsReportByClientAndPublication } from '@newstex/types/revenue';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatDatePeriod } from '~/lib/utils';

import DataTable, { DataTableMethods } from '../data-table';

const GroupedRevenueTable = ({ revenue }: { revenue: EarningsReportByClientAndPublication[] }) => {
	const dataTableRef = useRef<DataTableMethods>(null);

	const groupedData = useMemo(() => {
		const dataMap: Record<string, Record<string, any>> = {};

		for (const row of revenue) {
			const formattedPeriod = formatDatePeriod(row.period, { month: 'long' });
			const key = `${formattedPeriod}-${row.publicationNewstexID}`;
			if (!dataMap[key]) {
				dataMap[key] = {
					period: formattedPeriod,
					publicationName: row.publicationName,
					publicationNewstexID: row.publicationNewstexID,
					publicationNewscoreID: row.publicationNewscoreID,
					publication: `${row.publicationName} (${row.publicationNewstexID})`,
					publisher: row.publisherName,
					total: 0,
				};
			}
			dataMap[key][row.client] = (dataMap[key][row.client] || 0) + row.earnings;
			dataMap[key].total += row.earnings;
		}

		return Object.values(dataMap);
	}, [revenue]);

	const clientSet = useMemo(() => {
		const set = new Set<string>();
		for (const { client } of revenue) {
			set.add(client);
		}
		return set;
	}, [revenue]);

	const columnHelper = createColumnHelper<Record<string, any>>();
	const columns = useMemo(() => {
		return [
			columnHelper.accessor('period', {
				header: 'Period',
				enableSorting: true,
			}),
			columnHelper.accessor('publication', {
				header: 'Publication',
				enableSorting: true,
				cell: ({ getValue, row: { original }}) => {
					console.log('Publication', original);
					return (
						<Link to={`/publications/${original.publicationNewscoreID}`}>
							{getValue()}
						</Link>
					);
				},
			}),
			...Array.from(clientSet).map((client) => columnHelper.accessor(client, {
				header: client,
				enableSorting: true,
				cell: ({ getValue }) => {
					const earnings = getValue();
					return earnings ? earnings.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00';
				},
			})),
			columnHelper.accessor('total', {
				header: 'Total',
				enableSorting: true,
				cell: ({ getValue }) => {
					const total = Number(getValue() || 0);
					return total.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
				},
			}),
		];
	}, [clientSet]);

	return (
		<>
			<DataTable
				columns={columns}
				items={groupedData}
				ref={dataTableRef}
				striped
				sortable
			/>
			<div className="float-end pb-2">
				<Button variant="outline-secondary" size="sm" onClick={() => {
					dataTableRef.current?.exportToCSV('Newstex Revenue Statement', {
						Period: 'period',
						Publisher: 'publisher',
						Publication: 'publication',
						...Object.fromEntries(Array.from(clientSet).map((client) => [client, client])),
						Total: 'total',
					});
				}}>
					<FontAwesomeIcon icon={faDownload} /> CSV
				</Button>
			</div>
		</>
	);
};

export default GroupedRevenueTable;
