import { Dexie } from 'dexie';
import { PersistStorage } from 'zustand/middleware';

// Create a Dexie database for storing Zustand state
const db = new Dexie('newscore');

const DEXIE_STORE_NAMES = ['qualification', 'qualificationStats'] as const;

// Define the schema for our stores table
db.version(1).stores(
	DEXIE_STORE_NAMES.reduce((acc, name) => ({
		...acc,
		[name]: 'key',
	}), {}),
);

interface StoreData {
	key: string;
	value: string;
}

/**
 * Creates a storage adapter for Zustand that uses Dexie (IndexedDB)
 * This provides a more reliable storage solution than localStorage
 * with better storage limits and async storage capabilities
 */
export function createDexieStorage<T = any>(storeName: typeof DEXIE_STORE_NAMES[number]): PersistStorage<T> {
	return {
		getItem: async (name: string) => {
			try {
				const result = await db.table<StoreData>(storeName).get(name);
				return result?.value ? JSON.parse(result.value) : null;
			} catch (e) {
				console.warn(`Failed to get item from Dexie storage: ${name}`, e);
				return null;
			}
		},

		setItem: async (name: string, value: any): Promise<void> => {
			try {
				await db.table<StoreData>(storeName).put({
					key: name,
					value: JSON.stringify(value),
				});
			} catch (e) {
				console.warn(`Failed to set item in Dexie storage: ${name}`, e);
			}
		},

		removeItem: async (name: string): Promise<void> => {
			try {
				await db.table(storeName).delete(name);
			} catch (e) {
				console.warn(`Failed to remove item from Dexie storage: ${name}`, e);
			}
		},
	};
}
