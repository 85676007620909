import { COLORS, COLOR_NAMES } from '@newstex/types/report';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { HexColorPicker } from 'react-colorful';

interface ColorPickerProps {
	value?: string;
	onChange: (color: string) => void;
}

export default function ColorPicker({
	value = COLORS[0],
	onChange,
}: ColorPickerProps) {
	const [showPicker, setShowPicker] = useState(false);

	const handlePresetColorClick = (color: string, e: React.MouseEvent) => {
		e.stopPropagation();
		onChange(color);
		setShowPicker(false);
	};

	const handleCustomColorChange = (color: string) => {
		onChange(color);
	};

	return (
		<div className="position-relative">
			<div
				className="color-preview"
				style={{
					width: '38px',
					height: '38px',
					backgroundColor: value,
					border: '1px solid #ced4da',
					borderRadius: '4px',
					cursor: 'pointer',
				}}
				onClick={() => setShowPicker(!showPicker)}
				title={COLOR_NAMES.get(value) || value}
			/>

			{showPicker && (
				<div className="position-absolute" style={{
					right: 0, top: '100%', zIndex: 1100, marginTop: '4px',
				}}>
					<div
						style={{
							position: 'fixed',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							zIndex: 1,
						}}
						onClick={() => setShowPicker(false)}
					/>
					<div
						className="bg-white p-3 rounded shadow-lg"
						style={{
							width: '500px',
							position: 'relative',
							zIndex: 2,
						}}
						onClick={(e) => e.stopPropagation()}
					>
						<div className="d-flex gap-3">
							<div className="flex-grow-1">
								<h6 className="mb-2">Preset Colors</h6>
								<div
									className="d-flex flex-wrap gap-2"
									style={{ position: 'relative', zIndex: 3 }}
								>
									{COLORS.map((color) => (
										<div
											key={color}
											style={{
												width: '32px',
												height: '32px',
												backgroundColor: color,
												border: '1px solid #ced4da',
												borderRadius: '4px',
												cursor: 'pointer',
												boxShadow: value === color ? '0 0 0 2px #0d6efd' : undefined,
												position: 'relative',
												zIndex: 3,
											}}
											onClick={(e) => handlePresetColorClick(color, e)}
											title={COLOR_NAMES.get(color)}
										/>
									))}
								</div>
							</div>
							<div style={{ position: 'relative', zIndex: 3 }}>
								<h6 className="mb-2">Custom Color</h6>
								<HexColorPicker
									color={value}
									onChange={handleCustomColorChange}
								/>
								<div className="d-flex justify-content-end mt-2">
									<Button
										size="sm"
										variant="secondary"
										onClick={(e) => {
											e.stopPropagation();
											setShowPicker(false);
										}}
									>
										Close
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
