import type { Alert } from '@newstex/types/alert';
import { Card, Col, Row } from 'react-bootstrap';
import BarChart, { BarChartProps } from '~/components/charts/BarChart';

interface AlertHistoryProps {
	alert: Alert;
}

export function AlertHistory({ alert }: AlertHistoryProps) {
	// Only show chart if we have last_values
	if (!alert.last_values?.length) {
		return (
			<Card className="mb-3">
				<Card.Header>
					<h5 className="card-title">Alert History</h5>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col>
							<p className="text-muted">No historical data available yet.</p>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		);
	}

	// Sort values by timestamp ascending
	const sortedValues = [...alert.last_values].sort((a, b) => a.timestamp - b.timestamp);

	// Create datasets for the chart
	const datasets: BarChartProps['datasets'] = [
		{
			type: 'line',
			label: 'Min',
			data: sortedValues.map(() => alert.expected.min ?? 0),
			color: 'lightGray',
			borderColor: 'lightGray',
			borderDash: [5, 5],
			fill: false,
			borderWidth: 1,
			order: 1,
			yAxisID: 'yAxes',
			pointRadius: 0,
			spanGaps: true,
		},
		{
			type: 'line',
			label: 'Max',
			data: sortedValues.map(() => alert.expected.max ?? Math.max(...sortedValues.map((v) => v.value)) * 1.5),
			color: 'green',
			borderColor: 'green',
			backgroundColor: 'rgba(0, 255, 0, 0.1)',
			borderDash: [5, 5],
			fill: '-1',
			borderWidth: 1,
			order: 2,
			yAxisID: 'yAxes',
			pointRadius: 0,
			spanGaps: true,
		},
		{
			type: 'line',
			label: 'Value',
			data: sortedValues.map((v) => v.value),
			color: 'blue',
			order: 3, // Make sure bars are drawn on top
			yAxisID: 'yAxes',
		},
	];

	// Create labels from timestamps
	const labels = sortedValues.map((v) => ({
		name: new Date(v.timestamp * 1000).toLocaleString('en-US', { timeZone: 'America/New_York' }),
	}));

	return (
		<Card className="mb-3">
			<Card.Header>
				<h5 className="card-title">Alert History</h5>
			</Card.Header>
			<Card.Body>
				<Row>
					<Col>
						<BarChart
							datasets={datasets}
							labels={labels}
							height="200px"
							grid={true}
						/>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
}
