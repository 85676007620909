import { z } from 'zod';

import type { BaseObject, LegacyBaseObject, TypedObject } from './base';

export type TypedObjectName<T extends TypedObject> = T extends LegacyBaseObject
	? T['__type__'] : T extends BaseObject
		? T['$type'] : never;

/**
 * Explicit reference to another object
 */
export interface ExplicitReference<T extends TypedObject = any> {
	$type: TypedObjectName<T>;
	$id: string;
}

/**
 * Schema for explicit references to other objects
 */
export const ExplicitReferenceSchema = <T extends TypedObject>() => z.object({
	$type: z.string().superRefine((val, ctx): val is string => {
		// Runtime validation is not possible for generic types
		// We could potentially add validation here if we had a list of valid type names
		return true;
	}),
	$id: z.string(),
});

/**
 * Use this when using just string IDs
 */
export type ReferenceString<T> = string | T;

/**
 * Schema for string references that can also be full objects
 */
export const ReferenceStringSchema = <T>() => z.union([
	z.string(),
	z.lazy(() => z.custom<T>((val) => true)), // Runtime validation is not possible for generic types
]);

/**
 * Use this when it could be a string ID or a full reference object
 */
export type Reference<T extends TypedObject> = string | ExplicitReference<T> | T;

/**
 * Schema for references that can be strings, explicit references, or full objects
 */
export const ReferenceSchema = <T extends TypedObject>() => z.union([
	z.string(),
	ExplicitReferenceSchema<T>(),
	z.lazy(() => z.custom<T>((val) => true)), // Runtime validation is not possible for generic types
]);

export function convertReferenceToString(ref: Reference<any> | ReferenceString<any>): string {
	if (typeof ref === 'string') {
		return ref;
	}

	if (typeof ref === 'object') {
		if ('$id' in ref) {
			return ref.$id;
		}

		if ('__id__' in ref) {
			return ref.__id__;
		}

		if (ref.$type === 'Category' && ref.code) {
			return ref.code;
		}
	}

	if (ref.id) {
		return ref.id;
	}

	if (ref.code) {
		return ref.code;
	}

	throw new Error(`Could not convert reference to string: ${ref}`);
}

export function convertReferencesToStrings(
	refs: (Reference<any> | ReferenceString<any>)[] | Set<Reference<any>>,
): string[] {
	if (!refs) {
		return [];
	}

	if (Array.isArray(refs)) {
		return refs.map(convertReferenceToString);
	}

	if (typeof refs === 'object' && refs instanceof Set) {
		return Array.from(refs).map(convertReferenceToString);
	}

	return [convertReferenceToString(refs)];
}
