// Products/:id
import type { Delivery } from '@newstex/types/delivery';
import type { Product } from '@newstex/types/product';
import { useEffect, useState } from 'react';
import {
	Alert,
	Card,
	Col,
	Container,
	Row,
	Table,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import EditButton from '~/components/edit-button';
import LoadingSpinner from '~/components/LoadingSpinner';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { RefreshButton } from '~/components/refresh-button';
import { RequirePermissions } from '~/components/require-permissions';
import { StatusBadge } from '~/components/status-badge';
import { PropertiesTable } from '~/components/tables/properties-table';
import ViewPublicationsButton from '~/components/view-publications-button';
import ViewStoriesButton from '~/components/view-stories-button';
import { useAPI } from '~/providers/api-provider';
import { useSearch } from '~/providers/search';

export function ProductPage() {
	const params = useParams();
	const api = useAPI();
	const searchContext = useSearch();
	const [product, setProduct] = useState<Product>();
	const [loading, setLoading] = useState(true);
	const [relatedDeliveries, setRelatedDeliveries] = useState<Delivery[]>([]);
	const [loadingDeliveries, setLoadingDeliveries] = useState(false);

	const fetchRelatedDeliveries = async (prod: Product) => {
		if (!prod?.$id || !searchContext.searchClient) return;

		setLoadingDeliveries(true);
		try {
			// Use search system instead of direct API
			const searchResp = await searchContext.searchClient.search({
				indexName: 'Delivery',
				query: '',
				filter_by: `products:${prod.$id}`,
				per_page: 100,
			});

			setRelatedDeliveries(searchResp?.hits || []);
		} catch (err) {
			console.error('Failed to load related deliveries:', err);
		} finally {
			setLoadingDeliveries(false);
		}
	};

	const fetchData = async (refresh = false) => {
		setLoading(true);
		const resp = await api.fetchWithAuth(
			`resources/Product/${params.id}`,
			refresh ? { cache: 'reload' } : undefined,
		);
		const fetchedProduct = resp.items?.[0];
		setProduct(fetchedProduct);
		await fetchRelatedDeliveries(fetchedProduct);
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, [params]);

	return (
		<Container fluid>
			<div className="title-wrapper pt-30">
				<div className="row align-items-center">
					<div className="col-md-6">
						<div className="title">
							<h2>
								{product?.name}
							</h2>
							<h6 className="text-muted">
								{product?.group}
							</h6>
						</div>
					</div>
					<div className="col-md-6 text-end">
						<div className="breadcrumb-wrapper">
							<nav aria-label="breadcrumb" className="mb-0">
								<ol className="breadcrumb">
									<li className="breadcrumb-item active">
										<a>Product</a>
									</li>
								</ol>
							</nav>
							<div className="float-end">
								<RequirePermissions permissions={['admin']}>
									<span className="ps-1">
										<EditButton
											item={{
												...product,
												$type: 'Product',
											}}
											size="sm"
											refreshHandler={() => fetchData(true)}
										/>
									</span>
								</RequirePermissions>
								<span className="ps-1">
									{product && <ViewStoriesButton product={product.$id} size="sm" />}
								</span>
								<span className="ps-1">
									{product && <ViewPublicationsButton products={product.$id} size="sm" />}
								</span>
								<span className="ps-1">
									<RefreshButton size="sm" refreshHandler={() => {
										return fetchData(true);
									}} />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr />

			<Row>
				<Col lg={9} xxl={9}>
					<Card className="product">
						<Card.Body>
							<LoadingSpinner loading={loading} />
							<PropertiesTable item={product} />
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<h6>Related Deliveries</h6>
					<hr />
					{loadingDeliveries && (
						<LoadingSpinner loading={loadingDeliveries} />
					)}
					{!loadingDeliveries && relatedDeliveries.length > 0 && (
						<Table striped>
							<thead>
								<tr>
									<th>Name</th>
									<th>Type</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								{relatedDeliveries.map((delivery) => (
									<tr key={delivery.$id}>
										<td>
											<Link to={`/delivery/${delivery.$id}`}>
												{delivery.name}
											</Link>
											<div className="text-muted small">
												<PropertyDisplayValue propName="distributor" propValue={delivery.distributor} />
											</div>
										</td>
										<td><PropertyDisplayValue propName="type" propValue={delivery.type} /></td>
										<td>
											<StatusBadge status={delivery.status} />
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					)}
					{!loadingDeliveries && relatedDeliveries.length === 0 && (
						<Alert variant="info">No deliveries associated with this product</Alert>
					)}
				</Col>
			</Row>
		</Container>
	);
}
