import type { KeySchema } from 'typesense/lib/Typesense/Key';
import { z } from 'zod';

import { BaseObjectSchema } from './base';
import { UnixTimestampSchema } from './dates';

/**
 * Available user groups for access control
 */
export const UserGroupSchema = z.enum([
	'admin',
	'newscrunch',
	'user-admin',
	'tech',
	'publisher',
	'distributor',
	'user',
	// Grants access to beta features (Early access to new features)
	'beta',
	// Grants access to premium features (AI Analytics tools)
	'premium',
	// Grants access to advanced features (AI Publishing tools)
	'advanced',
]);

export type UserGroup = z.infer<typeof UserGroupSchema>;

/**
 * User type determined by the highest auth_group.
 */
export const UserTypeSchema = z.enum([
	'admin',
	'publisher',
	'distributor',
	'user',
]);

export type UserType = z.infer<typeof UserTypeSchema>;

/**
 * Base schema for user-related fields
 */
const UserBaseSchema = z.object({
	// Base object fields
	...BaseObjectSchema.shape,
	id: z.string(),
	name: z.string(),
	email: z.string().email(),
	domain: z.string().optional(),
	picture: z.string().url().optional(),
	phone: z.string().optional(),
	username: z.string().optional(),
	password: z.string().optional(),
	theme: z.enum(['light', 'dark']).optional(),

	user_type: UserTypeSchema.optional(),
	auth_groups: z.array(UserGroupSchema).optional(),
	role: z.enum([
		'tech',
		'reporting',
		'support',
	]).optional(),

	search_keys: z.record(z.string(), z.any() as z.ZodType<KeySchema>).optional(),

	/**
	 * Last time the user logged in
	 */
	last_login: UnixTimestampSchema.optional(),

	/**
	 * Last IP address used to login
	 */
	last_ip: z.string().optional(),

	// Content owned by this user
	owned_content: z.array(z.string()).optional(),

	/**
	 * Hidden from the user list until they log in again
	 */
	archived: z.boolean().optional(),

	/**
	 * Email address of the user who assumed this user
	 */
	assumed_by: z.string().email().optional(),

	/**
	 * Distributor ID
	 */
	distributor: z.string().optional(),

	/**
	 * Products this user is allowed to access
	 */
	allowed_products: z.array(z.string()).optional(),
}).omit({ $id: true });

/**
 * Schema for a user in the system
 */
export const UserSchema = UserBaseSchema.extend({
	$type: z.literal('User'),
});

export type User = z.infer<typeof UserSchema>;

/**
 * Schema for an authentication token
 */
export const AuthTokenSchema = UserBaseSchema.extend({
	$type: z.literal('AuthToken'),
	secret: z.string().optional(),
});

export type AuthToken = z.infer<typeof AuthTokenSchema>;

/**
 * Schema for an authenticated user from the auth provider
 */
export const AuthUserSchema = z.object({
	type: z.string().optional(),
	isAdmin: z.boolean().optional(),
	name: z.string(),
	given_name: z.string().optional(),
	family_name: z.string().optional(),
	email: z.string().email(),
	accountID: z.string(),
	accessToken: z.string(),
	idToken: z.string().optional(),
	sessionToken: z.string(),
	picture: z.string().url().optional(),
});

export type AuthUser = z.infer<typeof AuthUserSchema>;

/**
 * Schema for auth group configuration
 */
export const AuthGroupConfigSchema = z.object({
	name: z.string(),
	description: z.string(),
});

export type AuthGroupConfig = z.infer<typeof AuthGroupConfigSchema>;

export const AUTH_GROUPS = {
	admin: {
		name: 'Admin',
		description: 'Administrators have access to all features and settings.',
	},
	publisher: {
		name: 'Publisher',
		description: 'Publishers can manage their content and users.',
	},
} as const satisfies Record<string, AuthGroupConfig>;
