/* eslint-disable no-nested-ternary */
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
	faDownload,
	faExternalLinkAlt,
	faPause,
	faPlay,
	faRecycle,
	faTrash,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { QualificationScores } from '@newstex/ai/qualify';
import { createColumnHelper } from '@tanstack/react-table';
import { kebab as kebabCase } from 'case';
import { useMemo, useRef } from 'react';
import {
	Button,
	Col,
	OverlayTrigger,
	Row,
	Tooltip,
} from 'react-bootstrap';
import { useAnalytics } from '~/providers/analytics-provider';
import { LeadData } from '~/providers/api-provider';
import { useConfirm } from '~/providers/confirm';

import DataTable, { DataTableMethods } from '../data-table';
import { DateTime } from '../date-time';
import { HubSpotLink } from '../hubspot-link';

import '~/scss/qualify-leads.scss';

import { QualificationButtons } from './qualification-button';

interface LeadQualificationTableProps {
	items: LeadData[];
	running: boolean;
	abortController: AbortController;
	runQualification: (lead?: LeadData) => void;
	clearData: () => void;
	pause: () => void;
	className?: string;
	showScores: boolean;
	syncAll: () => void;
	syncLead: (lead: LeadData) => void;
	nextPage?: () => void;
	removeLead: (lead: LeadData) => void;
}

export function AIRankingReasons({ reasons }: { reasons: QualificationScores }) {
	return <ul>
		{Object.entries(reasons).map(([key, value]) => (
			<li key={key}><b>{key} ({value?.score || '0'}):</b> {value?.reason}</li>
		))}
	</ul>;
}

export const LeadQualificationTable = ({
	className,
	items,
	running,
	abortController,
	runQualification,
	clearData,
	showScores,
	pause,
	syncAll,
	syncLead,
	nextPage,
	removeLead,
}: LeadQualificationTableProps) => {
	const analytics = useAnalytics();
	const { confirmDialog } = useConfirm();
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<LeadData>();

	const columns = useMemo(() => [
		columnHelper.accessor('sync_status', {
			header: 'Sync Status',
			size: 10,
			meta: {
				priority: 1,
				responsiveLabel: 'Status',
			},
			cell: ({ getValue, row: { original }}) => {
				const value = getValue();
				return <>
					<div className={`sync-status-${kebabCase((value || '').toLowerCase())}`}>
						{value}
						{original.hubspot_id && <> <HubSpotLink id={original.hubspot_id} /></>}
					</div>
				</>;
			},
		}),
		columnHelper.accessor('url', {
			header: 'URL',
			size: 50,
			meta: {
				priority: 5,
				responsiveLabel: 'Website',
			},
			cell: ({ getValue, row: { original }}) => {
				const value = getValue();
				return (<>
					<div className="url" style={{
						maxWidth: '500px',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}>
						<a href={value} target="_blank" rel="noreferrer">{value}</a>
					</div>
					{original.feed_url && <div className="feed-url" style={{
						maxWidth: '500px',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'wrap',
					}}>
						<a
							href={original.feed_url}
							className="text-muted"
							target="_blank"
							rel="noreferrer"
						>
							{original.feed_url}
							<FontAwesomeIcon className="ms-1" icon={faExternalLinkAlt} />
						</a>
					</div>}
				</>);
			},
		}),
		columnHelper.accessor('name', {
			header: 'Name',
			size: 50,
			meta: {
				priority: 1,
				responsiveLabel: 'Name',
			},
			cell: ({ getValue, row: { original }}) => {
				const value = getValue();
				return (<>
					{original.hubspot_records?.[0]?.name ? (
						<div className="hubspot-name">
							<a
								href={`https://app.hubspot.com/contacts/20613241/record/0-2/${original.hubspot_records?.[0]?.hs_object_id || original.hubspot_records?.[0]?.id}`}
								target="_blank"
								rel="noreferrer"
							>
								{original.hubspot_records?.[0]?.name.slice(0, 100)} {original.hubspot_records?.[0]?.name.length > 100 ? '...' : ''}
							</a>
						</div>
					) : (
						<div className="name">{value}</div>
					)}
				</>);
			},
		}),
		columnHelper.accessor('qualified', {
			header: 'Tech Qualified',
			size: 10,
			meta: {
				priority: 2,
				responsiveLabel: 'Qualified',
			},
			cell: ({ getValue }) => {
				const value = getValue();
				return value == null ? '' : (value ? 'Yes' : 'No');
			},
		}),
		...(showScores ? [
			columnHelper.accessor('max_ai_score', {
				header: 'AI Score',
				size: 10,
				meta: {
					priority: 2,
					responsiveLabel: 'AI Score',
				},
				cell: ({ getValue, row: { original }}) => {
					const value = getValue();
					if (value == null) return '';
					let color = '';
					if (value > 1) {
						color = 'text-success';
					} else if (value < 0) {
						color = 'text-danger';
					}

					if (!original.ai_rankings && !original.rejection_reasons) {
						return <span className={color}>{value}</span>;
					}

					return (<>
						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip>
								{original.ai_rankings && <AIRankingReasons reasons={original.ai_rankings} />}
								{original.rejection_reasons && <ul>
									{original.rejection_reasons.map((reason) => <li key={reason}>{reason}</li>)}
								</ul>}
							</Tooltip>}
						>
							<span className={color}>{value}</span>
						</OverlayTrigger>
					</>);
				},
			}),
			columnHelper.accessor((row) => {
				if (row.qualified && row.ai_rankings) {
					return row.ai_rankings;
				}
				return row.rejection_reasons?.join(', ');
			}, {
				header: 'Score Reason',
				id: 'score_reason',
				size: 20,
				meta: {
					priority: 6,
					responsiveLabel: 'Reason',
				},
				cell: ({ row: { original }}) => {
					if (original.qualified && original.ai_rankings) {
						return <AIRankingReasons reasons={original.ai_rankings} />;
					}
					return <ul>
						{original.rejection_reasons?.map((reason) => <li key={reason}>{reason}</li>)}
					</ul>;
				},
			}),
		] : []),
		columnHelper.accessor((row) => row.stats?.average_word_count?.toFixed(0), {
			header: 'Average Word Count',
			id: 'avg_word_count',
			size: 10,
			meta: {
				priority: 6,
				responsiveLabel: 'Avg Words',
			},
		}),
		columnHelper.accessor((row) => {
			return Object.entries(row.newstex_categories || {})
				.filter(([, value]) => value > 10)
				.map(([key]) => key)
				.join(', ');
		}, {
			header: 'Possible Categories',
			id: 'categories',
			size: 20,
			meta: {
				priority: 6,
				responsiveLabel: 'Categories',
			},
		}),
		columnHelper.accessor('last_post_date', {
			header: 'Last Post Date',
			size: 20,
			meta: {
				priority: 4,
				responsiveLabel: 'Last Post',
			},
			cell: ({ getValue }) => {
				const value = getValue();
				if (!value) return '';

				return <DateTime ts={value} simple dateOnly />;
			},
		}),
		columnHelper.accessor((row) => {
			return {
				twitter: row.twitter,
				facebook: row.facebook,
				linkedin: row.linkedin,
			};
		}, {
			header: 'Social Media',
			id: 'social',
			size: 20,
			meta: {
				priority: 6,
				responsiveLabel: 'Social',
			},
			cell: ({ getValue }) => {
				const value = getValue();
				if (!value) {
					return '';
				}
				return <div className="social-media">
					{value.twitter && <a
						className="twitter ms-1"
						href={`https://twitter.com/${value.twitter}`}
						target="_blank"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faTwitter} />
					</a>}
					{value.facebook && <a
						className="facebook ms-1"
						href={value.facebook}
						target="_blank"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faFacebook} />
					</a>}
					{value.linkedin && <a
						className="linkedin ms-1"
						href={value.linkedin}
						target="_blank"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faLinkedin} />
					</a>}
				</div>;
			},
		}),
		columnHelper.accessor('email', {
			header: 'Email',
			meta: {
				priority: 6,
				responsiveLabel: 'Email',
			},
		}),
		columnHelper.display({
			id: 'actions',
			header: 'Actions',
			size: 80,
			meta: {
				priority: 1,
				responsiveLabel: 'Actions',
			},
			cell: ({ row: { original }}) => {
				return <>
					<Button variant="link-danger" className="text-danger" size="sm" onClick={async (e) => {
						e.preventDefault();
						if (await confirmDialog({
							title: 'Are you sure you want to remove this lead?',
							details: <>
								This will remove the lead <strong>{original.url}</strong>
								from this table, but not delete any records from HubSpot or NewsCore.
							</>,
						})) {
							removeLead(original);
						}
					}}>
						<FontAwesomeIcon icon={faTrash} />
					</Button>
					<Button disabled={running} variant="link-success" className="text-success" size="sm" onClick={async (e) => {
						e.preventDefault();
						if (await confirmDialog({
							title: 'Are you sure you want to qualify this lead?',
							details: <>
								This will qualify the lead <strong>{original.url}</strong> and update the AI scores.
							</>,
						})) {
							runQualification(original);
						}
					}}>
						<FontAwesomeIcon icon={original.sync_status && original.sync_status !== 'Waiting' ? faRecycle : faPlay} />
					</Button>
				</>;
			},
		}),
	], [showScores]);

	const exportToCSV = () => {
		analytics?.trackEvent('Export Qualified Leads', {}, items.length);
		dataTableRef.current?.exportToCSV<LeadData>('Qualified Leads', {
			sync_status: (item) => item.sync_status || '',
			hubspot_id: (item) => item.hubspot_id || '',
			qualified: (item) => (item.qualified ? 'Yes' : 'No'),
			url: (item) => item.url || '',
			feed_url: (item) => item.feed_url || '',
			name: (item) => item.name || '',
			max_ai_score: (item) => String(item.max_ai_score || '0'),
			score_reason: (item) => item.rejection_reasons?.join(', ') || '',
			avg_word_count: (item) => String(item.stats?.average_word_count?.toFixed(0) || '0'),
			categories: (item) => Object.entries(item.newstex_categories || {})
				.filter(([, value]) => value > 10)
				.map(([key]) => key)
				.join(', '),
			last_post_date: (item) => item.last_post_date || '',
			hubspot: (item) => item.hubspot_records?.map((record) => `${record.name} (${record.hs_object_id || record.id})`).join(', ') || '',
			twitter: (item) => item.twitter || '',
			facebook: (item) => item.facebook || '',
			linkedin: (item) => item.linkedin || '',
			email: (item) => item.email || '',
		});
	};

	return (
		<>
			<Row className="mt-3 mb-3">
				<Col>
					<Button
						onClick={exportToCSV}
						disabled={running}
						variant="outline-secondary"
					>
						<FontAwesomeIcon icon={faDownload} /> Export to CSV
					</Button>
				</Col>
				<Col>
					{running ? (
						<Button
							onClick={(e) => {
								e.preventDefault();
								pause();
							}}
							disabled={abortController.signal.aborted}
							variant="warning"
							className="ms-2 float-end"
						>
							<FontAwesomeIcon icon={faPause} /> Pause
						</Button>
					) : (
						<Button
							onClick={async (e) => {
								e.preventDefault();
								if (await confirmDialog('Are you sure you want to clear the data?')) {
									clearData();
								}
							}}
							variant="danger"
							className="ms-2 float-end"
						>
							<FontAwesomeIcon icon={faTrash} /> Clear
						</Button>
					)}

					<QualificationButtons
						items={items}
						running={running}
						syncAll={syncAll}
						runQualification={runQualification}
						nextPage={nextPage}
					/>
				</Col>
			</Row>
			<Row className={`mb-3 ${className}`}>
				<Col>
					<DataTable
						className="qualify-leads"
						ref={dataTableRef}
						columns={columns}
						items={items.toSorted((a, b) => {
							// Handle blank sync_status - move to bottom
							if (!a.sync_status && b.sync_status) return 1;
							if (a.sync_status && !b.sync_status) return -1;

							// Both are blank, maintain original order
							if (!a.sync_status && !b.sync_status) return 0;

							// Move non-updated status to top
							if (a.sync_status !== 'Updated' && b.sync_status === 'Updated') return -1;
							if (a.sync_status === 'Updated' && b.sync_status !== 'Updated') return 1;

							// Both are same category, maintain original order
							return 0;
						})}
						sortable={false}
						tableRowClassNameHelper={({ original }) => {
							let rowClassName = original.sync_status ? `sync-status-${kebabCase(original.sync_status)}` : '';
							if (original.qualified != null) {
								rowClassName += original.qualified ? ' qualified' : ' not-qualified';
							}
							return rowClassName;
						}}
						striped
					/>
				</Col>
			</Row>
		</>
	);
};
