import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Delivery } from '@newstex/types/delivery';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface ViewDeliveryStoriesButtonProps {
	delivery?: Delivery;
	size: 'sm' | 'lg';
	onClick?: () => void;
}

export default function ViewDeliveryStoriesButton({ delivery, size, onClick }: ViewDeliveryStoriesButtonProps) {
	const navigate = useNavigate();

	const routeToStories = () => {
		if (!delivery?.products?.length) {
			// If no products, just go to the stories page
			navigate('/stories');
			return;
		}

		// Create a comma-separated list of product IDs
		const productsList = delivery.products.join(',');
		console.log('Navigating to stories with products:', productsList);

		const filterQueryParameters = new URLSearchParams();
		filterQueryParameters.set('products', productsList);

		navigate(`/stories?${filterQueryParameters.toString()}`);
		onClick?.();
	};

	return (
		<Button
			size={size}
			variant="outline-secondary"
			onClick={routeToStories}
			disabled={!delivery?.products?.length}
			title={delivery?.products?.length ? `View stories for ${delivery.products.length} products` : 'No products associated with this delivery'}
		>
			<FontAwesomeIcon icon={faNewspaper} /> View Stories
		</Button>
	);
}
