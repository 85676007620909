/**
 * Edit Category Modal
 */
import { Category } from '@newstex/types/category';
import { useEffect, useState } from 'react';
import {
	Alert,
	Button,
	Container,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

import EditableMultiValueField from '../multi-value';
import EditableTextField from '../text';

export interface EditCategoryModalProps {
	item?: Category,
	show: boolean,
	onClose: (saved: boolean) => void,
}

export default function EditCategoryModal({
	item,
	show,
	onClose,
}: EditCategoryModalProps) {
	const api = useAPI();
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState<any>();
	const [updates, setUpdates] = useState<Partial<Category>>({});

	useEffect(() => {
		if (show) {
			setUpdates({});
		}
	}, [show, item?.code]);

	const doUpdate = async () => {
		if (item) {
			setError(null);

			setSaving(true);
			console.log('Saving', item, updates);
			try {
				await api.updateItem<any>({
					$type: 'Category',
					$id: item.code,
				}, {
					...updates,
				});
				onClose(true);
			} catch (err) {
				console.error(err);
				setError(err);
			}
			setTimeout(() => {
				setSaving(false);
				onClose(true);
			}, 2000);
		}
	};

	return <Modal show={item && show} onHide={() => onClose(false)} size="xl" fullscreen="xl-down">
		<Modal.Header closeButton>
			<Modal.Title>Edit Category <i>{item?.name}</i></Modal.Title>
			{ error && <Alert variant="danger">{error.message || String(error)}</Alert> }
		</Modal.Header>
		<Form onSubmit={(e) => {
			e.preventDefault();
			doUpdate();
		}}>
			<Modal.Body>
				<Container fluid className={saving ? 'opacity-50' : ''}>
					<Row className="mb-3">
						<EditableTextField
							typeName="Category"
							propKey="name"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
					</Row>
					<Row className="mb-3">
						<EditableTextField
							typeName="Category"
							propKey="description"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
					</Row>
					<Row className="mb-3">
						<EditableMultiValueField
							typeName="Category"
							propKey="category_map"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
							trimValues
						/>
					</Row>
					<Row>
						<EditableMultiValueField
							typeName="Category"
							propKey="negated_by"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
							trimValues
						/>
					</Row>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onClose(false)} disabled={saving}>
				Cancel
				</Button>
				<Button variant="primary" onClick={() => {
					doUpdate();
				}} disabled={saving || !updates || Object.keys(updates).length === 0}>
				Submit
				</Button>
			</Modal.Footer>
		</Form>

	</Modal>;
}
