import { z } from 'zod';

/**
 * Error response schema for API errors
 */
export const ErrorResponseSchema = z.object({
	$type: z.literal('Error'),
	message: z.string().optional(),
	details: z.string().optional(),
	code: z.string().optional(),
	statusCode: z.number().optional(),
	retryable: z.boolean().optional(),
	aws_request_id: z.string().optional(),
});

// Export the inferred type
export type ErrorResponse = z.infer<typeof ErrorResponseSchema>;

export function isErrorType(obj: any): obj is ErrorResponse {
	return obj?.$type === 'Error' || obj?.__type__ === 'Error';
}

export class ValidationError extends Error {
	public readonly code = 'VALIDATION_ERROR';
	public readonly statusCode = 400;
	public readonly retryable = false;

	constructor(
		message: string,
		public obj?: any,
		public missingFields?: string[],
	) {
		super(message);
		this.name = 'ValidationError';
		this.obj = obj;
		this.missingFields = missingFields;
	}

	toErrorResponse(): ErrorResponse {
		return ErrorResponseSchema.parse({
			$type: 'Error',
			message: this.message,
			details: String(this.obj),
			code: this.code,
			statusCode: this.statusCode,
			retryable: this.retryable,
		});
	}
}
