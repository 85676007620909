import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Message, ReportAgent } from '@newstex/ai/agents/report-agent';
import { ChartType, DatabaseSchema, Report } from '@newstex/types/report';
import { Results } from '@newstex/types/results';
import { useEffect, useRef, useState } from 'react';
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Spinner,
} from 'react-bootstrap';
import Markdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AccessGroupSelect } from '~/components/access-group-select';
import EditableSelectField from '~/components/editors/select';
import { useAI } from '~/providers/ai-provider';
import { useAPI } from '~/providers/api-provider';
import { useSearch } from '~/providers/search';

export interface ReportFormModalProps {
	item?: Report;
	startingUpdates?: Partial<Report>;
	show: boolean;
	onClose: (saved: boolean, newReport?: Report) => void;
	title: string;
}

export default function ReportFormModal({
	item,
	startingUpdates,
	show,
	onClose,
	title,
}: ReportFormModalProps) {
	const api = useAPI();
	const commentRef = useRef<HTMLInputElement>(null);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState<any>();
	const [updates, setUpdates] = useState<Partial<Report>>(startingUpdates || {});
	const [description, setDescription] = useState('');
	const [generatingFromAI, setGeneratingFromAI] = useState(false);
	const [agent, setAgent] = useState<ReportAgent>();
	const [messages, setMessages] = useState<Message[]>([]);
	const [categories, setCategories] = useState<string[]>([]);
	const chatEndRef = useRef<HTMLDivElement>(null);
	const ai = useAI();
	const { searchClient } = useSearch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState<Partial<Report>>({
		name: '',
		description: '',
		category: '',
		query: '',
		database: 'NewsCore',
		access_groups: [],
		...item,
	});

	// Fetch existing categories when modal opens
	useEffect(() => {
		if (show) {
			const fetchCategories = async () => {
				try {
					const response = await api.fetchWithAuth<{ items: Report[] }>('reports');
					const uniqueCategories = [...new Set(response.items
						.map((report) => report.category)
						.filter(Boolean) as string[])].sort();
					setCategories(uniqueCategories);
				} catch (err) {
					console.error('Failed to fetch categories:', err);
				}
			};
			fetchCategories();
		}
	}, [show, api]);

	// Reset state when modal is opened with a new item
	useEffect(() => {
		if (show) {
			setUpdates(startingUpdates || {});
			setDescription('');
			setMessages([]);
			(async () => {
				const modelProvider = await ai.refreshCredentials();
				if (!modelProvider) {
					throw new Error('Failed to initialize model provider');
				}
				setAgent(new ReportAgent({
					modelProvider,
					searchClient,
					report: item,
					resources: {
						listAll: async <T = any>(typeName: string) => {
							if (typeName !== 'Report') {
								return {};
							}
							const reports = await api.fetchWithAuth<Results<T>>('reports');
							return Object.fromEntries(reports.items.map((report: Report) => [report.id, report as T]));
						},
					},
				}));
			})();
			agent?.clearHistory();
		}
	}, [show, item?.id]);

	// Scroll chat to bottom when new messages arrive
	useEffect(() => {
		chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	}, [messages]);

	const generateFromDescription = async () => {
		if (!description) return;
		if (!agent) return;

		setError(null);
		setGeneratingFromAI(true);
		try {
			const result = await agent.generateReport({ description });
			setUpdates(result);
			setFormData(result);
			setMessages(agent.getHistory());
			setDescription('');
		} catch (err: any) {
			console.error(err);
			setError(err);
		}
		setGeneratingFromAI(false);
	};

	const sendFeedback = async (feedback: string) => {
		if (!feedback) return;
		if (!agent) return;

		setGeneratingFromAI(true);
		try {
			const result = await agent.updateReport(feedback);
			setUpdates(result);
			setMessages(agent.getHistory());
			setDescription('');
		} catch (err: any) {
			await agent.updateReport(err.message || String(err));
			console.error(err);
			setError(err);
		} finally {
			setGeneratingFromAI(false);
		}
	};

	const doSave = async (attempt: number = 0) => {
		setError(null);
		setSaving(true);

		try {
			const endpoint = item?.id ? `reports/${item.id}` : 'reports';
			const body: any = { ...updates };
			if (commentRef.current?.value) {
				body.$comment = commentRef.current.value;
			}
			const response = await api.fetchWithAuth<{ items: Report[] }>(endpoint, {
				method: item?.id ? 'PATCH' : 'POST',
				body: JSON.stringify(body),
			});
			if (response?.items?.[0]?.id) {
				toast.success('Report saved', {
					onClick: () => {
						navigate(`/reports/${response?.items?.[0].id}`);
					},
				});
			}
			onClose(true, response?.items?.[0]);
		} catch (err: any) {
			console.error(err);
			if (attempt < 3) {
				await sendFeedback([
					'Your query:',
					'```',
					`${updates.query || item?.query || ''}`,
					'```',
					'resulted in an error:',
					'```',
					`${err.message || String(err)}`,
					'```',
					'Please modify the query and try again.',
				].join('\r\n'));
				return doSave(attempt + 1);
			}
			console.error('Fatal Error updating report:', err);
			setError(err);
		}
		setTimeout(() => {
			setSaving(false);
		}, 2000);
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		try {
			const response = await api.fetchWithAuth<{ items: Report[] }>(
				item?.id ? `reports/${item.id}` : 'reports',
				{
					method: item?.id ? 'PATCH' : 'POST',
					body: JSON.stringify(formData),
				},
			);

			if (response?.items?.[0]) {
				onClose(true, response.items[0]);
			}
		} catch (err) {
			console.error('Failed to save report:', err);
			toast.error('Failed to save report');
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal show={show} onHide={() => onClose(false)} size="xl" fullscreen="xl-down">
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
				<br/>
				<small>
					{error && <Alert variant="danger">{error.message || String(error)}</Alert>}
				</small>
			</Modal.Header>
			<Modal.Body className="p-0">
				<Container fluid>
					<Row>
						{/* Chat Interface */}
						<Col md={5} className="border-end p-0 ps-1">
							<div className="d-flex flex-column h-100">
								{/* Chat Messages */}
								<div
									className="flex-grow-1 p-3 overflow-auto"
									style={{
										backgroundColor: '#f8f9fa',
										maxHeight: 'calc(100vh - 250px)',
									}}
								>
									{messages.map((msg, index) => (
										<div
											key={index}
											className={`mb-3 ${
												msg.role === 'assistant' ? 'text-start' : 'text-end'
											}`}
										>
											<div
												className={`d-inline-block p-2 rounded-3 ${
													msg.role === 'assistant'
														? 'bg-light'
														: 'bg-primary text-white'
												}`}
												style={{ maxWidth: '80%', whiteSpace: 'pre-wrap' }}
											>
												<Markdown>{msg.content || ''}</Markdown>
												{msg.role === 'assistant' && msg.details && (
													<>
														<hr className="my-2" />
														<details>
															<summary className="text-muted">JSON Details</summary>
															<pre>{JSON.stringify(msg.details, null, 2)}</pre>
														</details>
													</>
												)}
											</div>
										</div>
									))}
									<div ref={chatEndRef} />
								</div>

								{/* Chat Input */}
								<div className="p-3 border-top">
									<Form
										onSubmit={(e) => {
											e.preventDefault();
											if (messages.length === 0) {
												generateFromDescription();
											} else {
												sendFeedback(description);
											}
										}}
									>
										<div className="d-flex gap-2">
											<Form.Control
												as="textarea"
												rows={3}
												value={description}
												onChange={(e) => setDescription(e.target.value)}
												placeholder={
													messages.length === 0
														? 'Describe what you want this report to show...'
														: 'Provide feedback or request changes...'
												}
												disabled={generatingFromAI}
											/>
											<Button
												type="submit"
												variant="primary"
												disabled={!description || generatingFromAI}
												className="align-self-end"
											>
												{generatingFromAI ? (
													<Spinner
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
													/>
												) : (
													<FontAwesomeIcon icon={faPaperPlane} />
												)}
											</Button>
										</div>
									</Form>
								</div>
							</div>
						</Col>

						{/* Report Form */}
						<Col md={7} className="py-3">
							<Form onSubmit={handleSubmit}>
								<Container fluid className={saving ? 'opacity-50' : ''}>
									<Row className="mb-3">
										<Col md={3} className="text-end fw-bold">
											<Form.Label>Name</Form.Label>
										</Col>
										<Col md={9}>
											<Form.Control
												type="text"
												value={formData.name || item?.name || ''}
												onChange={(e) => setFormData({ ...formData, name: e.target.value })}
												required
											/>
										</Col>
									</Row>

									<EditableSelectField
										propKey="category"
										typeName="Report"
										item={item}
										updates={formData}
										setUpdates={setFormData}
										options={categories}
										placeholder="Select or enter a category..."
										allowNew
									/>

									<Row className="mb-3">
										<Col md={3} className="text-end fw-bold">
											<Form.Label>Description</Form.Label>
										</Col>
										<Col md={9}>
											<Form.Control
												as="textarea"
												rows={3}
												value={formData.description || item?.description || ''}
												onChange={(e) => setFormData({ ...formData, description: e.target.value })}
											/>
										</Col>
									</Row>

									<Row className="mb-3">
										<Col md={3} className="text-end fw-bold">
											<Form.Label>Database</Form.Label>
										</Col>
										<Col md={9}>
											<Form.Select
												value={formData.database || item?.database || DatabaseSchema.Values.NewsCore}
												onChange={(e) => setFormData({
													...formData,
													database: e.target.value as typeof DatabaseSchema._def.values[number],
												})}
												required
											>
												{Object.values(DatabaseSchema.Values).map((value) => (
													<option key={value} value={value}>{value}</option>
												))}
											</Form.Select>
											<Form.Text className="text-muted">
												Select the database to query. The backend will handle the connection details.
											</Form.Text>
										</Col>
									</Row>

									{formData.database === 'NewsCrunch' ? (
										<Row className="mb-3">
											<Col md={3} className="text-end fw-bold">
												<Form.Label>Access Control</Form.Label>
											</Col>
											<Col md={9}>
												<Form.Text>
													Reports using the NewsCrunch database are automatically restricted to the
													<code>newscrunch</code> group.
												</Form.Text>
											</Col>
										</Row>

									) : (
										<AccessGroupSelect
											value = {formData.access_groups || ['admin']}
											onChange={(access_groups) => setFormData((prev) => ({ ...prev, access_groups }))}
											className="mb-3"
										/>
									)}

									<Row className="mb-3">
										<Col md={3} className="text-end fw-bold">
											<Form.Label>Query</Form.Label>
										</Col>
										<Col md={9}>
											<Form.Control
												as="textarea"
												rows={10}
												value={formData.query || item?.query || ''}
												onChange={(e) => setFormData({ ...formData, query: e.target.value })}
												required
												className="font-monospace"
											/>
											<Form.Text className="text-muted">
												Write your query here. Use :paramName for parameters.
											</Form.Text>
										</Col>
									</Row>

									<Row className="mb-3">
										<Col md={3} className="text-end fw-bold">
											<Form.Label>Chart Type</Form.Label>
										</Col>
										<Col md={9}>
											<Form.Select
												value={formData.chart?.type ?? item?.chart?.type ?? 'bar'}
												onChange={(e) => setFormData({
													...formData,
													chart: {
														...(formData.chart ?? item?.chart ?? {}),
														type: e.target.value as ChartType,
														datasets: (formData.chart?.datasets ?? item?.chart?.datasets) || [{
															label: 'Default Dataset',
															color: 'blue',
														}],
													},
												})
												}
											>
												<option value="bar">Bar Chart</option>
												<option value="line">Line Chart</option>
												<option value="pie">Pie Chart</option>
												<option value="scatter">Scatter Plot</option>
												<option value="doughnut">Doughnut Chart</option>
												<option value="radar">Radar Chart</option>
												<option value="table">Table</option>
											</Form.Select>
										</Col>
									</Row>

									{/* Table Configuration - Only show when type is table */}
									{(formData.chart?.type === 'table' || item?.chart?.type === 'table') && (
										<>
											<Row className="mb-3">
												<Col md={3} className="text-end fw-bold">
													<Form.Label>Row Grouping</Form.Label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														value={formData.chart?.group_by ?? item?.chart?.group_by ?? ''}
														onChange={(e) => setFormData({
															...formData,
															chart: {
																...(formData.chart ?? item?.chart ?? {}),
																group_by: e.target.value,
															},
														})}
														placeholder="Field name to group rows by"
													/>
													<Form.Text className="text-muted">
														Field name to group the table rows by
													</Form.Text>
												</Col>
											</Row>

											<Row className="mb-3">
												<Col md={3} className="text-end fw-bold">
													<Form.Label>Column Grouping</Form.Label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														value={formData.chart?.stack_by ?? item?.chart?.stack_by ?? ''}
														onChange={(e) => setFormData({
															...formData,
															chart: {
																...(formData.chart ?? item?.chart ?? {}),
																stack_by: e.target.value,
															},
														})}
														placeholder="Field name to group columns by"
													/>
													<Form.Text className="text-muted">
														Field name to group the table columns by (top level)
													</Form.Text>
												</Col>
											</Row>

											<Row className="mb-3">
												<Col md={3} className="text-end fw-bold">
													<Form.Label>Sub-Column Grouping</Form.Label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														value={formData.chart?.sub_column_by ?? item?.chart?.sub_column_by ?? ''}
														onChange={(e) => setFormData({
															...formData,
															chart: {
																...(formData.chart ?? item?.chart ?? {}),
																sub_column_by: e.target.value,
															},
														})}
														placeholder="Field name to group sub-columns by"
													/>
													<Form.Text className="text-muted">
														Field name to create nested grouping under each column (optional)
													</Form.Text>
												</Col>
											</Row>

											<Row className="mb-3">
												<Col md={3} className="text-end fw-bold">
													<Form.Label>Value Field</Form.Label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														value={formData.chart?.value_field ?? item?.chart?.value_field ?? ''}
														onChange={(e) => setFormData({
															...formData,
															chart: {
																...(formData.chart ?? item?.chart ?? {}),
																value_field: e.target.value,
															},
														})}
														placeholder="Field containing the values to aggregate"
													/>
													<Form.Text className="text-muted">
														Field containing the values to aggregate in the table cells
													</Form.Text>
												</Col>
											</Row>
										</>
									)}

									<Row className="mt-3 pt-3" style={{ borderTop: 'dashed 1px #ccc' }}>
										<Col md={3} className="text-end fw-bold">
											<Form.Label>Comment</Form.Label>
										</Col>
										<Col md={9}>
											<Form.Control
												type="text"
												name="$comment"
												ref={commentRef}
												placeholder="Optional: Enter a comment about your changes"
											/>
										</Col>
									</Row>
								</Container>

								<div className="d-flex justify-content-end mt-3">
									<Button variant="secondary" onClick={() => onClose(false)} disabled={saving} className="me-2">
										Cancel
									</Button>
									<Button
										variant="primary"
										type="submit"
										disabled={loading || saving || !formData || Object.keys(formData).length === 0}
									>
										{saving ? 'Saving...' : 'Save'}
									</Button>
								</div>
							</Form>
						</Col>
					</Row>
				</Container>
			</Modal.Body>
		</Modal>
	);
}
