import { HasAccessGroups } from './access';
import { BaseObject } from './base';
import { UserGroupSchema } from './user';

export interface KnowledgeBase extends BaseObject, HasAccessGroups {
	$type: 'RAG';
	title?: string;
	questions?: string[];
	answer?: string;
	category?: string;
	locked?: boolean;
	/** User groups that have access to this knowledge base (empty array means admin only) */
	access_groups?: typeof UserGroupSchema._def.values[number][];
}

// Helper function to determine access level display
export function getKnowledgeBaseAccessInfo(kb: KnowledgeBase) {
	if (!kb.access_groups?.length) {
		return {
			label: 'Admin Only',
			variant: 'danger',
			icon: 'lock',
		};
	}

	const groups = kb.access_groups.map((g) => g.charAt(0).toUpperCase() + g.slice(1));
	return {
		label: `Accessible by: ${groups.join(', ')}`,
		variant: groups.includes('User') ? 'success' : 'warning',
	};
}

export function isKnowledgeBase(obj: any, typeHint?: string): obj is KnowledgeBase {
	return obj && (obj.$type === 'RAG' || typeHint === 'RAG');
}

export interface RAGMemory extends BaseObject {
	$type: 'RAGMemory';
	content?: string;
	link?: string;
	category?: string;
}

export function isRAGMemory(obj: any, typeHint?: string): obj is RAGMemory {
	return obj && (obj.$type === 'RAGMemory' || typeHint === 'RAGMemory');
}
