import * as Sentry from '@sentry/react';

import { AIChatCommand } from './ai-chat';
import { GenerateStoryCommand } from './generate-story';
import { LogoutCommand } from './logout';
import type { Command } from './type';

export * from './type';

export const commands: Command[] = [
	{
		id: 'qualify-leads',
		icon: 'table-cells',
		name: 'Qualify Leads',
		description: 'Qualify a list of leads (by URL or HubSpot List) using Technical and AI ranking. Can optionally sync to HubSpot.',
		handler: ({ navigate }) => {
			navigate('/tools/qualify-leads');
		},
	},
	{
		id: 'report-error',
		icon: 'bug',
		name: 'Report an Error',
		description: 'Bug Report: Report an error to the Newstex Development Team',
		searchTerms: 'report error bug report issues problem',
		handler: () => {
			const feedbackWidget = document.querySelector('[data-sentry-component="feedback-widget"]');
			if (feedbackWidget) {
				feedbackWidget.remove();
			} else {
				Sentry.showReportDialog({
					onClose: () => {
						const widget = document.querySelector('[data-sentry-component="feedback-widget"]');
						if (widget) widget.remove();
					},
				});
			}
		},
	},
	AIChatCommand,
	LogoutCommand,
	GenerateStoryCommand,
];
