import type { Distributor } from '@newstex/types/distributor';
import { type User, type UserGroup, UserGroupSchema } from '@newstex/types/user';
import { title as titleCase } from 'case';
import { useEffect, useState } from 'react';
import {
	Button,
	Form,
	Modal,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getDescriptionForAuthGroup, isAdminGroup } from '~/lib/auth-groups';
import { useAPI } from '~/providers/api-provider';

import { DistributorSelect } from '../distributor-select';

const ALL_USER_GROUPS = UserGroupSchema.options;

interface UserEditModalProps {
	show: boolean;
	user: User | null;
	onClose: () => void;
}

function authGroupLabel(group: UserGroup) {
	return (
		<OverlayTrigger
			placement="top"
			overlay={<Tooltip>{getDescriptionForAuthGroup(group)}</Tooltip>}
		>
			<span>{titleCase(group)}</span>
		</OverlayTrigger>
	);
}

export function UserEditModal({ show, user, onClose }: UserEditModalProps) {
	const [roles, setRoles] = useState<UserGroup[]>(user?.auth_groups as UserGroup[] || []);
	const [distributor, setDistributor] = useState<Distributor | null>(null);
	const [saving, setSaving] = useState(false);

	const api = useAPI();

	const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setSaving(true);
		console.log('saving', user);
		const formData = new FormData(event.currentTarget as HTMLFormElement);
		const updates: Partial<User> = { };
		for (const key of formData.keys()) {
			if (key === 'auth_groups') {
				continue;
			}
			const value = formData.get(key) as string;
			if (JSON.stringify(value) !== JSON.stringify(user[key] || '')) {
				updates[key] = value;
			}
		}

		if (JSON.stringify(roles) !== JSON.stringify(user.auth_groups || [])) {
			updates.auth_groups = formData.getAll('auth_groups') as UserGroup[];
		}

		if (distributor?.$id !== user?.distributor) {
			updates.distributor = distributor?.$id;
		}

		if (Object.keys(updates).length > 0) {
			try {
				await api.updateItem({
					$type: 'User',
					...user,
				}, updates);
			} catch (error) {
				console.error(error);
				toast.error('Failed to save changes');
			}
		}
		setSaving(false);
		onClose();
	};

	useEffect(() => {
		setRoles(user?.auth_groups as UserGroup[] || []);
	}, [user?.auth_groups]);

	useEffect(() => {
		if (user?.distributor) {
			api.getItem<Distributor>({
				$type: 'Distributor',
				$id: user.distributor,
			}).then(setDistributor).catch(console.error);
		} else {
			setDistributor(null);
		}
	}, [user?.distributor]);

	return (
		<Modal show={show} onHide={onClose}>
			<Form onSubmit={handleSave}>
				<Modal.Header closeButton>
					<Modal.Title>Edit User</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group controlId="formUserEmail">
						<Form.Label>Email</Form.Label>
						<Form.Control
							type="email"
							readOnly
							defaultValue={user?.email}
							name="email"
						/>
					</Form.Group>
					<Form.Group controlId="formUserName" className="mt-3">
						<Form.Label>Name</Form.Label>
						<Form.Control
							type="text"
							defaultValue={user?.name}
							name="name"
						/>
					</Form.Group>
					<Form.Group controlId="formUserPhone" className="mt-3">
						<Form.Label>Phone</Form.Label>
						<Form.Control
							type="tel"
							defaultValue={user?.phone}
							name="phone"
						/>
					</Form.Group>
					<Form.Group controlId="formUserDistributor" className="mt-3">
						<Form.Label>Distributor</Form.Label>
						<DistributorSelect
							value={distributor}
							onChange={setDistributor}
						/>
					</Form.Group>
					<Form.Group controlId="formUserRoles" className="mt-3">
						<Form.Label>Roles</Form.Label>
						{/* Admin Groups */}
						{ALL_USER_GROUPS.filter((group) => isAdminGroup(group)).map((group) => (
							<Form.Check
								disabled={!user?.email?.endsWith('@newstex.com')}
								key={group}
								id={`auth-group-${group}`}
								value={group}
								type="checkbox"
								name="auth_groups"
								label={authGroupLabel(group)}
								checked={roles.includes(group)}
								onChange={(e) => {
									if (e.target.checked) {
										setRoles([...roles, group]);
									} else {
										setRoles(roles.filter((g) => g !== group));
									}
								}}
							/>
						))}
						{!user?.email?.endsWith('@newstex.com') && (
							<Form.Text className="text-muted">
								Only Newstex email addresses can be assigned to admin groups.
							</Form.Text>
						)}
						<hr />
						{/* Other Groups */}
						{ALL_USER_GROUPS.filter((group) => !isAdminGroup(group)).map((group) => (
							<Form.Check
								key={group}
								id={`auth-group-${group}`}
								value={group}
								type="checkbox"
								name="auth_groups"
								label={authGroupLabel(group)}
								checked={roles.includes(group)}
								onChange={(e) => {
									if (e.target.checked) {
										setRoles([...roles, group]);
									} else {
										setRoles(roles.filter((g) => g !== group));
									}
								}}
							/>
						))}
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={onClose} disabled={saving}>Cancel</Button>
					<Button variant="success" type="submit" disabled={saving}>Save Changes</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}
