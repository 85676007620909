import type { UserGroup } from '@newstex/types/user';

export const AUTH_GROUP_DESCRIPTIONS: Record<UserGroup, string> = {
	admin: 'Admins are Newstex internal users with access to all publisher and distributor settings.',
	newscrunch: 'Newscrunch users are Admins with access to Revenue and Analytics reports.',
	'user-admin': 'Admins which can manage other users.',
	user: 'Standard users which are not Admins, Publishers, or Distributors. They may have general search access for public content.',
	tech: 'Technical users are allowed to modify RSS feeds and other technical settings.',
	publisher: 'Publishers have access to their own content and settings.',
	distributor: 'Distributors which receive content from our Publishers, they may manage their own content and settings.',
	beta: 'Beta users may access the admin-dev and admin-beta environments.',
	premium: 'Premium users have access to features that might cost us money, such as AI services.',
	advanced: 'Advanced users have access to beta features that might not be fully tested, such as the command bar.',
};

export function getDescriptionForAuthGroup(group: UserGroup) {
	return AUTH_GROUP_DESCRIPTIONS[group || 'default'] || '';
}

export function isAdminGroup(group: UserGroup) {
	return group === 'admin' || group === 'newscrunch' || group === 'user-admin';
}
