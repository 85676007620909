import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	COLORS,
	ChartConfig,
	ChartType,
	FormatType,
	Report,
	SumType,
} from '@newstex/types/report';
import { Results } from '@newstex/types/results';
import { useEffect, useMemo, useState } from 'react';
import {
	Button,
	Col,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import ColorPicker from '~/components/editors/color-picker';
import { useAPI } from '~/providers/api-provider';

export interface ChartConfigModalProps {
	show: boolean;
	onClose: (saved?: boolean) => void;
	report: Report;
	data?: Results;
	onSave: (config: ChartConfig) => Promise<void>;
}

export default function ChartConfigModal({
	show,
	onClose,
	report,
	data,
	onSave,
}: ChartConfigModalProps) {
	const api = useAPI();
	const [saving, setSaving] = useState(false);
	const [datasets, setDatasets] = useState<ChartConfig['datasets']>(report.chart?.datasets || []);
	const [chartType, setChartType] = useState<ChartType>(report.chart?.type || 'bar');
	const [groupBy, setGroupBy] = useState<string>(report.chart?.group_by || '');
	const [stackBy, setStackBy] = useState<string>(report.chart?.stack_by || '');
	const [subColumnBy, setSubColumnBy] = useState<string>(report.chart?.sub_column_by || '');
	const [valueField, setValueField] = useState<string>(report.chart?.value_field || '');
	const [totals, setTotals] = useState<ChartConfig['totals']>(report.chart?.totals || {});
	const [legendDisplay, setLegendDisplay] = useState<boolean>(report.chart?.legend?.display || true);
	const [valueFormat, setValueFormat] = useState<FormatType | ''>(report.chart?.value_format || '');
	const [rowTotals, setRowTotals] = useState<SumType | ''>(report.chart?.totals?.rows || '');
	const [columnTotals, setColumnTotals] = useState<SumType | ''>(report.chart?.totals?.columns || '');

	// Add useEffect to reset state when report prop changes
	useEffect(() => {
		if (show) { // Only reset if the modal is shown, prevents unnecessary updates
			setDatasets(report.chart?.datasets || []);
			setChartType(report.chart?.type || 'bar');
			setGroupBy(report.chart?.group_by || '');
			setStackBy(report.chart?.stack_by || '');
			setSubColumnBy(report.chart?.sub_column_by || '');
			setValueField(report.chart?.value_field || '');
			setTotals(report.chart?.totals || {}); // Reset totals object
			setLegendDisplay(report.chart?.legend?.display || true);
			setValueFormat(report.chart?.value_format || '');
			setRowTotals(report.chart?.totals?.rows || '');
			setColumnTotals(report.chart?.totals?.columns || '');
		}
	}, [report, show]); // Depend on report and show status

	// Get column names from the first row of data
	const columns = data?.items?.[0] ? Object.keys(data.items[0]) : [];

	// Stacked is true if stack_by is set
	const stacked = useMemo(() => {
		return Boolean(stackBy);
	}, [stackBy]);

	// Helper to check if chart is table type
	const isTableType = useMemo(() => chartType === 'table', [chartType]);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setSaving(true);
		const config: ChartConfig = {
			...report.chart,
			type: chartType,
			datasets: isTableType ? [{
				label: 'Default Dataset',
				color: COLORS[0],
				format: valueFormat || undefined,
				key: subColumnBy || stackBy || groupBy || undefined,
			}] : datasets, // Default dataset for table type
			group_by: groupBy,
			stack_by: stackBy,
			sub_column_by: isTableType ? subColumnBy : undefined,
			value_field: valueField,
			value_format: isTableType ? valueFormat || undefined : undefined,
			totals: isTableType ? {
				rows: rowTotals || undefined,
				columns: columnTotals || undefined,
			} : undefined,
			legend: {
				...report.chart?.legend,
				display: legendDisplay,
			},
		};

		try {
			console.log('Save Config', config);
			await api.fetchWithAuth(`reports/${report.id}`, {
				method: 'PATCH',
				body: JSON.stringify({ chart: config }),
			});
			await onSave(config);
			onClose(true);
		} catch (error) {
			console.error('Failed to save chart configuration:', error);
		} finally {
			setSaving(false);
		}
	};

	const updateDataset = (index: number, updates: Partial<ChartConfig['datasets'][0]>) => {
		setDatasets((prev) => prev.map((dataset, i) => {
			if (i !== index) return dataset;
			const updated = { ...dataset, ...updates };
			// Clean up empty format/total fields
			if (updated.format?.length === 0) delete updated.format;
			if (updated.total?.length === 0) delete updated.total;
			return updated;
		}));
	};

	const addDataset = () => {
		if (!datasets?.length) {
			setDatasets([
				{
					label: 'Dataset 1',
					color: COLORS[0],
				},
			]);
		} else {
			setDatasets((prev) => [
				...prev,
				{
					label: `Dataset ${prev.length + 1}`,
					color: COLORS[prev.length % COLORS.length],
				},
			]);
		}
	};

	const removeDataset = (index: number) => {
		const newDatasets = [...datasets];
		newDatasets.splice(index, 1);
		setDatasets(newDatasets);
	};

	return (
		<Modal show={show} onHide={() => onClose()} size="lg">
			<Form onSubmit={handleSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>Chart Configuration</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form.Group className="mb-3">
						<Form.Label>Chart Type</Form.Label>
						<Form.Select
							value={chartType}
							onChange={(e) => setChartType(e.target.value as ChartType)}
						>
							<option value="bar">Bar Chart</option>
							<option value="line">Line Chart</option>
							<option value="pie">Pie Chart</option>
							<option value="scatter">Scatter Plot</option>
							<option value="doughnut">Doughnut Chart</option>
							<option value="radar">Radar Chart</option>
							<option value="table">Table</option>
						</Form.Select>
					</Form.Group>

					{!isTableType && (
						<Form.Group className="mt-3">
							<Form.Label>Options</Form.Label>
							<Form.Check
								type="checkbox"
								label="Show Legend"
								checked={legendDisplay}
								onChange={(e) => setLegendDisplay(e.target.checked)}
							/>
						</Form.Group>
					)}

					{isTableType ? (
						<>
							<Row>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Row Grouping</Form.Label>
										<Form.Control
											type="text"
											value={groupBy || ''}
											onChange={(e) => setGroupBy(e.target.value)}
											placeholder="Field name for row grouping"
										/>
										<Form.Text className="text-muted">
											Field to group rows by
										</Form.Text>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Column Grouping</Form.Label>
										<Form.Control
											type="text"
											value={stackBy || ''}
											onChange={(e) => setStackBy(e.target.value)}
											placeholder="Field name for column grouping"
										/>
										<Form.Text className="text-muted">
											Field to group columns by
										</Form.Text>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Sub-Column Grouping</Form.Label>
										<Form.Control
											type="text"
											value={subColumnBy || ''}
											onChange={(e) => setSubColumnBy(e.target.value)}
											placeholder="Field name for nested column grouping"
										/>
										<Form.Text className="text-muted">
											Field to create nested grouping under each column
										</Form.Text>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Value Field</Form.Label>
										<Form.Control
											type="text"
											value={valueField || ''}
											onChange={(e) => setValueField(e.target.value)}
											placeholder="Field containing values"
										/>
										<Form.Text className="text-muted">
											Field containing the values to aggregate
										</Form.Text>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Value Format</Form.Label>
										<Form.Select
											value={valueFormat || ''}
											onChange={(e) => setValueFormat(e.target.value as FormatType)}
										>
											<option value="">None</option>
											<option value="number">Number</option>
											<option value="currency">Currency</option>
											<option value="date">Date</option>
										</Form.Select>
										<Form.Text className="text-muted">
											How to format the values and totals
										</Form.Text>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Totals</Form.Label>
										<Row>
											<Col>
												<Form.Select
													value={rowTotals || ''}
													onChange={(e) => setRowTotals(e.target.value as SumType)}
												>
													<option value="">No Row Totals</option>
													<option value="sum">Sum</option>
													<option value="avg">Average</option>
													<option value="min">Minimum</option>
													<option value="max">Maximum</option>
													<option value="count">Count</option>
												</Form.Select>
											</Col>
											<Col>
												<Form.Select
													value={columnTotals || ''}
													onChange={(e) => setColumnTotals(e.target.value as SumType)}
												>
													<option value="">No Column Totals</option>
													<option value="sum">Sum</option>
													<option value="avg">Average</option>
													<option value="min">Minimum</option>
													<option value="max">Maximum</option>
													<option value="count">Count</option>
												</Form.Select>
											</Col>
										</Row>
										<Form.Text className="text-muted">
											Configure how totals are calculated for rows and columns
										</Form.Text>
									</Form.Group>
								</Col>
							</Row>
						</>
					) : (
						<>
							<Row className="mt-3">
								<Col md={4}>
									<Form.Label>Group By (X-Axis)</Form.Label>
									<Form.Select
										value={groupBy}
										onChange={(e) => setGroupBy(e.target.value)}
									>
										<option value="">Select a field</option>
										{columns.map((col) => (
											<option key={col} value={col}>
												{col}
											</option>
										))}
									</Form.Select>
								</Col>
								<Col md={4}>
									<Form.Label>Stack By</Form.Label>
									<Form.Select
										value={stackBy}
										onChange={(e) => setStackBy(e.target.value)}
									>
										<option value="">Select a field</option>
										{columns.map((col) => (
											<option key={col} value={col}>
												{col}
											</option>
										))}
									</Form.Select>
								</Col>
								<Col md={4}>
									<Form.Label>Value Field (Y-Axis)</Form.Label>
									<Form.Select
										value={valueField}
										onChange={(e) => setValueField(e.target.value)}
									>
										<option value="">Select a field</option>
										{columns.map((col) => (
											<option key={col} value={col}>
												{col}
											</option>
										))}
									</Form.Select>
								</Col>
							</Row>

							<Form.Group className="mt-3">
								<Form.Label>Datasets</Form.Label>
								{Boolean(datasets?.length) && datasets.map((dataset, index) => (
									<Row key={index} className="mb-2 align-items-end">
										<Col md={3}>
											<Form.Label>Label</Form.Label>
											<Form.Control
												type="text"
												value={dataset.label || dataset.key}
												onChange={(e) => updateDataset(index, { label: e.target.value })}
											/>
										</Col>
										<Col md={3}>
											{stacked ? <>
												<Form.Label><i>{stackBy}</i> Key</Form.Label>
												<Form.Control
													type="text"
													value={dataset.key}
													onChange={(e) => updateDataset(index, { key: e.target.value })}
												/>
											</> : <>
												<Form.Label>Data Column</Form.Label>
												<Form.Select
													value={dataset.key}
													onChange={(e) => updateDataset(index, { key: e.target.value })}
												>
													<option value="">Select a column</option>
													{columns.map((col) => (
														<option key={col} value={col}>
															{col}
														</option>
													))}
												</Form.Select>
											</>}
										</Col>
										<Col md={2}>
											<Form.Label>Color</Form.Label>
											<ColorPicker
												value={dataset.color}
												onChange={(color) => updateDataset(index, { color })}
											/>
										</Col>
										<Col md={1}>
											<Button
												variant="outline-danger"
												onClick={() => removeDataset(index)}
											>
												<FontAwesomeIcon icon={faTrash} />
											</Button>
										</Col>
									</Row>
								))}
								<br/>
								<Button
									variant="outline-primary"
									size="sm"
									onClick={addDataset}
									disabled={datasets.length >= columns.length - 1}
									className="mt-2"
								>
									<FontAwesomeIcon icon={faPlus} /> Add Dataset
								</Button>
							</Form.Group>
						</>
					)}
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => onClose()}>
						Cancel
					</Button>
					<Button type="submit" variant="primary" disabled={saving}>
						{saving ? 'Saving...' : 'Save Configuration'}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}
