import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Content, isPublisher } from '@newstex/types/content';
import { DatePeriod } from '@newstex/types/dates';
import { Results } from '@newstex/types/results';
import { createColumnHelper } from '@tanstack/react-table';
import React, {
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

import BarChart, { BarChartProps } from '../charts/BarChart';
import DataTable, { DataTableMethods } from '../data-table';
import LoadingSpinner from '../LoadingSpinner';
import { hasPermission } from '../require-permissions';

interface StoryStatsProps {
	content?: Content;
	contents?: Content[];
	defaultPeriod?: DatePeriod;
}

type ChartColor = 'green' | 'primary' | 'blue' | 'indigo' | 'purple' | 'pink' | 'pinkLighter' | 'red' | 'orange' | 'yellow' | 'teal' | 'cyan' | 'white' | 'lightPurple' | 'gray' | 'lightGray';

interface Dataset {
	label: string;
	data: number[];
	type?: 'line' | 'bar';
	borderWidth?: number;
	color?: ChartColor;
}

interface TotalsData {
	date: string;
	[key: string]: number | string;
}

/**
 * Story Stats widget for a given publisher or publication
 */
export const StoryStats: React.FC<StoryStatsProps> = ({ content, contents, defaultPeriod = 'week' }) => {
	const [storyStats, setStoryStats] = useState<Pick<BarChartProps, 'datasets' | 'labels'>>();
	const [loading, setLoading] = useState<boolean>(true);
	const [period, setPeriod] = useState<DatePeriod>(defaultPeriod);
	const [tableData, setTableData] = useState<any[]>([]);
	const [distributors, setDistributors] = useState<string[]>([]);
	const [totalsData, setTotalsData] = useState<TotalsData>({
		date: 'Total',
	});
	const api = useAPI();
	const tableRef = useRef<DataTableMethods>();

	useEffect(() => {
		let cancelled = false;
		setStoryStats(undefined);
		const loadStoryStats = async (nextToken?: string) => {
			if (cancelled) {
				return;
			}
			setLoading(true);
			let since = '30d';
			if (period === 'day') {
				since = '30d';
			} else if (period === 'week') {
				since = '90d';
			} else if (period === 'month') {
				// 3 Months
				since = '120d';
			}
			const searchParams = new URLSearchParams({
				since,
				timeGroup: period,
			});
			let groupBy = 'recipient';
			const publicationNames: { [key: string]: string } = {};

			if (contents) {
				for (const pub of contents) {
					if (pub?.newstex_id) {
						if (isPublisher(pub)) {
							searchParams.append('prefix', pub.newstex_id);
						} else {
							searchParams.append('newstex_id', pub.newstex_id);
						}

						if (pub.name) {
							publicationNames[String(pub.newstex_id)] = `[${pub.newstex_id}] ${pub.name}`;
						}
					}
				}
				groupBy = 'content';
				searchParams.append('group_by', 'content');
			} else if (content?.newstex_id) {
				if (isPublisher(content)) {
					searchParams.append('prefix', content.newstex_id);
				} else {
					searchParams.append('newstex_id', content.newstex_id);
				}
			}

			if (nextToken) {
				searchParams.append('NextToken', nextToken);
			}

			const storyStatsResp = await api.fetchWithAuth<Results<any>>(`stats/story-counts?${searchParams}`);
			if (cancelled) {
				return;
			}

			if (storyStatsResp.nextToken) {
				setTimeout(loadStoryStats, 1000, storyStatsResp.nextToken);
			}

			if (storyStatsResp.items?.length) {
				let labels: BarChartProps['labels'] = [];
				const datasets: BarChartProps['datasets'] = [];
				const uniqueDistributors = new Set<string>();
				const dateMap = new Map<string, { [key: string]: number }>();

				// Get all unique days and distributors first
				for (const item of storyStatsResp.items) {
					if (item.day) {
						const date = item.day.split(' ')[0];
						const distributor = item[groupBy] || (hasPermission(['admin']) ? 'Received' : 'Story Count');
						const distributorName = groupBy === 'content' && publicationNames[distributor]
							? publicationNames[distributor]
							: distributor;

						uniqueDistributors.add(distributorName);

						// Initialize or update the date entry
						if (!dateMap.has(date)) {
							dateMap.set(date, {});
						}
						const dateEntry = dateMap.get(date)!;
						dateEntry[distributorName] = parseInt(item.story_count, 10) || 0;
					}
				}

				// Convert dates to labels for the chart
				labels = Array.from(dateMap.keys()).sort((a, b) => {
					const dateA = new Date(a);
					const dateB = new Date(b);
					return dateA.getTime() - dateB.getTime();
				}).map((date) => ({
					name: date,
					backgroundColor: (() => {
						const dateObj = new Date(date);
						return (period === 'day' && (dateObj.getDay() === 0 || dateObj.getDay() === 6))
							? 'rgba(0,0,0,0.1)'
							: undefined;
					})(),
				}));

				// Create datasets for the chart
				const sortedDistributors = Array.from(uniqueDistributors).sort();
				for (const distributor of sortedDistributors) {
					const dataset: Dataset = {
						label: distributor,
						data: labels.map((label) => dateMap.get(label.name)?.[distributor] || 0),
					};

					if (distributor === 'Received') {
						dataset.type = 'line';
						dataset.borderWidth = 2;
						dataset.color = 'green';
						datasets.unshift(dataset);
					} else {
						datasets.push(dataset);
					}
				}

				// Create table data (dates as rows)
				const tableRows = labels.map((label) => {
					const row: any = {
						date: label.name,
					};

					// Add distributor values
					for (const distributor of sortedDistributors) {
						row[distributor] = dateMap.get(label.name)?.[distributor] || 0;
					}

					// Calculate row total
					const rowTotal = sortedDistributors.reduce((sum, distributor) => {
						return sum + (dateMap.get(label.name)?.[distributor] || 0);
					}, 0);

					row.rowTotal = rowTotal;

					return row;
				});

				// Calculate totals for each distributor
				const totals: TotalsData = {
					date: 'Total',
				};

				let grandTotal = 0;

				for (const distributor of sortedDistributors) {
					const distributorTotal = tableRows.reduce((sum, row) => sum + (row[distributor] || 0), 0);
					totals[distributor] = distributorTotal;
					grandTotal += distributorTotal;
				}

				// Add grand total to totals
				totals.rowTotal = grandTotal;

				if (!cancelled) {
					setStoryStats({
						labels,
						datasets: datasets.slice(0, 10),
					});
					setTableData(tableRows);
					setDistributors(sortedDistributors);
					setTotalsData(totals);
				}
			}
			setLoading(false);
		};

		loadStoryStats();
		return () => {
			cancelled = true;
		};
	}, [content, contents, api, period]);

	const columnHelper = createColumnHelper<any>();
	const columns = useMemo(() => {
		if (!distributors.length) return [];

		return [
			columnHelper.accessor('date', {
				header: 'Date',
				enableSorting: true,
			}),
			...distributors.map((distributor) => columnHelper.accessor(
				distributor,
				{
					header: distributor,
					enableSorting: true,
					cell: ({ getValue }) => {
						const value = getValue();
						return typeof value === 'number' ? value.toLocaleString() : value;
					},
				},
			)),
			// Add total column
			...(hasPermission(['admin']) ? [
				columnHelper.accessor('rowTotal', {
					header: () => <span className="fw-bold">Total</span>,
					enableSorting: true,
					cell: ({ getValue }) => {
						const value = getValue();
						return (
							<span className="totals">
								{typeof value === 'number' ? value.toLocaleString() : value}
							</span>
						);
					},
				}),
			] : []),
		];
	}, [distributors]);

	// Create table footer with totals
	const tableFooter = useMemo(() => {
		if (!distributors.length || !totalsData) return null;

		return (
			<tr className="totals">
				<td>Total</td>
				{distributors.map((distributor) => (
					<td key={distributor}>
						{typeof totalsData[distributor] === 'number'
							? totalsData[distributor].toLocaleString()
							: '0'}
					</td>
				))}
				{hasPermission(['admin']) && (
					<td className="grand-total">
						{typeof totalsData.rowTotal === 'number'
							? totalsData.rowTotal.toLocaleString()
							: '0'}
					</td>
				)}
			</tr>
		);
	}, [distributors, totalsData]);

	return (
		<div>
			<div>
				{loading && (
					<LoadingSpinner
						hideTitle={true}
						loading={loading}
					/>
				)}

				{!loading && storyStats && (
					<BarChart
						setPeriod={setPeriod}
						period={period}
						{...storyStats}
					/>
				)}

				{!loading && tableData.length > 0 && (
					<DataTable
						className="table-bordered mt-4"
						ref={tableRef}
						items={tableData}
						columns={columns}
						defaultSort="date"
						nameAsc
						striped
						sortable
						pagination
						footer={tableFooter}
					/>
				)}

				{!loading && !storyStats && (
					<center>No story stats</center>
				)}
			</div>
			<div className="text-end">
				{!loading && tableData.length > 0 && (
					<Button
						variant="outline-secondary"
						size="sm"
						onClick={() => tableRef?.current?.exportToCSV(`Story Stats - ${content?.newstex_id || content?.name || 'Content'} - ${new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })}`)}
					>
						<FontAwesomeIcon icon={faDownload} /> Export CSV
					</Button>
				)}
			</div>
		</div>
	);
};
