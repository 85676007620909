import { EarningsReportByClientAndPublication, RevenueAndEarningsForContact } from '@newstex/types/revenue';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from '~/components/LoadingSpinner';
import { PageTitle } from '~/components/page-title';
import Preloader from '~/components/Preloader';
import { SimpleFacetMenu } from '~/components/simple-facet-menu';
import { RevenueTable } from '~/components/tables/revenue-table';
import GroupedRevenueTable from '~/components/tables/revenue-table-grouped';
import { formatDatePeriod } from '~/lib/utils';
import { useAPI } from '~/providers/api-provider';

export function RevenueListPage() {
	const [searchParams, setSearchParams] = useSearchParams();
	const api = useAPI();
	const [loading, setLoading] = useState<boolean>(false);
	const [allRevenue, setAllRevenue] = useState<EarningsReportByClientAndPublication[]>([]);
	const [revenue, setRevenue] = useState<EarningsReportByClientAndPublication[]>([]);
	const [distributors, setDistributors] = useState<{
		label: string,
		value: string,
		count: string,
	}[]>([]);
	const [periods, setPeriods] = useState<{
		label: string,
		value: string,
		count: string,
	}[]>([]);
	const [publications, setPublications] = useState<{
		label: string,
		value: string,
		count: string,
	}[]>([]);
	const [publishers, setPublishers] = useState<{
		label: string,
		value: string,
		count: string,
	}[]>([]);
	const [isGroupedView, setIsGroupedView] = useState<boolean>(true);

	// Filter Results
	useEffect(() => {
		let filteredRevenue = allRevenue;
		for (const facet of ['period', 'client', 'publicationNewstexID', 'publisherNewscoreID'] as (keyof EarningsReportByClientAndPublication)[]) {
			const filter = searchParams.get(facet);
			if (filter) {
				if (facet === 'period' && filter.split('-').length > 2) {
					const [year, month] = filter.split('-');
					setSearchParams((prev) => {
						prev.set('period', `${year}-${month}`);
						return prev;
					}, { replace: true });
				}
				filteredRevenue = filteredRevenue.filter((r) => r[facet] === filter);
			}
		}

		if (searchParams.get('q')) {
			// Implement search functionality here
			// This is a simple example, you might want to use a library like Fuse.js for more complex searching
			const searchTerm = searchParams.get('q')?.toLowerCase() || '';
			setRevenue(filteredRevenue.filter((r) => r.publicationName.toLowerCase().includes(searchTerm)));
		} else {
			setRevenue(filteredRevenue);
		}
	}, [allRevenue, searchParams]);

	const fetchData = async () => {
		setLoading(true);
		const resp = await api.fetchWithAuth<RevenueAndEarningsForContact>('revenue');
		setAllRevenue(resp?.earnings || []);
		setLoading(false);
	};

	// Load all results
	useEffect(() => {
		fetchData();
	}, [api]);

	const isFacetFiltered = (facet: string) => Boolean(searchParams?.get(facet));

	const formatCount = (count: number | undefined, facetName: string): string => {
		if (count) {
			return `$${count.toFixed(2)}`;
		}
		return isFacetFiltered(facetName) ? '' : '$0.00';
	};

	// Update counts based on allRevenue and revenue
	useEffect(() => {
		setDistributors(() => {
			const distributorCounts: Record<string, number> = {};
			for (const row of revenue) {
				if (!distributorCounts[row.client]) {
					distributorCounts[row.client] = 0;
				}
				distributorCounts[row.client] += row.earnings || 0;
			}
			const currentDistributors: {
				label: string,
				value: string,
				count: number,
			}[] = [];
			for (const row of allRevenue) {
				if (!currentDistributors.some((d) => d.value === row.client)) {
					currentDistributors.push({
						label: row.client,
						value: row.client,
						count: distributorCounts[row.client] || 0,
					});
				}
			}
			return currentDistributors.sort((a, b) => b.count - a.count).map((d) => ({
				...d,
				count: formatCount(d.count, 'client'),
			}));
		});

		setPeriods(() => {
			const reportPeriods: {
				label: string,
				value: string,
				count: string,
			}[] = [];
			const totalEarningsByPeriod: Record<string, number> = {};
			for (const row of revenue) {
				if (!totalEarningsByPeriod[row.period]) {
					totalEarningsByPeriod[row.period] = 0;
				}
				totalEarningsByPeriod[row.period] += row.earnings || 0;
			}
			for (const row of allRevenue) {
				if (!reportPeriods.some((p) => p.value === row.period)) {
					reportPeriods.push({
						label: formatDatePeriod(row.period),
						value: row.period,
						count: formatCount(totalEarningsByPeriod[row.period], 'period'),
					});
				}
			}
			return reportPeriods.sort((a, b) => new Date(b.value).getTime() - new Date(a.value).getTime());
		});

		setPublications(() => {
			const publicationCounts: Record<string, number> = {};
			for (const row of revenue) {
				if (!publicationCounts[row.publicationNewstexID]) {
					publicationCounts[row.publicationNewstexID] = 0;
				}
				publicationCounts[row.publicationNewstexID] += row.earnings || 0;
			}
			const currentPublications: {
				label: string,
				value: string,
				count: number,
			}[] = [];
			for (const row of allRevenue) {
				if (!currentPublications.some((p) => p.value === row.publicationNewstexID)) {
					currentPublications.push({
						label: row.publicationName,
						value: row.publicationNewstexID,
						count: publicationCounts[row.publicationNewstexID] || 0,
					});
				}
			}
			return currentPublications.sort((a, b) => b.count - a.count).map((p) => ({
				...p,
				count: formatCount(p.count, 'publicationNewstexID'),
			}));
		});

		setPublishers(() => {
			const publisherCounts: Record<string, { name: string, count: number }> = {};
			for (const row of revenue) {
				if (!publisherCounts[row.publisherNewscoreID]) {
					publisherCounts[row.publisherNewscoreID] = { name: row.publisherName, count: 0 };
				}
				publisherCounts[row.publisherNewscoreID].count += row.earnings || 0;
			}
			const currentPublishers: {
				label: string,
				value: string,
				count: number,
			}[] = [];
			for (const row of allRevenue) {
				if (!currentPublishers.some((p) => p.value === row.publisherNewscoreID)) {
					currentPublishers.push({
						label: row.publisherName,
						value: row.publisherNewscoreID,
						count: publisherCounts[row.publisherNewscoreID]?.count || 0,
					});
				}
			}
			return currentPublishers.sort((a, b) => b.count - a.count).map((p) => ({
				...p,
				count: formatCount(p.count, 'publisherNewscoreID'),
			}));
		});
	}, [allRevenue, revenue]);

	return (
		<Container fluid>
			<PageTitle title="Revenue Reports">
				{/*
				DISABLED: The control of different views (for now)
				<div className="d-flex justify-content-end">
					<ButtonGroup className="mb-3">
						<Button
							size="sm"
							variant={isGroupedView ? 'outline-secondary' : 'primary'}
							onClick={() => setIsGroupedView(false)}
						>
							Raw
						</Button>
						<Button
							size="sm"
							variant={isGroupedView ? 'primary' : 'outline-secondary'}
							onClick={() => setIsGroupedView(true)}
						>
							Statement
						</Button>
					</ButtonGroup>
				</div>
				*/}
			</PageTitle>

			{loading && <Preloader type="three-bounce" variant="success" />}
			<hr/>
			<Row>
				<Col md={4} xxl={2}>
					{Boolean(periods?.length && periods.length > 1) && (
						<SimpleFacetMenu
							title="Reporting Period"
							prop="period"
							options={[
								{
									label: 'All',
									value: '',
								},
								...periods,
							]}
						/>
					)}
					{Boolean(distributors?.length && distributors.length > 1) && (
						<SimpleFacetMenu
							title="Distributor"
							prop="client"
							options={[
								{
									label: 'All',
									value: '',
								},
								...distributors,
							]}
						/>
					)}
					{Boolean(publishers?.length && publishers.length > 1) && (
						<SimpleFacetMenu
							title="Publisher"
							prop="publisherNewscoreID"
							options={[
								{
									label: 'All',
									value: '',
								},
								...publishers,
							]}
						/>
					)}
					{Boolean(publications?.length && publications.length > 1) && (
						<SimpleFacetMenu
							title="Publication"
							prop="publicationNewstexID"
							options={[
								{
									label: 'All',
									value: '',
								},
								...publications,
							]}
						/>
					)}
				</Col>
				<Col md={8} xxl={10}>
					{(() => {
						if (loading) {
							return <LoadingSpinner loading={loading} />;
						}
						return isGroupedView
							? <GroupedRevenueTable revenue={revenue || []} />
							: <RevenueTable revenue={revenue || []} />;
					})()}
				</Col>
			</Row>
		</Container>
	);
}
