/**
 * Site configuration for the Newstex platform
 */
import type { SiteConfig } from '@newstex/types/site-config';

const siteConfigs: Record<string, SiteConfig> = {
	app: {
		$type: 'SiteConfig',
		$id: 'app',
		name: 'Newstex Management Portal',
		category: 'app',
		url: 'https://app.newstex.com',
		features: [
			{
				featureName: 'Publisher Portal',
				featureDescription: 'Access your revenue and usage statistics',
				featureIcon: 'chart-line',
			},
			{
				featureName: 'Distributor Portal',
				featureDescription: 'Configure your feeds and deliveries',
				featureIcon: 'cog',
			},
			{
				featureName: 'Research Portal',
				featureDescription: 'Curate and discover new content',
				featureIcon: 'search',
			},
		],
	},
	curator: {
		$type: 'SiteConfig',
		$id: 'curator',
		name: 'Newstex Curator',
		category: 'curator',
		url: 'https://curator.newstex.com',
		features: [
			{
				featureName: 'Curated Content',
				featureDescription: 'Curated content from Newstex publishers',
				featureIcon: 'search',
			},
			{
				featureName: 'Custom Newsfeeds',
				featureDescription: 'Create custom newsfeeds based on your interests',
				featureIcon: 'rss',
			},
			{
				featureName: 'AI-Powered Insights',
				featureDescription: 'Get insights and recommendations for content to follow',
				featureIcon: 'brain',
			},
		],
	},
	today: {
		$type: 'SiteConfig',
		$id: 'today',
		name: 'Newstex Today',
		category: 'today',
		url: 'https://today.newstex.com',
		features: [
			{
				featureName: 'Curated Content',
				featureDescription: 'Curated content from Newstex publishers',
				featureIcon: 'calendar',
			},
		],
	},
};
// App is an alias for admin
siteConfigs.admin = siteConfigs.app;

export function getSiteID(hostname: string): string {
	return hostname.split('.')[0].split('-')[0];
}

export function getSiteConfig(hostname: string): SiteConfig | null {
	const siteID = getSiteID(hostname);
	return siteConfigs[siteID] || null;
}
