import { SearchIndexName } from '@newstex/search';
import { Publisher } from '@newstex/types/content';
import AsyncSelect from 'react-select/async';
import { useSearch } from '~/providers/search';

interface PublisherSelectProps {
	value: Publisher | null;
	onChange: (publisher: Publisher | null) => void;
	// Manual filter after searching
	filter?: (publisher: Publisher) => boolean;
	// Search filter string to filter by
	// See: https://typesense.org/docs/27.1/api/search.html#filter-parameters
	filterBy?: string;
}

export function PublisherSelect({
	value, onChange, filter, filterBy,
}: PublisherSelectProps) {
	const { searchClient } = useSearch();

	const loadOptions = async (inputValue: string) => {
		if (!searchClient) return [];

		const results = await searchClient.search<Publisher>({
			indexName: 'Publisher' as SearchIndexName,
			query: inputValue || '*',
			filter_by: filterBy,
			sort_by: 'name:asc',
			per_page: 100,
			include_fields: 'id,name,description,status,newstex_id,url',
		});

		let pubs = results.hits;
		if (filter) {
			pubs = pubs.filter(filter);
		}
		// TODO: Should this normalization be done in the search client?
		for (const pub of pubs) {
			if (!pub.$id && pub.id) {
				pub.$id = pub.id;
			}
		}

		return pubs.map((pub) => ({
			value: pub.$id,
			label: `${pub.name} [${pub.newstex_id || 'NEW'}]`,
			data: pub,
		}));
	};

	return (
		<AsyncSelect
			value={value ? {
				value: value.$id,
				label: `${value.name} [${value.newstex_id || 'NEW'}]`,
				data: value,
			} : null}
			onChange={(option) => {
				onChange(option?.data || null);
			}}
			loadOptions={loadOptions}
			defaultOptions
			isClearable
			placeholder="Search for a publisher..."
		/>
	);
}
