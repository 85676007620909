import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import { PageTitle } from '~/components/page-title';

/**
 * User (non-publisher, non-admin, non-distributor) dashboard
 */
export function UserDashboard() {
	return (
		<Container fluid className="px-lg-4 px-xl-5">
			<PageTitle title="Newstex Labs" />
			<Row>
				<Col>
					<Alert variant="warning" className="text-center">
						<h3>Your account is not associated with any Newstex products</h3>
						<p>
							If you think this is an error, please contact support@newstex.com.
						</p>
					</Alert>
				</Col>
			</Row>
			<Row>
				<Col>
					<Card>
						<Card.Header><h4>What is Newstex?</h4></Card.Header>
						<Card.Body>
							Newstex is a platform for creating, managing, and distributing content to your audience.
						</Card.Body>
						<Card.Footer className="text-center">
							<Button
								variant="primary"
								onClick={() => {
									window.open('https://www.newstex.com/why-newstex', '_blank');
								}}
							>
								Learn more
							</Button>
						</Card.Footer>
					</Card>
				</Col>
				<Col>
					<Card>
						<Card.Header><h4>Suggest a publication</h4></Card.Header>
						<Card.Body>
							Do you have a blog or web publication in mind that you'd like to see on Newstex?
						</Card.Body>
						<Card.Footer className="text-center">
							<Button
								variant="primary"
								onClick={() => {
									window.open('https://www.newstex.com/suggest-a-publication', '_blank');
								}}
							>
								Suggest a publication
							</Button>
						</Card.Footer>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
