import { faConstruction } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Alert,
	Col,
	Container,
	Row,
} from 'react-bootstrap';

export function CuratorDashboard() {
	return <Container fluid>
		<Row>
			<Col className="justify-content-center text-center">
				<h1>Newstex Curator</h1>
			</Col>
		</Row>
		<Row>
			<Col className="justify-content-center text-center">
				<Alert variant="warning">
					<Alert.Heading>
						Coming Soon
					</Alert.Heading>
					<p>
						Please check back soon for more features.
					</p>
					<FontAwesomeIcon icon={faConstruction} className="ms-2" style={{ fontSize: '120px' }} />
				</Alert>
			</Col>
		</Row>
	</Container>;
}
