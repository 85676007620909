import { AlertStatus, AlertStatusValue } from '@newstex/types/alert';
import { Badge } from 'react-bootstrap';

const BG_STATUS_MAPPING: Record<AlertStatusValue, string> = {
	[AlertStatus.OK]: 'success',
	[AlertStatus.WARNING]: 'warning',
	[AlertStatus.CRITICAL]: 'danger',
	[AlertStatus.NO_DATA]: 'secondary',
	[AlertStatus.DISABLED]: 'light',
};

const STATUS_LABELS: Record<AlertStatusValue, string> = {
	[AlertStatus.OK]: 'OK',
	[AlertStatus.WARNING]: 'Warning',
	[AlertStatus.CRITICAL]: 'Critical',
	[AlertStatus.NO_DATA]: 'No Data',
	[AlertStatus.DISABLED]: 'Disabled',
};

interface AlertBadgeProps {
	status: AlertStatusValue;
	indicator?: boolean;
	showLabel?: boolean;
}

export function AlertBadge({ status, indicator = false, showLabel = true }: AlertBadgeProps) {
	const statusClassName = BG_STATUS_MAPPING[status] || 'secondary';

	return (
		<Badge bg={`${statusClassName}-subtle`} text="dark">
			{showLabel && <b>{STATUS_LABELS[status]}</b>}
			{indicator && <span className={`indicator right text-${statusClassName}`} />}
		</Badge>
	);
}
