import { SiteConfig } from '@newstex/types/site-config';
import { getSiteConfig } from '@newstex/utils/site-config';
import {
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';

export const SiteConfigContext = createContext<SiteConfig | null>(null);

export function SiteConfigProvider({ children }: { children: React.ReactNode }) {
	const [siteConfig, setSiteConfig] = useState<SiteConfig | null>(null);
	useEffect(() => {
		setSiteConfig(getSiteConfig(window.location.hostname));
	}, [window.location.hostname]);
	return <SiteConfigContext.Provider value={siteConfig}>{children}</SiteConfigContext.Provider>;
}

export function useSiteConfig() {
	const siteConfig = useContext(SiteConfigContext);
	return siteConfig;
}
