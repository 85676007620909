import sparkline from '@fnando/sparkline';
import React, { useEffect, useRef } from 'react';

export interface SparklineData {
	name: string;
	date: string;
	value: number;
}
export interface SparklineComponentProps {
	data: SparklineData[];
	strokeWidth?: number;
	height?: number;
	width?: number;
	stroke?: string;
	className?: string;
	showTooltip?: boolean;
}
const formatNumber = Intl.NumberFormat('en-US').format;

export default function SparklineComponent({
	data,
	strokeWidth,
	height,
	width,
	stroke,
	className,
	showTooltip,
}: SparklineComponentProps) {
	const sparklineRef = useRef<any>(false);
	const tooltipRef = useRef<any>(false);

	useEffect(() => {
		const tooltip = tooltipRef.current;
		const options = {
			onmousemove(event: any, datapoint: any) {
				const date = new Date(datapoint.date)
					.toLocaleDateString('en-US', { timeZone: 'America/New_York' });
				if (showTooltip) {
					tooltip.hidden = false;
					tooltip.textContent = `${date}: ${formatNumber(datapoint.value || 0)}`;
					tooltip.style.top = `${event.offsetY}px`;
					tooltip.style.left = `${event.offsetX + 20}px`;
				}
			},

			onmouseout() {
				if (showTooltip) {
					tooltip.hidden = true;
				}
			},
		};

		if (sparklineRef.current) {
			sparkline(sparklineRef.current, data, options);
		}
	}, [data, width, height]);

	return (
		<div className="position-relative">
			<svg
				ref={sparklineRef}
				width={width || 100}
				height={height || 20}
				strokeWidth={strokeWidth || 2}
				className={className || ''}
				stroke={stroke}
				fill="rgba(0, 0, 255, .2)"
			/>
			{showTooltip && (
				<span className="sparkline--tooltip" ref={tooltipRef} hidden></span>
			)}
		</div>
	);
}
