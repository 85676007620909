import { type Alert, AlertStatus } from '@newstex/types/alert';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { AlertBadge } from '../alert-badge';
import { LoadMoreButton } from '../load-more-button';
import { PropertyDisplayValue } from '../property-display-value';
import { SearchLinkButton } from '../search-link-button';

interface AlertsTableProps {
	alerts: Alert[];
	hasMore?: boolean;
	onLoadMore?: () => void;
}

export function AlertsTable({ alerts, hasMore, onLoadMore }: AlertsTableProps) {
	return (
		<>
			<Table hover responsive striped>
				<thead>
					<tr>
						<th style={{ maxWidth: '250px' }}>Name</th>
						<th>Collection</th>
						<th>Schedule</th>
						<th>Last Run</th>
						<th>Status</th>
						<th/>
					</tr>
				</thead>
				<tbody>
					{alerts.map((alert) => (
						<tr key={alert.$id} className={`alert-${AlertStatus[alert.status || AlertStatus.NO_DATA]?.toLowerCase()}`}>
							<td style={{ maxWidth: '250px' }}>
								<Link to={`/alerts/${alert.$id}`}>
									{alert.name}
								</Link>
								{alert.description && (<>
									<br/>
									<small className="text-muted">
										<PropertyDisplayValue propName="description" propValue={alert.description} short />
									</small>
								</>)}
							</td>
							<td>{alert.collection}</td>
							<td>
								<PropertyDisplayValue propName="schedule" propValue={alert.schedule} short />
							</td>
							<td>
								{alert.last_run
									? new Date(alert.last_run * 1000).toLocaleString('en-US', { timeZone: 'America/New_York' })
									: 'Never'}
							</td>
							<td>
								<AlertBadge status={alert.status || AlertStatus.NO_DATA} />
							</td>
							<td>
								<SearchLinkButton
									search={alert.search}
									indexName={alert.collection}
									size="sm"
									variant="outline-secondary"
								/>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<LoadMoreButton show={hasMore} onClick={onLoadMore} />
		</>
	);
}
