import { z } from 'zod';

import { BaseObjectSchema } from './base';
import { UnixTimestampSchema } from './dates';
import { ISOLanguageCodeSchema } from './language';
import type { ReferenceString } from './reference';
import type { User } from './user';

/**
 * Newstex Category
 * TableName: Categories
 *
 * @author: Chris Moyer <cmoyer@newstex.com>
 */
export const CategorySchema = BaseObjectSchema.extend({
	$type: z.literal('Category'),
	code: z.string(),
	category_map: z.array(z.string()).optional()
		.describe('List of categories this maps to'),
	negated_by: z.array(z.string()).optional()
		.describe('List of categories that negate this one'),

	description: z.string().optional()
		.describe('Description of this category'),
	type: z.string().optional()
		.describe('Type of category'),
	name: z.string().optional()
		.describe('Display name'),
	last_applied: UnixTimestampSchema.optional()
		.describe('Last time this category was applied to a story'),
	last_checked: UnixTimestampSchema.optional()
		.describe('Last time this category was checked for updates'),
	story_count: z.number().optional()
		.describe('Number of stories with this category'),

	created_by: z.string().optional() as z.ZodType<ReferenceString<User>>,
	created_at: UnixTimestampSchema.optional(),

	modified_by: z.string().optional() as z.ZodType<ReferenceString<User>>,
	modified_at: UnixTimestampSchema.optional(),
});

export type Category = z.infer<typeof CategorySchema>;

/**
 * A marker that indicates a category should be applied
 */
export const CategoryMarkerSchema = BaseObjectSchema.extend({
	$type: z.literal('CategoryMarker'),
	text: z.string()
		.describe('Text to match'),
	category: z.string()
		.describe('Category to apply'),
	weight: z.number()
		.describe('Weight of this marker'),
	language: ISOLanguageCodeSchema.optional()
		.describe('Language this marker applies to'),
	created_by: z.string().optional() as z.ZodType<ReferenceString<User>>,
	created_at: UnixTimestampSchema.optional(),
});

export type CategoryMarker = z.infer<typeof CategoryMarkerSchema>;

/**
 * Map of category markers by language and text
 */
export const CategoryMarkerMapSchema = z.record(
	z.string(),
	z.record(
		z.string(),
		z.record(z.string(), z.number()),
	),
).describe('Map of category markers by language, text, and category');

export type CategoryMarkerMap = z.infer<typeof CategoryMarkerMapSchema>;

export function isCategory(item: any, tableName?: string): item is Category {
	return item?.$type === 'Category' || tableName === 'Category';
}
