/**
 * Edit Report Modal
 */
import { Report } from '@newstex/types/report';

import ReportFormModal, { ReportFormModalProps } from './report-form-modal';

export interface EditReportModalProps extends Omit<ReportFormModalProps, 'title'> {
	item: Report;
}

export default function EditReportModal({
	item,
	show,
	onClose,
}: EditReportModalProps) {
	return (
		<ReportFormModal
			item={item}
			show={show}
			onClose={onClose}
			title={`Edit Report: ${item.name}`}
		/>
	);
}
