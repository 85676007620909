import { KnowledgeBase } from '@newstex/types/rag';
import { GetHubSpotRecordsResponse } from '@newstex/types/responses/get-hubspot-records';
import { Dexie } from 'dexie';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { LeadData } from '~/providers/api-provider';

import { createDexieStorage } from './dexie-storage-adapter';

// Custom storage object
const db = new Dexie('newscore');
db.version(1).stores({
	qualification: 'cacheKey',
});

interface QualificationState {
	// Form state
	knowledgeBaseArticle: KnowledgeBase | null;
	autoSyncToHubSpot: boolean;
	hubSpotList: GetHubSpotRecordsResponse['list'] | null;
	hubSpotListID: string | undefined;
	nextToken: string | undefined;

	// Processing state
	items: Record<string, LeadData>;
	running: boolean;
	syncing: boolean;
	synced: boolean;
	shouldRunQualification: boolean;

	// Actions
	setKnowledgeBaseArticle: (article: KnowledgeBase | null) => void;
	setAutoSyncToHubSpot: (autoSync: boolean) => void;
	setHubSpotList: (list: GetHubSpotRecordsResponse['list'] | null) => void;
	setHubSpotListID: (id: string | undefined) => void;
	setNextToken: (token: string | undefined) => void;
	setItems: (items: Record<string, LeadData>) => void;
	updateItem: (item: LeadData) => void;
	setRunning: (running: boolean) => void;
	setSyncing: (syncing: boolean) => void;
	setSynced: (synced: boolean) => void;
	setShouldRunQualification: (should: boolean) => void;
	cacheResult: (cacheKey: string, result: LeadData) => void;
	getCachedResult: (cacheKey: string) => Promise<LeadData | undefined>;
	clearCache: () => void;
	reset: () => void;
	initializeState: () => void;
	removeItem: (id: string) => void;
	getItem: (id: string) => LeadData | undefined;
}

const initialState = {
	knowledgeBaseArticle: null,
	autoSyncToHubSpot: true,
	hubSpotList: null,
	hubSpotListID: undefined,
	nextToken: undefined,
	items: {},
	running: false,
	syncing: false,
	synced: false,
	shouldRunQualification: false,
};

export const useQualificationStore = create<QualificationState>()(
	persist(
		(set, get) => ({
			...initialState,

			setKnowledgeBaseArticle: (article) => set({ knowledgeBaseArticle: article }),
			setAutoSyncToHubSpot: (autoSync) => set({ autoSyncToHubSpot: autoSync }),
			setHubSpotList: (list) => set({ hubSpotList: list }),
			setHubSpotListID: (id) => set({ hubSpotListID: id }),
			setNextToken: (token) => set({ nextToken: token }),
			setItems: (items) => set({ items }),
			updateItem: (item) => set((state) => ({
				items: {
					...state.items,
					[item.id]: { ...state.items[item.id], ...item },
				},
			})),
			getItem: (id) => get().items[id],
			setRunning: (running) => set({ running }),
			setSyncing: (syncing) => set({ syncing }),
			setSynced: (synced) => set({ synced }),
			setShouldRunQualification: (should) => set({ shouldRunQualification: should }),

			cacheResult: (cacheKey, result) => {
				db.table('qualification').put({ cacheKey, result });
			},

			getCachedResult: async (cacheKey) => {
				const result = await db.table('qualification').get(cacheKey);
				return result?.result as LeadData;
			},

			clearCache: () => db.table('qualification').clear(),

			reset: () => {
				set(initialState);
				db.table('qualification').clear();
			},

			// Initialize state on page load
			initializeState: () => {
				const state = get();
				const items = state.items;

				// Reset any "running" or "checking" items to waiting state
				const updatedItems = { ...items };
				for (const item of Object.values(updatedItems)) {
					if (item.running || item.sync_status === 'Checking') {
						item.running = false;
						if (item.sync_status !== 'Updated' && item.sync_status !== 'Ready') {
							item.sync_status = 'Waiting';
						}
					}
				}

				// Always ensure we're in a stopped state on initialization
				set({
					items: updatedItems,
					running: false,
					syncing: false,
					synced: false,
					shouldRunQualification: false,
				});
			},

			removeItem: (id) => {
				const items = get().items;
				delete items[id];
				set({ items });
			},
		}),
		{
			name: 'qualification-store',
			// Persist all important state
			partialize: (state) => ({
				knowledgeBaseArticle: state.knowledgeBaseArticle,
				autoSyncToHubSpot: state.autoSyncToHubSpot,
				items: state.items,
				hubSpotList: state.hubSpotList,
				hubSpotListID: state.hubSpotListID,
				nextToken: state.nextToken,
				// Don't persist running/syncing state since those should reset on page load
			}),
			// Add version number to handle future schema changes
			version: 1,
			// Use the Dexie storage adapter
			storage: createDexieStorage<QualificationState>('qualification'),
			// Migrate old state versions if needed
			migrate: (persistedState: any, version: number) => {
				if (version === 0) {
					// Handle migration from version 0 if needed in the future
					return persistedState;
				}
				return persistedState;
			},
		},
	),
);
