import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Report } from '@newstex/types/report';
import { title as titleCase } from 'case';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReportFormModal from '~/components/modals/report-form-modal';
import { RequirePermissions } from '~/components/require-permissions';
import { SearchFacetConfig, SearchWithFacets } from '~/components/search-with-facets';
import { ReportsTable } from '~/components/tables/reports-table';

export const ReportFacetConfig: SearchFacetConfig[] = [
	{
		attribute: 'category',
		title: 'Category',
		type: 'Menu',
		showMore: true,
	},
	{
		attribute: 'database',
		title: 'Database',
		type: 'Menu',
		showMore: true,
	},
	{
		attribute: 'access_groups',
		title: 'Access Groups',
		type: 'Menu',
		showMore: true,
		displayValue: (value) => {
			if (value === '') return 'All';
			return titleCase(value);
		},
	},
	{
		attribute: 'roles',
		title: 'Roles',
		type: 'Menu',
		showMore: true,
		searchable: true,
	},
];

export const ReportSearchPage = () => {
	const navigate = useNavigate();
	const [showNewModal, setShowNewModal] = useState(false);

	return (
		<>
			<SearchWithFacets<Report>
				indexName="Report"
				title="Reports"
				queryBy={['name', 'description', 'query']}
				facets={ReportFacetConfig}
				resultsTable={ReportsTable}
				actions={
					<RequirePermissions permissions={['admin']}>
						<Button
							size="sm"
							variant="outline-success"
							onClick={() => setShowNewModal(true)}
						>
							<FontAwesomeIcon icon={faPlus} /> New Report
						</Button>
					</RequirePermissions>
				}
			/>

			<ReportFormModal
				show={showNewModal}
				onClose={(saved, report) => {
					setShowNewModal(false);
					if (saved && report?.id) {
						navigate(`/reports/${report.id}`);
					}
				}}
				title="Create New Report"
			/>
		</>
	);
};
