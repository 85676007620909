import { faPlus, faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Parameter, Report } from '@newstex/types/report';
import { title as titleCase } from 'case';
import { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	Modal,
	Row,
	Spinner,
} from 'react-bootstrap';

import { useAPI } from '../../providers/api-provider';

interface DatabaseTable {
	name: string;
	type?: string; // BASE TABLE, VIEW, or MATERIALIZED VIEW
}

interface DatabaseColumn {
	name: string;
	type: string;
}

export interface ParametersConfigModalProps {
	show: boolean;
	onClose: (saved?: boolean) => void;
	report: Report;
	onSave: (parameters: Parameter[]) => Promise<void>;
}

export default function ParametersConfigModal({
	show,
	onClose,
	report,
	onSave,
}: ParametersConfigModalProps) {
	const api = useAPI();
	const [saving, setSaving] = useState(false);
	const [parameters, setParameters] = useState<Parameter[]>(report.parameters || []);
	const [tables, setTables] = useState<DatabaseTable[]>([]);
	const [columns, setColumns] = useState<Record<string, DatabaseColumn[]>>({});
	const [loadingTables, setLoadingTables] = useState(false);
	const [loadingColumns, setLoadingColumns] = useState<Record<string, boolean>>({});

	const fetchDatabaseTables = async () => {
		try {
			setLoadingTables(true);
			const data = await api.fetchWithAuth('database/tables');
			if (data.$type === 'Results') {
				setTables(data.items);
			}
		} catch (error) {
			console.error('Failed to fetch database tables:', error);
		} finally {
			setLoadingTables(false);
		}
	};

	const fetchTableColumns = async (tableName: string) => {
		try {
			setLoadingColumns((prev) => ({
				...prev,
				[tableName]: true,
			}));
			const data = await api.fetchWithAuth(`database/columns?table=${tableName}`);
			if (data.$type === 'Results') {
				setColumns((prev) => ({
					...prev,
					[tableName]: data.items,
				}));
			}
		} catch (error) {
			console.error(`Failed to fetch columns for table ${tableName}:`, error);
		} finally {
			setLoadingColumns((prev) => ({
				...prev,
				[tableName]: false,
			}));
		}
	};

	// Fetch available database tables when modal opens
	useEffect(() => {
		if (show && (report.database === 'NewsCore' || report.database === 'NewsCrunch')) {
			fetchDatabaseTables();
		}
	}, [show, report.database]);

	// Fetch columns when a table is selected
	useEffect(() => {
		for (const param of parameters) {
			if (
				param.optionsSource === 'database'
				&& param.optionsTable
				&& !columns[param.optionsTable]
				&& !loadingColumns[param.optionsTable]
			) {
				fetchTableColumns(param.optionsTable);
			}
		}
	}, [parameters]);

	// Helper to get a friendly display name for a table type
	const getTableTypeDisplay = (type?: string): string => {
		if (!type) return '';
		switch (type) {
			case 'BASE TABLE': return 'Table';
			case 'VIEW': return 'View';
			case 'MATERIALIZED VIEW': return 'Materialized View';
			default: return type;
		}
	};

	const getInputType = (paramType: string): string => {
		if (paramType === 'number') return 'number';
		if (paramType === 'date') return 'date';
		return 'text';
	};

	const handleSave = async () => {
		setSaving(true);
		try {
			await onSave(parameters);
			onClose(true);
		} catch (error) {
			console.error('Failed to save parameters:', error);
		} finally {
			setSaving(false);
		}
	};

	const addParameter = () => {
		setParameters([
			...parameters,
			{
				name: '',
				type: 'string',
				required: false,
				optionsSource: 'manual',
			},
		]);
	};

	const removeParameter = (index: number) => {
		setParameters(parameters.filter((_, i) => i !== index));
	};

	const updateParameter = (index: number, updates: Partial<Parameter>) => {
		setParameters(parameters.map((param, i) => {
			if (i !== index) return param;

			// Handle special case: if switching options source, clear existing options data
			if (updates.optionsSource && updates.optionsSource !== param.optionsSource) {
				const newParam = { ...param, ...updates };

				if (updates.optionsSource === 'manual') {
					// Clear database-specific fields when switching to manual
					delete newParam.optionsTable;
					delete newParam.optionsColumn;
				} else {
					// Clear manual options when switching to database
					delete newParam.options;
				}

				return newParam;
			}

			// Normal update
			return { ...param, ...updates };
		}));
	};

	// Helper to check if the current report database supports sourcing options from database
	const isDatabaseSupportedForOptions = (): boolean => {
		return report.database === 'NewsCore' || report.database === 'NewsCrunch';
	};

	return (
		<Modal show={show} onHide={() => onClose()} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>Configure Parameters</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					{parameters.map((param, index) => (
						<Row key={index} className="mb-4 pb-4 border-bottom">
							<Col md={6}>
								<Form.Group className="mb-3">
									<Form.Label>Name</Form.Label>
									<Form.Control
										type="text"
										value={param.name}
										onChange={(e) => updateParameter(index, { name: e.target.value })}
										required
									/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3">
									<Form.Label>Label</Form.Label>
									<Form.Control
										type="text"
										value={param.label || ''}
										onChange={(e) => updateParameter(index, { label: e.target.value })}
										placeholder={param.name ? titleCase(param.name) : 'Display Label'}
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group className="mb-3">
									<Form.Label>Type</Form.Label>
									<Form.Select
										value={param.type}
										onChange={(e) => updateParameter(index, { type: e.target.value as any })}
									>
										<option value="string">Text</option>
										<option value="number">Number</option>
										<option value="date">Date</option>
										<option value="boolean">Boolean</option>
									</Form.Select>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group className="mb-3">
									<Form.Label>Default Value</Form.Label>
									{param.type === 'boolean' ? (
										<Form.Check
											type="checkbox"
											checked={param.default === 'true'}
											onChange={(e) => updateParameter(index, { default: e.target.checked.toString() })}
											label="Default Checked"
										/>
									) : (
										<Form.Control
											type={getInputType(param.type)}
											value={param.default || ''}
											onChange={(e) => updateParameter(index, { default: e.target.value })}
										/>
									)}
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group className="mb-3">
									<Form.Label>Options</Form.Label>
									<div>
										<Form.Check
											type="checkbox"
											label="Required"
											checked={param.required}
											onChange={(e) => updateParameter(index, { required: e.target.checked })}
										/>
									</div>
								</Form.Group>
							</Col>
							{param.type !== 'boolean' && (
								<>
									<Col md={12}>
										<Form.Group className="mb-3">
											<Form.Label>Options Source</Form.Label>
											<Form.Select
												value={param.optionsSource || 'manual'}
												onChange={(e) => updateParameter(index, {
													optionsSource: e.target.value as 'manual' | 'database',
												})}
												disabled={!isDatabaseSupportedForOptions()}
											>
												<option value="manual">Manual Entry</option>
												<option value="database" disabled={!isDatabaseSupportedForOptions()}>
													Database Column
												</option>
											</Form.Select>
											{!isDatabaseSupportedForOptions() && param.optionsSource === 'database' && (
												<Form.Text className="text-warning">
															Database-sourced options are only available for NewsCore and NewsCrunch database reports.
												</Form.Text>
											)}
										</Form.Group>
									</Col>

									{param.optionsSource === 'manual' && (
										<Col md={12}>
											<Form.Group className="mb-3">
												<Form.Label>Dropdown Options</Form.Label>
												<Form.Control
													as="textarea"
													rows={2}
													value={param.options?.map((opt) => `${opt.value}:${opt.label}`).join('\n') || ''}
													onChange={(e) => {
														const options = e.target.value
															.split('\n')
															.filter((line) => line.trim())
															.map((line) => {
																const [value, label] = line.split(':').map((s) => s.trim());
																return { value, label: label || value };
															});
														updateParameter(index, { options: options.length ? options : undefined });
													}}
													placeholder="value:label (one per line)"
												/>
												<Form.Text className="text-muted">
													Enter one option per line in the format <code>value:label</code>.
													If label is omitted, value will be used as the label.
												</Form.Text>
											</Form.Group>
										</Col>
									)}

									{param.optionsSource === 'database' && (
										<>
											<Col md={6}>
												<Form.Group className="mb-3">
													<Form.Label>Database Table or View</Form.Label>
													<Form.Select
														value={param.optionsTable || ''}
														onChange={(e) => updateParameter(index, {
															optionsTable: e.target.value,
															optionsColumn: undefined, // Clear column when table changes
														})}
														disabled={loadingTables}
													>
														<option value="">Select a table or view</option>
														{tables.map((table) => (
															<option key={table.name} value={table.name}>
																{table.name} {table.type ? `(${getTableTypeDisplay(table.type)})` : ''}
															</option>
														))}
													</Form.Select>
													{loadingTables && (
														<div className="d-flex mt-2">
															<Spinner animation="border" size="sm" className="me-2" />
															<span>Loading tables and views...</span>
														</div>
													)}
												</Form.Group>
											</Col>

											<Col md={6}>
												<Form.Group className="mb-3">
													<Form.Label>Database Column</Form.Label>
													<Form.Select
														value={param.optionsColumn || ''}
														onChange={(e) => updateParameter(index, { optionsColumn: e.target.value })}
														disabled={!param.optionsTable || loadingColumns[param.optionsTable || '']}
													>
														<option value="">Select a column</option>
														{param.optionsTable && columns[param.optionsTable]?.map((column) => (
															<option key={column.name} value={column.name}>
																{column.name} ({column.type})
															</option>
														))}
													</Form.Select>
													{param.optionsTable && loadingColumns[param.optionsTable] && (
														<div className="d-flex mt-2">
															<Spinner animation="border" size="sm" className="me-2" />
															<span>Loading columns...</span>
														</div>
													)}
												</Form.Group>
											</Col>

											<Col md={6}>
												<Form.Group className="mb-3">
													<Form.Label>Label Column (Optional)</Form.Label>
													<Form.Select
														value={param.optionsLabelColumn || ''}
														onChange={(e) => updateParameter(
															index,
															{ optionsLabelColumn: e.target.value || undefined },
														)}
														disabled={!param.optionsTable || loadingColumns[param.optionsTable || '']}
													>
														<option value="">Use value column as label</option>
														{param.optionsTable && columns[param.optionsTable]?.map((column) => (
															<option key={column.name} value={column.name}>
																{column.name} ({column.type})
															</option>
														))}
													</Form.Select>
													{param.optionsTable && loadingColumns[param.optionsTable] && (
														<div className="d-flex mt-2">
															<Spinner animation="border" size="sm" className="me-2" />
															<span>Loading columns...</span>
														</div>
													)}
												</Form.Group>
											</Col>

											<Col md={12}>
												<Form.Text className="text-muted">
													Options will be dynamically loaded from the selected database column.
													{!param.optionsLabelColumn && (
														<>
															<br />
															The value column will be used as both the option value
															and label.
														</>
													)}
													{param.optionsLabelColumn && (
														<>
															<br />
															Values will be loaded from <code>{param.optionsColumn}</code>
															{' '}and labels from <code>{param.optionsLabelColumn}</code>.
														</>
													)}
												</Form.Text>
											</Col>
										</>
									)}
								</>
							)}
							<Col md={12} className="text-end">
								<Button
									variant="outline-danger"
									size="sm"
									onClick={() => removeParameter(index)}
								>
									<FontAwesomeIcon icon={faTrash} /> Remove Parameter
								</Button>
							</Col>
						</Row>
					))}
					<Button
						variant="outline-primary"
						size="sm"
						onClick={addParameter}
						className="mt-2"
					>
						<FontAwesomeIcon icon={faPlus} /> Add Parameter
					</Button>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onClose()}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleSave} disabled={saving}>
					{saving ? 'Saving...' : 'Save Changes'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
