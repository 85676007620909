import { Distributor } from '@newstex/types/distributor';
import AsyncSelect from 'react-select/async';
import { useSearch } from '~/providers/search';

interface DistributorSelectProps {
	value: Distributor | null;
	onChange: (distributor: Distributor | null) => void;
	// Manual filter after searching
	filter?: (distributor: Distributor) => boolean;
	// Search filter string to filter by
	// See: https://typesense.org/docs/27.1/api/search.html#filter-parameters
	filterBy?: string;
}

export function DistributorSelect({
	value, onChange, filter, filterBy,
}: DistributorSelectProps) {
	const { searchClient } = useSearch();

	const loadOptions = async (inputValue: string) => {
		if (!searchClient) return [];

		const results = await searchClient.search<Distributor>({
			indexName: 'Distributor',
			query: inputValue || '*',
			filter_by: filterBy,
			per_page: 100,
			include_fields: 'id,name,status,newscrunch_id',
		});

		let distributors = results.hits.sort((a, b) => a.name.localeCompare(b.name));
		if (filter) {
			distributors = distributors.filter(filter);
		}
		// TODO: Should this normalization be done in the search client?
		for (const distributor of distributors) {
			if (!distributor.$id && distributor.id) {
				distributor.$id = distributor.id;
			}
		}

		return distributors.map((distributor) => ({
			value: distributor.$id,
			label: `${distributor.name} [${distributor.status || 'NEW'}]`,
			data: distributor,
		}));
	};

	return (
		<AsyncSelect
			value={value ? {
				value: value.$id,
				label: `${value.name} [${value.status || 'NEW'}]`,
				data: value,
			} : null}
			onChange={(option) => {
				onChange(option?.data || null);
			}}
			loadOptions={loadOptions}
			defaultOptions
			isClearable
			placeholder="Search for a distributor..."
		/>
	);
}
