import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faComments } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Badge,
	Button,
	Form,
	InputGroup,
	Navbar,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { useAIChat } from '~/providers/ai-chat-provider';
import { useCommandBar } from '~/providers/command-bar';
import { usePageTitle } from '~/providers/page-title-provider';
import { useUserInfo } from '~/providers/user-info';

import UserMenu from './user-menu';

export default function Header() {
	const commandBar = useCommandBar();
	const userInfo = useUserInfo();
	const { title } = usePageTitle();
	const { visible, show, hide } = useAIChat();

	const handleAIChatClick = () => {
		if (!visible) {
			show();
		} else {
			hide();
		}
	};

	return (
		<header className="header">
			<Navbar
				expand="lg"
				className="px-4 py-2 shadow"
			>
				<div className="page-title d-none d-md-block">
					{title}
					{window.location.hostname?.includes('-dev.') && (
						<Badge bg="danger" text="light" className="ms-2 px-2 py-1">Dev</Badge>
					)}
					{window.location.hostname?.includes('-local.') && (
						<Badge bg="danger" text="light" className="ms-2 px-2 py-1">Local</Badge>
					)}
				</div>

				<div className="ms-auto d-flex align-items-center mb-0">
					{(userInfo?.auth_groups?.includes('admin') || userInfo?.auth_groups?.includes('advanced')) && (
						<>
							<Form className="me-3 d-none d-lg-block">
								<InputGroup className="search">
									<Form.Control
										type="search"
										placeholder="Search..."
										className="border-0 bg-light"
										onClick={() => commandBar.show()}
										readOnly
									/>
									<InputGroup.Text className="bg-light border-0">
										<FontAwesomeIcon icon={faSearch} />
									</InputGroup.Text>
								</InputGroup>
							</Form>
							<OverlayTrigger
								placement="bottom"
								overlay={<Tooltip>
									{visible ? 'Close AI Assistant' : 'Open AI Assistant'}
								</Tooltip>}
							>
								<Button
									variant="link"
									className="me-3 p-0"
									onClick={handleAIChatClick}
								>
									<FontAwesomeIcon
										icon={faComments}
										className={visible ? 'text-primary' : 'text-muted'}
									/>
								</Button>
							</OverlayTrigger>
						</>
					)}
					<UserMenu />
				</div>
			</Navbar>
		</header>
	);
}
