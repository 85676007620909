import { faExternalLinkAlt } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type Publication, type Publisher } from '@newstex/types/content';
import { convertReferenceToString } from '@newstex/types/reference';
import { Results } from '@newstex/types/results';
import { useEffect, useState } from 'react';
import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HubSpotLink } from '~/components/hubspot-link';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { StatusBadge } from '~/components/status-badge';
import { useAnalytics } from '~/providers/analytics-provider';
import { useAPI } from '~/providers/api-provider';

import { PublisherSelect } from '../publisher-select';

interface ChangePublisherModalProps {
	publication: Publication;
	show: boolean;
	onClose: (saved: boolean) => void;
}

function PubCardDetails({ pub }: { pub: Publisher }) {
	return (<>
		<Card.Body>
			<Card.Title>
				<h6>
					<Link to={`/publication/${pub.$id}`}>
						{pub.name}
					</Link>
				</h6>
			</Card.Title>
			<dl>
				<dt>Status:</dt>
				<dd><PropertyDisplayValue propName="status" propValue={pub.status} typeName="Publisher" /></dd>
				<dt>Newstex ID:</dt>
				<dd><PropertyDisplayValue propName="newstex_id" propValue={pub.newstex_id} typeName="Publisher" /></dd>
				<dt>Description:</dt>
				<dd><PropertyDisplayValue propName="description" propValue={pub.description} typeName="Publisher" /></dd>
			</dl>
		</Card.Body>
		<Card.Footer>
			{pub.url && (
				<a className="corporate-website" href={pub.url} target="_blank">
					{pub.url} <FontAwesomeIcon icon={faExternalLinkAlt} />
				</a>
			)}
		</Card.Footer>
	</>);
}

export function ChangePublisherModal({ publication, show, onClose }: ChangePublisherModalProps) {
	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState('');
	const [selectedPublisher, setSelectedPublisher] = useState<Publisher | null>(null);
	const [currentPublisher, setCurrentPublisher] = useState<Publisher | null>(null);
	const [comment, setComment] = useState(`Moved from ${currentPublisher?.name} to ${selectedPublisher?.name}`);
	const api = useAPI();
	const analytics = useAnalytics();

	const loadPublisher = async (id: string) => {
		const resp = await api.fetchWithAuth<Results<Publisher>>(`resources/Publisher/${id}`);
		setCurrentPublisher(resp?.items?.[0] || null);
	};

	const getCommentForChange = (current: Publisher, selected: Publisher) => {
		return `Changed Publisher from "${current.name} [${current.newstex_id}]" to "${selected.name} [${selected.newstex_id}]"`;
	};

	// Reset error and selection when modal is opened
	useEffect(() => {
		if (show) {
			setError('');
			setSelectedPublisher(null);
		}
	}, [show]);

	useEffect(() => {
		if (currentPublisher && selectedPublisher) {
			setComment(getCommentForChange(currentPublisher, selectedPublisher));
		}
	}, [currentPublisher, selectedPublisher]);

	useEffect(() => {
		if (publication.parent) {
			loadPublisher(convertReferenceToString(publication.parent));
		}
	}, [publication.parent]);

	const handleSave = async () => {
		if (!selectedPublisher) {
			setError('Please select a new Publisher');
			return;
		}

		if (selectedPublisher.$id === publication.parent) {
			setError('Please select a different Publisher');
			return;
		}

		setIsSaving(true);
		const changeComment = comment || getCommentForChange(currentPublisher, selectedPublisher);
		try {
			analytics.trackEvent('Move Publication', {
				id: publication.$id,
				from: currentPublisher.newstex_id || currentPublisher.$id || currentPublisher.name,
				to: selectedPublisher.newstex_id || selectedPublisher.$id || selectedPublisher.name,
				comment: changeComment,
			});
		} catch (e) {
			console.error('ERROR TRACKING EVENT', e);
		}

		try {
			await api.fetchWithAuth(`resources/Publication/${publication.$id}`, {
				method: 'PATCH',
				body: JSON.stringify({
					$comment: changeComment,
					parent: selectedPublisher.$id,
				}),
			});
			toast.success('Publication moved successfully');
			onClose(true);
		} catch (e) {
			console.error(e);
			toast.error(`Failed to move publication: ${String(e)}`);
			setError(String(e));
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Modal
			show={show}
			onHide={() => onClose(false)}
			size="xl"
		>
			<Modal.Header closeButton>
				<Modal.Title>Change Publisher for {publication.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="warning">
					<strong>Warning:</strong> Moving a Publication to a different Publisher is a major operation that affects:
					<ul>
						<li>Story delivery and distribution (Story IDs will change)</li>
						<li>Royalty reporting and payments</li>
						<li>HubSpot relationships</li>
					</ul>
					This operation should only be performed with explicit approval from the Editorial and Finance teams.
				</Alert>
				{error && <Alert variant="danger">{error}</Alert>}

				<Form.Group className="mb-3">
					<Form.Label>Select New Publisher</Form.Label>
					<PublisherSelect
						value={selectedPublisher}
						onChange={setSelectedPublisher}
					/>
					<Form.Text className="text-muted">
						Search for and select the new Publisher for this Publication
					</Form.Text>
				</Form.Group>

				{selectedPublisher && (
					<>
						<Alert variant="info">
							<Container fluid>
								<Row>
									<Col className="text-center">
										Moving <strong>{publication.name}</strong>
									</Col>
								</Row>

								<Row>
									{currentPublisher && (<Col>
										<Card>
											<Card.Header>Current Publisher
												<div className="float-end">
													<StatusBadge status={currentPublisher.status} />
													<HubSpotLink item={currentPublisher} size={28} />
												</div>
											</Card.Header>
											<PubCardDetails pub={currentPublisher} />
										</Card>
									</Col>
									)}
									<Col>
										<Card>
											<Card.Header>New Publisher
												<div className="float-end">
													<StatusBadge status={selectedPublisher.status} />
													<HubSpotLink item={selectedPublisher} size={28} />
												</div>
											</Card.Header>
											<PubCardDetails pub={selectedPublisher} />
										</Card>
									</Col>
								</Row>
							</Container>
						</Alert>
						<Form.Group className="mb-3">
							<Form.Label>Comment</Form.Label>
							<Form.Control
								as="input"
								value={comment}
								onChange={(e) => setComment(e.target.value)}
								placeholder="Add a comment about this change (optional)"
							/>
							<Form.Text className="text-muted">
								This comment will be saved with the change history
							</Form.Text>
						</Form.Group>
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				{isSaving && <Spinner animation="border" size="sm" />}
				<Button variant="secondary" onClick={() => onClose(false)}>Cancel</Button>
				<Button
					variant="warning"
					onClick={handleSave}
					disabled={isSaving || !selectedPublisher}
				>
					Move Publication
				</Button>
			</Modal.Footer >
		</Modal >
	);
}
