import { DatePeriod } from '@newstex/types/dates';
import {
	BarController,
	BarElement,
	CategoryScale,
	Chart,
	ChartConfiguration,
	Filler,
	Legend,
	LineController,
	LineElement,
	LinearScale,
	PointElement,
	Tooltip,
} from 'chart.js';
import Annotation, { AnnotationPluginOptions } from 'chartjs-plugin-annotation';
import { useEffect, useRef, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';

import { colors, gradients } from './defaults';

const colorKeys = Object.keys(colors) as (keyof typeof colors)[];
// Register everything with Chart.js outside of the component
Chart.register(
	Annotation,
	LineController,
	CategoryScale,
	BarController,
	BarElement,
	PointElement,
	LinearScale,
	LineElement,
	Tooltip,
	Legend,
	Filler,
);

/**
 * Calculate the maximum value for a bar chart, excluding any outliers, and rounding up to the
 * nearest 10, 100, 1000, or 5000 depending on the maximum value.
 *
 * @param data A list of numbers to calculate the max value for.
 * @returns The maximum value for the data, excluding any outliers, rounded
 */
function calculateStandardMax(data: number[]): number {
	const sortedData = [...data].sort((a, b) => a - b);
	// Remove any outliers
	const avg = sortedData.reduce((acc, value) => acc + value, 0) / sortedData.length;
	const stdDev = Math.sqrt(sortedData.reduce((acc, value) => acc + (value - avg) ** 2, 0) / sortedData.length);
	const filteredData = sortedData.filter((value) => Math.abs(value - avg) <= 2 * stdDev);
	const maxVal = Math.max(...filteredData);
	let roundingFactor = 5000; // Default rounding to nearest 5k
	if (maxVal < 100) {
		roundingFactor = 10; // Round to nearest 10s if under 100
	} else if (maxVal < 1000) {
		roundingFactor = 100; // Round to nearest 100s if under 1k
	} else if (maxVal < 10000) {
		roundingFactor = 1000; // Round to nearest 1k if under 10k
	}
	const roundedMax = Math.ceil((maxVal + (maxVal * 0.10)) / roundingFactor) * roundingFactor;
	return roundedMax;
}

const PERIOD_LABEL_MAP = {
	day: 'Daily',
	week: 'Weekly',
	month: 'Monthly',
	year: 'Yearly',
};

export interface BarChartLabel {
	name: string;
	backgroundColor?: string;
}

export interface BarChartProps {
	loading?: boolean;
	hideLegend?: boolean;
	className?: string;
	id?: string;
	datasets: {
		type?: 'bar' | 'line';
		label: string;
		data: number[];
		xAxisID?: string;
		yAxisID?: string;
		borderWidth?: number;
		color?: keyof typeof colors;
		gradient?: keyof ReturnType<typeof gradients>;
		fill?: boolean | string;
		backgroundColor?: string;
		borderColor?: string;
		borderDash?: [number, number];
		borderJoinStyle?: 'miter' | 'round' | 'bevel';
		order?: number;
		pointRadius?: number;
		spanGaps?: boolean;
		stack?: string;
	}[];
	labels: BarChartLabel[];
	hideX?: boolean;
	grid?: boolean;
	min?: number;
	max?: number;
	height?: string;
	setPeriod?: (period: DatePeriod) => void;
	period?: DatePeriod;
	stacked?: boolean;
	formatValue?: (value: number, isAxis?: boolean) => string;
	legendPosition?: 'top' | 'bottom' | 'left' | 'right';
	title?: string;
}

export default function BarChart({
	id,
	loading,
	className,
	datasets,
	labels,
	hideX,
	height,
	grid,
	min,
	max,
	hideLegend,
	setPeriod,
	period,
	stacked,
	formatValue,
	legendPosition = 'top',
	title,
}: BarChartProps) {
	const chartRef = useRef<HTMLCanvasElement>(null);
	const [selectedDataset, setSelectedDataset] = useState<string | null>(null);

	const annotations = labels.map((label, index) => (label.backgroundColor ? {
		type: 'box',
		xMin: index - 0.5,
		xMax: index + 0.5,
		borderWidth: 0,
		borderColor: 'transparent',
		z: 0,
		backgroundColor: label.backgroundColor,
	} : null)).filter((v) => v != null) as AnnotationPluginOptions['annotations'];
	const options: ChartConfiguration<'bar'>['options'] = {
		plugins: {
			legend: {
				display: !hideLegend,
				position: legendPosition,
				onClick: (e, legendItem, legend) => {
					const index = legendItem.datasetIndex;
					if (index === undefined) return;

					const chart = legend.chart;
					const clickedDatasetLabel = chart.data.datasets[index].label || '';

					setSelectedDataset((prev) => (prev === clickedDatasetLabel ? null : clickedDatasetLabel));
				},
				labels: {
					usePointStyle: true,
					pointStyle: 'circle',
					pointStyleWidth: 18,
					padding: 20,
					generateLabels: (chart) => {
						const { labels: { generateLabels }} = Chart.defaults.plugins.legend;

						const defaultLabels = generateLabels ? generateLabels(chart) : [];
						return defaultLabels.map((label) => {
							const text = label.text;
							const isSelected = !selectedDataset || selectedDataset === text;

							// If we have a selected dataset and this isn't it, reduce the opacity
							const opacity = selectedDataset && !isSelected ? 0.5 : 1;
							const fillStyle = label.fillStyle as string;

							return {
								...label,
								text,
								// Add a subtle border for the selected item
								lineWidth: selectedDataset && isSelected ? 2 : 0,
								hidden: false,
								// Apply opacity to the fill color
								fillStyle: fillStyle.startsWith('rgb')
									? fillStyle.replace('rgb', 'rgba').replace(')', `, ${opacity})`)
									: fillStyle,
								strokeStyle: selectedDataset && isSelected ? '#000' : label.strokeStyle,
								// Only mute the text if there's a selection and this isn't the selected item
								fontColor: '#6c757d',
							};
						});
					},
				},
			},
			tooltip: {
				// Show all values for the same date
				mode: 'index',
				intersect: false,
				usePointStyle: true,
				callbacks: {
					label(context) {
						// Hide zero values
						if (context.parsed.y === 0) return '';
						let label = context.dataset.label || '';
						if (label) {
							label += ': ';
						}

						if (context.parsed.y !== null) {
							label += formatValue
								? formatValue(context.parsed.y)
								: context.parsed.y.toLocaleString();
						}
						return label;
					},
				},
			},
			annotation: {
				annotations,
			},
		},
		scales: {
			yAxes: {
				display: true,
				min: min || 0,
				grid: {
					display: grid,
					color: '#eee',
				},
				position: 'left',
				stacked,
				ticks: {
					callback(tickValue: number | string) {
						const value = Number(tickValue);
						return formatValue ? formatValue(value, true) : value.toLocaleString();
					},
				},
			},
			x: {
				stacked,
			},
		},
		maintainAspectRatio: false,
		responsive: true,
	};

	if (!stacked && datasets.some((dataset) => dataset.type === 'line' && !dataset.yAxisID)) {
		if (!options.scales) options.scales = {};
		options.scales.yAxesRight = {
			min: 0,
			display: true,
			position: 'right',
			grid: {
				display: false,
			},
			ticks: {
				callback(tickValue: number | string) {
					const value = Number(tickValue);
					return formatValue ? formatValue(value, true) : value.toLocaleString();
				},
			},
		};
	}
	useEffect(() => {
		// NOTE: We need this "any" override because the typescript definitions for Chart.js do not
		// allow mixing bar and line charts together
		const chartData: any = {
			labels: labels.map((l) => l.name),
			datasets: datasets.map((dataset, index) => {
				const datasetColor = dataset.color || colorKeys[index % colorKeys.length];
				const backgroundColor = dataset.backgroundColor || (
					dataset.gradient
						? gradients(chartRef)[dataset.gradient]
						: colors[datasetColor]
				);

				const isVisible = !selectedDataset || dataset.label === selectedDataset;

				return {
					type: dataset.type || 'bar',
					label: dataset.label,
					backgroundColor,
					hoverBackgroundColor: backgroundColor,
					borderColor: dataset.type === 'line' ? backgroundColor : dataset.borderColor,
					borderWidth: dataset.borderWidth,
					borderDash: dataset.borderDash,
					borderJoinStyle: dataset.borderJoinStyle || 'miter',
					pointRadius: dataset.pointRadius,
					fill: dataset.fill,
					xAxisID: dataset.xAxisID,
					yAxisID: (!stacked && dataset.type === 'line') ? 'yAxesRight' : 'yAxes',
					data: isVisible ? dataset.data : dataset.data.map(() => null),
					order: dataset.order || (dataset.type === 'line' ? 1 : 2),
					spanGaps: dataset.spanGaps,
					stack: dataset.stack,
					borderRadius: 4,
				};
			}),
		};
		Chart.defaults.font.family = 'Poppins, system-ui, -apple-system, Roboto, Arial, system-ui, -apple-system, sans-serif';
		Chart.defaults.plugins.tooltip.padding = 10;
		Chart.defaults.plugins.tooltip.backgroundColor = 'rgba(0, 0, 0, 0.7)';
		Chart.defaults.scale.ticks.color = 'rgba(0, 0, 0, 0.4)';
		if (chartRef.current && chartData.datasets.length > 0) {
			const chart = new Chart(chartRef.current!, {
				type: 'bar',
				options,
				data: chartData,
			});
			return () => {
				chart.destroy();
			};
		}
	}, [datasets, labels, options, selectedDataset]);
	return (
		<div className="chart-container" id={id}>
			<div className="chart-header">
				{title && <div className="chart-title">{title} <i className="fas fa-info-circle text-muted ms-1" style={{ fontSize: '0.8rem' }}></i></div>}
				{setPeriod && (
					<div className="period-selector">
						<Dropdown>
							<Dropdown.Toggle variant="light" size="sm">
								{PERIOD_LABEL_MAP[period || 'day']}
							</Dropdown.Toggle>
							<Dropdown.Menu align="end">
								<Dropdown.Item
									active={period === 'day'}
									onClick={() => setPeriod('day')}
								>
									{PERIOD_LABEL_MAP.day}
								</Dropdown.Item>
								<Dropdown.Item
									active={period === 'week'}
									onClick={() => setPeriod('week')}
								>
									{PERIOD_LABEL_MAP.week}
								</Dropdown.Item>
								<Dropdown.Item
									active={period === 'month'}
									onClick={() => setPeriod('month')}
								>
									{PERIOD_LABEL_MAP.month}
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				)}
			</div>
			<div style={{ position: 'relative', height: height || '400px' }}>
				{loading && (
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							zIndex: 2,
							backgroundColor: 'rgba(255,255,255,0.7)',
						}}
					>
						<Spinner animation="border" role="status" />
					</div>
				)}
				<canvas
					ref={chartRef}
					className={className}
				/>
			</div>
		</div>
	);
}
