import { DistributorReportSettings } from '@newstex/types/distributor';
import { useEffect, useRef, useState } from 'react';
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';

export interface EditDistributorReportModalProps {
	show: boolean;
	onClose: (saved: boolean) => void;
	title: string;
	settings?: DistributorReportSettings;
	onSave: (settings: DistributorReportSettings) => Promise<void>;
}

export function EditDistributorReportModal({
	show,
	onClose,
	title,
	settings,
	onSave,
}: EditDistributorReportModalProps) {
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [updates, setUpdates] = useState<Partial<DistributorReportSettings>>({});
	const commentRef = useRef<HTMLInputElement>(null);

	// Reset updates when modal is opened
	useEffect(() => {
		if (show) {
			setUpdates({});
			setError(null);
		}
	}, [show]);

	const handleSave = async () => {
		if (!settings) return;
		if (!commentRef.current?.value) {
			commentRef.current?.focus();
			commentRef.current?.setCustomValidity('Please enter a comment');
			return;
		}

		setSaving(true);
		setError(null);

		try {
			await onSave({
				...settings,
				...updates,
			});
			onClose(true);
		} catch (err) {
			console.error('Failed to save report settings:', err);
			setError(String(err));
		} finally {
			setSaving(false);
		}
	};

	if (!settings) return null;

	return (
		<Modal show={show} onHide={() => onClose(false)} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>Edit {title}</Modal.Title>
			</Modal.Header>
			<Form onSubmit={(e) => {
				e.preventDefault();
				handleSave();
			}}>
				<Modal.Body>
					{error && <Alert variant="danger">{error}</Alert>}
					<Container fluid className={saving ? 'opacity-50' : ''}>
						<Row className="mb-3">
							<Col md={3} className="text-end fw-bold">
								<Form.Label>Enabled</Form.Label>
							</Col>
							<Col md={9}>
								<Form.Check
									type="switch"
									checked={updates.enabled ?? settings.enabled}
									onChange={(e) => setUpdates({
										...updates,
										enabled: e.target.checked,
									})}
								/>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col md={3} className="text-end fw-bold">
								<Form.Label>Frequency</Form.Label>
							</Col>
							<Col md={9}>
								<Form.Select
									value={updates.frequency || settings.frequency || ''}
									onChange={(e) => setUpdates({
										...updates,
										frequency: e.target.value as 'Weekly' | 'Monthly',
									})}
								>
									<option value="">Select Frequency</option>
									<option value="Weekly">Weekly</option>
									<option value="Monthly">Monthly</option>
								</Form.Select>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col md={3} className="text-end fw-bold">
								<Form.Label>Recipients</Form.Label>
							</Col>
							<Col md={9}>
								<Form.Control
									as="textarea"
									rows={4}
									value={updates.emails?.join('\n') || settings.emails?.join('\n') || ''}
									onChange={(e) => setUpdates({
										...updates,
										emails: e.target.value.split('\n').filter(Boolean),
									})}
									placeholder="Enter email addresses (one per line)"
								/>
							</Col>
						</Row>

						<Row className="mt-3 pt-3" style={{ borderTop: 'dashed 1px #ccc' }}>
							<Col md={3} className="text-end fw-bold">
								<Form.Label>Comment</Form.Label>
							</Col>
							<Col md={9}>
								<Form.Control
									type="text"
									name="$comment"
									ref={commentRef}
									required
									placeholder="Required: Enter a comment"
								/>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => onClose(false)} disabled={saving}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={handleSave}
						disabled={saving || !updates || Object.keys(updates).length === 0}
					>
						{saving ? 'Saving...' : 'Save'}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}
