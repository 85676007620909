// External dependencies
import { faDownload, faMessage, faSave } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HotTable, { HotTableClass } from '@handsontable/react';
import { convertToDate } from '@newstex/core/date';
import { getAccessInfo } from '@newstex/types/access';
import { KnowledgeBase } from '@newstex/types/rag';
import { kebab } from 'case';
import Handsontable from 'handsontable';
import HyperFormula from 'hyperformula';
import { useEffect, useRef, useState } from 'react';
import {
	Badge,
	Button,
	Card,
	Col,
	Container,
	OverlayTrigger,
	Row,
	Table,
	Tooltip,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import 'react-json-view-lite/dist/index.css';
import EditButton from '~/components/edit-button';
import LoadingSpinner from '~/components/LoadingSpinner';
import { MarkdownTextBlock } from '~/components/markdown-text-block';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { useAIChat } from '~/providers/ai-chat-provider';
import { useAPI } from '~/providers/api-provider';

function convertToTableData(data: React.ReactNode | React.ReactNode[]): any {
	// Handle single node case
	if (!Array.isArray(data)) {
		return [];
	}

	const tableData: string[][] = [];

	// Process each table section (thead/tbody)
	for (const section of data as any[]) {
		if (!section?.props?.children) {
			continue;
		}

		// Handle rows
		const rows = Array.isArray(section.props.children)
			? section.props.children
			: [section.props.children];

		for (const row of rows) {
			if (!row?.props?.children) {
				continue;
			}

			// Handle cells
			const cells = Array.isArray(row.props.children)
				? row.props.children
				: [row.props.children];

			const rowData: string[] = [];

			for (const cell of cells) {
				// Extract text content from th/td cells
				if (cell?.props?.children) {
					rowData.push(String(cell.props.children));
				}
			}

			if (rowData.length > 0) {
				tableData.push(rowData);
			}
		}
	}

	return tableData;
}

/**
 * Handson Table Block, allows tables to be exported, as well as modified
 */
export function CustomTableBlock({ children, saveChanges }: React.PropsWithChildren<{
	saveChanges: (data: any) => void
}>) {
	if (!children) {
		return null;
	}

	if (!Array.isArray(children)) {
		return <Table>{children}</Table>;
	}
	const hotRef = useRef<HotTableClass | null>(null);

	const tableData = convertToTableData(children) || [];
	const data = tableData.slice(1);
	const headers = tableData?.[0]?.map((title: string) => ({
		title,
	}));

	const exportToCSV = () => {
		const hot = hotRef.current?.hotInstance;
		if (hot) {
			const exportPlugin = hot.getPlugin('exportFile');
			exportPlugin.downloadFile('csv', {
				bom: false,
				columnDelimiter: ',',
				columnHeaders: true,
				fileExtension: 'csv',
				filename: `KnowledgeBase-Table-${new Date().toISOString().split('T')[0].split('-').join('')}`,
				mimeType: 'text/csv',
				rowDelimiter: '\r\n',
			});
		}
	};

	return (
		<div className="kb-table">
			<HotTable
				id="kb-hot-table"
				undo
				filters
				dropdownMenu
				formulas={{
					engine: HyperFormula,
				}}
				data={data}
				ref={hotRef}
				colHeaders
				rowHeaders
				columns={headers}
				height="auto"
				licenseKey="non-commercial-and-evaluation"
				stretchH="all"
				autoWrapRow
				autoWrapCol
				persistentState
				minSpareRows={1}
				minSpareCols={0}
				columnSorting
				manualColumnMove
				contextMenu={{
					items: {
						row_above: {
							name: 'Insert row above',
						},
						row_below: {
							name: 'Insert row below',
						},
						separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
						col_left: {
							name: 'Insert column left',
						},
						col_right: {
							name: 'Insert column right',
						},
						remove_col: {
							name: 'Remove column',
						},
						separator2: Handsontable.plugins.ContextMenu.SEPARATOR,
						undo: {
							name: 'Undo',
						},
						redo: {
							name: 'Redo',
						},
					},
				}}
				afterCreateCol={(index, amount) => {
					console.log('afterCreateCol', index, amount);
				}}
			/>
			<div className="mt-2 table-tools float-end">
				<OverlayTrigger
					trigger={['hover', 'focus']}
					placement="top"
					overlay={<Tooltip>Save Table Changes</Tooltip>}
				>
					<Button
						onClick={() => {
							saveChanges(
								hotRef
									.current?.hotInstance
									?.getData()
									.filter((row: any[]) => row.some((cell: any) => !!cell)),
							);
						}}
						variant="outline-success"
						className="me-2"
					>
						<FontAwesomeIcon icon={faSave} />
					</Button>
				</OverlayTrigger>

				<OverlayTrigger
					trigger={['hover', 'focus']}
					placement="top"
					overlay={<Tooltip>Export Table as CSV</Tooltip>}
				>
					<Button onClick={exportToCSV} variant="outline-secondary" className="me-2">
						<FontAwesomeIcon icon={faDownload} />
					</Button>
				</OverlayTrigger>
			</div>
			<div className="clearfix" />
		</div>);
}

export function KnowledgeBasePage() {
	const params = useParams();
	const api = useAPI();
	const [kb, setKB] = useState<KnowledgeBase>();
	const [loading, setLoading] = useState(true);
	const aiChat = useAIChat();

	const fetchData = async (refresh = false) => {
		setLoading(true);
		const resp = await api.fetchWithAuth(
			`resources/RAG/${params.id}`,
			refresh ? { cache: 'reload' } : undefined,
		);
		setKB(resp.items?.[0]);
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, [params]);

	const updateKBContent = async (newContent: string) => {
		if (!kb) return;

		await api.fetchWithAuth(`resources/RAG/${params.id}`, {
			method: 'PATCH',
			body: JSON.stringify({
				answer: newContent,
			}),
		});

		await fetchData(true);
	};

	return (
		<Container fluid>
			<Row>
				<Col xs={12}>
					<LoadingSpinner loading={loading} />
					<Card className="kb-entry" hidden={loading}>
						<Card.Header>
							<Container fluid>
								<Row>
									<Col>
										<h1 className="text-center mb-0">
											{kb?.title || ''}
											<span className="ms-2 float-end">
												{kb?.locked
													? <FontAwesomeIcon
														icon={faLock}
														title="This entry is locked and cannot be edited" size="sm" />
													: <EditButton
														item={kb} refreshHandler={() => fetchData(true)} />
												}
											</span>
										</h1>
										{kb?.title && kb?.questions && <div className="text-center">
											{kb.questions.map((q) => (
												<small key={kebab(q)} className="text-muted mb-0 me-2">{q}</small>
											))}
										</div>}
										<hr />
									</Col>
								</Row>
								<Row>
									<Col xs={12} md={6}>
										<dl className="properties">
											<dt>Category</dt>
											<dd><PropertyDisplayValue propName="category" propValue={kb?.category} /></dd>

											<dt>Access Level</dt>
											<dd>
												{kb && (() => {
													const accessInfo = getAccessInfo(kb);
													return (
														<Badge bg={accessInfo.variant}>
															{accessInfo.label}
														</Badge>
													);
												})()}
											</dd>

											<dt>Created At</dt>
											<dd>{kb?.created_at ? convertToDate(kb.created_at).format('MMM Do YYYY, h:mma') : ''}</dd>

											<dt>Modified At</dt>
											<dd>{kb?.modified_at ? convertToDate(kb.modified_at).format('MMM Do YYYY, h:mma') : ''}</dd>
										</dl>
									</Col>
									<Col xs={12} md={6}>
										<dl className="properties">
											<dt>Created By</dt>
											<dd><PropertyDisplayValue propName="created_by" propValue={kb?.created_by} /></dd>

											<dt>Modified By</dt>
											<dd><PropertyDisplayValue propName="modified_by" propValue={kb?.modified_by} /></dd>
										</dl>
										{kb?.title && <span className="ms-2 float-end">
											<Button variant="success" size="sm" onClick={() => {
												aiChat.createWithPrompt(kb!);
											}}>
												<FontAwesomeIcon icon={faMessage} /> Start New Chat
											</Button>
										</span>}
									</Col>
								</Row>

							</Container>
						</Card.Header>
						<Card.Body>
							<MarkdownTextBlock
								locked={kb?.locked}
								onUpdateContent={updateKBContent}
							>
								{kb?.answer || ''}
							</MarkdownTextBlock>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
