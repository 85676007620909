import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type Distributor } from '@newstex/types/distributor';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

import { CreateDeliveryModal } from '../create-delivery-modal';

interface CreateDistributorDeliveryButtonProps {
	distributor: Distributor | null;
	onDeliveryCreated?: () => void;
	size?: 'sm' | 'lg';
	variant?: string;
	className?: string;
}

export function CreateDistributorDeliveryButton({
	distributor,
	onDeliveryCreated,
	size = 'sm',
	variant = 'outline-primary',
	className,
}: CreateDistributorDeliveryButtonProps) {
	const [showCreateModal, setShowCreateModal] = useState(false);

	const handleCreateDelivery = () => {
		setShowCreateModal(true);
	};

	const handleCloseModal = (saved: boolean) => {
		setShowCreateModal(false);
		if (saved && onDeliveryCreated) {
			onDeliveryCreated();
		}
	};

	return (
		<>
			<Button
				size={size}
				variant={variant}
				className={className}
				onClick={handleCreateDelivery}
				disabled={!distributor}
			>
				<FontAwesomeIcon icon={faPlus} className="me-1" />
				Add Delivery
			</Button>

			<CreateDeliveryModal
				show={showCreateModal}
				onClose={handleCloseModal}
				initialDistributorId={distributor?.$id}
			/>
		</>
	);
}
