import { AssistantRuntimeProvider } from '@assistant-ui/react';
import { DEFAULT_MODEL } from '@newstex/ai/bedrock';
import { ChatMessage } from '@newstex/ai/chat';
import { KnowledgeBase } from '@newstex/types/rag';
import React, {
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useAIRuntime } from '~/hooks/use-ai-runtime';

import { useAI } from './ai-provider';

export interface AIChatContextType {
	visible: boolean;
	setVisible: (visible: boolean) => void;
	createWithPrompt: (kb: KnowledgeBase) => Promise<void>;
	show: () => void;
	hide: () => void;
	sendMessage: (message: string) => Promise<void>;
	messages: ChatMessage[];
	exportToCSV: () => void;
	selectedModel: string;
	setSelectedModel: (model: string) => void;
}

const AIChatProviderContext = createContext<AIChatContextType | null>(null);

export function AIChatProvider({ children }: React.PropsWithChildren<{}>) {
	const [visible, setVisible] = useState(false);
	const [selectedModel, setSelectedModel] = useState<string | 'default'>('default');
	const runtime = useAIRuntime();
	const { setModel } = useAI();

	useEffect(() => {
		if (selectedModel === 'default') {
			setModel(DEFAULT_MODEL);
		} else {
			setModel(selectedModel);
		}
	}, [selectedModel]);

	// Update show/hide methods to save user preferences
	const showChat = () => {
		setVisible(true);
	};

	const hideChat = () => {
		setVisible(false);
	};

	const handleKeyDown = (event: globalThis.KeyboardEvent) => {
		if (event.key === 'Escape') {
			hideChat();
		}
	};

	useEffect(() => {
		if (visible) {
			document.addEventListener('keydown', handleKeyDown);
			return () => {
				document.removeEventListener('keydown', handleKeyDown);
			};
		}
	}, [visible]);

	const value: AIChatContextType = {
		visible,
		setVisible,
		show: showChat,
		hide: hideChat,
		sendMessage: () => Promise.resolve(),
		messages: [],
		exportToCSV: () => {},
		createWithPrompt: async () => {
			runtime.threads.switchToNewThread();
		},
		selectedModel: selectedModel === 'default' ? DEFAULT_MODEL : selectedModel,
		setSelectedModel,
	};

	return (
		<AIChatProviderContext.Provider value={value}>
			<AssistantRuntimeProvider runtime={runtime}>
				{children}
			</AssistantRuntimeProvider>
		</AIChatProviderContext.Provider>
	);
}

export function useAIChat() {
	const context = useContext(AIChatProviderContext);
	if (!context) {
		throw new Error('useAIChat must be used within an AIChatProvider');
	}
	return context;
}
