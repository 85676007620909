import {
	faDownload,
	faExclamationTriangle,
	faFlaskVial,
	faGroupArrowsRotate,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Delivery } from '@newstex/types/delivery';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DataTable, { DataTableMethods } from '../data-table';
import { LoadMoreButton } from '../load-more-button';
import { PropertyDisplayValue } from '../property-display-value';

export function DeliveriesTable(
	{
		items,
		searchText,
		hasMore,
		onLoadMore,
	}: {
		items: Delivery[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void,
	},
) {
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<Delivery>();
	const columns = useMemo(() => [
		columnHelper.accessor('name', {
			header: 'Name',
			enableSorting: !hasMore,
			cell: ({ getValue, row: { original }}) => {
				return <>
					<Link to={`/delivery/${original.$id}`}>
						<PropertyDisplayValue propName="name" propValue={getValue()} />
					</Link>
				</>;
			},
		}),
		columnHelper.accessor('type', {
			header: 'Type / Format',
			enableSorting: !hasMore,
			cell: ({ getValue, row: { original }}) => {
				return <>
					<div>{getValue()}</div>
					<div className="text-muted">{original.story_template}</div>
				</>;
			},
		}),
		columnHelper.accessor('host', {
			header: 'Host',
			enableSorting: !hasMore,
			cell: ({ getValue, row: { original }}) => {
				if (original.type === 'S3' && original.bucket_name) {
					return <PropertyDisplayValue propName="host" propValue={`s3://${original.bucket_name}`} />;
				}
				const value = getValue();
				return <PropertyDisplayValue propName="host" propValue={value} />;
			},
		}),
		columnHelper.accessor('username', {
			header: 'Username',
			enableSorting: !hasMore,
			cell: ({ getValue, row: { original }}) => {
				if (original.type === 'S3' && (original.user_email || original.user_id)) {
					return <PropertyDisplayValue propName="username" propValue={original.user_email || original.user_id} />;
				}
				const value = getValue();
				return <PropertyDisplayValue propName="username" propValue={value} />;
			},
		}),
		columnHelper.accessor('queue_name', {
			header: 'Queue',
			enableSorting: !hasMore,
			cell: ({ getValue }) => {
				const value = getValue();
				if (!value) {
					return <span className="text-danger">
						<FontAwesomeIcon icon={faExclamationTriangle} />
						&nbsp;NOT SET&nbsp;
						<FontAwesomeIcon icon={faExclamationTriangle} />
					</span>;
				}

				if (value?.startsWith('dev-')) {
					return <span>
						<FontAwesomeIcon
							icon={faFlaskVial}
							title='Dev NC4 Delivery'
						/>&nbsp;
						{value}
					</span>;
				}

				if (!value?.startsWith('prod-') && !value?.startsWith('dev-')) {
					return <span>
						<FontAwesomeIcon
							icon={faGroupArrowsRotate}
							title='Legacy NC3 Delivery'
						/>&nbsp;
						{value}
					</span>;
				}

				return <PropertyDisplayValue propName="queue_name" propValue={value} />;
			},
		}),
		columnHelper.accessor('products', {
			header: 'Products',
			enableSorting: !hasMore,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="products" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('status', {
			header: 'Status',
			enableSorting: !hasMore,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="status" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('last_delivery', {
			header: 'Last Delivery',
			enableSorting: !hasMore,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="last_delivery" propValue={getValue()} />;
			},
		}),
	], [hasMore]);

	return (<>
		<DataTable
			columns={columns}
			items={items || []}
			ref={dataTableRef}
			searchText={searchText}
			tableRowClassNameHelper={({ original }: { original: Delivery }) => {
				if (original.status === 'Active' && original.products?.length) {
					return 'table-success';
				}

				if (original.status === 'Inactive') {
					return 'table-danger';
				}

				return '';
			}}
			nameAsc
			striped
			sortable
		/>
		<LoadMoreButton show={hasMore} onClick={onLoadMore} />
		<div className="float-end pb-2">
			<Button variant="outline-secondary" size="sm" onClick={() => {
				dataTableRef.current?.exportToCSV('Deliveries');
			}}>
				<FontAwesomeIcon icon={faDownload} /> CSV
			</Button>
		</div>
	</>);
}
