/**
 * Date Edit Field
 */
import { TypeNameToTypeMap } from '@newstex/types/infer-types';
import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';

import PropertyDisplayName from '../property-display-name';

import { EditTextFieldProps } from './text';

export interface EditDateFieldProps<T extends keyof TypeNameToTypeMap> extends EditTextFieldProps<T> {
}

export default function EditableDateField<T extends keyof TypeNameToTypeMap>({
	typeName,
	propKey,
	item,
	updates,
	setUpdates,
}: EditDateFieldProps<T>) {
	const [dateValue, setDateValue] = useState<string>('');

	useEffect(() => {
		const initialValue = updates?.[propKey] as string || item?.[propKey] as string || '';
		setDateValue(initialValue);
	}, [updates, item]);

	const handleChange = (value: string) => {
		const date = new Date(value);
		if (!Number.isNaN(date.getTime())) {
			const formattedDate = date.toISOString().split('T')[0];
			setDateValue(formattedDate);
			if (setUpdates) {
				setUpdates({
					...updates,
					[propKey]: formattedDate,
				});
			}
		} else {
			setDateValue(value);
		}
	};

	return (
		<>
			<Col md={3} className="text-end fw-bold">
				<Form.Label><PropertyDisplayName propKey={propKey} typeName={typeName} /></Form.Label>
			</Col>
			<Col md={9}>
				<Form.Control
					type="date"
					name={String(propKey)}
					value={dateValue}
					onChange={(e) => handleChange(e.target.value)}
				/>
			</Col>
		</>
	);
}
