/* eslint-disable max-len */
import { type ThreadMessage } from '@assistant-ui/react';
import { QualificationAgent, type QualificationResult } from '@newstex/ai/agents/qualification-agent';
import { DEFAULT_MODEL, createAmazonBedrock } from '@newstex/ai/bedrock';
import { AIChatContext, ChatMessage, ChatParams } from '@newstex/ai/chat';
import {
	QualificationScores,
	QualifyPublicationParams,
	QualifyStoryParams,
	qualifyPublication,
	qualifySite,
	qualifyStory,
} from '@newstex/ai/qualify';
import { rerank } from '@newstex/ai/rerank';
import { DetectFeedResponse } from '@newstex/types/detect-feed';
import { KnowledgeBase, RAGMemory, isKnowledgeBase } from '@newstex/types/rag';
import { sleep } from '@newstex/utils/sleep';
import { type CoreMessage, ToolExecutionOptions, tool } from 'ai';
import { ChartConfiguration } from 'chart.js';
import omit from 'object.omit';
import {
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useCacheStore } from '~/stores/cache-store';

import { useAnalytics } from './analytics-provider';
import { useAPI } from './api-provider';
import { useSearch } from './search';
import { useUserInfo } from './user-info';

// 5 minutes
const AUTH_CACHE_TIME = 5 * 60 * 1000;

export interface AIContextType {
	ctx: AIChatContext;
	modelProvider: ReturnType<typeof createAmazonBedrock> | null;
	qualifyStory: (options: Omit<QualifyStoryParams, 'modelProvider'>) => Promise<QualificationScores>;
	qualifySite: (params: {
		detectResp: DetectFeedResponse;
		criteria: string;
	}) => Promise<QualificationScores>;
	qualifyPublication: (options: Omit<QualifyPublicationParams, 'modelProvider'>) => Promise<QualificationScores>;
	chat: (
		options: {
			messages: readonly ThreadMessage[],
			onToolCall?: (cmdName: string, cmdParams: Record<string, any>, id?: string) => void,
			onStepFinish?: (step: any) => void,
			prompt?: string | null,
			signal?: AbortSignal,
		}
	) => ReturnType<AIChatContext['chat']>;
	generateObject: <T = unknown>(options: {
		schema: {
			type: 'object';
			properties: Record<string, any>;
			required?: string[];
		};
		prompt: string;
	}) => Promise<T>;
	refreshCredentials: () => Promise<ReturnType<typeof createAmazonBedrock> | null>;
	qualifyPublicationWithAgent: (publicationId: string, criteria: string) => Promise<QualificationResult>;
	/**
	 * Set the model to use for the AI chat.
	 * @param model - ARN of the model to use for the AI chat.
	 */
	setModel: (model: string) => void;
	/**
	 * Active tool call (if any)
	 */
	toolCall: {
		status: 'running' | 'complete' | 'failed';
		cmdName: string;
		cmdParams: Record<string, any>;
		id?: string;
	} | null;

	/**
	 * Finish reason for the AI chat
	 */
	finishReason: string | null;

	/**
	 * Most recent usage data from the AI Chat
	 */
	usage: any | null;
}

const AIContext = createContext<AIContextType | undefined>(undefined);

export function AIProvider({ children }: React.PropsWithChildren<{}>) {
	const userInfo = useUserInfo();
	const [modelProvider, setModelProvider] = useState<ReturnType<typeof createAmazonBedrock> | null>(null);
	const [selectedModel, setSelectedModel] = useState<string | null>(DEFAULT_MODEL);
	const [aiChatCtx, setAIChatCtx] = useState<AIChatContext | null>(null);
	const [toolCall, setToolCall] = useState<AIContextType['toolCall'] | null>(null);
	const [finishReason, setFinishReason] = useState<string | null>(null);
	const [usage, setUsage] = useState<any>(null);
	const searchClient = useSearch();
	const api = useAPI();
	const navigate = useNavigate();
	const cacheStore = useCacheStore();
	const analytics = useAnalytics();
	const [refreshPromise, setRefreshPromise] = useState<{
		promise: Promise<ReturnType<typeof createAmazonBedrock>>;
		timestamp: number;
	} | null>(null);

	function initAIChatCtx(chatModel?: string) {
		if (userInfo?.aws && searchClient?.searchClient) {
			console.log('Setting model provider', {
				model: chatModel || selectedModel,
			});
			const newModelProvider = createAmazonBedrock({
				region: 'us-east-1',
				accessKeyId: userInfo.aws.AccessKeyId,
				secretAccessKey: userInfo.aws.SecretAccessKey,
				sessionToken: userInfo.aws.SessionToken,
			});
			setModelProvider(() => newModelProvider);
			const ctx = new AIChatContext({
				refreshCredentials: async () => {
					await userInfo.refresh();
				},
				searchClient: searchClient.searchClient!,
				saveResource: async (resource) => {
					if (!resource.$type) {
						throw new Error('Resource type is required');
					}

					if (isKnowledgeBase(resource)) {
						if (!resource.$id && (!resource.title || !resource.answer)) {
							throw new Error('Invalid resource, title and answer are required');
						}
					}

					if (resource.$id) {
						await api.updateItem({
							$type: resource.$type,
							$id: resource.$id,
						}, omit(resource, ['$id', '$type', 'created_at', 'created_by']) as Partial<KnowledgeBase | RAGMemory>);
					} else {
						await api.createItem({
							$type: resource.$type,
							...omit(resource, ['$id', '$type', 'created_at', 'created_by']),
						});
					}
				},
				modelProvider: newModelProvider,
				model: chatModel || selectedModel,
				logger: console,
				user: userInfo,
				extraTools: {
					navigateTo: tool({
						description: 'Navigate to a specific path in NewsCore.',
						parameters: z.object({
							path: z.string()
								.describe('the path to navigate to'),
						}),
						execute: async (args: { path?: string }) => {
							if (!args.path) throw new Error('Path is required');
							navigate(args.path);
							return 'OK';
						},
					}),
					/*
					useCanvas: tool({
						description: 'Use this tool to write content in the Canvas editor. This should be used for creating stories, articles, and other long-form content. You can edit specific sections by providing a sectionId.',
						parameters: z.object({
							content: z.string()
								.describe('The markdown content to write to the Canvas editor'),
							sectionId: z.string().optional()
								.describe('Optional ID of the section to update. If not provided, the **entire content will be replaced**. Always call getCanvasContent FIRST to get the current sections and section IDs.'),
							title: z.string().optional()
								.describe('Optional title for the section when creating a new section'),
						}),
						execute: async (args: { content?: string; sectionId?: string; title?: string }) => {
							if (!args.content) throw new Error('Content is required');

							// Generate a valid toolUseId that matches Bedrock's requirements
							const timestamp = Date.now();
							const randomPart = Math.random().toString(36).slice(2).replace(/[^a-zA-Z0-9]/g, '');
							const toolUseId = `canvas_${timestamp}_${randomPart}`;

							// Get the canvas store functions
							const canvasStore = useCanvasStore.getState();
							const setContent = canvasStore.setContent;
							const setIsEditing = canvasStore.setIsEditing;

							if (args.sectionId) {
								// Update specific section
								const sectionUpdateResp = canvasStore.updateSection(args.sectionId, args.content);
								console.debug('*** SECTION UPDATE RESPONSE ***', sectionUpdateResp);
								return sectionUpdateResp;
							}

							if (args.title) {
								// Create new section with title
								const sections = canvasStore.getSections();
								const newSectionId = `section_${timestamp}`;
								const newContent = canvasStore.rebuildContent([
									...sections,
									{
										id: newSectionId,
										content: args.content,
										title: args.title,
										level: 1,
									},
								]);
								setContent(newContent);
							} else {
								// Replace entire content
								setContent(args.content);
							}

							setIsEditing(false); // Switch to preview mode

							return {
								content: args.content,
								toolUseId,
								sectionId: args.sectionId,
								title: args.title,
							};
						},
					}),
					getCanvasContent: tool({
						description: 'Get the current content from the Canvas editor. Use this to read the current canvas content before performing operations like saving it as a story.',
						parameters: z.object({}),
						execute: async () => {
							const canvasStore = useCanvasStore.getState();
							return {
								content: canvasStore.content,
								sections: canvasStore.getSections().map((section) => ({
									id: section.id,
									title: section.title,
									content: section.content,
									level: section.level,
								})),
							};
						},
					}),
					*/
					getReportData: tool({
						description: 'Get the data for a report',
						parameters: z.object({
							reportId: z.string().describe('The ID of the report to get data for'),
						}),
						execute: async (args: { reportId?: string }, options?: ToolExecutionOptions) => {
							if (!args.reportId) throw new Error('Report ID is required');
							console.log('Fetching report data', args.reportId);
							const report = await api.fetchWithAuth(`reports/${args.reportId}/data`);
							return report;
						},
					}),
					generateChart: tool({
						description: 'Generate a chart using Chart.js configuration',
						parameters: z.object({
							type: z.enum(['line', 'bar', 'pie', 'doughnut', 'radar', 'polarArea', 'scatter', 'bubble'])
								.describe('The type of chart to generate'),
							data: z.object({
								labels: z.array(z.string()).describe('Labels for the data points'),
								datasets: z.array(z.object({
									label: z.string().describe('Label for the dataset'),
									data: z.array(z.number()).describe('Data points for the dataset'),
									backgroundColor: z.string().optional().describe('Background color for the dataset'),
									borderColor: z.string().optional().describe('Border color for the dataset'),
									fill: z.boolean().optional().describe('Whether to fill the area under the line'),
								})),
							}).describe('The data to display in the chart'),
							options: z.object({
								responsive: z.boolean().optional(),
								maintainAspectRatio: z.boolean().optional(),
								plugins: z.object({
									title: z.object({
										display: z.boolean().optional(),
										text: z.string().optional(),
									}).optional(),
									legend: z.object({
										display: z.boolean().optional(),
										position: z.enum(['top', 'bottom', 'left', 'right']).optional(),
									}).optional(),
								}).optional().describe('Chart.js options configuration'),
							}).optional().describe('Chart.js options configuration'),
						}),
						execute: async (args: { type?: string, data?: any, options?: any }) => {
							if (!args.type) throw new Error('Chart type is required');
							return args as ChartConfiguration;
						},
					}),
				},
			});
			setAIChatCtx(ctx);
			return ctx;
		}
	}

	useEffect(() => {
		initAIChatCtx();
	}, [userInfo?.aws, searchClient]);

	const getModelProvider = async () => {
		if (modelProvider) {
			return modelProvider;
		}

		if (userInfo?.aws) {
			const newModelProvider = createAmazonBedrock({
				region: 'us-east-1',
				accessKeyId: userInfo.aws.AccessKeyId,
				secretAccessKey: userInfo.aws.SecretAccessKey,
				sessionToken: userInfo.aws.SessionToken,
			});
			setModelProvider(() => newModelProvider);
			return newModelProvider;
		}

		console.error('No AWS credentials found', userInfo);
		throw new Error('No AWS credentials found');
	};

	const refreshCredentials = async () => {
		const now = Date.now();

		// Return existing promise if it's less than 5 minutes old
		if (refreshPromise && (now - refreshPromise.timestamp) < AUTH_CACHE_TIME) {
			return refreshPromise.promise;
		}

		// Create new promise for refresh operation
		const promise = (async () => {
			if (!userInfo?.refresh) {
				throw new Error('No refresh function available');
			}

			const updateInfo = await userInfo.refresh();

			if (!updateInfo?.aws) {
				throw new Error('No AWS credentials found');
			}

			const newProvider = createAmazonBedrock({
				region: 'us-east-1',
				accessKeyId: updateInfo.aws.AccessKeyId,
				secretAccessKey: updateInfo.aws.SecretAccessKey,
				sessionToken: updateInfo.aws.SessionToken,
			});

			setModelProvider(() => newProvider);
			if (aiChatCtx) {
				aiChatCtx.modelProvider = newProvider;
			}
			await sleep(1000);
			return newProvider;
		})();

		// Store promise and timestamp
		setRefreshPromise({
			promise,
			timestamp: now,
		});

		return promise;
	};

	const handlerWithRetry = async <T, R>(
		fn: (options: T) => Promise<R>,
		options: T,
	): Promise<R> => {
		const provider = await getModelProvider();
		if (!provider) {
			throw new Error('Model provider not initialized');
		}

		return fn({
			modelProvider: provider,
			...options,
		}).catch(async (err) => {
			console.error('AWS Bedrock Error', err);
			if (String(err).toLowerCase().includes('expired') && userInfo?.refresh) {
				setModelProvider(null);
				const newProvider = await refreshCredentials();
				return fn({
					modelProvider: newProvider,
					...options,
				});
			}
			throw err;
		});
	};

	const wrappedQualifyStory = async (options: Omit<QualifyStoryParams, 'modelProvider'>) => {
		return handlerWithRetry(qualifyStory, options);
	};

	const wrappedQualifySite = async ({
		detectResp,
		criteria,
	}: {
		detectResp: DetectFeedResponse;
		criteria: string;
	}) => {
		const encoder = new TextEncoder();
		const data = encoder.encode(criteria);
		const hashBuffer = await crypto.subtle.digest('SHA-256', data);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const criteriaHash = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
		const cacheKey = `qualify:${detectResp.url}:${criteriaHash}`;

		const cachedResult = cacheStore.getItem<QualificationScores>(cacheKey);
		if (cachedResult) {
			return cachedResult;
		}

		analytics?.trackEvent('Qualify Site', {
			url: detectResp.url,
			criteria: criteriaHash,
		});
		console.log('Qualifying site', detectResp.url, criteriaHash);
		const resp = await handlerWithRetry(qualifySite, {
			detectResp,
			criteria,
		});
		try {
			cacheStore.setItem(cacheKey, resp);
		} catch (e) {
			console.warn('Failed to cache site qualification', { cacheKey, error: e });
		}
		return resp;
	};

	const wrappedQualifyPublication = async (options: Omit<QualifyPublicationParams, 'modelProvider'>) => {
		return handlerWithRetry(qualifyPublication, options);
	};

	const sanitizeMessages = (msgs: ChatMessage[]) => {
		for (const msg of msgs) {
			console.log('Checking message', msg);
			if (msg.content && Array.isArray(msg.content)) {
				for (const content of msg.content) {
					console.log('Checking', content);
					if ('toolCallId' in content && !content.toolCallId) {
						content.toolCallId = `placeholder_${Date.now()}_${Math.random().toString(36).slice(2)}`;
						console.log('FIXED', content.toolCallId);
					}
				}
			}
		}
		return msgs;
	};

	const wrappedChat = async ({
		messages,
		onToolCall,
		onStepFinish,
		prompt,
		signal,
		ctx,
		attempt = 1,
	}: {
		messages: readonly ThreadMessage[],
		onToolCall?: (cmdName: string, cmdParams: Record<string, any>, id?: string) => void,
		onStepFinish?: (step: any) => void,
		prompt?: string | null,
		signal?: AbortSignal,
		ctx?: AIChatContext,
		attempt?: number,
	}) => {
		if (!ctx) {
			ctx = aiChatCtx;
		}

		if (!ctx) {
			console.error('AI chat context not initialized', {
				userInfo,
				searchClient,
			});
			if (attempt < 5 && !signal?.aborted) {
				await sleep(attempt * 1000);
				return wrappedChat({
					messages,
					onToolCall,
					onStepFinish,
					prompt,
					signal,
					ctx: await initAIChatCtx(),
					attempt: attempt + 1,
				});
			}
			throw new Error('Failed to initialize AI chat context');
		}

		if (signal?.aborted) {
			throw new Error('Aborted');
		}

		// Set up tool call handler to update UI
		ctx.toolContext.onToolCall = (cmdName, cmdParams, id) => {
			setToolCall({
				status: 'running',
				cmdName,
				cmdParams,
				id,
			});

			document.dispatchEvent(new CustomEvent('ai-chat-response'));
			// Call the provided onToolCall if it's set
			onToolCall?.(cmdName, cmdParams, id);
		};

		const stepFinishHandler: ChatParams['onStepFinish'] = (step) => {
			console.log('***# stepFinishHandler', step);
			if (step.toolResults) {
				setToolCall((prev) => {
					return {
						...prev,
						status: 'complete',
					};
				});
			}
			document.dispatchEvent(new CustomEvent('ai-chat-response'));
			onStepFinish?.(step);
		};

		// On the first message, search the knowledge base
		if (messages.length < 2) {
			const firstMessage = messages[0];
			const question = Array.isArray(firstMessage.content)
				? firstMessage.content.map((part) => ('text' in part ? part.text : '')).join(' ')
				: String(firstMessage.content);

			console.log('FIRST MESSAGE', question);
			const searchResults = await ctx.toolContext.searchClient.search<KnowledgeBase>({
				indexName: 'RAG',
				query: question,
				exclude_fields: 'embedding',
				query_by: ['question', 'questions', 'title', 'answer', 'embedding'],
			});
			if (searchResults.hits.length > 0) {
				// Re-rank search results based on relevance to the user's question
				const rerankedResults = rerank(question, searchResults.hits);

				// Add the most relevant results to the context
				if (rerankedResults.length > 0) {
					const systemMessage: ThreadMessage = {
						id: `system_${Date.now()}`,
						role: 'system',
						content: [{
							type: 'text',
							text: [
								'Relevant knowledge base articles:\n',
								...rerankedResults.slice(0, 3).map(
									(result: KnowledgeBase) => `Title: ${result.title}\n${result.answer}\n---`,
								),
							].join('\n'),
						}],
						createdAt: new Date(),
						metadata: {
							custom: {},
							steps: [],
							unstable_data: [],
							unstable_annotations: [],
						},
					};
					messages = [systemMessage, ...messages];
					console.log('Updated Messages', messages);
				}
				console.log('Ranked Results', rerankedResults);
			}
		}

		if (signal?.aborted) {
			console.log('***ABORTED', signal);
			throw new Error('Aborted');
		}

		const convertToCoreMessage = (msg: ThreadMessage): CoreMessage | null => {
			// Skip messages with empty content
			if (!msg.content || (Array.isArray(msg.content) && msg.content.length === 0)) {
				return null;
			}

			const text = Array.isArray(msg.content)
				? msg.content.map((part) => ('text' in part ? part.text : '')).join(' ').trim()
				: String(msg.content).trim();

			// Skip messages with empty text after processing
			if (!text) {
				return null;
			}

			const role = msg.role;
			if (!role || !['system', 'user', 'assistant'].includes(role)) {
				throw new Error(`Unknown message role: ${role}`);
			}

			switch (role) {
				case 'system':
					return {
						role: 'system' as const,
						content: text,
					};
				case 'user':
					return {
						role: 'user' as const,
						content: text,
					};
				case 'assistant':
					return {
						role: 'assistant' as const,
						content: text,
					};
				default: {
					// This case will never be reached due to the type guard above
					const exhaustiveCheck: never = role;
					throw new Error(`Unhandled message role: ${exhaustiveCheck}`);
				}
			}
		};

		if (signal?.aborted) {
			throw new Error('Aborted');
		}

		const onFinish: ChatParams['onFinish'] = (p) => {
			setFinishReason(p.finishReason);
			console.log('***# onFinish', p);
			if (p.usage) {
				console.log('***# onFinish.usage', p.usage);
				setUsage(p.usage);
			} else {
				setUsage(null);
			}
			setToolCall(null);
		};

		setFinishReason(null);
		setToolCall(null);

		try {
			return await ctx.chat({
				messages: messages.map(convertToCoreMessage).filter((msg): msg is CoreMessage => msg !== null),
				onChunk: () => {
					document.dispatchEvent(new CustomEvent('ai-chat-response'));
				},
				onStepFinish: stepFinishHandler,
				prompt,
				signal,
				onFinish,
			});
		} catch (err) {
			if (signal?.aborted) {
				throw new Error('Aborted');
			}

			console.error('AWS Bedrock Error', err);
			if (String(err).toLowerCase().includes('expired') && userInfo?.refresh) {
				await refreshCredentials();
				return ctx.chat({
					messages: messages.map(convertToCoreMessage).filter((msg): msg is CoreMessage => msg !== null),
					onStepFinish: stepFinishHandler,
					prompt,
					signal,
					onFinish,
				});
			}

			if (String(err).includes('validation errors detected')) {
				console.warn('Bedrock validation error, retrying with sanitized messages');
				return ctx.chat({
					messages: sanitizeMessages(
						messages
							.map(convertToCoreMessage)
							.filter((msg): msg is CoreMessage => msg !== null),
					),
					onStepFinish: stepFinishHandler,
					prompt,
					signal,
					onFinish,
				});
			}
			console.error('Chat Error', String(err));
			throw err;
		}
	};

	const wrappedGenerateObject = async <T = unknown>(options: {
		schema: {
			type: 'object';
			properties: Record<string, any>;
			required?: string[];
		};
		prompt: string;
	}): Promise<T> => {
		if (!aiChatCtx) {
			throw new Error('AI chat context not initialized');
		}

		const messages: ChatMessage[] = [
			{
				role: 'system',
				content: 'You are a helpful assistant that generates structured data based on user prompts. Your responses should strictly follow the provided schema.',
			},
			{
				role: 'user',
				content: options.prompt,
			},
		];

		const result = await aiChatCtx.chat({
			messages,
			prompt: `Please generate a response that matches this schema: ${JSON.stringify(options.schema)}`,
		});

		if (!result) {
			throw new Error('No response from AI');
		}

		let responseContent = '';
		for await (const chunk of result.textStream) {
			responseContent += chunk;
		}

		try {
			return JSON.parse(responseContent) as T;
		} catch (err) {
			console.error('Failed to parse AI response:', err);
			throw new Error('Failed to generate structured data');
		}
	};

	const qualifyPublicationWithAgent = async (publicationId: string, criteria: string): Promise<QualificationResult> => {
		const provider = await getModelProvider();
		if (!provider) {
			throw new Error('Model provider not initialized');
		}

		const agent = new QualificationAgent({
			modelProvider: provider,
			resources: {
				getPublication: async (id: string) => {
					const response = await api.fetchWithAuth(`resources/Publication/${id}`);
					return response.items?.[0] || null;
				},
				query: async (params) => {
					const query = new URLSearchParams();
					for (const [key, value] of Object.entries(params.ExpressionAttributeValues || {})) {
						query.set(key.slice(1), value);
					}
					query.set('limit', '10');
					const response = await api.fetchWithAuth(`resources/${params.TableName}?${query.toString()}`);
					return response.items || [];
				},
			},
		});

		agent.setCriteria(criteria);
		return agent.qualifyPublicationById(publicationId);
	};

	const setModel = (model: string) => {
		console.log('Setting model', model);
		setSelectedModel(model);
		initAIChatCtx(model);
	};

	const value: AIContextType = {
		ctx: aiChatCtx,
		modelProvider,
		qualifyStory: wrappedQualifyStory,
		qualifySite: wrappedQualifySite,
		qualifyPublication: wrappedQualifyPublication,
		chat: wrappedChat,
		generateObject: wrappedGenerateObject,
		refreshCredentials,
		qualifyPublicationWithAgent,
		setModel,
		toolCall,
		finishReason,
		usage,
	};

	return <AIContext.Provider value={value}>{children}</AIContext.Provider>;
}

export function useAI() {
	const context = useContext(AIContext);
	if (context === undefined) {
		throw new Error('useAI must be used within an AIProvider');
	}
	return context;
}
