import type { NormalizedSearchResponse } from '@newstex/search/types';
import { Story } from '@newstex/types/story';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import KPIStatsWidget, { KPIProps } from './KPIStats';

export interface SearchStatsProps {
	thisWeek: NormalizedSearchResponse<Story> | null;
	lastWeek: NormalizedSearchResponse<Story> | null;
}

// Facet Stats
const FACET_STAT_CONFIGS = [
	/*
	{
		name: 'Average Sentiment',
		icon: 'angle-brackets-1',
		id: 'stats.sentiment_score',
	},
	*/
	{
		name: 'Average Word Count',
		icon: 'comment',
		id: 'stats.word_count',
	},
] as const;

// Category Differences
const CATEGORY_STAT_CONFIGS = [
	{
		name: 'Breaking News',
		icon: 'trophy',
		code: 'BREAKING',
		colors: {
			up: 'success',
			down: 'danger',
		},
	},
	{
		name: 'Guest Post',
		icon: 'user',
		code: 'GUESTPOST',
		colors: {
			up: 'danger',
			down: 'success',
		},
	},
	{
		name: 'PR Hype',
		icon: 'heart',
		code: 'PRHYPE',
		colors: {
			up: 'danger',
			down: 'success',
		},
	},
	{
		name: 'Spam',
		icon: 'trash-can',
		code: 'SPAM',
		colors: {
			up: 'danger',
			down: 'success',
		},
	},
	{
		name: 'Controversial Content',
		icon: 'face-frown',
		code: 'CONTROV',
		colors: {
			up: 'danger',
			down: 'success',
		},
	},
] as const;

export function SearchStats({ thisWeek, lastWeek }: SearchStatsProps) {
	const [kpiStats, setKPIStats] = useState<KPIProps[]>([]);
	useEffect(() => {
		if (thisWeek && lastWeek) {
			console.log({
				thisWeek,
				lastWeek,
			});
			// Total Stories
			const thisWeekHits = thisWeek.found || 0;
			const lastWeekHits = lastWeek.found || 0;
			const storyDiff = thisWeekHits - lastWeekHits;
			const totalStoriesWidget: KPIProps = {
				name: 'Stories Last 7 Days',
				icon: 'newspaper',
				number: thisWeekHits.toLocaleString(),
				color: 'blue',
				indicator: storyDiff > 0 ? 'arrow-up' : 'arrow-down',
				changePct: (storyDiff / thisWeekHits),
			};
			if (storyDiff > 0) {
				totalStoriesWidget.color = 'success';
			} else {
				totalStoriesWidget.color = 'danger';
			}

			// Tickered Stories
			const tickeredStoriesThisWeek = thisWeek.facets?._tags?.values?.['Has Company Tickers'] || 0;
			const tickeredStoriesLastWeek = lastWeek.facets?._tags?.values?.['Has Company Tickers'] || 0;
			const tickeredDiff = tickeredStoriesThisWeek - tickeredStoriesLastWeek;
			const tickeredStoriesWidget: KPIProps = {
				name: 'Tickered Stories',
				icon: 'dollar-sign',
				number: tickeredStoriesThisWeek.toLocaleString(),
				color: 'blue',
				indicator: tickeredDiff > 0 ? 'arrow-up' : 'arrow-down',
				changePct: (tickeredDiff / tickeredStoriesThisWeek),
			};

			if (tickeredDiff > 0) {
				tickeredStoriesWidget.color = 'success';
			} else {
				tickeredStoriesWidget.color = 'danger';
			}

			const widgets: KPIProps[] = [
				totalStoriesWidget,
				tickeredStoriesWidget,
			];

			// Facet Stats
			for (const facetStatConfig of FACET_STAT_CONFIGS) {
				const currentStatValue = thisWeek.facets?.[facetStatConfig.id]?.stats?.avg || 0;
				const lastStatValue = lastWeek.facets?.[facetStatConfig.id]?.stats?.avg || 0;
				const facetStatWidget: KPIProps = {
					name: facetStatConfig.name,
					icon: facetStatConfig.icon,
					number: currentStatValue.toLocaleString('en-US', {
						maximumFractionDigits: 0,
					}),
					indicator: currentStatValue > lastStatValue ? 'arrow-up' : 'arrow-down',
					color: currentStatValue > lastStatValue ? 'success' : 'danger',
					changePct: (currentStatValue - lastStatValue) / lastStatValue,
				};
				widgets.push(facetStatWidget);
			}

			// Category Stats
			for (const categoryStatConfig of CATEGORY_STAT_CONFIGS) {
				const currentStatValue = thisWeek.facets?.categories?.values?.[categoryStatConfig.code] || 0;
				const currentStatPercent = currentStatValue / thisWeekHits;
				const lastStatValue = lastWeek.facets?.categories?.values?.[categoryStatConfig.code] || 0;
				const lastStatPercent = lastWeekHits ? lastStatValue / lastWeekHits : 0;
				const facetStatWidget: KPIProps = {
					name: categoryStatConfig.name,
					icon: categoryStatConfig.icon,
					number: currentStatValue.toLocaleString('en-US'),
					color: 'blue',
					indicator: currentStatValue > lastStatValue ? 'arrow-up' : 'arrow-down',
					link: `/stories?categories=${categoryStatConfig.code}&date=%7Bnow+-+7d%7D`,
					changePct: (currentStatValue - lastStatValue) / lastStatValue,
				};
				if (currentStatValue > lastStatValue) {
					facetStatWidget.color = categoryStatConfig.colors.up;
				} else {
					facetStatWidget.color = categoryStatConfig.colors.down;
				}
				widgets.push(facetStatWidget);
			}

			setKPIStats(widgets);
		}
	}, [thisWeek, lastWeek]);
	return (
		<Row className="mb-3">
			{kpiStats.map((item, index) => (
				<Col sm={6} lg={3} key={index} className="mb-4">
					<KPIStatsWidget {...item} />
				</Col>
			))}
		</Row>
	);
}
