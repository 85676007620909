import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { SearchHit } from '@newstex/search';
import type { Report } from '@newstex/types/report';
import { createColumnHelper } from '@tanstack/react-table';
import { title as titleCase } from 'case';
import { useMemo, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useUserInfo } from '~/providers/user-info';

import DataTable, { DataTableMethods } from '../data-table';
import { LoadMoreButton } from '../load-more-button';
import { PropertyDisplayValue } from '../property-display-value';

interface ReportsTableProps {
	items: SearchHit<Report>[];
	searchText?: string;
	hasMore?: boolean;
	onLoadMore?: () => void;
}

export function ReportsTable({
	items,
	searchText,
	hasMore,
	onLoadMore,
}: ReportsTableProps) {
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<SearchHit<Report>>();
	const userInfo = useUserInfo();

	const canAccessReport = (report: Report) => {
		if (!report.access_groups?.length) {
			return true;
		}
		console.log(report.access_groups, userInfo?.auth_groups);

		return report.access_groups?.some((group) => userInfo?.auth_groups?.includes(group));
	};

	const columns = useMemo(() => [
		columnHelper.accessor('name', {
			header: 'Name',
			enableSorting: true,
			cell: ({ row: { original }}) => {
				if (!canAccessReport(original)) {
					return <span className="text-muted">{original.name}</span>;
				}

				return (
					<Link to={`/reports/${original.id}`}>
						{original.name}
					</Link>
				);
			},
		}),
		columnHelper.accessor('category', {
			header: 'Category',
			enableSorting: true,
			cell: ({ getValue }) => getValue() || '-',
		}),
		columnHelper.accessor('database', {
			header: 'Database',
			enableSorting: true,
			cell: ({ getValue }) => getValue() || '-',
		}),
		columnHelper.accessor('description', {
			header: 'Description',
			enableSorting: true,
			cell: ({ getValue }) => getValue() || '-',
		}),
		columnHelper.accessor('access_groups', {
			header: 'Access Groups',
			enableSorting: true,
			cell: ({ getValue }) => (
				<div className="text-center w-100">
					<PropertyDisplayValue
						propName="access_groups"
						propValue={getValue()?.map((group) => titleCase(group))}
						typeName="Report"
					/>
				</div>
			),
		}),
		columnHelper.accessor('created_at', {
			header: 'Created',
			enableSorting: true,
			cell: ({ getValue }) => (
				<PropertyDisplayValue
					propName="created_at"
					propValue={getValue()}
					typeName="Report"
				/>
			),
		}),
		columnHelper.accessor('updated_at', {
			header: 'Updated',
			enableSorting: true,
			cell: ({ getValue }) => (
				<PropertyDisplayValue
					propName="updated_at"
					propValue={getValue()}
					typeName="Report"
				/>
			),
		}),
	], []);

	return (
		<>
			<DataTable
				columns={columns}
				items={items}
				ref={dataTableRef}
				searchText={searchText}
				striped
				sortable
			/>
			<LoadMoreButton show={hasMore} onClick={onLoadMore} />
			<div className="float-end pb-2">
				<Button variant="outline-secondary" size="sm" onClick={() => {
					dataTableRef.current?.exportToCSV('Reports');
				}}>
					<FontAwesomeIcon icon={faDownload} /> CSV ({items.length})
				</Button>
			</div>
		</>
	);
}
