import type { Report as ReportType } from '@newstex/types/report';

import type { CollectionFieldSchmaForObject } from './types';

export const Report: CollectionFieldSchmaForObject<ReportType & { owner_emails?: string[] }> = [
	{
		name: 'name',
		type: 'string',
		store: true,
		sort: true,
		defaultSearchOrder: 1,
	},
	{
		name: 'category',
		type: 'string',
		store: true,
		facet: true,
		optional: true,
		defaultSearchOrder: 2,
	},
	{
		name: 'database',
		type: 'string',
		store: true,
		facet: true,
	},
	{
		name: 'description',
		type: 'string',
		store: true,
		optional: true,
		defaultSearchOrder: 3,
	},
	{
		name: 'icon',
		type: 'string',
		store: true,
		optional: true,
	},
	{
		name: 'access_groups',
		type: 'string[]',
		store: true,
		facet: true,
		optional: true,
	},
	{
		name: 'query',
		type: 'string',
		store: true,
		defaultSearchOrder: 4,
	},
	{
		name: 'refresh_interval',
		type: 'int32',
		store: true,
		optional: true,
	},
	{
		name: 'roles',
		type: 'string[]',
		store: true,
		facet: true,
		optional: true,
	},
	{
		name: 'created_by',
		type: 'string',
		store: true,
		facet: true,
		optional: true,
	},
	{
		name: 'created_at',
		type: 'int64',
		store: true,
		facet: true,
		range_index: true,
		optional: true,
	},
	{
		name: 'updated_at',
		type: 'int64',
		store: true,
		facet: true,
		range_index: true,
		optional: true,
	},
	{
		name: 'updated_by',
		type: 'string',
		store: true,
		optional: true,
	},
	/** For Authorization purposes */
	{
		name: 'owner_emails',
		type: 'string[]',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
];
