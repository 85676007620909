import { User as UserType } from '@newstex/types/user';

import type { CollectionFieldSchmaForObject } from './types';

export const User: CollectionFieldSchmaForObject<UserType> = [
	{
		name: 'name',
		type: 'string',
		store: true,
		defaultSearchOrder: 11,
	},
	{
		name: 'email',
		type: 'string',
		store: true,
		defaultSearchOrder: 2,
	},
	{
		name: 'phone',
		type: 'string',
		optional: true,
		store: true,
		defaultSearchOrder: 3,
	},
	{
		name: 'domain',
		type: 'string',
		optional: true,
		store: true,
		facet: true,
		defaultSearchOrder: 4,
	},
	{
		name: 'auth_groups',
		type: 'string[]',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'role',
		type: 'string',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'created_at',
		type: 'int64',
		optional: true,
		store: true,
		facet: true,
		sort: true,
	},
	{
		name: 'last_login',
		type: 'int64',
		optional: true,
		store: true,
		facet: true,
		sort: true,
	},
	{
		name: 'archived',
		type: 'bool',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'distributor',
		type: 'string',
		optional: true,
		store: true,
		facet: true,
	},
];
