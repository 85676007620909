import { makeAssistantToolUI } from '@assistant-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Spinner } from 'react-bootstrap';

// Tool UI Components
export const NavigateToolUI = makeAssistantToolUI<{ path: string }, string>({
	toolName: 'navigateTo',
	render: ({ args, status }) => (
		<div className="tool-ui">
			<FontAwesomeIcon icon={['fas', 'arrow-right']} className="me-2" />
			Navigating to: {args.path}
			{status.type === 'running' && <Spinner animation="border" size="sm" className="ms-2" />}
		</div>
	),
});

export const CanvasToolUI = makeAssistantToolUI<
{ content: string; sectionId?: string; title?: string },
{ content: string; toolUseId: string; sectionId?: string; title?: string }
>({
	toolName: 'useCanvas',
	render: ({ args, status }) => {
		console.log('CanvasToolUI', args, status);
		return (
			<div className="tool-ui">
				<FontAwesomeIcon icon={['fas', 'pen-to-square']} className="me-2" />
				{args.sectionId ? 'Updating section in Canvas' : 'Writing to Canvas'}
				{status.type === 'running' && <Spinner animation="border" size="sm" className="ms-2" />}
			</div>
		);
	},
});

export const GetCanvasContentUI = makeAssistantToolUI<{}, { content: string; sections: any[] }>({
	toolName: 'getCanvasContent',
	render: ({ status }) => (
		<div className="tool-ui">
			<FontAwesomeIcon icon={['fas', 'file-lines']} className="me-2" />
			Reading Canvas content
			{status.type === 'running' && <Spinner animation="border" size="sm" className="ms-2" />}
		</div>
	),
});

export const ReportDataToolUI = makeAssistantToolUI<{ reportId: string }, any>({
	toolName: 'getReportData',
	render: ({ args, status }) => (
		<div className="tool-ui">
			<FontAwesomeIcon icon={['fas', 'chart-line']} className="me-2" />
			Fetching report data: {args.reportId}
			{status.type === 'running' && <Spinner animation="border" size="sm" className="ms-2" />}
		</div>
	),
});

export const ChartToolUI = makeAssistantToolUI<
{
	type: string;
	data: { labels: string[]; datasets: any[] };
	options?: any;
},
any
>({
	toolName: 'generateChart',
	render: ({ args, status }) => (
		<div className="tool-ui">
			<FontAwesomeIcon icon={['fas', 'chart-simple']} className="me-2" />
			Generating {args.type} chart
			{status.type === 'running' && <Spinner animation="border" size="sm" className="ms-2" />}
		</div>
	),
});

export const SearchTypeSenseToolUI = makeAssistantToolUI<{ query: string }, any>({
	toolName: 'searchTypeSense',
	render: ({ args, status }) => {
		console.log('SearchTypeSenseToolUI', args, status);
		return (
			<div className="tool-ui">
				<FontAwesomeIcon icon={['fas', 'search']} className="me-2" />
				Searching TypeSense: {args.query}
				{status.type === 'running' && <Spinner animation="border" size="sm" className="ms-2" />}
			</div>
		);
	},
});

export const ToolUIs: FC = () => {
	return (
		<div className="tool-uis">
			<NavigateToolUI />
			<CanvasToolUI />
			<GetCanvasContentUI />
			<ReportDataToolUI />
			<ChartToolUI />
			<SearchTypeSenseToolUI />
		</div>
	);
};
