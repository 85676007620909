import { UserError } from '@newstex/core/errors';
import type { AccountInfoResponse } from '@newstex/types/responses/info';
/**
 * User Info (/info) Provider
 */
import { User, UserGroup } from '@newstex/types/user';
import {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useCacheStore } from '~/stores/cache-store';

import { useAPI } from './api-provider';
import { useAuth } from './auth';

declare global {
	interface Window {
		_hsq: any[];
	}
}

export interface UserInfo extends AccountInfoResponse {
	refresh: () => Promise<UserInfo | null>;
	update: (updates: Partial<User>) => Promise<void>;
	hasAuthGroup: (group: UserGroup) => boolean;
	initials?: string;
}
const UserInfoContext = createContext<UserInfo | null>(null);

export function UserInfoProvider(props: React.PropsWithChildren<{}>) {
	const [data, setData] = useState<AccountInfoResponse | null>(null);
	const [error, setError] = useState<UserError | null>(null);
	const { fetchWithAuth } = useAPI();
	const {
		user, runAs, logout, assumeUser,
	} = useAuth();
	const refreshLock = useRef<Promise<UserInfo | null> | null>(null);
	const toastId = useRef<string | number | null>(null);
	const cacheStore = useCacheStore();

	useEffect(() => {
		if (data?.theme) {
			document.body.className = `${data.theme}-mode`;
			document.body.setAttribute('data-bs-theme', data.theme);
		}
	}, [data?.theme]);

	const update = async (updates: Partial<User>) => {
		if (!data) {
			throw new Error('User info not loaded');
		}
		await fetchWithAuth('settings', {
			method: 'PATCH',
			body: JSON.stringify(updates),
		});
		setData((existing) => ({
			...existing,
			...updates,
		} as AccountInfoResponse));
		toast.success('Settings updated');
	};

	const refresh = async () => {
		// If there's already a refresh in progress, return that promise
		if (refreshLock.current) {
			return refreshLock.current;
		}

		// Create new refresh promise
		refreshLock.current = (async (): Promise<UserInfo> => {
			try {
				// Show loading toast only on manual refreshes (not initial load)
				// or when credentials have expired
				if (data || error?.message?.toLowerCase().includes('expired')) {
					if (toastId.current) {
						toast.update(toastId.current, {
							render: 'Refreshing credentials...',
							type: 'info',
							isLoading: true,
							autoClose: false,
							toastId: 'refresh-credentials',
						});
					} else {
						toastId.current = toast.loading('Refreshing credentials...', {
							toastId: 'refresh-credentials',
						});
					}
				}

				const response = await fetchWithAuth<AccountInfoResponse>('info', {
					cache: 'reload',
				});

				setError(null);
				setData(response);
				cacheStore.setItem('userInfo', response);

				if (toastId.current) {
					toast.update(toastId.current, {
						render: 'Credentials refreshed successfully',
						type: 'success',
						isLoading: false,
						autoClose: 2000,
					});
					// Ensure the toast is properly cleaned up
					setTimeout(() => {
						if (toastId.current) {
							toast.dismiss(toastId.current);
							toastId.current = null;
						}
					}, 2000);
				}

				return {
					...response,
					refresh,
					update,
					initials: response.name?.split(' ').map((n) => n[0]).join('').toUpperCase(),
					hasAuthGroup: (group: UserGroup) => response.auth_groups?.includes(group),
				};
			} catch (e: any) {
				setError(e);
				if (toastId.current) {
					toast.update(toastId.current, {
						render: `Failed to refresh credentials: ${e.message}`,
						type: 'error',
						isLoading: false,
						autoClose: 5000,
					});
					// Ensure the toast is properly cleaned up
					setTimeout(() => {
						if (toastId.current) {
							toast.dismiss(toastId.current);
							toastId.current = null;
						}
					}, 5000);
				}
				return null;
			} finally {
				refreshLock.current = null;
			}
		})();

		return refreshLock.current;
	};

	useEffect(() => {
		refresh();
	}, [user, runAs]);

	useEffect(() => {
		const scriptId = 'hs-script-loader';
		const existingScript = document.getElementById(scriptId);

		if (data && !data.auth_groups?.includes('admin')) {
			if (!existingScript) {
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.id = scriptId;
				script.async = true;
				script.defer = true;
				script.src = '//js-na1.hs-scripts.com/20613241.js';
				script.onload = () => {
					if (window._hsq) {
						window._hsq.push(['identify', {
							email: data.email,
						}]);
					}
				};
				document.body.appendChild(script);
			}
		} else if (existingScript) {
			document.body.removeChild(existingScript);
		}

		return () => {
			const script = document.getElementById(scriptId);
			if (script) {
				document.body.removeChild(script);
			}
		};
	}, [data]);

	return (
		<UserInfoContext.Provider value={data ? {
			...data,
			initials: data.name?.split(' ').map((n) => n[0]).join('').toUpperCase(),
			hasAuthGroup: (group: UserGroup) => data?.auth_groups?.includes(group),
			refresh,
			update,
		} : null}>{
				error?.message ? <Modal
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={true}
					id="user-info-error-modal"
				>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">
							{error.statusCode === 403 ? 'Access Denied' : 'Fatal Error'}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="justify-content-center pt-5 pb-5">
						{(window.location.hostname?.includes('-dev.') || window.location.hostname?.includes('-local.')) && (
							<div className="alert alert-warning mb-3">
								You are currently on the development environment. Consider switching to <a href="https://app.newstex.com">production</a>.
							</div>
						)}
						<div className="text-center">
							{error.message}<br /><br />
							Try refreshing the page. If the problem persists, contact support.
						</div>
					</Modal.Body>
					<Modal.Footer className="justify-content-between d-flex gap-2">
						{runAs && (
							<Button variant="outline-secondary" onClick={() => {
								assumeUser(null);
							}}>
								Return to User
							</Button>
						)}
						<Button variant="outline-danger" onClick={() => {
							logout();
						}}>
							Logout
						</Button>
						<Button variant="danger" onClick={() => {
							localStorage.clear();
							cacheStore.clear();
							window.location.reload();
						}}>
							Clear Cache and Reload
						</Button>
						<Button variant="secondary" onClick={() => window.location.reload()}>Refresh</Button>
						{(window.location.hostname?.includes('-dev.') || window.location.hostname?.includes('-local.')) && (
							<Button variant="success" onClick={() => {
								window.location.href = 'https://app.newstex.com';
							}}>
								Switch to Production
							</Button>
						)}
					</Modal.Footer>
				</Modal> : props.children
			}</UserInfoContext.Provider>
	);
}

export function useUserInfo() {
	const result = useContext(UserInfoContext);
	if (result === undefined) {
		throw new Error('useUserInfo must be used within a UserInfoProvider');
	}
	return result;
}
