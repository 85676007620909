import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type Delivery, STORY_TEMPLATES, type StoryTemplate } from '@newstex/types/delivery';
import type { Distributor } from '@newstex/types/distributor';
import { kebab } from 'case';
import { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Container,
	Form,
	Row,
} from 'react-bootstrap';

import { DistributorSelect } from './distributor-select';
import { ProductEditor } from './products';

export const DELIVERY_TYPES = ['SFTP', 'HTTP', 'S3'] as const;
export type DeliveryType = typeof DELIVERY_TYPES[number];

export const HTTP_METHODS = ['POST', 'PUT'] as const;
export type HttpMethod = typeof HTTP_METHODS[number];

export interface DeliveryFormProps {
	delivery: Partial<Delivery>;
	onChange: (updates: Partial<Delivery>) => void;
	distributor: Distributor | null;
	onDistributorChange: (distributor: Distributor | null) => void;
	showProductEditor?: boolean;
	error?: string | null;
	isCreate?: boolean;
}

export function DeliveryForm({
	delivery,
	onChange,
	distributor,
	onDistributorChange,
	showProductEditor = false,
	error,
	isCreate = false,
}: DeliveryFormProps) {
	const [usePrivateKey, setUsePrivateKey] = useState(!!delivery.private_key);
	const [showPassword, setShowPassword] = useState(false);
	const deliveryType = delivery.type || 'SFTP';

	// Update queue name when title changes in create mode
	const handleNameChange = (name: string) => {
		if (isCreate) {
			onChange({
				...delivery,
				name,
				queue_name: `prod-${kebab(name)}`,
			});
		} else {
			onChange({
				...delivery,
				name,
			});
		}
	};

	// Reset private key state when delivery type changes
	useEffect(() => {
		setUsePrivateKey(!!delivery.private_key);
	}, [delivery.private_key]);

	return (
		<Container fluid>
			<Row className="mb-3">
				<Col>
					<Form.Group>
						<Form.Label>Name</Form.Label>
						<Form.Control
							type="text"
							name="name"
							value={delivery.name || ''}
							onChange={(e) => handleNameChange(e.target.value)}
							required
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Form.Group>
						<Form.Label>Type</Form.Label>
						<Form.Select
							value={deliveryType}
							name="type"
							onChange={(e) => onChange({
								...delivery,
								type: e.target.value as DeliveryType,
							})}
						>
							{DELIVERY_TYPES.map((type) => (
								<option key={type} value={type}>{type}</option>
							))}
						</Form.Select>
					</Form.Group>
				</Col>
			</Row>

			{/* Common Fields */}
			<Row className="mb-3">
				<Col>
					<Form.Group>
						<Form.Label>Technical Contact</Form.Label>
						<Form.Control
							type="text"
							name="tech_contact"
							value={delivery.tech_contact || ''}
							onChange={(e) => onChange({ ...delivery, tech_contact: e.target.value })}
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group>
						<Form.Label>Distributor</Form.Label>
						<DistributorSelect
							value={distributor}
							onChange={onDistributorChange}
						/>
					</Form.Group>
				</Col>
			</Row>

			<Row className="mb-3">
				<Col>
					<Form.Group>
						<Form.Label>Queue Name</Form.Label>
						<Form.Control
							type="text"
							value={delivery.queue_name || ''}
							name="queue_name"
							onChange={(e) => onChange({ ...delivery, queue_name: e.target.value })}
							placeholder="prod-delivery-queue"
							required
							className={error && !delivery.queue_name?.trim() ? 'border-danger' : ''}
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group>
						<Form.Label>Story Template</Form.Label>
						<Form.Select
							value={delivery.story_template || 'xml'}
							name="story_template"
							onChange={(e) => onChange({
								...delivery,
								story_template: e.target.value as StoryTemplate,
							})}
						>
							{STORY_TEMPLATES.map((template) => (
								<option key={template} value={template}>{template.toUpperCase()}</option>
							))}
						</Form.Select>
					</Form.Group>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Form.Group>
						<Form.Label>Path</Form.Label>
						<Form.Control
							type="text"
							value={delivery.path || ''}
							name="path"
							onChange={(e) => onChange({ ...delivery, path: e.target.value })}
						/>
						<Form.Text className="text-muted">
								Optional. You can use date placeholders: <code>%YYYY%</code>, <code>%MM%</code>, <code>%DD%</code>
						</Form.Text>
					</Form.Group>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Form.Group>
						<Form.Check
							type="checkbox"
							label="Verify Receipt"
							name="verify"
							checked={delivery.verify || false}
							onChange={(e) => onChange({ ...delivery, verify: e.target.checked })}
						/>
					</Form.Group>
				</Col>
				{isCreate && (
					<Col>
						<Form.Group>
							<Form.Check
								type="checkbox"
								label="Start in Standby Mode"
								name="status"
								checked={delivery.status === 'Standby'}
								onChange={(e) => onChange({
									...delivery,
									status: e.target.checked ? 'Standby' : 'Active',
								})}
							/>
						</Form.Group>
					</Col>
				)}
			</Row>

			{/* SFTP & HTTP Fields */}
			{(deliveryType === 'SFTP' || deliveryType === 'HTTP') && (
				<>
					<Row className="mb-3">
						<Col>
							<Form.Group>
								<Form.Label>Username</Form.Label>
								<Form.Control
									type="text"
									name="username"
									value={delivery.username || ''}
									onChange={(e) => onChange({ ...delivery, username: e.target.value })}
									required
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label className="d-flex justify-content-between align-items-center">
									{usePrivateKey ? 'Private Key' : 'Password'}
									<Button
										size="sm"
										variant={usePrivateKey ? 'secondary' : 'outline-secondary'}
										name="use_private_key"
										onClick={() => {
											const useKey = !usePrivateKey;
											setUsePrivateKey(useKey);
											if (useKey) {
												onChange({
													...delivery,
													private_key: 'SSM:SFTP_SECRET_KEY',
													password: undefined,
												});
											} else {
												onChange({
													...delivery,
													private_key: undefined,
													password: '',
												});
											}
										}}
									>
										{usePrivateKey ? 'Use Password' : 'Use Private Key'}
									</Button>
								</Form.Label>
								{!usePrivateKey ? (
									<div className="position-relative">
										<Form.Control
											type={showPassword ? 'text' : 'password'}
											name="password"
											value={delivery.password || ''}
											onChange={(e) => onChange({ ...delivery, password: e.target.value })}
										/>
										<Button
											size="sm"
											variant="link"
											className="position-absolute top-50 end-0 translate-middle-y"
											style={{ padding: '0.25rem 0.5rem' }}
											onClick={() => setShowPassword(!showPassword)}
										>
											<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
										</Button>
									</div>
								) : (
									<Form.Control
										type="text"
										name="private_key"
										value={delivery.private_key || 'SSM:SFTP_SECRET_KEY'}
										onChange={(e) => onChange({ ...delivery, private_key: e.target.value })}
										placeholder="SSM:SFTP_SECRET_KEY"
									/>
								)}
							</Form.Group>
						</Col>
					</Row>
				</>
			)}

			{/* SFTP-specific Fields */}
			{deliveryType === 'SFTP' && (
				<>
					<Row className="mb-3">
						<Col>
							<Form.Group>
								<Form.Label>Host</Form.Label>
								<Form.Control
									type="text"
									name="host"
									value={delivery.host || ''}
									className={error && !delivery.host?.trim() ? 'border-danger' : ''}
									onChange={(e) => onChange({ ...delivery, host: e.target.value })}
									required
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>Port</Form.Label>
								<Form.Control
									type="number"
									name="port"
									value={delivery.port || '22'}
									onChange={(e) => onChange({ ...delivery, port: parseInt(e.target.value, 10) })}
									placeholder="22"
								/>
							</Form.Group>
						</Col>
					</Row>
				</>
			)}

			{/* HTTP-specific Fields */}
			{deliveryType === 'HTTP' && (
				<>
					<Row className="mb-3">
						<Col>
							<Form.Group>
								<Form.Label>URL</Form.Label>
								<Form.Control
									type="url"
									name="url"
									value={delivery.url || ''}
									onChange={(e) => onChange({ ...delivery, url: e.target.value })}
									required
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>HTTP Method</Form.Label>
								<Form.Select
									name="method"
									value={delivery.method || 'POST'}
									onChange={(e) => onChange({ ...delivery, method: e.target.value as HttpMethod })}
								>
									{HTTP_METHODS.map((method) => (
										<option key={method} value={method}>{method}</option>
									))}
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col>
							<Form.Group>
								<Form.Check
									type="checkbox"
									name="encode"
									label="Base64 Encode Content"
									checked={delivery.encode || false}
									onChange={(e) => onChange({ ...delivery, encode: e.target.checked })}
								/>
							</Form.Group>
						</Col>
					</Row>
				</>
			)}

			{/* S3-specific Fields */}
			{deliveryType === 'S3' && (
				<>
					<Row className="mb-3">
						<Col>
							<Form.Group>
								<Form.Label>Bucket Name</Form.Label>
								<Form.Control
									type="text"
									name="bucket_name"
									value={delivery.bucket_name || ''}
									onChange={(e) => onChange({ ...delivery, bucket_name: e.target.value })}
									required
									placeholder="bucket-name or https://s3.custom-endpoint.com/bucket-name"
								/>
								<Form.Text className="text-muted">
										For S3-compatible storage, use full URL and provide credentials below
								</Form.Text>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>User ID/Email</Form.Label>
								<Form.Control
									type="text"
									name="user_id"
									value={delivery.user_id || delivery.user_email || ''}
									onChange={(e) => onChange({
										...delivery,
										user_id: e.target.value,
										user_email: e.target.value.includes('@') ? e.target.value : undefined,
									})}
									required
								/>
							</Form.Group>
						</Col>
					</Row>
					{delivery.bucket_name?.startsWith('https://') && (
						<Row className="mb-3">
							<Col>
								<Form.Group>
									<Form.Label>Access Key ID</Form.Label>
									<Form.Control
										type="text"
										name="access_key_id"
										value={delivery.username || ''}
										onChange={(e) => onChange({ ...delivery, username: e.target.value })}
										required
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label>Secret Access Key</Form.Label>
									<div className="position-relative">
										<Form.Control
											type={showPassword ? 'text' : 'password'}
											name="secret_access_key"
											value={delivery.password || ''}
											onChange={(e) => onChange({ ...delivery, password: e.target.value })}
											required
										/>
										<Button
											size="sm"
											variant="link"
											className="position-absolute top-50 end-0 translate-middle-y"
											style={{ padding: '0.25rem 0.5rem' }}
											onClick={() => setShowPassword(!showPassword)}
										>
											<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
										</Button>
									</div>
								</Form.Group>
							</Col>
						</Row>
					)}
				</>
			)}

			{/* Product Editor (only shown in edit mode) */}
			{showProductEditor && (
				<Row className="mb-3">
					<ProductEditor
						products={delivery.products}
						onChange={(products) => onChange({
							...delivery,
							products: products.map((p) => p.$id),
						})}
					/>
				</Row>
			)}
		</Container>
	);
}
