import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Content } from '@newstex/types/content';
import type { Results } from '@newstex/types/results';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAPI } from '~/providers/api-provider';

function getLink(typeName: string, id: string) {
	if (typeName === 'Delivery') {
		return `/delivery/${id}`;
	}
	return `/${typeName.toLowerCase()}s/${id}`;
}

export const RecordLink: React.FC<{
	typeName: 'Publication' | 'Publisher' | 'Distributor' | 'Delivery';
	id?: string | Content;
	obj?: Content;
}> = (props) => {
	if (typeof props.id === 'object' && props.id.$id) {
		return <RecordLink
			id={props.id.$id}
			obj={props.id}
			typeName={props.typeName}
		/>;
	}

	const [content, setContent] = useState<Content | null | undefined>(props.obj);
	const api = useAPI();

	useEffect(() => {
		const fetchData = async () => {
			const resp = await api.fetchWithAuth<Results<Content>>(`resources/${props.typeName}/${props.id}`);
			setContent(resp.items?.[0]);
		};

		if (props.id && props.typeName && !props.obj) {
			fetchData();
		}
	}, [props.id, props.typeName]);

	if (!props.id || !props.typeName) {
		return <></>;
	}

	return (
		<Link to={getLink(props.typeName, String(props.id))}>
			{content ? (
				<>
					<span>{content.name || String(props.id)}</span>
					<span className="ms-2">{content.newstex_id && ` [${content.newstex_id}]`}</span>
				</>
			) : (
				<FontAwesomeIcon icon={faSpinner} spin />
			)}
		</Link>
	);
};
